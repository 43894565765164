.breadcrumb__container {
  @extend %slidein-from-top;
  display: none;
}

.breadcrumb {
  height: 3.1rem;
  border-bottom: 1px solid rgba(#c8c8c8, .4);
  background-color: rgba($light-gray, .45);
  pointer-events: visible;
}

.breadcrumb-navigation {
  display: flex;
  padding: 1.5rem 0 1rem 1.5rem;

  &__arrow {
    width: .35rem;
    height: .35rem;
    margin: -.25rem .6rem 0;
  }

  &__link {
    display: block;
    transition: color .3s ease;
    color: rgba(#000, .7);
    font-size: .65rem;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      color: $blue;
    }
  }
}

.breadcrumb-navigation__item {
  display: flex;
  align-items: flex-start;

  &:last-child {
    .breadcrumb-navigation__link {
      color: #000;
      pointer-events: none;
    }

    .svg-icon {
      display: none;
    }
  }
}


@include respond-to(ipad-portrait) {
  .breadcrumb__container {
    display: block;
  }
}
