.textblock {
  @extend %fadein;
  @extend %bodytext;
  @extend .gutter-bottom;
  margin-top: 1rem;
  line-height: 145%;

  &--intro {
    @extend .like-h3;
    margin-top: 2rem;
    line-height: 120%;
  }

  &--career-dropdowm {
    margin-top: 0;
  }
}

@include respond-to('ipad-portrait') {
  .textblock {
    margin: 3.6rem 0 1.55rem;
    padding-left: 3.5rem;
    line-height: 145%;

    &--intro {
      width: 100%;
      padding-left: 3.3rem;
      line-height: 120%;
    }

    &--two-columns {
      padding-right: 1rem;
      padding-left: 1rem;
      column-gap: 2rem;
      column-count: 2;
      -webkit-perspective: 1;
    }

    &--two-columns-only {
      margin-top: 1.6rem;
    }

    &--career-dropdowm {
      margin-top: 0;
      padding: 0;
    }
  }
}
