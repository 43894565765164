.threesixty-slider {
  width: 100%;
  margin: 19.5px 0;
  background-color: transparent;
  -webkit-appearance: none;
}

.threesixty-slider:focus {
  outline: none;
}

.threesixty-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: .8rem;
  border-radius: .4rem;
  background: #eae7ea;
  cursor: pointer;
}

.threesixty-slider::-webkit-slider-thumb {
  width: 80px;
  height: 55px;
  margin-top: -19.5px;
  border-radius: 4px;
  background: $blue url('//svg/360-white.svg') center no-repeat;
  background-size: 50%;
  cursor: pointer;
  -webkit-appearance: none;
}

.threesixty-slider:focus::-webkit-slider-runnable-track {
  background: #eae7ea;
}

.threesixty-slider::-moz-range-track {
  width: 100%;
  height: .8rem;
  border-radius: 8px;
  background: #eae7ea;
  cursor: pointer;
}

.threesixty-slider::-moz-range-thumb {
  width: 80px;
  height: 55px;
  border-radius: 4px;
  background: $blue;
  cursor: pointer;
}

.threesixty-slider::-ms-track {
  width: 100%;
  height: .8rem;
  border-color: transparent;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

.threesixty-slider::-ms-fill-lower {
  border-radius: 50%;
  background: #eae7ea;
}

.threesixty-slider::-ms-fill-upper {
  border-radius: 50%;
  background: #eae7ea;
}

.threesixty-slider::-ms-thumb {
  width: 80px;
  height: 55px;
  border-radius: 4px;
  background: $blue;
  cursor: pointer;
}

.threesixty-slider:focus::-ms-fill-lower {
  background: #eae7ea;
}

.threesixty-slider:focus::-ms-fill-upper {
  background: #eae7ea;
}
