.jump-navigation {
  display: flex;
  justify-content: center;
  margin: 2rem 0 1rem;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1.25rem;
    text-decoration: none;
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    margin-bottom: .5rem;
    opacity: .8;
  }

  &__label {
    color: #000;
    font-size: .75rem;
    text-transform: uppercase;
  }

  &__arrow {
    width: .75rem;
    height: .75rem;
    margin-top: .5rem;
    transform: rotate(90deg);

    path {
      stroke-width: .5rem;
      stroke: #000;
    }
  }
}

.jump-navigation__item:hover {
  .jump-navigation__icon,
  .jump-navigation__arrow {
    @extend .svg-icon--blue;
    opacity: 1;
  }
}
