.searchmask {
  display: block;
  position: relative;
  width: 25rem;
  margin: 0 auto;

  &__clear {
    position: absolute;
    top: 50%;
    left: .9rem;
    width: 1rem;
    height: 1rem;
    transform: translateY(-.5rem);
    transition: opacity .2s ease;
    opacity: .3;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &__search-icon {
    position: absolute;
    top: 50%;
    right: .9rem;
    width: 1rem;
    height: 1rem;
    padding: 0;
    transform: translateY(-.5rem);
    border: 0;
    background: transparent;
  }

  &__input.input {
    width: 100%;
    padding: 0 3rem 0 2.8rem;
  }
}
