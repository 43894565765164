$tc-light-blue: #e4edf3 !default;
$tc-blue: #dae1e8 !default;
@import 'chosen-dimensions';
@import 'reporting-price-offer';
@import 'blueprint';
trendline-configurator-section {
  overflow: hidden;

  &.wrap.full-height {
    align-items: flex-start;
  }
}


trendline-configurator .section {
  h6.with-decor {
    margin-bottom: .6rem;

    &--topspace {
      margin-top: 2rem;
    }

    + p {
      margin-bottom: 2rem;
    }

    &--button {
      margin-bottom: 3rem;
    }
  }

  .align-center {
    margin: 0 auto;
  }
}

trendline-configurator {
  .horizontal-navigation__container {
    min-height: 3rem;
  }

  .horizontal-navigation--fixed {
    display: none;
    position: fixed;
    z-index: 4;
    top: 3rem;
    right: 0;
    left: 0;
    background-color: $white;
  }

  p {
    font-size: .85rem;
  }
}

.tc-reset-button {
  &__container {
    margin: 0 0 2.5rem;
    text-align: center;
  }

  color: $black;
  font-size: .85rem;
  text-decoration: underline;
}

.popup-infobox {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 80vw;
  padding: 2.5rem 3.5rem;
  overflow: hidden;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  box-shadow: 1px 1px 22px rgba(0, 0, 0, .5);
  font-size: .85rem;

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.65rem;
    background-color: $blue;
    line-height: 0;

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      margin: .7rem;
    }
  }

  &__info-icon {
    position: absolute;
    top: -.2rem;
    left: -1.5rem;
    width: 1rem;

    svg {
      height: 1.5rem;
    }
  }

  &__content-container {
    position: relative;
  }

  &__content {
    h3 {
      margin-bottom: 1rem;
    }
  }

  ol {
    margin: 1rem 0 0;
    padding-left: 1rem;
    list-style: decimal;

    li {
      margin-bottom: 1rem;
    }
  }
}

@include respond-to(ipad-portrait) {
  trendline-configurator {
    .align-center {
      width: 60%;
    }
  }


  .popup-infobox {
    width: 60vw;
  }
}


@include respond-to(ipad-landscape) {
  trendline-configurator {
    .horizontal-navigation--fixed {
      display: flex;
      top: 5.8rem;
    }
  }
}


@include respond-to(ipad-pro) {
  trendline-configurator-section.wrap.full-height {
    align-items: stretch;
  }

  trendline-configurator {
    .horizontal-navigation--fixed {
      top: 6rem;
    }
  }

  .popup-infobox {
    width: 50vw;
  }
}

@include respond-to(desktop-small) {
  trendline-configurator {
    .horizontal-navigation--fixed {
      top: 5.6rem;
    }
  }

  .popup-infobox {
    width: 30vw;
  }
}
