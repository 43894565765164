.teaser__container {
  .inner-wrap {
    width: inherit;
  }

  &--no-slider-wrap {
    .teaser__icon {
      top: .5rem;
      right: .5rem;
      left: inherit;
    }
  }

  &--single-item {
    .inner-slider__button {
      top: 40%;
    }
  }
}

.teaser__container--big {
  .teaser-infobox {
    display: none;
    position: absolute;
    z-index: 2;
    top: 1.7rem;
    left: 8.7%;
    pointer-events: none;
  }
}


@include respond-to(ipad-portrait) {
  .teaser__container {
    .inner-wrap {
      width: 87.5%;

      &--full {
        width: 100%;
      }
    }

    &.teaser__container--big,
    &.teaser__container--large,
    &.teaser__container--full {
      .inner-wrap--full {
        width: 87.5%;
      }
    }
  }

  .teaser__container--big {
    .teaser-infobox {
      display: flex;
    }
  }
}
