.hr-main-slider__slide {
  @extend %fadein;
  height: 53rem;
  background-position: bottom center;
  background-size: cover;
  cursor: ew-resize;
}

.hr-main-slider {
  @include slider-navigation();
  display: block;
  background-color: $light-gray;
}

.hr-main-slider__slide-wrap {
  overflow: hidden;
}

.hr-logo {
  z-index: 4;
  height: 2rem;
  margin-left: auto;

  img {
    width: auto;
    height: 1.75rem;
  }
}

.hr-main-slider__overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .wrap,
  [class*="col-"],
  .inner-wrap {
    height: 100%;
  }

  &::after {
    display: none;
  }

  &--inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
  }

  &--left {
    margin-top: 1.8rem;
  }

  &--left,
  &--right {
    width: 100%;
  }
}

.hr-main-slider__content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;

  p {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: right;
    color: #fff;
  }

  .svg-icon {
    width: 7.56rem;
    height: 6.875rem;
    margin-bottom: 3.2rem;

    svg {
      width: 7.56rem;
      height: 6.875rem;
    }
  }
}

.hr-main-slider__pagination {
  display: none;
  position: relative;
  height: .25rem;
  max-width: fit-content;
  margin-top: auto;

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 50%;
    width: 100%;
    height: 1px;
    background: #fff;
  }

  .swiper-pagination-bullet {
    display: table-cell;
    width: 2.5rem;
    height: .25rem;
    border-radius: 0;
    background: transparent;
  }

  .swiper-pagination-bullet-active {
    background: $blue;
  }
}

.hr-main-slider__box {
  position: relative;
  width: 100%;
  margin: 0 0 2rem 0;
  background-color: #fff;
  pointer-events: visible;
  box-shadow: 9px 11px 38px -17px rgba(0, 0, 0, .5);

  h3 {
    @extend %bold;
    max-width: 14.15rem;
    padding-bottom: .55rem;
    color: $blue;
    font-size: 2.25rem;
    line-height: .84;
  }

  p {
    max-width: 12.7rem;
    padding-bottom: 1rem;
    color: $blue;
    font-size: 1.5rem;
    line-height: 1;
  }

  &--top {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 3.2rem;
  }

  &.green {
    background-color: #0fd774;

    .audio-player__container {
      background-color: #57D798;
      .audio-player__title {
        background-color: #57D798;
      }

      &:hover {
        background-color: #73D7A5;
        .audio-player__title {
          background-color: #73D7A5;
        }
      }
    }
  }

  &.lightblue {
    background-color: #0fd7cf;

    .audio-player__container {
      background-color: #85D7D4;
      .audio-player__title {
        background-color: #85D7D4;
      }

      &:hover {
        background-color: #97D7D4;
        .audio-player__title {
          background-color: #97D7D4;
        }
      }
    }
  }

  &.yellow {
    background-color: #d7b50f;

    .audio-player__container {
      background-color: #D7C157;
      .audio-player__title {
        background-color: #D7C157;
      }

      &:hover {
        background-color: #D7C673;
        .audio-player__title {
          background-color: #D7C673;
        }
      }
    }
  }

  &.brown {
    background-color: #ab9778;

    .audio-player__container {
      background-color: #B3A38B;
      .audio-player__title {
        background-color: #B3A38B;
      }

      &:hover {
        background-color: #B9AC98;
        .audio-player__title {
          background-color: #B9AC98;
        }
      }
    }
  }

  &.red {
    background-color: #cc4747;

    .audio-player__container {
      background-color: #ce6363;
      .audio-player__title {
        background-color: #ce6363;
      }

      &:hover {
        background-color: #cf7777;
        .audio-player__title {
          background-color: #cf7777;
        }
      }
    }
  }

  &.orange {
    background-color: #f26946;

    .audio-player__container {
      background-color: #EC7F63;
      .audio-player__title {
        background-color: #EC7F63;
      }

      &:hover {
        background-color: #E88E77;
        .audio-player__title {
          background-color: #E88E77;
        }
      }
    }
  }

  &.eosin {
    background-color: #D23F63;

    .audio-player__container {
      background-color: #d35d7a;
      .audio-player__title {
        background-color: #d35d7a;
      }

      &:hover {
        background-color: #D3728A;
        .audio-player__title {
          background-color: #D3728A;
        }
      }
    }
  }

  &--inner {
    padding: 1.5rem 2rem;
  }
}

.hr-main-slider__scroll {
  height: 100%;
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 3px;
  animation: 3s cubic-bezier(0.14, 1.12, 0.67, 0.99) 0s infinite normal none running scrolldown;
  -ms-animation: 3s cubic-bezier(0.14, 1.12, 0.67, 0.99) 0s infinite normal none scrolldown;

  &--container {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    height: 30px;
    width: 2px;
    border-radius: 3px;
    transform: translateX(-50%);
    overflow: hidden;
  }
}

@-webkit-keyframes scrolldown {
  0% {
    transform: translateY(-200%);
  }
  50% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes scrolldown {
  0% {
    transform: translateY(-200%);
  }
  50% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

@include respond-to(mobile-landscape) {
  .hr-main-slider__scroll {
    &--container {
      height: 50px;
    }
  }
}

@include respond-to(ipad-portrait) {
  .hr-main-slider {

    &__slide {
      height: auto;
      padding-top: 80%;
    }

    &__pagination {
      display: block;
    }

    &__box {
      position: relative;
      margin: 0 2rem 0 0;

      &--inner {
        padding: 2.4rem 2.25rem 2.15rem 2.3rem;
      }

      &--top {
        margin-bottom: 6.65rem;
      }

      h3 {
        padding-bottom: .9rem;
        font-size: 3rem;
      }

      p {
        padding-bottom: 1.65rem;
        font-size: 1.5rem;
      }
    }

    &__overlay {

      &--right,
      &--left {
        margin-top: 4.5rem;
      }

      &--right {
        width: auto;
      }

      &--left {
        width: 26rem;
      }
    }

    &__content {
      height: 32.45rem;

      .svg-icon {
        width: 11.45rem;
        height: 10.45rem;
        margin-bottom: 6.95rem;

        svg {
          width: 11.45rem;
          height: 10.45rem;
        }

        p {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }
    }
  }

  .hr-main-slider__overlay--left {
    width: calc(50% - 15px);
  }

  .hr-logo {
    img {
      height: 2rem;
    }
  }
}

@include respond-to(ipad-landscape) {
  .hr-main-slider {
    &__slide {
      padding-top: 54.5%;
    }

    &__box--top {
      margin-bottom: 3rem;

      .hr-logo {
        height: 1.7rem;

        img {
          height: 1.7rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1020px) {
  .hr-main-slider__overlay--left {
    width: calc(33.33333333% - 15px);
  }
}

@include respond-to(ipad-pro) {
  .hr-main-slider {
    &__box--top {
      margin-bottom: 6.65rem;

      .hr-logo {
        height: 2rem;

        img {
          height: 2rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1420px) {
  .hr-main-slider__overlay--left {
    width: calc(33.33333333% - 30px);
  }
}

@include respond-to(desktop-medium) {
  .hr-main-slider {
    &__slide {
      padding-top: 47.657%;
    }
  }
}
