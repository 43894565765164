// Colors
$blue: #005093;
$light-blue: rgba($blue, .08);
$lighter-blue: #e1eaf3;
$lightest-blue: #f5f8fb;
$mid-blue: #d3e0ec;
$white: #fff;
$black: #000;
$gray: #aaa;
$medium-gray: #3b3b3b;
$dark-gray: #636363;
$light-gray: #f5f4f5;
$contrast-gray: #eaeaea;
$divider-color: #c8c8c8;
$border-color: rgba(#979797, .4);
$error: #7a1515;

$grid-columns: 12;
$basic-print-sheet: true;
$reset: true;

$breakpoint: (
  base: 300px,
  mobile: 350px,
  mobile-landscape: 540px,
  ipad-portrait: 767px,
  ipad-landscape: 1020px,
  ipad-pro: 1190px,
  desktop-smallest: 1350px,
  desktop-small: 1420px,
  desktop-medium: 1580px,
  desktop-large: 1700px,
  full: 1890px
);

$no-mobile-breakpoint: (
  ipad-portrait,
  ipad-landscape,
  ipad-pro,
  desktop-smallest,
  desktop-small,
  desktop-medium,
  desktop-large,
  full
);

$wrap: (
  base: 100vw,
  mobile: 90vw,
  mobile-landscape: 85vw,
  ipad-portrait: 90vw,
  ipad-landscape: 95vw,
  ipad-pro: 95vw,
  desktop-smallest: 93vw,
  desktop-small: 93vw,
  desktop-medium: 1540px,
  desktop-large: 1650px,
  full: 1780px
);

$gutter: (
  base: 0,
  mobile: 6px,
  mobile-landscape: 8px,
  ipad-portrait: 10px,
  ipad-landscape: 10px,
  ipad-pro: 10px,
  desktop-smallest: 10px,
  desktop-small: 10px,
  desktop-medium: 10px,
  desktop-large: 10px,
  full: 10px
);

$rem: (
  full: 20px,
  desktop-large: 20px,
  desktop-medium: 18px,
  desktop-small: 18px,
  desktop-smallest: 14px,
  ipad-pro: 14px,
  ipad-landscape: 14px,
  ipad-portrait: 14px,
  mobile-landscape: 18px,
  mobile: 16px,
  base: 16px
);

$svgcolors: (
  "black": #000,
  "white": #fff,
  "blue": $blue,
  "red": $error,
  "grey": $medium-gray
);
