.checkbox,
.radio {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
  background-color: $light-gray;

  &--image {
    background-color: #e6e7e6;
  }

  &__label-text {
    p {
      display: inline;
    }
  }

  &--error {
    border: 1px solid $error;

    + .checkbox__label-text {
      color: $error;
    }
  }

  &__container {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
  }

  &__label-text {
    width: calc(80% - 1.5rem);
    margin-top: .75rem;

    a {
      color: inherit;
    }
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
    opacity: 0;

    &:checked ~ i {
      opacity: 1;
    }
  }
}

.checkbox__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  opacity: 0;

  svg {
    width: 1.35rem;
    height: 1rem;
    transform: rotate(-10deg);
    fill: $blue;
  }
}

.radio {
  &__icon {
    width: 1rem;
    height: 1rem;
    opacity: 0;
    background-color: $blue;
  }

  &__container {
    display: inline-flex;
    position: relative;
    margin-right: 1.9rem;
  }
}


@include respond-to(desktop-smallest) {
  .checkbox,
  .radio {
    &__label-text {
      width: 80%;
    }
  }
}
