swiper > .swiper.s-wrapper .swiper-wrapper .swiper-slide.border-box {
  height: auto;
  margin-bottom: 1rem;
}

.border-box {
  @extend %fadein;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  &::before,
  &::after {
    content: '\A';
    display: block;
    position: relative;
    z-index: 2;
    flex-shrink: 0;
    width: 3.8rem;
    border: 1px solid rgba(#979797, .9);
  }

  &::before {
    border-right: 0;
  }

  &::after {
    border-left: 0;
  }

  &--counter {
    margin: 2rem auto 0;
  }

  &--blue {
    &::before,
    &::after {
      border-color: #4C85B4;
    }

    h3,
    h4,
    .current,
    .total {
      color: $blue;
    }

    .inner-slider__arrow {
      .svg-icon {
        @extend %svg-icon--blue;
      }
    }
  }
}

.border-box__inner {
  margin: 0 -1.7rem;
  padding: 1.9rem 0 1rem;
  overflow: hidden;
  flex-grow: 1;

  h3 {
    padding-bottom: 1.3rem;
    color: #000;
    font-size: 1.35rem;
    line-height: 130%;
  }

  p {
    padding-bottom: 1rem;
    color: rgba(#000, .7);
    font-size: 1.1rem;
    line-height: 130%;
  }

  &--counter {
    justify-content: center;
    padding-right: 0;
    text-align: center;
  }
}

.border-box__project-data {
  @extend %flex-helper;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 3.1rem .3rem;
  overflow: hidden;

  h3 {
    margin-bottom: 1rem;
  }

  .data {
    font-size: .85rem;

    td {
      padding: .25rem 0;
    }

    td:first-child {
      padding-right: 1rem;
    }
  }
}

.border-box__column {
  flex-basis: 50%;

  &--full {
    flex-basis: inherit;
  }
}

.border-box__counter {
  font-size: 4rem;
  line-height: 80%;
}

.border-box__counter-label {
  margin-bottom: 1rem;
}

.border-box__numbers {
  display: flex;
  justify-content: space-around;
  width: calc(100% - 4rem);
  margin: 0 -2rem;
  padding: 2.5rem 0;
}

.border-box__number {
  text-align: center;

  h3 {
    font-size: 6rem;
    line-height: 100%;
  }

  h4 {
    font-size: 1.3rem;
    text-transform: uppercase;
  }
}

.border-box__container {
  .wrap {
    align-items: inherit;
  }
}

.border-box__no-slider-wrap {
  margin-top: -20px;

  [class^=col-] {
    margin-top: 20px;
  }
}

@include respond-to('ipad-portrait') {
  .border-box {
    &__inner--counter {
      margin-right: 0;
      padding-right: 0;
    }

    &__numbers {
      width: calc(100% - 4.6rem);
      margin: 0;
      padding: 1.8rem 0 1.5rem;
    }

    &--counter {
      margin: 0;
    }
  }

  .border-box__container--numbers {
    @extend .inner-wrap--hr;
    .border-box {
      &::before,
      &::after {
        width: 2.3rem;
      }
    }

    .inner-slider__navigation {
      right: calc(6% + 10px);
      transform: translateX(0) translateY(-100%);
    }
  }

  .border-box__number {
    h3 {
      font-size: 4rem;
    }

    h4 {
      font-size: 1.2rem;
    }
  }
}

@include respond-to(ipad-landscape) {
  .border-box__inner {
    padding: 3rem 0 2.7rem;

    h3 {
      font-size: 1.4rem;
      line-height: 100%;
    }
  }
}

@include respond-to(desktop-small) {
  .border-box {
    &::before,
    &::after {
      width: 3rem;
    }

    &__inner {
      margin: 0 2rem 0 -.3rem;
      padding: 2.5rem 0 2.1rem;

      h3 {
        font-size: 1.5rem;
        line-height: 110%;
      }
    }


    &__inner--counter {
      margin-right: 0;
      padding-right: 0;
    }

    &__numbers {
      width: calc(100% - 5.6rem);
      margin: 0;
      padding: 2.2rem 0 2.1rem;
    }
  }

  .border-box__container--numbers {
    .border-box {
      &::before,
      &::after {
        width: 2.8rem;
      }

      &__number h3 {
        font-size: 5.5rem;
      }
    }
  }
}
