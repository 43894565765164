.tc-reporting-price-offer {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin: 1.5rem 0 0;

  &__price,
  &__listed-options {
    padding: 1.6rem 1.4rem;
  }

  &__price {
    position: relative;
    width: 100%;
    background-color: $tc-light-blue;

    &::before {
      content: '';
      display: none;
      position: absolute;
      top: 6rem;
      right: -1.5rem;
      width: 0;
      height: 0;
      transform: rotate(360deg);
      border-width: 1.5rem 0 1.5rem 1.5rem;
      border-style: solid;
      border-color: transparent transparent transparent $tc-light-blue;
    }

    h3 {
      margin-bottom: 1.5rem;
    }
  }

  &__price-summary p {
    font-weight: bold;

    &:first-child {
      margin-bottom: .6rem;
      font-weight: normal;
    }
  }

  &__overall-price {
    display: inline-block;
    position: relative;
    margin: 1.5rem -1.5rem 1.3rem;
    padding: 1.2rem 1.5rem;
    overflow: hidden;
    font-size: 2rem;

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      height: 1px;
      background: linear-gradient(to left, rgba(#c8c8c8, 0%), rgba(#c8c8c8, .8), rgba(#c8c8c8, 0%));
    }
  }
}

.tc-reporting-price-offer__listed-options {
  width: 100%;
  background-color: $tc-blue;

  ul {
    margin-top: 1.4rem;
  }

  li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1.1rem 1.2rem 1.2rem;
    border-bottom: 1px solid $border-color;

    span:first-child {
      max-width: 70%;
    }

    &.is-active {
      background-color: $tc-light-blue;
    }

    &:first-child {
      border-top: 1px solid $border-color;
    }

    small {
      padding-left: 1.1rem;
    }
  }
}

@include respond-to(desktop-smallest) {
  .tc-reporting-price-offer {
    &__price,
    &__listed-options {
      padding: 2.6rem 3rem;
    }
  }

  .tc-reporting-price-offer__price {
    width: 40%;

    &::before {
      display: block;
    }
  }

  .tc-reporting-price-offer__listed-options {
    width: 60%;
  }
}
