.border-button {
  display: inline-flex;
  align-items: center;
  height: 3.2rem;
  padding: 0 1.2rem 0 1.5rem;
  transition: color .3s ease, background-color .3s ease, opacity .3s ease;
  border: 1px solid $blue;
  background-color: rgba($blue, .05);
  color: $blue;
  cursor: pointer;

  &__label {
    margin-top: .2rem;
    font-size: .75rem;
    text-transform: uppercase;
  }

  &__icon {
    width: 1rem;
    height: 1rem;
    margin-right: .5rem;
  }

  &--inactive {
    opacity: .4;
    pointer-events: none;
  }

  &:hover {
    background-color: rgba($blue, .15);
  }
}
