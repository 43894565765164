swiper > .swiper.s-wrapper .swiper-wrapper teaser-contact.swiper-slide {
  /// manual fix to make slides always the same height
  height: auto;
}

.contact {
  display: flex;
  flex-direction: column;
  height: 100%;


  &__image {
    padding-top: 75.6%;
    background: $light-gray;
    background-size: cover;
  }

  &__name {
    text-transform: none;
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding: 2.5rem 2.1rem;
    background-color: $light-gray;
  }

  &__extra {
    padding: 1.25rem 2.1rem .9rem;
    background-color: #e6e5e6;
  }

  &__position {
    margin-top: .2rem;
    font-size: .85rem;
  }

  &__phone {
    display: none;
    margin-top: 1.6rem;
  }

  &__phone-button {
    margin-top: 1rem;
  }

  &__button {
    margin-top: 1rem;
  }

  &--inline {
    width: 75%;
    margin-top: 2rem;

    .contact__info {
      padding-bottom: 1rem;
    }

    .contact__name {
      margin-bottom: 0;
    }
  }
}

.contact__aside {
  margin-bottom: 4rem;
}

.contact__vcard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1px;
  font-size: 0.9rem;
  line-height: 1.35rem;
  padding: 1rem 2.1rem 0.75rem;
  background-color: $contrast-gray;
  color: initial;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }

  .vcard-container__left {
    display: flex;
    flex-direction: row;
  }

  .svg-icon--business-card svg {
    height: 1.2rem;
    width: 1.2rem;
    opacity: 0.75;
    margin-right: 0.65rem;
  }

  svg {
    height: 1rem;
    width: 1rem;
  }
}

@include respond-to(ipad-portrait) {
  .contact__aside {
    margin: 0;
    padding-right: 2.6rem;
  }

  .contact__phone {
    display: block;
  }

  .contact__phone-button {
    display: none;
  }
}
