.teaser__image {
  position: relative;
  transition: filter .3s ease;
  background: no-repeat center;
  background-size: cover;


  .play-button {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 4.5rem;
    height: 4.5rem;
    margin: -2.25rem -2.25rem 0 0;
  }

  &--small {
    padding-top: 88.2%;
  }

  &--flat {
    padding-top: 39.77%;
  }

  &--full {
    padding-top: 43.88%;
  }

  &--big {
    padding-top: 60%;
  }

  &--large {
    padding-top: 54.19%;
  }

  &--expansion-panel,
  &--project {
    padding-top: 46.77%;
  }
}
