.project-detail-slider {
  @include slider-navigation();
  position: relative;
  margin-bottom: 2.5rem;

  .flex-helper {
    align-items: stretch;
  }

  .inner-slider__button {
    top: 50%;
    margin-top: -1rem;
  }

  &__navigation {
    right: 50%;
    bottom: .9rem;
    transform: translateX(50%);
  }

  &__arrow {
    .svg-icon {
      width: 1rem;
      height: 1rem;
    }
  }
}

@include respond-to(ipad-portrait) {
  .project-detail-slider {
    margin-bottom: 5.5rem;

    &__navigation {
      top: 2rem;
      right: 2.75rem;
      bottom: inherit;
    }
  }

  .project-detail-slider--odd {
    .project-detail-slider__component--text {
      order: 1;
    }

    .project-detail-slider__component--image {
      order: 2;
    }
  }
}
