.footer-static-swiper {
  &__image {
    display: block;
    width: auto;
    height: 4rem;
    margin: 0 auto .9rem;
  }

  &__headline {
    text-transform: uppercase;
  }

  &__slide {
    text-align: center;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin: 1rem 0 0 -1rem;
  }

  &__pagination-bullet {
    width: 2.5rem;
    margin-left: 1rem;
    padding: 1rem 0;
    transition: background-color .3s ease-in;
    cursor: pointer;

    &::after {
      content: '\A';
      display: block;
      height: .15rem;
      background-color: $border-color;
    }

    &:hover::after,
    &--active::after {
      background-color: $blue;
    }
  }
}
