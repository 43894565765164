teaser-shop {
  display: flex;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.shop-teaser {
  display: block;
  max-width: 19.75rem;
  color: $black;
  text-decoration: none;

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__inner {
    position: relative;
    margin-top: -3.6rem;
    padding: 0 0 0 .5rem;

    h3 {
      text-transform: inherit;
    }
  }

  &__image {
    padding-top: 94.77%;
    background-size: cover;
  }

  .button {
    margin-top: 2.6rem;
  }

  &:hover {
    .button {
      @extend .button--hover;
    }
  }
}

@include respond-to(ipad-landscape) {
  teaser-shop {
    margin-bottom: 0;
  }
}
