.tool-nav__item {
  display: inline-flex;
  align-items: flex-start;
  height: .9rem;
  margin-right: 1.1rem;
  transition: color .2s ease;
  color: rgba(#000, .65);
  font-size: .9rem;
  text-decoration: none;
  text-transform: uppercase;

  &:hover,
  &--active {
    color: $blue;

    .tool-nav__badge {
      background-color: $blue;
      color: #fff;
    }
  }

  .item-label {
    height: .9rem;
  }
}

.tool-nav__icon {
  @include svg-size(.9rem);
  margin: 0 .3rem 0 0;

  &--tack {
    @include svg-size(.9rem);
    align-self: self-start;
  }
}

.tool-nav__badge {
  align-self: flex-start;
  margin: 0 0 0 .3rem;
}

@include respond-to(desktop-small) {
  .tool-nav__item {
    font-size: .65rem;
  }

  .tool-nav__icon {
    @include svg-size(.7rem);

    &--tack {
      @include svg-size(.85rem);
    }

    &--book {
      @include svg-size(.8rem);
      margin-top: -.15rem;
      margin-right: .35rem;
    }
  }

  .tool-nav__badge {
    transform: translateY(-.15rem);
  }
}
