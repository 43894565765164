body {
  background-color: #fff;
}


[hidden] {
  display: none !important;
}

.fullscreen {
  overflow: hidden;
}

section {
  position: relative;
}

.inner-wrap {
  @extend %wrap;
}

@include respond-to(ipad-portrait) {
  .inner-wrap {
    width: 87.5%;

    &--full {
      width: 100%;
    }

    &--hr {
      width: 91%;
    }
  }
}


.version-tag {
  position: fixed;
  z-index: 20;
  right: 2rem;
  bottom: 2rem;
  opacity: .1;
  background-color: #fff;
  font-size: .7rem;
  pointer-events: none;
}
