@mixin slider-navigation() {
  &__navigation {
    display: flex;
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 1.9rem;
    align-items: center;
    user-select: none;

    font-size: .75rem;

    .current {
      &::after {
        content: '/';
        display: inline-block;
        margin: 0 .2rem;
      }
    }
  }

  &__arrow {
    padding: 0 .3rem;
    transition: opacity .3s ease;
    cursor: pointer;

    .svg-icon {
      width: 1.4rem;
      height: 1.4rem;
    }

    &--inactive {
      opacity: .1;
      pointer-events: none;
    }

    &--prev .svg-icon svg {
      transform: rotate(180deg);
    }

    &:hover .svg-icon {
      @extend %svg-icon--blue;
    }
  }

  @include respond-to(ipad-portrait) {
    &__navigation {
      right: 3.75rem;
    }
  }
}
