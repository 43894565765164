@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
  $burger-height: $height !global;
  $burger-gutter: $gutter !global;

  position: relative;
  margin-top: $height + $gutter;
  margin-bottom: $height + $gutter;
  user-select: none;

  &,
  &::before,
  &::after {
    display: block;
    width: $width;
    height: $height;
    transition-property: background-color, transform;
    transition-duration: $transition-duration;
    outline: 1px solid transparent;
    background-color: $color;

    @if $border-radius != 0 {
      border-radius: $border-radius;
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    top: -($height + $gutter);
  }

  &::after {
    top: $height + $gutter;
  }
}


@mixin burger-to-cross($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
  $burger-height: $height !global;
  $burger-gutter: $gutter !global;
  background-color: transparent;

  @if ($color != auto) {
    &::before,
    &::after {
      background-color: $color;
    }
  }

  &::before {
    transform: translateY($burger-gutter + $burger-height) rotate(45deg);
  }

  &::after {
    transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
  }
}
