.main-footer__level-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 1.75rem;
  background-color: #fff;
}

.main-footer__level-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 0 3.25rem;
  background-color: #eeedee;

  h3 {
    font-size: 1.2rem;
  }

  .search {
    justify-content: center;
    padding-top: 2rem;
  }
}

.main-footer__level-3 {
  padding: 4.25rem 0 3.5rem;
  background-color: $light-gray;
}

.main-footer__level-4 {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0;
  background-color: #fff;

  .legal,
  .contact-nav,
  .social {
    padding-top: .2rem;
  }

  .legal,
  .contact-nav {
    flex-basis: 50%;
    min-width: 50%;
  }

  .social {
    flex-basis: 100%;
    min-width: 100%;
  }
}

.main-footer--grey {
  background-color: $light-gray;

  .main-footer__level-4 {
    background-color: transparent;
  }
}

.main-footer__niemann {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 3.5rem;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      margin-right: 2.15rem;

      a {
        color: #4c90d7;
        cursor: pointer;
      }

      a:hover {
        opacity: .8;
      }
    }
  }
}

@include respond-till(ipad-portrait) {
  .main-footer--alt {
    .legal,
    .contact-nav,
    .social {
      flex-basis: 33.333%;
      min-width: 33.333%;
    }
  }
}

@include respond-to(ipad-portrait) {
  .main-footer__level-4 {
    padding: 0;

    .legal,
    .contact-nav,
    .social {
      align-items: center;
      height: 3rem;
    }

    .contact-nav {
      justify-content: flex-end;
    }
  }
}

@include respond-to(ipad-landscape) {
  .main-footer__level-4 {
    justify-content: space-between;

    .legal,
    .contact-nav,
    .social {
      flex-basis: auto;
      min-width: auto;
    }

    .contact-nav {
      flex: 2;
      justify-content: center;
    }

    .social {
      justify-content: flex-end;
    }
  }
}

@include respond-to(desktop-smallest) {
  .main-footer--alt {
    .legal,
    .contact-nav,
    .social {
      height: 4rem;
    }
  }
}
