.bodytext {
  font-size: 1rem;
  line-height: 160%;

  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    transition: color .2s ease;
    color: rgba(#000, .8);

    &:hover {
      color: $blue;
    }
  }

  ol {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    list-style: decimal-leading-zero outside;
  }

  ul {
    margin-bottom: 1.5rem;
    padding-left: 1rem;
    list-style: square outside;
  }

  &__intro {
    display: block;
    margin-bottom: 2rem;
  }

  &--inline {
    padding: 0 2rem;
  }

  &--detail {
    h3 {
      margin-bottom: .2rem;
    }
  }
}

@include respond-to(ipad-portrait) {
  .bodytext--detail {
    width: 67.34%;
    padding: 0 0 0 2.7rem;
  }

  .bodytext--inline {
    padding: 0 0 0 5.2rem;
  }
}
