.bookmark-page {
  display: flex;
  position: absolute;
  top: 0;
  right: 1.5rem;
  align-items: center;
  height: 3.1rem;
  padding-top: .4rem;
  font-size: .65rem;

  &__arrow-container {
    height: 1rem;
    margin: .3rem -.25rem 0;
    padding-left: .25rem;
  }
}

.bookmark-page__arrow {
  width: .75rem;
  height: .75rem;
  margin: 0 .25rem;
  transition: opacity .2s ease;
  opacity: .7;
  cursor: pointer;

  &:hover {
    opacity: 1;

    path {
      stroke: $blue;
    }
  }

  path {
    stroke-width: .5rem;
    stroke: #000;
  }

  &--left {
    transform: rotateY(180grad);
  }

  &--inactive {
    opacity: .2;
    pointer-events: none;
  }
}

.bookmark-page__button {
  position: relative;
  margin-right: .5rem;
  padding-right: 1rem;
  color: rgba(0, 0, 0, .7);
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 1px;
    right: 0;
    width: 1px;
    height: 1.1rem;
    background-color: rgba(#979797, .53);
  }

  .svg--tack {
    width: .9rem;
    height: .9rem;
    margin-right: .1rem;
    transform: translateY(.2rem);
  }

  &--bookmarked {
    opacity: .5;
    pointer-events: none;
  }

  &:hover {
    color: $blue;
  }
}
