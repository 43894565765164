.detail-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 2rem 2.75rem 3rem;

  background-color: $light-gray;

  &__upper {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: .85rem;
    text-transform: uppercase;
  }

  &__indicator {
    font-size: .85rem;
  }

  &__headline {
    margin-bottom: .8rem;
  }

  &__bodytext {
    @extend %bodytext;
    width: 90%;
  }

  &.detail-box__with-link {
    padding: 2rem 2.75rem 2rem;

    .detail-box__title {
      text-transform: none;
      font-size: 0.75rem;
      opacity: 0.8;
    }

    .detail-box__headline {
      text-transform: none;
      font-size: 1.25rem;
    }

    p {
      font-size: 0.85rem;
      line-height: 1.3;
      opacity: 0.9;

      a {
        text-decoration: underline;
        color: inherit;
      }
    }
  }
}
