.nav-flyout {
  display: none;
  position: absolute;
  z-index: -1;
  top: 100%;
  right: 0;
  left: 0;

  transition: opacity .3s ease-in;
  border-top: 3px solid $divider-color;
  opacity: 0;
  background-color: $light-gray;
  box-shadow: rgba($black, .5) 0 2px 70px;
  pointer-events: all;

  &--opened {
    display: flex;
    opacity: 1;
  }

  > div {
    position: relative;
    z-index: 10;
  }
}

.flyout {
  display: flex;
  width: 100%;
  margin: 1.9rem 1.6rem 2.9rem;
}


@include respond-to(ipad-pro) {
  .flyout {
    margin: 2.3rem 2rem 3.2rem;
  }
}

@include respond-to(desktop-smallest) {
  .flyout {
    margin: 3rem 3.6rem 3.45rem;
  }
}

@include respond-to(desktop-small) {
  .nav-flyout {
    border-top-width: 5px;
  }
}
