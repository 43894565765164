.section--teaser-map {
  padding-top: 0!important;
  min-height: 100vh;
  max-height: 500px;
  overflow-y: scroll;

  .teaser__inner {
    margin: -3.45rem 2.75rem 0 1.75rem;
    padding: 1.25rem;

    @include respond-till(mobile-landscape) {
      margin: 0;
      max-width: initial;
    }
  }

  .teaser__icon {
    height: 1rem;
    width: 1rem;
    top: 1rem;
    right: 1rem;
  }

  .section--teaser-list .teaser {
    margin-bottom: 1rem;
  }

  @include respond-to(ipad-pro) {
    h3 {
      width: calc(100% - 1.5rem);
      font-size: 1rem;
    }
  }

  @include respond-to(desktop-small) {
    h3 {
      font-size: 0.8rem;
    }

    p {
      font-size: 0.6rem;
    }
  }
  @include respond-to(full) {
    h3 {
      font-size: 1rem;
    }
  }

  @include respond-till(ipad-landscape) {
    .inner-wrap>div {
      padding: 0;
    }
  }

}

body.overlayMode {
  overflow-y: hidden;
}

.overlay--reference-map {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -105%;
  transition: left 0.5s ease-in;
  z-index: 100;
  background: pink;

  &.overlay-active {
    left: 0;
    transition: left 0.5s ease-in;
  }
}

.filter-controls {
  min-width: 450px;
}

.icon-button__container-map {
  padding-bottom: 0;

  .icon-button {
    padding: 0;
  }

  .icon-button__label {
    font-size: 0.6rem;
  }

  @include respond-to(ipad-portrait) {
    padding-bottom: 2.5rem;
  }

  @include respond-to(desktop-smallest) {
    .svg-icon {
      width: 2rem;
      height: 2rem;
    }
  }
}

.mapview--divider {
  display: block;
  width: 1px;
  height: 50%;
  background: lightgray;
  position: relative;
  top: 25%;
}

.reference-filter__container.reference-filter-maps__container {
  width: 100%;
  justify-content: space-between;

  .reference-filter-wrap {
    margin-right: 0;
    margin-top: 1.5rem;

    @include respond-to(mobile-landscape) {
      width: 49%;
    }

    @include respond-to(desktop-smallest) {
      width: 32%;
    }
  }

  .select-dropdown__reference-filter {
    padding-right: 4rem;
    padding-left: 0.5rem;

    .ng-select-container .ng-value {
      text-indent: 0.5rem;
    }
  }

  .select-dropdown__reference-filter-smaller {
    padding-right: 2.5rem;
  }

  .select-dropdown__reference-filter-smallest {
    padding-right: 1.5rem;
  }

  @include respond-till(ipad-landscape) {
    .reference-filter-wrap {

      &.reference-filter-wrap__filter-button {
        box-shadow: none;
        .show-filtered-results {
          box-shadow: 6px 6px 6px -1px rgba(#000, .1);
        }
      }
    }
  }

  @include respond-till(ipad-landscape) {
    margin-top: 0;
  }
}

.projects-map-wrap {
  position: fixed;
  height: 100vh;
  width: 50vw;
  background-color: $light-gray;

  @include respond-till(ipad-portrait) {
    height: 90vh;
    width: 100vw;
  }
}

.teaser-infobox--reference-map-mobile {
  display: inline-block;
  position: absolute;
  top: 1.5rem;
  left: 0;
  z-index: 5;
  cursor: pointer;

  @include respond-to(ipad-portrait) {
    display: none;
  }
}

.projects-maps__google-map {
  width: 100%;
  height: 100%;
}

.intro.intro--map-overlay {
  padding: 1rem 0;

  .logo__container {
    padding: 0!important;
  }

  .close-map-label {
    cursor: pointer;
    text-decoration: none;
    color: black;

    .svg-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 1rem;
    }

    span {
      font-size: 0.9rem;
      opacity: 0.6;
    }
  }

  h1 {
    text-align: left;
    margin-top: 1rem;
  }

  @include respond-to(ipad-landscape){
    padding: 2.5rem 0 1rem;

    h1 {
      margin-top: 2.5rem;
    }
  }
}

.wrap.wrap-map-overlay {
  width: 100vw;
  margin: 0;
  padding: 0;
  background: #fff;

  @include respond-till(ipad-portrait) {
    .intro.intro--map-overlay, section {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.purpose-filter-slider {
  .icon-button__icon {
    width: 4rem;
    height: 4rem;
  }

  &__wrap {
    width: 88%;

    @include respond-to(ipad-landscape) {
      width: 90%;
    }
  }

  &__arrow {
    cursor: pointer;
    display: flex;
    align-items: center;

    .svg-icon {
      height: 1.25rem;
      width: 1.25rem;
    }

    &--prev {
      transform: rotateY(180deg);
    }
  }
}

.end-referenced-by-marker {
  position: absolute;
  right: 10px;
  top: 0;
  width: 3rem;
  height: 3rem;
  background: $blue;
  padding: 0.75rem;
  z-index: 5;
  cursor: pointer;

  @include respond-till(ipad-landscape) {
    right: 0;
  }
}

.mapview-toggle-wrap {
  cursor: pointer;
  padding: 0 0.35rem;
  padding-top: 0.4rem;

  &:hover {
    background-color: #F4FBFF;

    .mapview-toggle--label {
      display: block;
    }
  }

  .mapview-toggle--label {
    display: none;
    position: absolute;
    bottom: -43px;
    font-size: 0.75rem;
    left: 0;
    right: 0;
    background-color: #F4FBFF;
    color: #005093;
    padding: 1rem;
    z-index: 1;
    box-shadow: 6px 6px 6px -1px rgba(#000, .1);

    @include respond-to(mobile-landscape) {
      bottom: -48px;
    }
    @include respond-to(ipad-portrait) {
      bottom: -37px;
    }
    @include respond-to(desktop-smallest) {
      bottom: -51px;
    }
    @include respond-to(desktop-large) {
      bottom: -58px;
    }
  }

  .svg-icon--mapview-toggle {
    padding: 0.4rem;
    width: 2rem;
    height: 2rem;
  }

  .svg-icon--mapview {
      position: absolute;
      height: 1rem;
      width: 1rem;
      right: 0.4rem;
  }
}

.reference-filter--hide-toggle {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: right;
  background: #fff;

  .map-filter--arrow-icon {
    width: 8px;
    height: 8px;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    transform: rotate(135deg);
    display: inline-block;
    position: relative;
    top: 6px;
    left: 12px;

    &.filter--open {
      transform: rotate(135deg);
      transition: transform .35s linear;
    }
    &.filter--closed {
      transform: rotate(315deg);
      transition: transform .35s linear;
      top: 3px;
    }
  }
}

.reference-map-filters, .project-list--filters {
  max-height: 0;
  overflow: auto;
  transition: max-height 0.5s ease-in;

  hr {
    margin: 1.35rem auto 0.35rem;
  }

  &.active {
    transition: max-height 0.5s ease-out;
    max-height: 350px;

    @include respond-to(desktop-smallest) {
      max-height: 175px;
    }

    @include respond-to(desktop-small) {
      max-height: 215px;
    }
    @include respond-to(desktop-large) {
      max-height: 240px;
    }
  }
}

.reference-map-filters {
  overflow: inherit;
}

.project-list--filters {
  &.active {
    overflow: inherit;
    max-height: 610px;

    @include respond-to(mobile-landscape) {
      max-height: 425px;
    }
  }
}

.button__reset-filters {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  width: 40px;
  height: 40px;
  right: 10px!important;
  top: 10px!important;
  cursor: pointer;
  display: none;

  .gm-style & {
    display: block;
  }

  i {
    padding: 11px;
    transform: rotate(63deg);
    position: relative;
    top: 0;
    left: 1px;
    opacity: 0.6;
  }
}

.gmnoprint.gm-bundled-control {
  top: 50px!important;
}

