.contact-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__item {
    display: flex;
    margin-top: 1.2rem;
    padding: 0;
    text-decoration: none;

    &:first-child {
      margin-top: 0;
    }

    &:hover .contact-nav__icon {
      @extend %svg-icon--blue;
      opacity: 1;
    }
  }

  &__label {
    color: rgba(#000, .7);
    font-size: .9rem;
  }
}

.contact-nav__icon {
  display: none;
  transition: opacity .2s ease;

  &--phone {
    width: .85rem;
    height: .85rem;
    margin: .1rem .5rem 0 0;
  }

  &--email {
    width: 1.05rem;
    height: 1.05rem;
    margin-right: .5rem;
  }
}

@include respond-to(ipad-portrait) {
  .contact-nav {
    flex-direction: inherit;

    &__item {
      margin-top: 0;
      padding: 0 .8rem;
    }

    &__icon {
      display: block;
    }
  }
}

@include respond-to(ipad-landscape) {
  .contact-nav {
    justify-content: flex-end;
  }
}
