.pagination {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;

  &__button {
    min-width: 2.5rem;
    margin-right: .2rem;
    padding-right: 0;
    padding-left: 0;
    border: 0;
    background-color: $lighter-blue;
    color: $black;

    &:hover {
      background-color: $white;
    }
  }

  &__button--arrow {
    background-color: $light-blue;

    &:hover path {
      fill: $black;
    }

    path {
      fill: $white;
    }
  }

  &__button--arrow-left {
    transform: rotateY(180deg);
  }

  &__controls {
    display: inline-flex;
    margin-left: .8rem;

    svg {
      width: 1rem;
    }
  }

  h5 {
    min-width: 100%;
    margin-bottom: .75rem;
  }
}

@include respond-to(ipad-portrait) {
  .pagination {
    align-items: center;
    justify-content: center;

    h5 {
      min-width: auto;
      margin: 0 .5rem 0 0;
      text-transform: inherit;
    }
  }
}
