.image-comparison {
  position: relative;
  padding-top: 46.7%;
  overflow: hidden;

  &__element {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__element--overlay {
    z-index: 2;
    width: 50%;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }

  &__icon {
    position: absolute;
    top: 1.75rem;

    &--left {
      left: 1.75rem;
    }

    &--right {
      right: 1.75rem;
    }
  }

  &__slider {
    position: absolute;
    z-index: 3;
    top: 0;
  }

  &__textbox {
    position: absolute;
    z-index: 4;
    transform: translateY(0);
    transition: opacity .3s ease, transform .3s ease;
  }

  &__textbox--inactive {
    transform: translateY(1rem);
    opacity: 0;
  }
}

.image-comparison-slider {
  width: 5px;
  height: 100%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 4px 2px .8rem 0 rgba(0, 0, 0, .5);

  &__inner-handle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 3.5rem;
    height: 3.5rem;
    padding: 0 .1rem;
    transition: background-color .3s ease;
    background-color: $blue;
  }

  &__arrow {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__arrow--left svg {
    transform: rotate(180deg);
  }
}

.image-comparison-slider__handle {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  width: 7.5rem;
  height: 7.5rem;
  transform: translate(-50%, -50%);
  transition: background-color .2s ease;
  background-color: rgba($blue, .36);
  cursor: pointer;

  &:hover {
    background-color: rgba($blue, 0);

    .image-comparison-slider__inner-handle {
      background-color: #fff;
    }

    .image-comparison-slider__arrow {
      @extend .svg-icon--blue;
    }
  }
}

@include respond-to(ipad-landscape) {
  .image-comparison {
    &__textbox {
      bottom: 0;
    }

    &__textbox--right {
      right: 1.75rem;
      margin-right: 0;
    }
  }
}
