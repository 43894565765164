.hr-section-nav {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  padding-left: 3vw;
  pointer-events: auto;

  &__container {
    display: none;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;

    &--inner {
      position: relative;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }

  &--up,
  &--down {
    width: 1.55rem;
    height: 1.55rem;
    cursor: pointer;
    pointer-events: auto;

    svg {
      width: 1.55rem;
      height: auto;
    }
  }

  &--up {
    transform: rotate(-90deg);
    margin-bottom: .5rem;
  }

  &--down {
    transform: rotate(90deg);
  }

  &--disabled {
    pointer-events: none;
    opacity: 0;
  }
}

@include respond-to(ipad-portrait) {
  .hr-section-nav {
    &__container {
      display: block;
    }
  }
}

@include respond-to(ipad-landscape) {
  .hr-section-nav {
    padding-left: 2vw;
  }
}

@include respond-to(desktop-smallest) {
  .hr-section-nav {
    padding-left: 3vw;
  }
}
