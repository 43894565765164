.zip-filter {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 2rem auto 0;

  .select-dropdown__container {
    min-width: 17rem;
    margin-right: .5rem;
  }

  .input {
    min-width: 17rem;
    margin-right: .5rem;
    margin-bottom: 1rem;
  }

  &__reset {
    flex-basis: 100%;
  }
}

.reset-button {
  display: flex;
  justify-content: center;
  transition: color .3s ease;
  font-size: .85rem;
  cursor: pointer;

  &:hover {
    color: $blue;
  }


  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: .75rem;
    height: .75rem;
    margin-right: .5rem;
    border-radius: 50%;
    background-color: #000;
  }

  .svg-icon {
    @include svg-size(.375rem);
  }
}

.contact-zip-search {
  .zip-filter {
    flex-direction: row;
    align-items: flex-start;
  }

  .input {
    min-width: 9rem;
  }
}

@include respond-to(ipad-portrait) {
  .zip-filter {
    flex-direction: row;
    align-items: flex-start;
  }
}
