@if $basic-print-sheet {
  @media print {
    @page {
      margin: .5cm;
    }

    .no-print {
      display: none;
    }

    .wrap {
      width: 100%;
    }

    [class*='col-'] {
      width: 100%;
    }

    body,
    html {
      background: #fff none;
    }
  }
}
