.bookmark {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  background-color: $light-gray;

  &__meta {
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    padding: .5rem 1.9rem 1rem;
  }

  &__date {
    font-size: .85rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding: 2rem 0 1rem 1.9rem;
    transition: color .2s ease;
    color: #000;
    text-decoration: none;

    h4 {
      width: 95%;
      text-transform: uppercase;
    }

    p {
      width: 95%;
      font-size: .85rem;
    }

    &:hover {
      color: $blue;
    }
  }

  &__breadcrumb {
    display: none;
    align-items: center;
    margin-left: -.3rem;
    font-size: .75rem;

    .svg-icon {
      width: 1.45rem;
      height: 1.45rem;
      margin: 0 .75rem 0 0;
    }
  }

  &__image {
    display: none;
    flex-shrink: 0;
    width: 17.75rem;
    background: center no-repeat;
    background-size: cover;
  }

  &__delete {
    margin-right: -.5rem;
  }
}


.delete-button {
  display: flex;
  align-items: flex-end;
  transition: color .2s ease;
  cursor: pointer;

  &__label {
    font-size: .85rem;
    text-decoration: underline;
  }

  &__icon {
    width: 1.3rem;
    height: 1.3rem;
    margin: 0 0 .15rem .4rem;
  }

  &:hover {
    color: $error;

    .delete-button__icon {
      @extend .svg-icon--red;
    }

    .animated {
      transform: rotate(-10deg) translateY(-5px);
      transform-origin: left center;
    }
  }
}

@include respond-to(ipad-landscape) {
  .bookmark {
    flex-wrap: nowrap;
    height: 10rem;

    &__content {
      padding: 1rem 0 1rem 2.5rem;

      h4,
      p {
        width: 65%;
      }
    }

    &__image {
      display: block;
    }

    &__meta {
      flex-basis: auto;
      flex-direction: column;
      padding: 2rem 1.9rem 1rem 0;
    }

    &__breadcrumb {
      display: block;
    }
  }
}
