@if $reset {
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }


  body {
    margin: 0;
    overflow-x: hidden;
  }

  * {
    box-sizing: border-box;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  progress,
  video {
    display: inline-block;
  }

  audio {
    &:not([controls]) {
      display: none;
      height: 0;
    }
  }

  progress {
    vertical-align: baseline;
  }

  template,
  [hidden] {
    display: none;
  }

  a {
    background-color: transparent;

    &:active,
    &:hover {
      outline-width: 0;
    }
  }

  b,
  strong {
    font-weight: inherit;
  }

  b,
  strong {
    font-weight: bolder;
  }

  dfn {
    font-style: italic;
  }

  mark {
    background-color: #ff0;
    color: #000;
  }

  small {
    font-size: 80%;
  }


  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -.25em;
  }

  sup {
    top: -.5em;
  }

  img {
    border-style: none;
  }


  svg {
    &:not(:root) {
      overflow: hidden;
    }
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  figure {
    margin: 1em 0;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font: inherit;
  }

  optgroup {
    font-weight: bold;
  }

  button,
  input {
    overflow: visible;
  }


  button,
  select {
    text-transform: none;
  }

  button,
  html [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: none;
  }

  :focus {
    outline: none;
  }

  input {
    &:-webkit-autofill {
      &,
      &:hover,
      &:focus,
      &:active {
        transition: color 9999s ease-out, background-color 9999s ease-out;
        transition-delay: 9999s;
      }
    }

    &::-ms-clear {
      display: none;
    }
  }

  input,
  select,
  textarea {
    border: 0;
    border-radius: 0;
  }

  :focus,
  ::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    padding: 0;
    border: 0 none;
  }

  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }


  fieldset {
    margin: 0 2px;
    padding: .35em .625em .75em;
    border: 1px solid #c0c0c0;
  }


  legend {
    display: table;
    box-sizing: border-box;
    max-width: 100%;
    padding: 0;
    color: inherit;
    white-space: normal;
  }

  textarea {
    overflow: auto;
  }

  [type='checkbox'],
  [type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }

  [type='number'] {
    &::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      height: auto;
    }
  }

  [type='search'] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
  }

  [type='search'] {
    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }

  ::-webkit-input-placeholder {
    opacity: .54;
    color: inherit;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0;
    padding: 0;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}


html {
  width: 100%;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}


button {
  padding: 0;
  overflow: visible;
  border: 0;
  background: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-user-select: none;
  -webkit-appearance: button;
  -moz-user-select: none;
  -ms-user-select: none;
}
