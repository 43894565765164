.pictogram {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background-color: #fff;
  pointer-events: none;

  &__icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}
