.tile__container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.tile {
  position: relative;
  min-height: 21rem;
  background-color: $light-gray;
  background-size: cover;
}

.tile--long {
  grid-column: span 2;
}

.tile--large {
  grid-column: span 2;
  grid-row: span 2;
}

@include respond-to(ipad-landscape) {
  .tile__container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
