.article-detail {
  &__headline {
    margin-top: 10.7rem;
  }

  &__buttons {
    display: flex;
    margin: 1rem 0 6rem;

    .button {
      margin-right: .8rem;
    }
  }

  &__slider {
    display: block;
    margin: 2rem 0;

    img {
      line-height: 0;
    }

    .inner-slider__button {
      top: 48%;
    }
  }
}

.article-detail__block--media {
  position: relative;
  margin: 1.5rem 0 2rem;
  line-height: 0;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    transition: opacity .2s ease;
  }

  .zoom-icon {
    position: absolute;
    bottom: 1.2rem;
    left: 1.1rem;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5rem;
    height: 5rem;
    transform: translate(-50%, -50%);
  }

  &:hover {
    img {
      opacity: .9;
    }

    .zoom-icon {
      @extend .zoom-icon--hover;
    }
  }
}
