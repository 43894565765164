.link-list {
  border-top: 1px solid rgba($divider-color, .5);

  &__item {
    display: block;
    padding: .7rem 3rem;
    border-bottom: 1px solid rgba($divider-color, .5);
    color: $black;
    font-size: .85rem;
    text-decoration: none;

    &:hover,
    &--active {
      color: $blue;
    }
  }
}
