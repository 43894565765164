.story-detail {
  position: relative;

  &__slide {
    padding-top: 59.79%;
    background-position: center;
    background-size: cover;
  }

  &__box {
    padding: 2.4rem 2.9rem 0;
    background-color: $white;

    h1 {
      margin-right: 4.5rem;
      margin-bottom: 2rem;
      font-size: 2rem;
    }

    h4 {
      margin-bottom: 1.8rem;
    }

    h4,
    p {
      font-size: 1rem;
      line-height: 160%;
    }

    svg {
      width: 2rem;
    }
  }

  &__navigation {
    display: flex;
    position: absolute;
    top: 2.4rem;
    right: 1.2rem;
    justify-content: space-between;
    width: 4.5rem;
  }

  &__arrow--prev {
    transform: rotateY(180deg);
  }
}

@include respond-to(ipad-landscape) {
  .story-detail {
    &__box {
      position: absolute;
      z-index: 1;
      bottom: 0;
    }
  }
}
