@keyframes underline {
  0% {
    left: 10%;
    width: 80%;
    height: 0;
  }

  100% {
    left: 0;
    width: 100%;
    height: 1px;
  }
}


%underline {
  animation: underline .3s ease both;
}
