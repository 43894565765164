.link-dropdown {
  position: relative;
  padding-bottom: 1.5rem;

  &__list {
    position: absolute;
    top: 1.5rem;
    left: 0;
    width: 100%;

    .svg-icon {
      position: absolute;
      top: .2rem;
      right: 0;
      width: .75rem;
      height: .75rem;
      transform: rotate(90deg);
    }
  }

  &__element {
    display: none;
    opacity: .54;
  }

  &__element--active {
    display: block;
  }
}
