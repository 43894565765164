@mixin respond-to($name) {
  @if map-has-key($breakpoint, $name) {
    @media (min-width: #{map-get($breakpoint, $name)}) {
      @content;
    }
  } @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
  }
}
