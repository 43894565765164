.sitemap {
  @extend %flex-helper;
  max-width: 80vw;
  margin: 0 auto;

  &__column {
    flex-basis: 100%;
  }

  &__headline {
    padding-bottom: 1rem;
    text-transform: uppercase;
  }

  ul {
    overflow: hidden;
  }
}

.sitemap__item {
  margin-bottom: .9rem;

  a {
    display: block;
    transition: color .2s ease;
    color: rgba(#000, .7);
    font-size: .9rem;
    text-decoration: none;

    &:hover {
      color: $blue;
    }
  }
}

@include respond-to('ipad-portrait') {
  .sitemap {
    justify-content: left;
    max-width: 70vw;

    &__column {
      flex-basis: 33.333%;
    }
  }
}

@include respond-to('desktop-smallest') {
  .sitemap {
    justify-content: space-around;
    max-width: 80vw;

    &__column {
      flex-basis: inherit;
    }
  }
}
