.search-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0 0;

  &__icon {
    width: 1.3rem;
    height: 1.3rem;
    margin: 0 .5rem;
    cursor: pointer;

    &--inactive {
      opacity: .2;
      pointer-events: none;
    }

    &--back {
      transform: rotate(180deg);
    }

    &:hover {
      @extend .svg-icon--blue;
    }
  }

  &__counter {
    margin-top: .2rem;
    font-size: .75rem;
  }

  &::before,
  &::after {
    content: '\A';
    display: block;
    flex-grow: 1;
    width: auto;
    height: 1px;
  }

  &::before {
    background: linear-gradient(to right, rgba(#c8c8c8, 0%), rgba(#c8c8c8, .8));
  }

  &::after {
    background: linear-gradient(to left, rgba(#c8c8c8, 0%), rgba(#c8c8c8, .8));
  }
}

