.search {
  display: flex;
  position: relative;
  justify-content: stretch;
  width: 45%;

  &__input {
    width: 80%;
    border-bottom: 1px solid rgba($border-color, .35);
    background-color: transparent;
    font-size: .75rem;
  }

  &__button {
    transition: opacity .2s ease;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    -webkit-appearance: none;

    .svg-icon {
      width: 1rem;
      height: 1rem;
    }

    &:hover {
      opacity: .7;
    }
  }

  &--small {
    width: 8.2rem;

    .search__input {
      flex: 1 1 auto;
      width: auto;
      font-size: .7rem;
    }

    .search__button {
      flex: 0 0 auto;
      width: .8rem;
      height: .8rem;
      padding: 0;
    }

    .svg-icon {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-to(ipad-pro) {
  .search--small {
    width: 9.5rem;
  }
}

@include respond-to(desktop-smallest) {
  .search--small {
    width: 11.5rem;
  }
}

@include respond-to(desktop-small) {
  .search--small {
    margin-top: -.5rem;
  }
}
