.per-page {
  display: flex;
  justify-content: center;
  margin: 0 0 1.8rem;

  font-size: .85rem;

  &__label {
    margin-right: .5rem;
  }
}

.per-page__item {
  padding: 0 .6rem;
  transition: opacity .2s ease;
  border-right: 1px solid rgba(#000, .5);
  opacity: .5;
  cursor: pointer;

  &::after {
    @extend %fadein;
    content: '\A';
    display: none;
    width: .8rem;
    height: 2px;
    background-color: $blue;
  }

  &--active {
    opacity: 1;

    &::after {
      display: block;
    }
  }

  &:hover::after {
    display: block;
  }

  &:last-child {
    border-right: 0;
  }
}
