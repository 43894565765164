tc-blueprint {
  display: block;
  margin-bottom: 3.7rem;
}

.tc-blueprint{
  &__blueprints__disabled-overlay {
    position: absolute;
    display: flex;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .9;
    background: $light-gray;
  }

  &__disabled-overlay-content {
    width: 100%;
    margin: auto;
    text-align: center;

    p {
      margin-top: .5rem;
    }

    .button {
      margin-top: 2rem;
      font-size: .85rem;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.3rem;
  }

  &__info {
    position: relative;
    flex-basis: 100%;
    padding: 2rem 1.5rem 0;
    background-color: #e7e7e7;

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 4;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      transform: rotate(90deg) translateX(2rem) translateY(.75rem);
      border-width: 1.5rem 0 1.5rem 1.5rem;
      border-style: solid;
      border-color: transparent transparent transparent #e7e7e7;
    }

    h2 {
      font-size: 1.5rem;
    }

    p {
      font-size: .85rem;
    }
  }

  &__mobile-select {
    max-width: 12rem;

    .select-dropdown {
      background-color: $white;

      .ng-value {
        background-color: $white;
      }
    }
  }

  &__blueprints {
    position: relative;
    flex-basis: 100%;
    padding: 0 1.5rem;
    background-color: $light-gray;
  }

  &__nav {
    position: relative;
    z-index: 2;
    margin: 2.6rem 0 0;
    text-transform: uppercase;
  }

  &__image {
    padding-top: 141%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &__image-container {
    margin-top: -4rem;
  }
}

.tc-blueprint__zoom-in {
  position: absolute;
  z-index: 2;
  top: 3rem;
  right: 1.5rem;
  width: 1.5rem;

  &:hover {
    cursor: pointer;
  }
}

.tc-blueprint__view-link {
  display: none;
  position: relative;
  margin: 0 1.2rem;
  color: $dark-gray;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: -.8rem;
    left: 0;
    height: .1rem;
    transition: background-color .2s ease;
    background-color: transparent;
  }

  &--active {
    color: $black;

    &::before {
      background-color: $blue;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: -1.1rem;
    width: .1rem;
    height: .8rem;
    background-color: $border-color;
  }

  &:first-child {
    margin-left: 0;

    &::after {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.tc-blueprint-slider {
  @include slider-navigation();

  &__navigation {
    right: 2rem;
  }
}

.tc-blueprint__label {
  position: absolute;
  bottom: 2rem;
  left: 3.5rem;
  font-size: .85rem;
}

@include respond-to(ipad-portrait) {
  .tc-blueprint__nav {
    font-size: .75rem;
  }

  .tc-blueprint__container {
    flex-wrap: nowrap;
  }

  .tc-blueprint__mobile-select {
    display: none;
  }

  .tc-blueprint__image {
    padding-top: 46%;
  }

  .tc-blueprint__image-container {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin-top: 0;
    margin-bottom: 0;
    transform: translateY(-50%);
  }

  .tc-blueprint__view-link {
    display: inline-block;
  }

  .tc-blueprint__info {
    flex-basis: 25%;
    padding: 2.5rem 2.5rem 0;

    &::before {
      top: 6rem;
      right: -1.5rem;
      bottom: inherit;
      left: inherit;
      transform: rotate(0) translateX(0);
    }
  }

  .tc-blueprint__zoom-in {
    top: 2.5rem;
    right: 2rem;
  }

  .tc-blueprint__blueprints {
    flex-basis: 75%;
    padding: 0 3.5rem;
  }
}

@include respond-to(mobile) {
  .tc-blueprint__nav {
    font-size: 16px;
  }
}
