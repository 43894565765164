.cookie-banner {
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  &__inner {
    @extend %slidein-from-bottom--with-delay;

    padding: 1.5rem;
    background-color: #ffffff;
    box-shadow: 0 2px 28px -4px rgba(#000, .36);
  }

  &__content .bodytext p {
    font-size: .75rem;
  }

  &__headline {
    font-size: 1rem;
    padding-bottom: .4rem;
  }

  @include respond-to(ipad-landscape) {
    &__inner {
      display: flex;
      justify-content: space-between;
      padding: 2.5rem 3.5rem;
    }

    &__content {
      width: 75%;

      .bodytext p {
        font-size: 1rem;
      }
    }

    &__headline {
      font-size: 1.5rem;
      padding-bottom: .8rem;
    }
  }
}
