.preview {
  position: relative;
  height: 1.72rem;
  padding: .5rem 5rem .4rem 2rem;
  background-color: #fff;

  &__headline {
    font-size: .85rem;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    width: 3.7rem;
    height: 100%;
    background-size: cover;
  }
}

.preview__container {
  display: flex;
  justify-content: flex-end;
}
