app-google-map {
  .google-map {
    display: block;
    width: 100%;
    height: 60vw;
  }

  .teaser-infobox {
    display: none;
  }
}

.google-maps {
  &__marker-window {
    display: block;
    position: relative;
    z-index: 1;
    max-width: 27rem;
    background-color: $white;
    color: $black;
    text-decoration: none;

    .svg-icon {
      cursor: pointer;
    }

    .svg-icon:hover {
      @extend .svg-icon--blue;
    }

    a {
      color: $black;
    }

    h3,
    h3 + p {
      margin-bottom: 1.4rem;
    }
  }
}

@include respond-to(ipad-landscape) {
  app-google-map {
    .google-map {
      height: 40vw;

      .teaser-infobox {
        position: absolute;
        z-index: 1;
        top: 1.5rem;
        left: 2.35rem;
      }
    }
  }
}
