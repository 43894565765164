.teaser {
  @extend %fadein;
  display: block;
  position: relative;
  overflow: hidden;
  transition: opacity .2s ease;
  color: #000;
  text-decoration: none;
  cursor: pointer;

  &__pictogram {
    position: absolute;
    top: 1.75rem;
    left: 1.75rem;
  }

  &__icon {
    position: absolute;
    bottom: 0;
    width: 1.45rem;
    height: 1.45rem;
    opacity: .8;
  }

  &__link-list {
    margin: 0 -1rem;
  }


  &--overview {
    p {
      position: relative;
    }
  }

  &--inactive {
    pointer-events: none;

    .teaser__image {
      filter: opacity(40%) grayscale(100%) brightness(110%);
    }

    .teaser__inner > * {
      opacity: .4;
    }
  }

  &:not(.teaser--static):hover {
    .teaser__image {
      filter: opacity(95%);
    }

    .teaser__icon {
      @extend %svg-icon--blue;
    }
  }
}

