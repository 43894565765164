.pull-down {
  &__icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin: 2.5rem auto;
    cursor: pointer;

    svg {
      transform: rotate(90deg);
    }

    &:hover {
      @extend .svg-icon--blue;
    }
  }
}
