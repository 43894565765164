.wrap .form-section .form-image {
  height: auto;
}

.form-image {
  display: flex;
  align-items: flex-start;
  margin-top: 2.8rem;
  background-color: $light-gray;
  text-transform: uppercase;

  &--select {
    text-transform: inherit;
  }

  &__container {
    width: 100%;
    margin: 0 0 0;
    padding: 84% 2rem 5%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__info-icon {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $blue;
    width: 2.5rem;
    height: 2.5rem;
    padding: .75rem 0;

    svg {
      height: 1rem;
    }
  }

  &__top-label {
    position: absolute;
    top: 1.5rem;
    left: 2.2rem;
  }
}


.form-image--select {
  .form-image__container {
    padding-top: 88%;
  }

  .select-dropdown--gray {
    height: 2.5rem;
    background-color: $white;
    line-height: 2.5rem;
    text-align: left;

    .ng-value {
      background: $white;
    }

    .ng-arrow-wrapper {
      width: 2.7rem;
      margin: 0;
      background-color: #eceaec;
    }

    ng-dropdown-panel {
      //background-color: $white;
    }

    @include respond-to(mobile) {
      font-size: 16px;
    }

    @include respond-to(ipad-portrait) {
      font-size: .75rem;
    }
  }
}
