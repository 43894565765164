.intro-slider__slide {
  @extend %fadein;
  margin-top: 4rem;
  padding-top: 78%;
  background-position: center;
  background-size: cover;
  cursor: ew-resize;
}

.intro-slider {
  @include slider-navigation();
  display: block;
  background-color: $light-gray;
}

.intro-slider__slide-wrap {
  overflow: hidden;
}

.intro-slider__navigation {
  bottom: 3rem;
  font-size: 1rem;
}

.intro-slider__arrow {
  .svg-icon {
    width: 1.8rem;
  }
}

.intro-slider__navigation--wrapped {
  bottom: 0;
  left: 50%;
  justify-content: center;
  transform: translateX(-50%);
}

.intro-slider__overlay {
  width: 100%;
  pointer-events: none;

  &::after {
    content: '';
    display: block;
    width: 70%;
    height: 1px;
    margin: 0 auto;
    border: 0;
    background: $divider-color;
    background: -webkit-gradient(linear, 0 0, 100% 0, from($white), to($white), color-stop(50%, $divider-color));
  }
}

.intro-slider__box {
  margin: 0 2rem 0 0;
  padding: 2.1rem 0 2.8rem;
  background-color: #fff;
  pointer-events: visible;

  h2 {
    padding-bottom: .5rem;
    font-size: 1.9rem;
    line-height: 100%;
  }

  h3 {
    padding-bottom: .3rem;
    font-size: .85rem;
    line-height: 110%;
  }

  p {
    padding-bottom: 1.75rem;
    color: rgba(#000, .7);
    font-size: 1.1rem;
    letter-spacing: -.03rem;
    line-height: 130%;
  }
}

.intro-slider__container {
  position: relative;

  .intro-slider__button--wrapped {
    top: calc(50% - 1rem);
  }
}

.intro-slider__overlay--wrapped {
  position: relative;

  h2 {
    position: relative;
    padding-bottom: .75rem;
    font-size: 3.5rem;

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 3.6rem;
      height: .25rem;
      background-color: $blue;
    }
  }

  h3 {
    margin-top: 1.6rem;
    padding: 0;
    font-size: 2rem;
  }

  p {
    margin-top: 1.6rem;
    font-size: 1rem;
    line-height: 150%;
  }
}

.intro-slider__wrapped {
  .intro-slider__slide {
    padding-top: 46.77%;
  }
}

@include respond-to(mobile-landscape) {
  .intro-slider {
    &__slide {
      padding-top: 80%;
    }
  }
}

@include respond-to(ipad-portrait) {
  .intro-slider {
    &__overlay {
      position: absolute;
      z-index: 2;
      bottom: 5.8vw;
      left: 0;

      &::after {
        display: none;
      }
    }

    &__box {
      padding: 2rem 3.25rem 1.7rem 1.9rem;
    }

    &__navigation {
      right: 5.2rem;
      bottom: 1.9rem;
    }

    &__slide {
      padding-top: 54.8%;
    }
  }

  .intro-slider__overlay--wrapped {
    margin-top: -6.3rem;

    .intro-slider__box {
      position: relative;
      z-index: 1;
      max-width: 45rem;
      margin: 0 0 0 2.5rem;
      padding: 2.2rem 0 0 3.1rem;
    }

    .inner {
      max-width: 27rem;
    }
  }

  .intro-slider__navigation--wrapped {
    display: none;
  }
}

@include respond-to(ipad-landscape) {
  .intro-slider {
    &__slide {
      margin-top: 0;
      padding-top: 54.5%;
    }
  }
}

@include respond-to(desktop-smallest) {
  .intro-slider {
    &__slide {
      padding-top: 38.5%;
    }

    &__box {

      h2 {
        font-size: 2rem;
      }

      p {
        font-size: .85rem;
      }
    }

    &__navigation {
      bottom: 1.9rem;
      font-size: .75rem;
    }

    &__arrow {
      .svg-icon {
        width: 1.4rem;
      }
    }
  }
}
