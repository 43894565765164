.svg-icon,
%svg-icon {
  display: inline-block;
  line-height: 100%;

  svg {
    width: 100%;
    height: auto;

    * {
      transition: fill .3s ease, transform ease .6s;
      stroke: none;
    }
  }
}


@each $name, $hex in $svgcolors {
  .svg-icon--#{$name} svg,
  %svg-icon--#{$name} svg {
    background-color: transparent;
    fill: transparent;

    polygon,
    path,
    rect,
    circle,
    ellipse,
    line,
    polyline {
      fill: $hex;
    }

    .stroke {
      stroke: $hex;
      fill: transparent;
    }
  }
}
