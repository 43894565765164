@-webkit-keyframes slidein-from-bottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slidein-from-bottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}


%slidein-from-bottom {
  animation: slidein-from-bottom .2s cubic-bezier(.39, .575, .565, 1) both;
}

%slidein-from-bottom--with-delay {
  @extend %slidein-from-bottom;
  animation-delay: 2s;
}
