.hr-half-teaser {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vw;
  padding: 2.7rem 3rem 1.8rem 2.4rem;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &__link-box {
    align-self: baseline;
    padding: 1.65rem 1.35rem 1.5rem 1.35rem;
    box-shadow: 0 2px 40px 0 rgba($black, .23);

    h2 {
      @extend %bold;
      margin-bottom: 1.5rem;
      color: $blue;
      font-size: 2rem;
      line-height: .9;
    }

    &.green {
      background-color: #0fd774;
    }

    &.lightblue {
      background-color: #0fd7cf;
    }

    &.yellow {
      background-color: #d7b50f;
    }

    &.red {
      background-color: #cc4747;
    }

    &.eosin {
      background-color: #d23f63;
    }

    &.orange {
      background-color: #f26946;
    }

    &.brown {
      background-color: #ab9778;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 2rem;
    margin-left: auto;

    .svg-icon {
      width: 4.1825rem;
      height: 4.375rem;
      margin-bottom: 1.25rem;

      svg {
        width: 4.1825rem;
        height: 4.375rem;
      }
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 1.25rem;
      line-height: 1.25;
    }
  }

  &__section {
    .section {
      padding-top: 3.1rem;
      padding-bottom: 0;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__headline {
    max-width: 14.25rem;
    margin: 0 auto 2.7rem;
    color: $blue;
    font-size: 1.125rem;
    line-height: 1.2;
    text-align: center;
  }
}

@include respond-till(ipad-portrait) {
  .hr-half-teaser__section {
    .wrap {
      width: 100vw;

      [class*="col-"] {
        padding: 0;
      }
    }
  }
}

@include respond-to(ipad-portrait) {
  .hr-half-teaser {
    height: 34rem;
    min-height: 0;
    margin: 0 .575rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &__headline {
      max-width: 100%;
      margin-bottom: 1.7rem;
      font-size: 1.14rem;
    }

    &__link-box {
      width: 14.8rem;

      h2 {
        margin-bottom: 1.15rem;
        font-size: 1.86rem;
      }
    }

    &__content {
      margin-bottom: 0;

      p {
        font-size: 1.14rem;
      }

      .svg-icon {
        width: 6.21rem;
        height: 5.71rem;
        margin-bottom: 3.39rem;

        svg {
          width: 6.21rem;
          height: 5.71rem;
        }
      }
    }
  }
}

@include respond-to(desktop-smallest) {
  .hr-half-teaser {
    height: 40rem;

    &__headline {
      margin-bottom: 2.7rem;
      font-size: 1.5rem;
    }

    &__link-box {
      width: 16.45rem;
      height: 14.55rem;

      h2 {
        margin-bottom: 1.8rem;
        font-size: 2.5rem;
      }
    }

    &__content {
      p {
        font-size: 1.5rem;
      }

      .svg-icon {
        width: 8.2rem;
        height: 7.5rem;
        margin-bottom: 4.1rem;

        svg {
          width: 8.2rem;
          height: 7.5rem;
        }
      }
    }
  }
}
