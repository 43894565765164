.teaser__inner {
  position: relative;
  max-width: 27rem;
  min-height: 6rem;
  margin: -2.1rem 2.5rem 0 1.1rem;
  padding: 1rem .5rem 1.4rem 1rem;

  > * {
    transition: opacity .2s ease;
  }

  h3 {
    width: calc(100% - 1.9rem);
    margin-bottom: .8rem;
    opacity: .8;
    font-size: 1.35rem;
    line-height: 100%;

    &.no-margin {
      margin-bottom: .1rem;
    }
  }

  h4 {
    padding-bottom: .5rem;
    opacity: .8;
    font-size: 1rem;
  }

  p {
    opacity: .8;
    font-size: 1rem;
    line-height: 130%;
  }

  .button {
    margin-top: 1rem;
  }

  &--small {
    margin-top: -3rem;
    padding-right: 0;

    h3 {
      width: auto;
    }
  }

  &--big {
    margin-top: -2.28rem;

    h3 {
      text-transform: inherit;
    }

    .teaser__icon {
      bottom: 0;
      left: 1rem;
    }
  }

  &--flat {
    margin-top: -2.28rem;

    p {
      padding-bottom: .2rem;
    }

    .teaser__icon {
      top: 1.6rem;
      right: 1.6rem;
    }
  }

  &--large,
  &--expansion-panel {
    padding: 1rem;

    h3 {
      line-height: 120%;
    }

    .teaser__icon {
      top: .75rem;
      right: .75rem;
    }
  }

  &--expansion-panel {
    padding-bottom: 0;
  }

  &--on-gray {
    background-color: $light-gray;
  }

  &--on-white {
    background-color: #fff;
  }
}

@include respond-to(ipad-portrait) {
  .teaser__inner {
    &--big {
      padding-bottom: 0;

      .teaser__icon {
        top: 1rem;
        right: 1rem;
        bottom: inherit;
        left: inherit;
      }
    }
  }
}

@include respond-to(ipad-landscape) {
  .teaser__inner {
    margin: -4.35rem 7.5rem 0 1.3rem;
    padding: 1.4rem 1rem 1rem 1.7rem;

    h3 {
      font-size: 1.45rem;
    }

    p {
      font-size: 1.1rem;
    }

    &--small {
      margin: -3.6rem 4rem 0 1rem;
      padding: 1.1rem 0 0 1.1rem;

      p {
        line-height: 110%;
      }
    }

    &--full {
      padding: .8rem .5rem 1.4rem;

      .teaser__icon {
        top: .75rem;
        right: .75rem;
      }
    }

    &--large {
      .teaser__icon {
        top: 1.25rem;
        right: 1.25rem;
        width: 1.25rem;
      }
    }
  }
}

@include respond-to(desktop-smallest) {
  .teaser__inner {
    &--flyout {
      margin-right: 6rem;
    }
  }
}

@include respond-to(desktop-small) {
  .teaser__inner {
    h3 {
      width: calc(100% - 2.5rem);
      font-size: 1.5rem;
    }

    p {
      font-size: .85rem;
    }

    .teaser__icon {
      top: 1.7rem;
      right: 1.5rem;
      width: 1.5rem;
    }

    &--small {
      margin: -4.15rem 5.45rem 0 1.3rem;
      padding: 1.5rem .5rem 0 1.3rem;
    }

    &--big {
      margin: -5rem 6.3rem 0 1.7rem;
      padding: 1.75rem .5rem 0 2.1rem;


    }

    &--large,
    &--expansion-panel {
      margin: -5.45rem 9.5rem 0 1.75rem;
      padding: 1.75rem 3rem 0 2.3rem;
    }

    &--expansion-panel {
      h3 {
        margin-bottom: 2rem;
      }
    }

    &--full {
      position: absolute;
      bottom: 0;
      margin: 0 9.5rem 0 1.75rem;
      padding: 1.85rem 1.5rem 1.2rem 2.1rem;
    }

    &--flat {
      width: 49%;
      margin: -5.4rem 0 0 1.75rem;
      padding: 1.9rem .5rem 0 2.1rem;
    }
  }
}
