.listing-wrap {
  li {
    line-height: 2.15;
    font-size: 1.4rem;
    position: relative;
    opacity: 0.75;

    &:before {
      content: "";
      height: 3px;
      background: black;
      display: block;
      width: 1rem;
      position: absolute;
      top: 1.8rem;
      left: -2rem;
    }
  }
}

.listing-wrap__brackets {
  width: 100%;
  padding-left: 25px;

  @include respond-to(ipad-landscape) {
    padding-left: 15%;
  }

  li {
    text-transform: uppercase;
    a {
      text-decoration: none;
    }
  }

  a {
    text-decoration: underline;
    color: inherit;
  }
}

.border-box__listing-brackets {
  max-width: 100%;
}
