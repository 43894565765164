.teaser--static {
  cursor: auto;

  .teaser__inner {
    h3 {
      line-height: 120%;
      text-transform: uppercase;
    }
  }


  @include respond-to(ipad-landscape) {
    .teaser__inner h3 {
      font-size: 2rem;
    }
  }
}
