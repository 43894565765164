teaser-download {
  display: flex;
}

swiper > .swiper.s-wrapper .swiper-wrapper {
  teaser-download.swiper-slide {
    height: auto;
  }
}

.download {
  display: block;
  position: relative;
  width: 100%;
  padding: 1rem 1.2rem 1.2rem;
  background-color: $light-gray;
  color: $black;
  text-decoration: none;

  &__inner {
    padding-left: .4rem;
  }

  &__headline {
    margin-bottom: .25rem;
    text-transform: none;
  }

  &__bodytext {
    font-size: .85rem;
  }

  &__image {
    width: 100%;
    margin-bottom: 1.5rem;
    padding-top: 141.58%;
    transition: box-shadow .3s ease;
    background-size: cover;
    box-shadow: 0 1px 8px 0 rgba(#000, .3);
  }

  &__button {
    display: none;
  }

  &:hover {
    .download__image {
      box-shadow: 0 3px 9px 0 rgba(#000, .4);
    }

    .button {
      @extend .button--hover;
    }
  }
}

.download__container {
  display: flex;

  .download {
    width: 30vw;
  }

  teaser-download:first-child {
    margin-right: 1.2rem;
  }
}

@include respond-to(ipad-landscape) {
  .download {
    padding: 2rem 2rem 1.85rem;

    &__bodytext {
      width: 75%;
      margin-bottom: 3.75rem;
    }

    &__button {
      display: inline-flex;
      position: absolute;
      bottom: 2.1rem;
      min-width: auto;
      font-size: .65rem;
    }
  }

  .download__container .download {
    width: 15.5vw;
  }
}
