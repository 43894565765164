.logo {
  display: inline-block;
  width: 9.5rem;
  margin-left: auto;
  transition: width .3s ease, height .3s ease;
  line-height: 0;
}

.logo__container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
}

@include respond-to(ipad-pro) {
  .logo {
    width: 10rem;
  }
}

@include respond-to(desktop-smallest) {
  .logo {
    width: 10rem;
  }
}

@include respond-to(desktop-small) {
  .logo {
    width: 10rem;
  }
}
