@-webkit-keyframes slidein-from-top {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slidein-from-top {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}


%slidein-from-top {
  animation: slidein-from-top .2s cubic-bezier(.39, .575, .565, 1) both;
}
