.mykleusberg--header {
  &.intro {
    padding-top: 1rem;
  }

  .breadcrumb {
    height: 1.75rem;
    margin-bottom: 2rem;
  }

}

.svg-icon--my-kleusberg-login {
  width: 1.3rem;
  height: auto;
  position: relative;
  right: 8px;
  top: 2px;
}

.svg-icon--my-kleusberg-logout {
  width: 0.8rem;
  opacity: 0.5;
  top: 3px;
  position: relative;
  right: 12px;
}

.multi-pdf-export--container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: flex;

  .multi-pdf-export--controls {
    background-color: $lighter-blue;
    box-shadow: 0 0 1rem 3px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 1rem;

    .multi-pdf-export__reference-count {
      color: $blue;
    }

    .button {
      @include respond-to(ipad-portrait) {
        margin: 0 0 0 1.5rem;
      }
      margin: 0 0 1rem 0;
    }
  }
}

.multi-pdf--reset {
  svg {
    width: 0.8rem;
    height: 0.8rem;
    opacity: 0.8;
    margin-left: 0.8rem;
  }
}

.multi-pdf-selector {
  display: flex;
  position: absolute;
  top: 0;
  right: 10px;
  align-items: center;
  justify-content: center;
  width: 3.15rem;
  height: 3.15rem;
  background-color: $blue;
  cursor: pointer;
  z-index: 1;

  &:hover .svg-icon{
    opacity: 0.5;
  }

  &.selected .svg-icon {
    opacity: 1!important;
  }

  .svg-icon {
    width: 1.5rem;
    height: 1.5rem;
    transform: rotate(-10deg);
    opacity: 0.25;
    transition: opacity 0.1s;
  }

  .section--teaser-list-unfiltered & {
    right: 0;
  }
}

.mykleusberg--logout {
  cursor: pointer;
}
