.icon-button {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  width: 40vw;
  padding: 0 .8rem;
  transition: opacity .2s ease;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &__icon {
    width: 3rem;
    height: 3rem;
  }

  &__label {
    @extend %bodytext;
    margin-top: .6rem;
    text-align: center;
    text-transform: uppercase;
  }

  &--active:not(&--inactive),
  &--selected:not(&--inactive) {
    opacity: 1;

    .icon-button__icon {
      @extend %svg-icon--blue;
    }

    .icon-button__label {
      color: $blue;
    }

    &::after {
      @extend %underline;
      content: '\A';
      display: block;
      position: absolute;
      z-index: 1;
      bottom: calc((1rem + 3px) * -1);
      left: .8rem;
      width: calc(100% - 1.6rem);
      height: 1px;
      background-color: $blue;
    }
  }

  &--inactive {
    opacity: .2;
    pointer-events: none;
  }

  &--selected::before {
    content: '\A';
    display: block;
    position: absolute;
    top: 0;
    right: 1rem;
    width: .5rem;
    height: .5rem;
    background-image: url('//svg/close.svg');
  }


  &:hover {
    opacity: 1;
  }
}

.icon-button__container {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1.1rem;
  overflow-x: auto;
  overflow-y: hidden;
}

@include respond-to(ipad-portrait) {
  .icon-button__container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .icon-button {
    width: auto;
    padding: 0 1.75rem;

    &__label {
      text-align: left;
      display: block;
    }
  }
}

.icon-button--inactive.icon-button--selected {
  pointer-events: auto!important;
}
