html {
  font-size: #{map-get($rem, base)};
}

.flex-helper,
%flex-helper {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
}

.col-helper,
%col-helper {
  position: relative;
  min-width: 0;
  min-height: 0;
  padding-right: #{map-get($gutter, base)};
  padding-left: #{map-get($gutter, base)};
}

.wrap,
%wrap {
  @extend %flex-helper;
  @if map-get($gutter, base) != 0 {
    width: calc(#{map-get($wrap, base)} + 2 * #{map-get($gutter, base)});
  } @else {
    width: 100%;
  }
  margin: 0 auto;

  &.vertical-center {
    align-items: center;
  }

  &.vertical-bottom {
    align-items: flex-end;
  }

  &.full-height {
    align-items: stretch;

    [class*='col-'] > * {
      height: 100%;
    }
  }

  &.horizontal-center {
    justify-content: center;
  }
}

.neutralizer,
%neutralizer {
  margin-right: -#{map-get($gutter, base)};
  margin-left: -#{map-get($gutter, base)};
}

.no-gutters > [class^='col-'],
.no-gutters > [class*=' col-'],
%no-gutters > [class^='col-'],
%no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.gutter-left,
%gutter-left {
  margin-left: #{map-get($gutter, base)};
}

.gutter-right,
%gutter-right {
  margin-right: #{map-get($gutter, base)};
}

.gutter-top,
%gutter-top {
  margin-top: #{map-get($gutter, base)};
}

.gutter-bottom,
%gutter-bottom {
  margin-bottom: #{map-get($gutter, base)};
}

.hide {
  display: none;
}

.show {
  display: block;
}

[class*='col-'] {
  @extend %col-helper;
}



@for $i from 1 through $grid-columns {
  .col-#{$i} {
    flex-basis: ($i / $grid-columns) * 100%;
    max-width: ($i / $grid-columns) * 100%;
  }

  .order-#{$i} {
    order: $i;
  }

  .offset-#{$i} {
    margin-left: ($i / $grid-columns) * 100%;
  }
}

@each $name, $bp in $breakpoint {
  @include respond-to($name) {
    html {
      font-size: #{map-get($rem, $name)};
    }

    .wrap {
      width: calc(#{map-get($wrap, $name)} + #{map-get($gutter, $name)} * 2);
    }

    .wrap-#{$name}-full {
      width: 100vw;
      margin: 0;
      padding: 0;
    }

    .gutter {
      margin: #{map-get($gutter, $name)};
    }

    .gutter-left {
      margin-left: #{map-get($gutter, $name)};
    }

    .gutter-right {
      margin-right: #{map-get($gutter, $name)};
    }

    .gutter-top {
      margin-top: #{map-get($gutter, $name)};
    }

    .gutter-bottom {
      margin-bottom: #{map-get($gutter, $name)};
    }

    .col-helper,
    %col-helper {
      padding-right: #{map-get($gutter, $name)};
      padding-left: #{map-get($gutter, $name)};
    }

    .neutralizer,
    %neutralizer {
      margin-right: -#{map-get($gutter, $name)};
      margin-left: -#{map-get($gutter, $name)};
    }

    .hide-#{$name} {
      display: none;
    }

    .show-#{$name} {
      display: block;
    }

    @for $i from 1 through $grid-columns {
      .col-#{$name}-#{$i} {
        flex-basis: ($i / $grid-columns) * 100%;
        max-width: ($i / $grid-columns) * 100%;
      }

      .order-#{$name}-#{$i} {
        order: $i;
      }

      .offset-#{$name}-#{$i} {
        margin-left: ($i / $grid-columns) * 100%;
      }
    }

    .offset-#{$name}-0 {
      margin-left: 0;
    }
  }
}


.debug {
  .wrap {
    outline: 1px solid rgba(#f00, .5);
    outline-offset: -1px;
  }

  [class*='col-'] {
    outline: 1px solid rgba(#0f0, .5);
    outline-offset: -1px;
  }
}
