.link-with-icon {
  display: inline-flex;
  align-items: center;
  height: 2.5rem;
  color: rgba(#000, .8);
  text-decoration: none;

  &__label {
    padding: 0 0 0 .5rem;
    font-size: .75rem;
    text-decoration: underline;
  }

  .svg-icon {
    width: 2.1rem;
    height: 2.1rem;
  }

  &:hover .svg-icon {
    @extend .svg-icon--blue;
  }
}
