.social {
  display: flex;
  justify-content: flex-end;

  &__label {
    color: rgba(#000, .7);
    font-size: .9rem;
  }

  &__icon {
    width: 1.1rem;
    height: 1.1rem;
    margin-left: .8rem;
    transition: opacity .2s ease;

    &:hover {
      @extend %svg-icon--blue;
      opacity: 1;
    }
  }
}

@include respond-to(ipad-portrait) {
  .social {
    justify-content: flex-start;
  }
}
