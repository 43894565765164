.teaser__image--flyout {
  display: block;
  padding-top: 60%;
}

.teaser__inner--flyout {
  min-height: 5rem;
  margin: -3.3rem 2rem 0 .65rem;
  padding: 1.5rem 1rem 0;

  h3 {
    margin-bottom: 0;
    font-size: 1rem;
    text-transform: inherit;
  }

  p {
    margin-top: .8rem;
  }

  a {
    transition: color .15s ease-in;
    color: $black;
    text-decoration: none;
  }

  a:hover {
    color: $blue;
  }

  .teaser__text-icon {
    position: relative;
    margin-top: 2.5rem;
    padding-left: 1.4rem;
  }

  .teaser__icon {
    top: 1.2rem;
    right: 1rem;
    bottom: inherit;
    left: inherit;
    width: 1.2rem;
    height: 1.2rem;


    transform: rotate(90deg);
  }

  .teaser__icon--90deg {
    transform: rotate(0deg);
  }

  .teaser__icon--info {
    top: 0;
    right: inherit;
    left: 0;
    width: .35rem;
    height: .75rem;
    transform: rotate(0deg);

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: .7rem;
      width: .35rem;
      height: 1px;
      background-color: $black;
    }
  }
}
