.main-nav {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-start;

  &--level-1 {
    margin-right: .4rem;

    li:first-child {
      display: none;
    }
  }

  &--level-2 li:last-child .main-nav__element {
    display: none;
    margin-right: 0;
  }

  &--level-2 li:nth-last-child(2) .main-nav__element {
    margin-right: 0;
  }
}

.main-nav__element {
  display: flex;
  position: relative;
  align-items: flex-end;
  margin-right: 1.05rem;
  transition: color .3s ease;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    color: $blue;
  }

  &--bold {
    @extend %bold;

    * {
      @extend %bold;
    }
  }

  &--active::after {
    content: '\A';
    display: block;
    position: absolute;
    z-index: 1;
    bottom: -12px;
    left: -4%;
    width: 110%;
    height: 3px;
    background: $blue;
  }

  &--opened {
    a {
      color: $blue;
    }
  }
}

.main-nav__element--level-1 {
  @extend %bold;
  padding-top: 1.25rem;
  letter-spacing: -.03rem;
}

.main-nav__element--level-2 {
  font-size: 1rem;
  letter-spacing: -.02rem;
}


@include respond-to(ipad-pro) {
  .main-nav_element {
    margin-right: .85rem;
  }

  .main-nav__element--level-1 {
    font-size: 1.2rem;
  }

  .main-nav--level-1 li:first-child {
    display: block;
  }

  .main-nav--level-2 li:last-child .main-nav__element {
    display: flex;
  }

  .main-nav--level-2 li:nth-last-child(2) .main-nav__element {
    margin-right: 1.05rem;
  }
}

@include respond-to(desktop-smallest) {
  .main-nav__element {
    margin-right: 1.25rem;

    &--active::after {
      bottom: -19px;
    }
  }

  .main-nav__element--level-1 {
    padding-top: 1.4rem;
  }

  .main-nav--level-2 li:nth-last-child(2) .main-nav__element {
    margin-right: 1.3rem;
  }
}

@include respond-to(desktop-small) {
  .main-nav__element {
    padding-top: 1.15rem;
    line-height: .75rem;

    &--active::after {
      bottom: -21px;
      height: 5px;
    }
  }

  .main-nav__element--level-1 {
    font-size: 1rem;
  }

  .main-nav__element--level-2 {
    font-size: .75rem;
  }
}

@include respond-to(desktop-medium) {
  .main-nav__element {
    &--active::after {
      bottom: -21px;
    }
  }
}


@include respond-to(desktop-large) {
  .main-nav__element {
    &--active::after {
      bottom: -19px;
    }
  }
}
