.text-teaser {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 1rem;
  padding: 1rem 1rem .8rem;
  background-color: $white;
  color: #000;
  text-decoration: none;
  cursor: pointer;

  &__container {
    margin-top: -1rem;
    padding-bottom: 3rem;
  }

  &__headline {
    flex-basis: 100%;
  }

  &__col,
  .button {
    margin-top: 1rem;
  }

  &__col {
    font-size: .85rem;
  }

  &__col--align-right {
    display: flex;
    justify-content: flex-end;
  }

  &__label {
    opacity: .7;
    text-transform: uppercase;
  }

  &:hover .button {
    @extend .button--hover;
  }
}

@include respond-to(ipad-portrait) {
  .text-teaser {
    flex-direction: inherit;
    align-items: center;

    &__headline {
      margin-bottom: 1rem;
      padding-right: 2rem;
    }

    &__col {
      flex-grow: 1;
      margin-top: 0;
    }

    .button {
      margin-top: 0;
    }
  }

}

@include respond-to(desktop-smallest) {
  .text-teaser {
    padding: 1rem 1.5rem .9rem;

    &__headline {
      flex-basis: 40%;
      margin-bottom: 0;
    }

    &__col {
      flex-basis: 15%;
    }
  }
}
