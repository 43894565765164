.outlet {
  @extend %slidein-from-right;
  display: flex;
  position: fixed;
  z-index: 11;
  top: 0;
  right: 0;
  align-items: center;
  height: 100vh;
  max-width: 100%;

  &__item {
    position: relative;
    padding: 1.6rem 1.2rem 1rem;
    background-color: #fff;
    box-shadow: -1px 7px 33px 0 rgba(#000, .35);
    max-width: 100%;
  }

  &__close {
    position: absolute;
    top: 1.4rem;
    right: 1.2rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;

    &:hover {
      @extend .svg-icon--blue;
    }
  }


  h3 {
    margin-bottom: 1.4rem;
  }
}

