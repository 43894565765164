@import url("//hello.myfonts.net/count/32186d");
.center-block {
  margin-right: auto;
  margin-left: auto;
}

.clear::before, .clear::after,
.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}
.clear::after,
.clearfix::after {
  clear: both;
}

.noscroll {
  overflow: hidden;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.trans-5 {
  opacity: 0.05;
}

.trans-10 {
  opacity: 0.1;
}

.trans-15 {
  opacity: 0.15;
}

.trans-20 {
  opacity: 0.2;
}

.trans-25 {
  opacity: 0.25;
}

.trans-30, .vacancy-search__button--disabled {
  opacity: 0.3;
}

.trans-35 {
  opacity: 0.35;
}

.trans-40 {
  opacity: 0.4;
}

.trans-45 {
  opacity: 0.45;
}

.trans-50 {
  opacity: 0.5;
}

.trans-55 {
  opacity: 0.55;
}

.trans-60 {
  opacity: 0.6;
}

.trans-65 {
  opacity: 0.65;
}

.trans-70 {
  opacity: 0.7;
}

.trans-75 {
  opacity: 0.75;
}

.trans-80 {
  opacity: 0.8;
}

.trans-85 {
  opacity: 0.85;
}

.trans-90 {
  opacity: 0.9;
}

.trans-95 {
  opacity: 0.95;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-justify {
  text-align: justify;
}

.uppercase {
  text-transform: uppercase;
}

.disabled--mobile {
  display: none;
}

.f-horizontal-centered {
  justify-content: center;
}

@media (min-width: 1020px) {
  .disabled--mobile {
    display: flex;
  }
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  background-color: transparent;
}
a:active, a:hover {
  outline-width: 0;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

figure {
  margin: 1em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
}

optgroup {
  font-weight: bold;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: none;
}

:focus {
  outline: none;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  transition: color 9999s ease-out, background-color 9999s ease-out;
  transition-delay: 9999s;
}
input::-ms-clear {
  display: none;
}

input,
select,
textarea {
  border: 0;
  border-radius: 0;
}

:focus,
::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border: 0 none;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
  border: 1px solid #c0c0c0;
}

legend {
  display: table;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0;
  color: inherit;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number] ::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  opacity: 0.54;
  color: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  width: 100%;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

button {
  padding: 0;
  overflow: visible;
  border: 0;
  background: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-user-select: none;
  -webkit-appearance: button;
  -moz-user-select: none;
  -ms-user-select: none;
}

html {
  font-size: 16px;
}

.flex-helper,
.main-header__level--2,
.main-header__level--1,
.sitemap,
.border-box__project-data,
.wrap,
.inner-wrap,
h6.with-decor,
.like-h6.with-decor {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
}

.col-helper,
[class*=col-] {
  position: relative;
  min-width: 0;
  min-height: 0;
  padding-right: 0;
  padding-left: 0;
}

.wrap,
.inner-wrap,
h6.with-decor,
.like-h6.with-decor {
  width: 100%;
  margin: 0 auto;
}
.wrap.vertical-center,
.vertical-center.inner-wrap,
h6.vertical-center.with-decor,
.vertical-center.like-h6.with-decor {
  align-items: center;
}
.wrap.vertical-bottom,
.vertical-bottom.inner-wrap,
h6.vertical-bottom.with-decor,
.vertical-bottom.like-h6.with-decor {
  align-items: flex-end;
}
.wrap.full-height,
.full-height.inner-wrap,
h6.full-height.with-decor,
.full-height.like-h6.with-decor {
  align-items: stretch;
}
.wrap.full-height [class*=col-] > *,
.full-height.inner-wrap [class*=col-] > *,
h6.full-height.with-decor [class*=col-] > *,
.full-height.like-h6.with-decor [class*=col-] > * {
  height: 100%;
}
.wrap.horizontal-center,
.horizontal-center.inner-wrap,
h6.horizontal-center.with-decor,
.horizontal-center.like-h6.with-decor {
  justify-content: center;
}

.neutralizer {
  margin-right: -0;
  margin-left: -0;
}

.no-gutters > [class^=col-],
.no-gutters > [class*=" col-"],
.main-header__level--2 > [class^=col-],
.main-header__level--1 > [class^=col-],
.image-text-combo > [class^=col-],
.main-header__level--2 > [class*=col-],
.main-header__level--1 > [class*=col-],
.image-text-combo > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.gutter-left {
  margin-left: 0;
}

.gutter-right {
  margin-right: 0;
}

.gutter-top {
  margin-top: 0;
}

.gutter-bottom, .textblock {
  margin-bottom: 0;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.col-1 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}

.order-1 {
  order: 1;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.col-2 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}

.order-2 {
  order: 2;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.col-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.order-3 {
  order: 3;
}

.offset-3 {
  margin-left: 25%;
}

.col-4 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

.order-4 {
  order: 4;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.col-5 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}

.order-5 {
  order: 5;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.col-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.order-6 {
  order: 6;
}

.offset-6 {
  margin-left: 50%;
}

.col-7 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}

.order-7 {
  order: 7;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.col-8 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}

.order-8 {
  order: 8;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.col-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.order-9 {
  order: 9;
}

.offset-9 {
  margin-left: 75%;
}

.col-10 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}

.order-10 {
  order: 10;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.col-11 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}

.order-11 {
  order: 11;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.col-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.order-12 {
  order: 12;
}

.offset-12 {
  margin-left: 100%;
}

@media (min-width: 300px) {
  html {
    font-size: 16px;
  }

  .wrap {
    width: calc(100vw + 0 * 2);
  }

  .wrap-base-full {
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .gutter {
    margin: 0;
  }

  .gutter-left {
    margin-left: 0;
  }

  .gutter-right {
    margin-right: 0;
  }

  .gutter-top {
    margin-top: 0;
  }

  .gutter-bottom, .textblock {
    margin-bottom: 0;
  }

  .col-helper,
[class*=col-] {
    padding-right: 0;
    padding-left: 0;
  }

  .neutralizer {
    margin-right: -0;
    margin-left: -0;
  }

  .hide-base {
    display: none;
  }

  .show-base {
    display: block;
  }

  .col-base-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-base-1 {
    order: 1;
  }

  .offset-base-1 {
    margin-left: 8.3333333333%;
  }

  .col-base-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-base-2 {
    order: 2;
  }

  .offset-base-2 {
    margin-left: 16.6666666667%;
  }

  .col-base-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .order-base-3 {
    order: 3;
  }

  .offset-base-3 {
    margin-left: 25%;
  }

  .col-base-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-base-4 {
    order: 4;
  }

  .offset-base-4 {
    margin-left: 33.3333333333%;
  }

  .col-base-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-base-5 {
    order: 5;
  }

  .offset-base-5 {
    margin-left: 41.6666666667%;
  }

  .col-base-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .order-base-6 {
    order: 6;
  }

  .offset-base-6 {
    margin-left: 50%;
  }

  .col-base-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-base-7 {
    order: 7;
  }

  .offset-base-7 {
    margin-left: 58.3333333333%;
  }

  .col-base-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-base-8 {
    order: 8;
  }

  .offset-base-8 {
    margin-left: 66.6666666667%;
  }

  .col-base-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .order-base-9 {
    order: 9;
  }

  .offset-base-9 {
    margin-left: 75%;
  }

  .col-base-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-base-10 {
    order: 10;
  }

  .offset-base-10 {
    margin-left: 83.3333333333%;
  }

  .col-base-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-base-11 {
    order: 11;
  }

  .offset-base-11 {
    margin-left: 91.6666666667%;
  }

  .col-base-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .order-base-12 {
    order: 12;
  }

  .offset-base-12 {
    margin-left: 100%;
  }

  .offset-base-0 {
    margin-left: 0;
  }
}
@media (min-width: 350px) {
  html {
    font-size: 16px;
  }

  .wrap {
    width: calc(90vw + 6px * 2);
  }

  .wrap-mobile-full {
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .gutter {
    margin: 6px;
  }

  .gutter-left {
    margin-left: 6px;
  }

  .gutter-right {
    margin-right: 6px;
  }

  .gutter-top {
    margin-top: 6px;
  }

  .gutter-bottom, .textblock {
    margin-bottom: 6px;
  }

  .col-helper,
[class*=col-] {
    padding-right: 6px;
    padding-left: 6px;
  }

  .neutralizer {
    margin-right: -6px;
    margin-left: -6px;
  }

  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: block;
  }

  .col-mobile-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-mobile-1 {
    order: 1;
  }

  .offset-mobile-1 {
    margin-left: 8.3333333333%;
  }

  .col-mobile-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-mobile-2 {
    order: 2;
  }

  .offset-mobile-2 {
    margin-left: 16.6666666667%;
  }

  .col-mobile-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .order-mobile-3 {
    order: 3;
  }

  .offset-mobile-3 {
    margin-left: 25%;
  }

  .col-mobile-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-mobile-4 {
    order: 4;
  }

  .offset-mobile-4 {
    margin-left: 33.3333333333%;
  }

  .col-mobile-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-mobile-5 {
    order: 5;
  }

  .offset-mobile-5 {
    margin-left: 41.6666666667%;
  }

  .col-mobile-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .order-mobile-6 {
    order: 6;
  }

  .offset-mobile-6 {
    margin-left: 50%;
  }

  .col-mobile-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-mobile-7 {
    order: 7;
  }

  .offset-mobile-7 {
    margin-left: 58.3333333333%;
  }

  .col-mobile-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-mobile-8 {
    order: 8;
  }

  .offset-mobile-8 {
    margin-left: 66.6666666667%;
  }

  .col-mobile-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .order-mobile-9 {
    order: 9;
  }

  .offset-mobile-9 {
    margin-left: 75%;
  }

  .col-mobile-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-mobile-10 {
    order: 10;
  }

  .offset-mobile-10 {
    margin-left: 83.3333333333%;
  }

  .col-mobile-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-mobile-11 {
    order: 11;
  }

  .offset-mobile-11 {
    margin-left: 91.6666666667%;
  }

  .col-mobile-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .order-mobile-12 {
    order: 12;
  }

  .offset-mobile-12 {
    margin-left: 100%;
  }

  .offset-mobile-0 {
    margin-left: 0;
  }
}
@media (min-width: 540px) {
  html {
    font-size: 18px;
  }

  .wrap {
    width: calc(85vw + 8px * 2);
  }

  .wrap-mobile-landscape-full {
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .gutter {
    margin: 8px;
  }

  .gutter-left {
    margin-left: 8px;
  }

  .gutter-right {
    margin-right: 8px;
  }

  .gutter-top {
    margin-top: 8px;
  }

  .gutter-bottom, .textblock {
    margin-bottom: 8px;
  }

  .col-helper,
[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
  }

  .neutralizer {
    margin-right: -8px;
    margin-left: -8px;
  }

  .hide-mobile-landscape {
    display: none;
  }

  .show-mobile-landscape {
    display: block;
  }

  .col-mobile-landscape-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-mobile-landscape-1 {
    order: 1;
  }

  .offset-mobile-landscape-1 {
    margin-left: 8.3333333333%;
  }

  .col-mobile-landscape-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-mobile-landscape-2 {
    order: 2;
  }

  .offset-mobile-landscape-2 {
    margin-left: 16.6666666667%;
  }

  .col-mobile-landscape-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .order-mobile-landscape-3 {
    order: 3;
  }

  .offset-mobile-landscape-3 {
    margin-left: 25%;
  }

  .col-mobile-landscape-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-mobile-landscape-4 {
    order: 4;
  }

  .offset-mobile-landscape-4 {
    margin-left: 33.3333333333%;
  }

  .col-mobile-landscape-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-mobile-landscape-5 {
    order: 5;
  }

  .offset-mobile-landscape-5 {
    margin-left: 41.6666666667%;
  }

  .col-mobile-landscape-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .order-mobile-landscape-6 {
    order: 6;
  }

  .offset-mobile-landscape-6 {
    margin-left: 50%;
  }

  .col-mobile-landscape-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-mobile-landscape-7 {
    order: 7;
  }

  .offset-mobile-landscape-7 {
    margin-left: 58.3333333333%;
  }

  .col-mobile-landscape-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-mobile-landscape-8 {
    order: 8;
  }

  .offset-mobile-landscape-8 {
    margin-left: 66.6666666667%;
  }

  .col-mobile-landscape-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .order-mobile-landscape-9 {
    order: 9;
  }

  .offset-mobile-landscape-9 {
    margin-left: 75%;
  }

  .col-mobile-landscape-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-mobile-landscape-10 {
    order: 10;
  }

  .offset-mobile-landscape-10 {
    margin-left: 83.3333333333%;
  }

  .col-mobile-landscape-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-mobile-landscape-11 {
    order: 11;
  }

  .offset-mobile-landscape-11 {
    margin-left: 91.6666666667%;
  }

  .col-mobile-landscape-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .order-mobile-landscape-12 {
    order: 12;
  }

  .offset-mobile-landscape-12 {
    margin-left: 100%;
  }

  .offset-mobile-landscape-0 {
    margin-left: 0;
  }
}
@media (min-width: 767px) {
  html {
    font-size: 14px;
  }

  .wrap {
    width: calc(90vw + 10px * 2);
  }

  .wrap-ipad-portrait-full {
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .gutter {
    margin: 10px;
  }

  .gutter-left {
    margin-left: 10px;
  }

  .gutter-right {
    margin-right: 10px;
  }

  .gutter-top {
    margin-top: 10px;
  }

  .gutter-bottom, .textblock {
    margin-bottom: 10px;
  }

  .col-helper,
[class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
  }

  .neutralizer {
    margin-right: -10px;
    margin-left: -10px;
  }

  .hide-ipad-portrait {
    display: none;
  }

  .show-ipad-portrait {
    display: block;
  }

  .col-ipad-portrait-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-ipad-portrait-1 {
    order: 1;
  }

  .offset-ipad-portrait-1 {
    margin-left: 8.3333333333%;
  }

  .col-ipad-portrait-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-ipad-portrait-2 {
    order: 2;
  }

  .offset-ipad-portrait-2 {
    margin-left: 16.6666666667%;
  }

  .col-ipad-portrait-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .order-ipad-portrait-3 {
    order: 3;
  }

  .offset-ipad-portrait-3 {
    margin-left: 25%;
  }

  .col-ipad-portrait-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-ipad-portrait-4 {
    order: 4;
  }

  .offset-ipad-portrait-4 {
    margin-left: 33.3333333333%;
  }

  .col-ipad-portrait-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-ipad-portrait-5 {
    order: 5;
  }

  .offset-ipad-portrait-5 {
    margin-left: 41.6666666667%;
  }

  .col-ipad-portrait-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .order-ipad-portrait-6 {
    order: 6;
  }

  .offset-ipad-portrait-6 {
    margin-left: 50%;
  }

  .col-ipad-portrait-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-ipad-portrait-7 {
    order: 7;
  }

  .offset-ipad-portrait-7 {
    margin-left: 58.3333333333%;
  }

  .col-ipad-portrait-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-ipad-portrait-8 {
    order: 8;
  }

  .offset-ipad-portrait-8 {
    margin-left: 66.6666666667%;
  }

  .col-ipad-portrait-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .order-ipad-portrait-9 {
    order: 9;
  }

  .offset-ipad-portrait-9 {
    margin-left: 75%;
  }

  .col-ipad-portrait-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-ipad-portrait-10 {
    order: 10;
  }

  .offset-ipad-portrait-10 {
    margin-left: 83.3333333333%;
  }

  .col-ipad-portrait-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-ipad-portrait-11 {
    order: 11;
  }

  .offset-ipad-portrait-11 {
    margin-left: 91.6666666667%;
  }

  .col-ipad-portrait-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .order-ipad-portrait-12 {
    order: 12;
  }

  .offset-ipad-portrait-12 {
    margin-left: 100%;
  }

  .offset-ipad-portrait-0 {
    margin-left: 0;
  }
}
@media (min-width: 1020px) {
  html {
    font-size: 14px;
  }

  .wrap {
    width: calc(95vw + 10px * 2);
  }

  .wrap-ipad-landscape-full {
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .gutter {
    margin: 10px;
  }

  .gutter-left {
    margin-left: 10px;
  }

  .gutter-right {
    margin-right: 10px;
  }

  .gutter-top {
    margin-top: 10px;
  }

  .gutter-bottom, .textblock {
    margin-bottom: 10px;
  }

  .col-helper,
[class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
  }

  .neutralizer {
    margin-right: -10px;
    margin-left: -10px;
  }

  .hide-ipad-landscape {
    display: none;
  }

  .show-ipad-landscape {
    display: block;
  }

  .col-ipad-landscape-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-ipad-landscape-1 {
    order: 1;
  }

  .offset-ipad-landscape-1 {
    margin-left: 8.3333333333%;
  }

  .col-ipad-landscape-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-ipad-landscape-2 {
    order: 2;
  }

  .offset-ipad-landscape-2 {
    margin-left: 16.6666666667%;
  }

  .col-ipad-landscape-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .order-ipad-landscape-3 {
    order: 3;
  }

  .offset-ipad-landscape-3 {
    margin-left: 25%;
  }

  .col-ipad-landscape-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-ipad-landscape-4 {
    order: 4;
  }

  .offset-ipad-landscape-4 {
    margin-left: 33.3333333333%;
  }

  .col-ipad-landscape-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-ipad-landscape-5 {
    order: 5;
  }

  .offset-ipad-landscape-5 {
    margin-left: 41.6666666667%;
  }

  .col-ipad-landscape-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .order-ipad-landscape-6 {
    order: 6;
  }

  .offset-ipad-landscape-6 {
    margin-left: 50%;
  }

  .col-ipad-landscape-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-ipad-landscape-7 {
    order: 7;
  }

  .offset-ipad-landscape-7 {
    margin-left: 58.3333333333%;
  }

  .col-ipad-landscape-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-ipad-landscape-8 {
    order: 8;
  }

  .offset-ipad-landscape-8 {
    margin-left: 66.6666666667%;
  }

  .col-ipad-landscape-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .order-ipad-landscape-9 {
    order: 9;
  }

  .offset-ipad-landscape-9 {
    margin-left: 75%;
  }

  .col-ipad-landscape-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-ipad-landscape-10 {
    order: 10;
  }

  .offset-ipad-landscape-10 {
    margin-left: 83.3333333333%;
  }

  .col-ipad-landscape-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-ipad-landscape-11 {
    order: 11;
  }

  .offset-ipad-landscape-11 {
    margin-left: 91.6666666667%;
  }

  .col-ipad-landscape-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .order-ipad-landscape-12 {
    order: 12;
  }

  .offset-ipad-landscape-12 {
    margin-left: 100%;
  }

  .offset-ipad-landscape-0 {
    margin-left: 0;
  }
}
@media (min-width: 1190px) {
  html {
    font-size: 14px;
  }

  .wrap {
    width: calc(95vw + 10px * 2);
  }

  .wrap-ipad-pro-full {
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .gutter {
    margin: 10px;
  }

  .gutter-left {
    margin-left: 10px;
  }

  .gutter-right {
    margin-right: 10px;
  }

  .gutter-top {
    margin-top: 10px;
  }

  .gutter-bottom, .textblock {
    margin-bottom: 10px;
  }

  .col-helper,
[class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
  }

  .neutralizer {
    margin-right: -10px;
    margin-left: -10px;
  }

  .hide-ipad-pro {
    display: none;
  }

  .show-ipad-pro {
    display: block;
  }

  .col-ipad-pro-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-ipad-pro-1 {
    order: 1;
  }

  .offset-ipad-pro-1 {
    margin-left: 8.3333333333%;
  }

  .col-ipad-pro-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-ipad-pro-2 {
    order: 2;
  }

  .offset-ipad-pro-2 {
    margin-left: 16.6666666667%;
  }

  .col-ipad-pro-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .order-ipad-pro-3 {
    order: 3;
  }

  .offset-ipad-pro-3 {
    margin-left: 25%;
  }

  .col-ipad-pro-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-ipad-pro-4 {
    order: 4;
  }

  .offset-ipad-pro-4 {
    margin-left: 33.3333333333%;
  }

  .col-ipad-pro-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-ipad-pro-5 {
    order: 5;
  }

  .offset-ipad-pro-5 {
    margin-left: 41.6666666667%;
  }

  .col-ipad-pro-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .order-ipad-pro-6 {
    order: 6;
  }

  .offset-ipad-pro-6 {
    margin-left: 50%;
  }

  .col-ipad-pro-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-ipad-pro-7 {
    order: 7;
  }

  .offset-ipad-pro-7 {
    margin-left: 58.3333333333%;
  }

  .col-ipad-pro-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-ipad-pro-8 {
    order: 8;
  }

  .offset-ipad-pro-8 {
    margin-left: 66.6666666667%;
  }

  .col-ipad-pro-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .order-ipad-pro-9 {
    order: 9;
  }

  .offset-ipad-pro-9 {
    margin-left: 75%;
  }

  .col-ipad-pro-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-ipad-pro-10 {
    order: 10;
  }

  .offset-ipad-pro-10 {
    margin-left: 83.3333333333%;
  }

  .col-ipad-pro-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-ipad-pro-11 {
    order: 11;
  }

  .offset-ipad-pro-11 {
    margin-left: 91.6666666667%;
  }

  .col-ipad-pro-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .order-ipad-pro-12 {
    order: 12;
  }

  .offset-ipad-pro-12 {
    margin-left: 100%;
  }

  .offset-ipad-pro-0 {
    margin-left: 0;
  }
}
@media (min-width: 1350px) {
  html {
    font-size: 14px;
  }

  .wrap {
    width: calc(93vw + 10px * 2);
  }

  .wrap-desktop-smallest-full {
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .gutter {
    margin: 10px;
  }

  .gutter-left {
    margin-left: 10px;
  }

  .gutter-right {
    margin-right: 10px;
  }

  .gutter-top {
    margin-top: 10px;
  }

  .gutter-bottom, .textblock {
    margin-bottom: 10px;
  }

  .col-helper,
[class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
  }

  .neutralizer {
    margin-right: -10px;
    margin-left: -10px;
  }

  .hide-desktop-smallest {
    display: none;
  }

  .show-desktop-smallest {
    display: block;
  }

  .col-desktop-smallest-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-desktop-smallest-1 {
    order: 1;
  }

  .offset-desktop-smallest-1 {
    margin-left: 8.3333333333%;
  }

  .col-desktop-smallest-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-desktop-smallest-2 {
    order: 2;
  }

  .offset-desktop-smallest-2 {
    margin-left: 16.6666666667%;
  }

  .col-desktop-smallest-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .order-desktop-smallest-3 {
    order: 3;
  }

  .offset-desktop-smallest-3 {
    margin-left: 25%;
  }

  .col-desktop-smallest-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-desktop-smallest-4 {
    order: 4;
  }

  .offset-desktop-smallest-4 {
    margin-left: 33.3333333333%;
  }

  .col-desktop-smallest-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-desktop-smallest-5 {
    order: 5;
  }

  .offset-desktop-smallest-5 {
    margin-left: 41.6666666667%;
  }

  .col-desktop-smallest-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .order-desktop-smallest-6 {
    order: 6;
  }

  .offset-desktop-smallest-6 {
    margin-left: 50%;
  }

  .col-desktop-smallest-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-desktop-smallest-7 {
    order: 7;
  }

  .offset-desktop-smallest-7 {
    margin-left: 58.3333333333%;
  }

  .col-desktop-smallest-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-desktop-smallest-8 {
    order: 8;
  }

  .offset-desktop-smallest-8 {
    margin-left: 66.6666666667%;
  }

  .col-desktop-smallest-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .order-desktop-smallest-9 {
    order: 9;
  }

  .offset-desktop-smallest-9 {
    margin-left: 75%;
  }

  .col-desktop-smallest-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-desktop-smallest-10 {
    order: 10;
  }

  .offset-desktop-smallest-10 {
    margin-left: 83.3333333333%;
  }

  .col-desktop-smallest-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-desktop-smallest-11 {
    order: 11;
  }

  .offset-desktop-smallest-11 {
    margin-left: 91.6666666667%;
  }

  .col-desktop-smallest-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .order-desktop-smallest-12 {
    order: 12;
  }

  .offset-desktop-smallest-12 {
    margin-left: 100%;
  }

  .offset-desktop-smallest-0 {
    margin-left: 0;
  }
}
@media (min-width: 1420px) {
  html {
    font-size: 18px;
  }

  .wrap {
    width: calc(93vw + 10px * 2);
  }

  .wrap-desktop-small-full {
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .gutter {
    margin: 10px;
  }

  .gutter-left {
    margin-left: 10px;
  }

  .gutter-right {
    margin-right: 10px;
  }

  .gutter-top {
    margin-top: 10px;
  }

  .gutter-bottom, .textblock {
    margin-bottom: 10px;
  }

  .col-helper,
[class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
  }

  .neutralizer {
    margin-right: -10px;
    margin-left: -10px;
  }

  .hide-desktop-small {
    display: none;
  }

  .show-desktop-small {
    display: block;
  }

  .col-desktop-small-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-desktop-small-1 {
    order: 1;
  }

  .offset-desktop-small-1 {
    margin-left: 8.3333333333%;
  }

  .col-desktop-small-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-desktop-small-2 {
    order: 2;
  }

  .offset-desktop-small-2 {
    margin-left: 16.6666666667%;
  }

  .col-desktop-small-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .order-desktop-small-3 {
    order: 3;
  }

  .offset-desktop-small-3 {
    margin-left: 25%;
  }

  .col-desktop-small-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-desktop-small-4 {
    order: 4;
  }

  .offset-desktop-small-4 {
    margin-left: 33.3333333333%;
  }

  .col-desktop-small-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-desktop-small-5 {
    order: 5;
  }

  .offset-desktop-small-5 {
    margin-left: 41.6666666667%;
  }

  .col-desktop-small-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .order-desktop-small-6 {
    order: 6;
  }

  .offset-desktop-small-6 {
    margin-left: 50%;
  }

  .col-desktop-small-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-desktop-small-7 {
    order: 7;
  }

  .offset-desktop-small-7 {
    margin-left: 58.3333333333%;
  }

  .col-desktop-small-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-desktop-small-8 {
    order: 8;
  }

  .offset-desktop-small-8 {
    margin-left: 66.6666666667%;
  }

  .col-desktop-small-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .order-desktop-small-9 {
    order: 9;
  }

  .offset-desktop-small-9 {
    margin-left: 75%;
  }

  .col-desktop-small-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-desktop-small-10 {
    order: 10;
  }

  .offset-desktop-small-10 {
    margin-left: 83.3333333333%;
  }

  .col-desktop-small-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-desktop-small-11 {
    order: 11;
  }

  .offset-desktop-small-11 {
    margin-left: 91.6666666667%;
  }

  .col-desktop-small-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .order-desktop-small-12 {
    order: 12;
  }

  .offset-desktop-small-12 {
    margin-left: 100%;
  }

  .offset-desktop-small-0 {
    margin-left: 0;
  }
}
@media (min-width: 1580px) {
  html {
    font-size: 18px;
  }

  .wrap {
    width: calc(1540px + 10px * 2);
  }

  .wrap-desktop-medium-full {
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .gutter {
    margin: 10px;
  }

  .gutter-left {
    margin-left: 10px;
  }

  .gutter-right {
    margin-right: 10px;
  }

  .gutter-top {
    margin-top: 10px;
  }

  .gutter-bottom, .textblock {
    margin-bottom: 10px;
  }

  .col-helper,
[class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
  }

  .neutralizer {
    margin-right: -10px;
    margin-left: -10px;
  }

  .hide-desktop-medium {
    display: none;
  }

  .show-desktop-medium {
    display: block;
  }

  .col-desktop-medium-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-desktop-medium-1 {
    order: 1;
  }

  .offset-desktop-medium-1 {
    margin-left: 8.3333333333%;
  }

  .col-desktop-medium-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-desktop-medium-2 {
    order: 2;
  }

  .offset-desktop-medium-2 {
    margin-left: 16.6666666667%;
  }

  .col-desktop-medium-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .order-desktop-medium-3 {
    order: 3;
  }

  .offset-desktop-medium-3 {
    margin-left: 25%;
  }

  .col-desktop-medium-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-desktop-medium-4 {
    order: 4;
  }

  .offset-desktop-medium-4 {
    margin-left: 33.3333333333%;
  }

  .col-desktop-medium-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-desktop-medium-5 {
    order: 5;
  }

  .offset-desktop-medium-5 {
    margin-left: 41.6666666667%;
  }

  .col-desktop-medium-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .order-desktop-medium-6 {
    order: 6;
  }

  .offset-desktop-medium-6 {
    margin-left: 50%;
  }

  .col-desktop-medium-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-desktop-medium-7 {
    order: 7;
  }

  .offset-desktop-medium-7 {
    margin-left: 58.3333333333%;
  }

  .col-desktop-medium-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-desktop-medium-8 {
    order: 8;
  }

  .offset-desktop-medium-8 {
    margin-left: 66.6666666667%;
  }

  .col-desktop-medium-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .order-desktop-medium-9 {
    order: 9;
  }

  .offset-desktop-medium-9 {
    margin-left: 75%;
  }

  .col-desktop-medium-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-desktop-medium-10 {
    order: 10;
  }

  .offset-desktop-medium-10 {
    margin-left: 83.3333333333%;
  }

  .col-desktop-medium-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-desktop-medium-11 {
    order: 11;
  }

  .offset-desktop-medium-11 {
    margin-left: 91.6666666667%;
  }

  .col-desktop-medium-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .order-desktop-medium-12 {
    order: 12;
  }

  .offset-desktop-medium-12 {
    margin-left: 100%;
  }

  .offset-desktop-medium-0 {
    margin-left: 0;
  }
}
@media (min-width: 1700px) {
  html {
    font-size: 20px;
  }

  .wrap {
    width: calc(1650px + 10px * 2);
  }

  .wrap-desktop-large-full {
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .gutter {
    margin: 10px;
  }

  .gutter-left {
    margin-left: 10px;
  }

  .gutter-right {
    margin-right: 10px;
  }

  .gutter-top {
    margin-top: 10px;
  }

  .gutter-bottom, .textblock {
    margin-bottom: 10px;
  }

  .col-helper,
[class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
  }

  .neutralizer {
    margin-right: -10px;
    margin-left: -10px;
  }

  .hide-desktop-large {
    display: none;
  }

  .show-desktop-large {
    display: block;
  }

  .col-desktop-large-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-desktop-large-1 {
    order: 1;
  }

  .offset-desktop-large-1 {
    margin-left: 8.3333333333%;
  }

  .col-desktop-large-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-desktop-large-2 {
    order: 2;
  }

  .offset-desktop-large-2 {
    margin-left: 16.6666666667%;
  }

  .col-desktop-large-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .order-desktop-large-3 {
    order: 3;
  }

  .offset-desktop-large-3 {
    margin-left: 25%;
  }

  .col-desktop-large-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-desktop-large-4 {
    order: 4;
  }

  .offset-desktop-large-4 {
    margin-left: 33.3333333333%;
  }

  .col-desktop-large-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-desktop-large-5 {
    order: 5;
  }

  .offset-desktop-large-5 {
    margin-left: 41.6666666667%;
  }

  .col-desktop-large-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .order-desktop-large-6 {
    order: 6;
  }

  .offset-desktop-large-6 {
    margin-left: 50%;
  }

  .col-desktop-large-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-desktop-large-7 {
    order: 7;
  }

  .offset-desktop-large-7 {
    margin-left: 58.3333333333%;
  }

  .col-desktop-large-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-desktop-large-8 {
    order: 8;
  }

  .offset-desktop-large-8 {
    margin-left: 66.6666666667%;
  }

  .col-desktop-large-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .order-desktop-large-9 {
    order: 9;
  }

  .offset-desktop-large-9 {
    margin-left: 75%;
  }

  .col-desktop-large-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-desktop-large-10 {
    order: 10;
  }

  .offset-desktop-large-10 {
    margin-left: 83.3333333333%;
  }

  .col-desktop-large-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-desktop-large-11 {
    order: 11;
  }

  .offset-desktop-large-11 {
    margin-left: 91.6666666667%;
  }

  .col-desktop-large-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .order-desktop-large-12 {
    order: 12;
  }

  .offset-desktop-large-12 {
    margin-left: 100%;
  }

  .offset-desktop-large-0 {
    margin-left: 0;
  }
}
@media (min-width: 1890px) {
  html {
    font-size: 20px;
  }

  .wrap {
    width: calc(1780px + 10px * 2);
  }

  .wrap-full-full {
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .gutter {
    margin: 10px;
  }

  .gutter-left {
    margin-left: 10px;
  }

  .gutter-right {
    margin-right: 10px;
  }

  .gutter-top {
    margin-top: 10px;
  }

  .gutter-bottom, .textblock {
    margin-bottom: 10px;
  }

  .col-helper,
[class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
  }

  .neutralizer {
    margin-right: -10px;
    margin-left: -10px;
  }

  .hide-full {
    display: none;
  }

  .show-full {
    display: block;
  }

  .col-full-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .order-full-1 {
    order: 1;
  }

  .offset-full-1 {
    margin-left: 8.3333333333%;
  }

  .col-full-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .order-full-2 {
    order: 2;
  }

  .offset-full-2 {
    margin-left: 16.6666666667%;
  }

  .col-full-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .order-full-3 {
    order: 3;
  }

  .offset-full-3 {
    margin-left: 25%;
  }

  .col-full-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .order-full-4 {
    order: 4;
  }

  .offset-full-4 {
    margin-left: 33.3333333333%;
  }

  .col-full-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .order-full-5 {
    order: 5;
  }

  .offset-full-5 {
    margin-left: 41.6666666667%;
  }

  .col-full-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .order-full-6 {
    order: 6;
  }

  .offset-full-6 {
    margin-left: 50%;
  }

  .col-full-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .order-full-7 {
    order: 7;
  }

  .offset-full-7 {
    margin-left: 58.3333333333%;
  }

  .col-full-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .order-full-8 {
    order: 8;
  }

  .offset-full-8 {
    margin-left: 66.6666666667%;
  }

  .col-full-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .order-full-9 {
    order: 9;
  }

  .offset-full-9 {
    margin-left: 75%;
  }

  .col-full-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .order-full-10 {
    order: 10;
  }

  .offset-full-10 {
    margin-left: 83.3333333333%;
  }

  .col-full-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .order-full-11 {
    order: 11;
  }

  .offset-full-11 {
    margin-left: 91.6666666667%;
  }

  .col-full-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .order-full-12 {
    order: 12;
  }

  .offset-full-12 {
    margin-left: 100%;
  }

  .offset-full-0 {
    margin-left: 0;
  }
}
.debug .wrap {
  outline: 1px solid rgba(255, 0, 0, 0.5);
  outline-offset: -1px;
}
.debug [class*=col-] {
  outline: 1px solid rgba(0, 255, 0, 0.5);
  outline-offset: -1px;
}

@font-face {
  font-family: "Regular";
  font-display: swap;
  src: url("../webfonts/32186D_0_unhinted_0.woff2") format("woff2"), url("../webfonts/32186D_0_unhinted_0.woff") format("woff"), url("../webfonts/32186D_0_unhinted_0.ttf") format("truetype");
}
@font-face {
  font-family: "Bold";
  font-display: swap;
  src: url("../webfonts/32186D_1_unhinted_0.woff2") format("woff2"), url("../webfonts/32186D_1_unhinted_0.woff") format("woff"), url("../webfonts/32186D_1_unhinted_0.ttf") format("truetype");
}
.kle__learn-more p, .teaser-media h3, * {
  font-family: "Regular", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.mobile-header__nav-item--main span, .main-nav__element--level-1, .main-nav__element--bold *, .main-nav__element--bold, .hr-menu__link span, .hr-statement__quote, .hr-main-slider__box h3, .contact-zip-search__headline, .hr-call-to-action__bodytext, .hr-half-teaser__link-box h2, .homepage__label, .degree__label, strong {
  font-family: "Bold", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.icon-button__label, .detail-box__bodytext, .textblock {
  font-size: 0.85rem;
  line-height: 130%;
}

h1,
.like-h1 {
  font-size: 1.4rem;
  text-transform: uppercase;
}

h2,
.like-h2 {
  font-size: 1rem;
  text-transform: uppercase;
}

h3,
.like-h3,
.textblock--intro {
  font-size: 1.1rem;
  text-transform: uppercase;
}

h4,
.like-h4 {
  font-size: 1.1rem;
}

h5,
.like-h5 {
  font-size: 0.888rem;
  text-transform: uppercase;
}

h6,
.like-h6 {
  margin: 0 0 2.5rem;
  font-size: 1rem;
  text-transform: uppercase;
}
h6.with-decor,
.like-h6.with-decor {
  display: flex;
  width: 85%;
  align-items: center;
  height: 1rem;
  margin-bottom: 2.05rem;
}
h6.with-decor::before, h6.with-decor::after,
.like-h6.with-decor::before,
.like-h6.with-decor::after {
  content: "\a";
  display: block;
  flex-grow: 1;
  width: auto;
  height: 1px;
}
h6.with-decor::before,
.like-h6.with-decor::before {
  margin-right: 1rem;
  background: linear-gradient(to right, rgba(200, 200, 200, 0), rgba(200, 200, 200, 0.8));
}
h6.with-decor::after,
.like-h6.with-decor::after {
  margin-left: 1rem;
  background: linear-gradient(to left, rgba(200, 200, 200, 0), rgba(200, 200, 200, 0.8));
}

img {
  width: 100%;
  height: auto;
}

.centered-headline {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.center {
  text-align: center;
}

.color--blue {
  color: #005093;
}

.color--gray {
  color: #aaa;
}

strong {
  font-weight: normal;
}

small {
  font-size: 100%;
  text-transform: none;
}

sup {
  top: -1em;
  font-size: 0.5em;
}

@media (min-width: 1020px) {
  h1,
.like-h1 {
    font-size: 2rem;
  }

  h3,
.like-h3,
.textblock--intro {
    font-size: 1.45rem;
  }

  .centered-headline {
    width: 65%;
  }
}
body {
  background-color: #fff;
}

[hidden] {
  display: none !important;
}

.fullscreen {
  overflow: hidden;
}

section {
  position: relative;
}

@media (min-width: 767px) {
  .inner-wrap, h6.with-decor,
.like-h6.with-decor {
    width: 87.5%;
  }
  .inner-wrap--full {
    width: 100%;
  }
  .inner-wrap--hr, .border-box__container--numbers {
    width: 91%;
  }
}
.version-tag {
  position: fixed;
  z-index: 20;
  right: 2rem;
  bottom: 2rem;
  opacity: 0.1;
  background-color: #fff;
  font-size: 0.7rem;
  pointer-events: none;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.intro h1,
.intro h2, .hr-main-slider__slide, .threesixty__container--multi-setup.zoom-out, .cta__container, .per-page__item::after, .overlay, .detail-slider__slide, .intro-slider__slide, .textblock, .image-text-combo, .border-box, .teaser, .newsletter-popup__container {
  animation: fadein 0.45s linear both;
}

.lazy-loaded {
  animation: fadein 0.6s linear both;
}

@-webkit-keyframes slidein-from-top {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slidein-from-top {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.breadcrumb__container {
  animation: slidein-from-top 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes slidein-from-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.outlet {
  animation: slidein-from-right 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes slidein-from-bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slidein-from-bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.cookie-banner__inner {
  animation: slidein-from-bottom 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.cookie-banner__inner {
  animation-delay: 2s;
}

@keyframes underline {
  0% {
    left: 10%;
    width: 80%;
    height: 0;
  }
  100% {
    left: 0;
    width: 100%;
    height: 1px;
  }
}
.icon-button--active:not(.icon-button--inactive)::after, .icon-button--active.mobile-header__container:not(.icon-button--inactive)::after, .icon-button--selected:not(.icon-button--inactive)::after, .icon-button--selected.mobile-header__container:not(.icon-button--inactive)::after {
  animation: underline 0.3s ease both;
}

.bodytext {
  font-size: 1rem;
  line-height: 160%;
}
.bodytext h3 {
  margin-bottom: 1rem;
}
.bodytext p {
  margin-bottom: 1rem;
}
.bodytext a {
  transition: color 0.2s ease;
  color: rgba(0, 0, 0, 0.8);
}
.bodytext a:hover {
  color: #005093;
}
.bodytext ol {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  list-style: decimal-leading-zero outside;
}
.bodytext ul {
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  list-style: square outside;
}
.bodytext__intro {
  display: block;
  margin-bottom: 2rem;
}
.bodytext--inline {
  padding: 0 2rem;
}
.bodytext--detail h3 {
  margin-bottom: 0.2rem;
}

@media (min-width: 767px) {
  .bodytext--detail {
    width: 67.34%;
    padding: 0 0 0 2.7rem;
  }

  .bodytext--inline {
    padding: 0 0 0 5.2rem;
  }
}
hr {
  width: 80%;
  height: 1px;
  margin: 2.35rem auto;
  border: 0;
  background: linear-gradient(to left, rgba(200, 200, 200, 0), rgba(200, 200, 200, 0.8), rgba(200, 200, 200, 0));
}

.svg-icon {
  display: inline-block;
  line-height: 100%;
}
.svg-icon svg {
  width: 100%;
  height: auto;
}
.svg-icon svg * {
  transition: fill 0.3s ease, transform ease 0.6s;
  stroke: none;
}

.svg-icon--black svg, .overlay__close:hover svg {
  background-color: transparent;
  fill: transparent;
}
.svg-icon--black svg polygon, .overlay__close:hover svg polygon,
.svg-icon--black svg path,
.overlay__close:hover svg path,
.svg-icon--black svg rect,
.overlay__close:hover svg rect,
.svg-icon--black svg circle,
.overlay__close:hover svg circle,
.svg-icon--black svg ellipse,
.overlay__close:hover svg ellipse,
.svg-icon--black svg line,
.overlay__close:hover svg line,
.svg-icon--black svg polyline,
.overlay__close:hover svg polyline {
  fill: #000;
}
.svg-icon--black svg .stroke, .overlay__close:hover svg .stroke {
  stroke: #000;
  fill: transparent;
}

.svg-icon--white svg, .vacancy-search__filter:hover .vacancy-search__filter-close svg {
  background-color: transparent;
  fill: transparent;
}
.svg-icon--white svg polygon, .vacancy-search__filter:hover .vacancy-search__filter-close svg polygon,
.svg-icon--white svg path,
.vacancy-search__filter:hover .vacancy-search__filter-close svg path,
.svg-icon--white svg rect,
.vacancy-search__filter:hover .vacancy-search__filter-close svg rect,
.svg-icon--white svg circle,
.vacancy-search__filter:hover .vacancy-search__filter-close svg circle,
.svg-icon--white svg ellipse,
.vacancy-search__filter:hover .vacancy-search__filter-close svg ellipse,
.svg-icon--white svg line,
.vacancy-search__filter:hover .vacancy-search__filter-close svg line,
.svg-icon--white svg polyline,
.vacancy-search__filter:hover .vacancy-search__filter-close svg polyline {
  fill: #fff;
}
.svg-icon--white svg .stroke, .vacancy-search__filter:hover .vacancy-search__filter-close svg .stroke {
  stroke: #fff;
  fill: transparent;
}

.svg-icon--blue svg, .jump-navigation__item:hover .jump-navigation__icon svg,
.jump-navigation__item:hover .jump-navigation__arrow svg, .image-comparison-slider__handle:hover .image-comparison-slider__arrow svg, .contact-zip-search__close:hover svg, .link-with-icon:hover .svg-icon svg, .search-pagination__icon:hover svg, .pull-down__icon:hover svg, .outlet__close:hover svg, .searchresult:hover .searchresult__icon svg, .google-maps__marker-window .svg-icon:hover svg, .zoom-icon--hover .svg-icon svg, .article-detail__block--media:hover .zoom-icon .svg-icon svg, .zoom-icon:hover .svg-icon svg,
.contact-nav__item:hover .contact-nav__icon svg,
.social__icon:hover svg,
.hr-statement-slider__arrow:hover .svg-icon svg,
.hr-main-slider__arrow:hover .svg-icon svg,
.threesixty__navigation:hover .svg-icon svg,
.icon-button--active:not(.icon-button--inactive) .icon-button__icon svg,
.icon-button--selected:not(.icon-button--inactive) .icon-button__icon svg,
.inner-slider__button:hover svg,
.inner-slider__arrow:hover .svg-icon svg,
.intro-slider__arrow:hover .svg-icon svg,
.tc-blueprint-slider__arrow:hover .svg-icon svg,
.project-detail-slider__arrow:hover .svg-icon svg,
.border-box--blue .inner-slider__arrow .svg-icon svg,
.teaser:not(.teaser--static):hover .teaser__icon svg {
  background-color: transparent;
  fill: transparent;
}
.svg-icon--blue svg polygon, .jump-navigation__item:hover .jump-navigation__icon svg polygon,
.jump-navigation__item:hover .jump-navigation__arrow svg polygon, .image-comparison-slider__handle:hover .image-comparison-slider__arrow svg polygon, .contact-zip-search__close:hover svg polygon, .link-with-icon:hover .svg-icon svg polygon, .search-pagination__icon:hover svg polygon, .pull-down__icon:hover svg polygon, .outlet__close:hover svg polygon, .searchresult:hover .searchresult__icon svg polygon, .google-maps__marker-window .svg-icon:hover svg polygon, .zoom-icon--hover .svg-icon svg polygon, .article-detail__block--media:hover .zoom-icon .svg-icon svg polygon, .zoom-icon:hover .svg-icon svg polygon,
.svg-icon--blue svg path,
.jump-navigation__item:hover .jump-navigation__icon svg path,
.jump-navigation__item:hover .jump-navigation__arrow svg path,
.image-comparison-slider__handle:hover .image-comparison-slider__arrow svg path,
.contact-zip-search__close:hover svg path,
.link-with-icon:hover .svg-icon svg path,
.search-pagination__icon:hover svg path,
.pull-down__icon:hover svg path,
.outlet__close:hover svg path,
.searchresult:hover .searchresult__icon svg path,
.google-maps__marker-window .svg-icon:hover svg path,
.zoom-icon--hover .svg-icon svg path,
.article-detail__block--media:hover .zoom-icon .svg-icon svg path,
.zoom-icon:hover .svg-icon svg path,
.svg-icon--blue svg rect,
.jump-navigation__item:hover .jump-navigation__icon svg rect,
.jump-navigation__item:hover .jump-navigation__arrow svg rect,
.image-comparison-slider__handle:hover .image-comparison-slider__arrow svg rect,
.contact-zip-search__close:hover svg rect,
.link-with-icon:hover .svg-icon svg rect,
.search-pagination__icon:hover svg rect,
.pull-down__icon:hover svg rect,
.outlet__close:hover svg rect,
.searchresult:hover .searchresult__icon svg rect,
.google-maps__marker-window .svg-icon:hover svg rect,
.zoom-icon--hover .svg-icon svg rect,
.article-detail__block--media:hover .zoom-icon .svg-icon svg rect,
.zoom-icon:hover .svg-icon svg rect,
.svg-icon--blue svg circle,
.jump-navigation__item:hover .jump-navigation__icon svg circle,
.jump-navigation__item:hover .jump-navigation__arrow svg circle,
.image-comparison-slider__handle:hover .image-comparison-slider__arrow svg circle,
.contact-zip-search__close:hover svg circle,
.link-with-icon:hover .svg-icon svg circle,
.search-pagination__icon:hover svg circle,
.pull-down__icon:hover svg circle,
.outlet__close:hover svg circle,
.searchresult:hover .searchresult__icon svg circle,
.google-maps__marker-window .svg-icon:hover svg circle,
.zoom-icon--hover .svg-icon svg circle,
.article-detail__block--media:hover .zoom-icon .svg-icon svg circle,
.zoom-icon:hover .svg-icon svg circle,
.svg-icon--blue svg ellipse,
.jump-navigation__item:hover .jump-navigation__icon svg ellipse,
.jump-navigation__item:hover .jump-navigation__arrow svg ellipse,
.image-comparison-slider__handle:hover .image-comparison-slider__arrow svg ellipse,
.contact-zip-search__close:hover svg ellipse,
.link-with-icon:hover .svg-icon svg ellipse,
.search-pagination__icon:hover svg ellipse,
.pull-down__icon:hover svg ellipse,
.outlet__close:hover svg ellipse,
.searchresult:hover .searchresult__icon svg ellipse,
.google-maps__marker-window .svg-icon:hover svg ellipse,
.zoom-icon--hover .svg-icon svg ellipse,
.article-detail__block--media:hover .zoom-icon .svg-icon svg ellipse,
.zoom-icon:hover .svg-icon svg ellipse,
.svg-icon--blue svg line,
.jump-navigation__item:hover .jump-navigation__icon svg line,
.jump-navigation__item:hover .jump-navigation__arrow svg line,
.image-comparison-slider__handle:hover .image-comparison-slider__arrow svg line,
.contact-zip-search__close:hover svg line,
.link-with-icon:hover .svg-icon svg line,
.search-pagination__icon:hover svg line,
.pull-down__icon:hover svg line,
.outlet__close:hover svg line,
.searchresult:hover .searchresult__icon svg line,
.google-maps__marker-window .svg-icon:hover svg line,
.zoom-icon--hover .svg-icon svg line,
.article-detail__block--media:hover .zoom-icon .svg-icon svg line,
.zoom-icon:hover .svg-icon svg line,
.svg-icon--blue svg polyline,
.jump-navigation__item:hover .jump-navigation__icon svg polyline,
.jump-navigation__item:hover .jump-navigation__arrow svg polyline,
.image-comparison-slider__handle:hover .image-comparison-slider__arrow svg polyline,
.contact-zip-search__close:hover svg polyline,
.link-with-icon:hover .svg-icon svg polyline,
.search-pagination__icon:hover svg polyline,
.pull-down__icon:hover svg polyline,
.outlet__close:hover svg polyline,
.searchresult:hover .searchresult__icon svg polyline,
.google-maps__marker-window .svg-icon:hover svg polyline,
.zoom-icon--hover .svg-icon svg polyline,
.article-detail__block--media:hover .zoom-icon .svg-icon svg polyline,
.zoom-icon:hover .svg-icon svg polyline,
.contact-nav__item:hover .contact-nav__icon svg polygon,
.social__icon:hover svg polygon,
.hr-statement-slider__arrow:hover .svg-icon svg polygon,
.hr-main-slider__arrow:hover .svg-icon svg polygon,
.threesixty__navigation:hover .svg-icon svg polygon,
.icon-button--active:not(.icon-button--inactive) .icon-button__icon svg polygon,
.icon-button--selected:not(.icon-button--inactive) .icon-button__icon svg polygon,
.inner-slider__button:hover svg polygon,
.inner-slider__arrow:hover .svg-icon svg polygon,
.intro-slider__arrow:hover .svg-icon svg polygon,
.tc-blueprint-slider__arrow:hover .svg-icon svg polygon,
.project-detail-slider__arrow:hover .svg-icon svg polygon,
.border-box--blue .inner-slider__arrow .svg-icon svg polygon,
.teaser:not(.teaser--static):hover .teaser__icon svg polygon,
.contact-nav__item:hover .contact-nav__icon svg path,
.social__icon:hover svg path,
.hr-statement-slider__arrow:hover .svg-icon svg path,
.hr-main-slider__arrow:hover .svg-icon svg path,
.threesixty__navigation:hover .svg-icon svg path,
.icon-button--active:not(.icon-button--inactive) .icon-button__icon svg path,
.icon-button--selected:not(.icon-button--inactive) .icon-button__icon svg path,
.inner-slider__button:hover svg path,
.inner-slider__arrow:hover .svg-icon svg path,
.intro-slider__arrow:hover .svg-icon svg path,
.tc-blueprint-slider__arrow:hover .svg-icon svg path,
.project-detail-slider__arrow:hover .svg-icon svg path,
.border-box--blue .inner-slider__arrow .svg-icon svg path,
.teaser:not(.teaser--static):hover .teaser__icon svg path,
.contact-nav__item:hover .contact-nav__icon svg rect,
.social__icon:hover svg rect,
.hr-statement-slider__arrow:hover .svg-icon svg rect,
.hr-main-slider__arrow:hover .svg-icon svg rect,
.threesixty__navigation:hover .svg-icon svg rect,
.icon-button--active:not(.icon-button--inactive) .icon-button__icon svg rect,
.icon-button--selected:not(.icon-button--inactive) .icon-button__icon svg rect,
.inner-slider__button:hover svg rect,
.inner-slider__arrow:hover .svg-icon svg rect,
.intro-slider__arrow:hover .svg-icon svg rect,
.tc-blueprint-slider__arrow:hover .svg-icon svg rect,
.project-detail-slider__arrow:hover .svg-icon svg rect,
.border-box--blue .inner-slider__arrow .svg-icon svg rect,
.teaser:not(.teaser--static):hover .teaser__icon svg rect,
.contact-nav__item:hover .contact-nav__icon svg circle,
.social__icon:hover svg circle,
.hr-statement-slider__arrow:hover .svg-icon svg circle,
.hr-main-slider__arrow:hover .svg-icon svg circle,
.threesixty__navigation:hover .svg-icon svg circle,
.icon-button--active:not(.icon-button--inactive) .icon-button__icon svg circle,
.icon-button--selected:not(.icon-button--inactive) .icon-button__icon svg circle,
.inner-slider__button:hover svg circle,
.inner-slider__arrow:hover .svg-icon svg circle,
.intro-slider__arrow:hover .svg-icon svg circle,
.tc-blueprint-slider__arrow:hover .svg-icon svg circle,
.project-detail-slider__arrow:hover .svg-icon svg circle,
.border-box--blue .inner-slider__arrow .svg-icon svg circle,
.teaser:not(.teaser--static):hover .teaser__icon svg circle,
.contact-nav__item:hover .contact-nav__icon svg ellipse,
.social__icon:hover svg ellipse,
.hr-statement-slider__arrow:hover .svg-icon svg ellipse,
.hr-main-slider__arrow:hover .svg-icon svg ellipse,
.threesixty__navigation:hover .svg-icon svg ellipse,
.icon-button--active:not(.icon-button--inactive) .icon-button__icon svg ellipse,
.icon-button--selected:not(.icon-button--inactive) .icon-button__icon svg ellipse,
.inner-slider__button:hover svg ellipse,
.inner-slider__arrow:hover .svg-icon svg ellipse,
.intro-slider__arrow:hover .svg-icon svg ellipse,
.tc-blueprint-slider__arrow:hover .svg-icon svg ellipse,
.project-detail-slider__arrow:hover .svg-icon svg ellipse,
.border-box--blue .inner-slider__arrow .svg-icon svg ellipse,
.teaser:not(.teaser--static):hover .teaser__icon svg ellipse,
.contact-nav__item:hover .contact-nav__icon svg line,
.social__icon:hover svg line,
.hr-statement-slider__arrow:hover .svg-icon svg line,
.hr-main-slider__arrow:hover .svg-icon svg line,
.threesixty__navigation:hover .svg-icon svg line,
.icon-button--active:not(.icon-button--inactive) .icon-button__icon svg line,
.icon-button--selected:not(.icon-button--inactive) .icon-button__icon svg line,
.inner-slider__button:hover svg line,
.inner-slider__arrow:hover .svg-icon svg line,
.intro-slider__arrow:hover .svg-icon svg line,
.tc-blueprint-slider__arrow:hover .svg-icon svg line,
.project-detail-slider__arrow:hover .svg-icon svg line,
.border-box--blue .inner-slider__arrow .svg-icon svg line,
.teaser:not(.teaser--static):hover .teaser__icon svg line,
.contact-nav__item:hover .contact-nav__icon svg polyline,
.social__icon:hover svg polyline,
.hr-statement-slider__arrow:hover .svg-icon svg polyline,
.hr-main-slider__arrow:hover .svg-icon svg polyline,
.threesixty__navigation:hover .svg-icon svg polyline,
.icon-button--active:not(.icon-button--inactive) .icon-button__icon svg polyline,
.icon-button--selected:not(.icon-button--inactive) .icon-button__icon svg polyline,
.inner-slider__button:hover svg polyline,
.inner-slider__arrow:hover .svg-icon svg polyline,
.intro-slider__arrow:hover .svg-icon svg polyline,
.tc-blueprint-slider__arrow:hover .svg-icon svg polyline,
.project-detail-slider__arrow:hover .svg-icon svg polyline,
.border-box--blue .inner-slider__arrow .svg-icon svg polyline,
.teaser:not(.teaser--static):hover .teaser__icon svg polyline {
  fill: #005093;
}
.svg-icon--blue svg .stroke, .jump-navigation__item:hover .jump-navigation__icon svg .stroke,
.jump-navigation__item:hover .jump-navigation__arrow svg .stroke, .image-comparison-slider__handle:hover .image-comparison-slider__arrow svg .stroke, .contact-zip-search__close:hover svg .stroke, .link-with-icon:hover .svg-icon svg .stroke, .search-pagination__icon:hover svg .stroke, .pull-down__icon:hover svg .stroke, .outlet__close:hover svg .stroke, .searchresult:hover .searchresult__icon svg .stroke, .google-maps__marker-window .svg-icon:hover svg .stroke, .zoom-icon--hover .svg-icon svg .stroke, .article-detail__block--media:hover .zoom-icon .svg-icon svg .stroke, .zoom-icon:hover .svg-icon svg .stroke,
.contact-nav__item:hover .contact-nav__icon svg .stroke,
.social__icon:hover svg .stroke,
.hr-statement-slider__arrow:hover .svg-icon svg .stroke,
.hr-main-slider__arrow:hover .svg-icon svg .stroke,
.threesixty__navigation:hover .svg-icon svg .stroke,
.icon-button--active:not(.icon-button--inactive) .icon-button__icon svg .stroke,
.icon-button--selected:not(.icon-button--inactive) .icon-button__icon svg .stroke,
.inner-slider__button:hover svg .stroke,
.inner-slider__arrow:hover .svg-icon svg .stroke,
.intro-slider__arrow:hover .svg-icon svg .stroke,
.tc-blueprint-slider__arrow:hover .svg-icon svg .stroke,
.project-detail-slider__arrow:hover .svg-icon svg .stroke,
.border-box--blue .inner-slider__arrow .svg-icon svg .stroke,
.teaser:not(.teaser--static):hover .teaser__icon svg .stroke {
  stroke: #005093;
  fill: transparent;
}

.svg-icon--red svg, .delete-button:hover .delete-button__icon svg {
  background-color: transparent;
  fill: transparent;
}
.svg-icon--red svg polygon, .delete-button:hover .delete-button__icon svg polygon,
.svg-icon--red svg path,
.delete-button:hover .delete-button__icon svg path,
.svg-icon--red svg rect,
.delete-button:hover .delete-button__icon svg rect,
.svg-icon--red svg circle,
.delete-button:hover .delete-button__icon svg circle,
.svg-icon--red svg ellipse,
.delete-button:hover .delete-button__icon svg ellipse,
.svg-icon--red svg line,
.delete-button:hover .delete-button__icon svg line,
.svg-icon--red svg polyline,
.delete-button:hover .delete-button__icon svg polyline {
  fill: #7a1515;
}
.svg-icon--red svg .stroke, .delete-button:hover .delete-button__icon svg .stroke {
  stroke: #7a1515;
  fill: transparent;
}

.svg-icon--grey svg {
  background-color: transparent;
  fill: transparent;
}
.svg-icon--grey svg polygon,
.svg-icon--grey svg path,
.svg-icon--grey svg rect,
.svg-icon--grey svg circle,
.svg-icon--grey svg ellipse,
.svg-icon--grey svg line,
.svg-icon--grey svg polyline {
  fill: #3b3b3b;
}
.svg-icon--grey svg .stroke {
  stroke: #3b3b3b;
  fill: transparent;
}

.badge {
  display: inline-flex;
  align-items: center;
  height: 0.9rem;
  padding: 0 0.45rem;
  transition: background-color 0.2s ease, color 0.2s ease;
  border-radius: 0.2rem;
  background: rgba(216, 216, 216, 0.5);
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.55rem;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.loader img {
  width: 12rem;
  height: auto;
}

.shortfact {
  display: flex;
  flex-basis: 50%;
  align-items: flex-end;
  margin: 0 0 1rem;
}
.shortfact__icon {
  width: 2rem;
  height: 2rem;
  margin: -0.2rem 0.5rem 0 0;
}
.shortfact__label {
  margin-bottom: -0.1rem;
  font-size: 0.85rem;
}

.shortfact_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0 0.2rem 0.7rem;
  padding-bottom: 1.35rem;
}

@media (min-width: 767px) {
  .shortfact {
    flex-basis: auto;
    margin: 0 0 0 2rem;
  }
}
.groundplan {
  display: block;
  width: 100%;
  height: auto;
  transition: filter 0.3s ease;
  cursor: pointer;
}

.zoom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 2.9rem;
  transition: background-color 0.2s ease;
  background-color: #fff;
}
.zoom-icon .svg-icon {
  width: 1.25rem;
  height: 1.25rem;
}
.degree__container {
  display: none;
  position: relative;
  width: calc(100% - 70px);
  height: 3rem;
}

.degree {
  display: flex;
  position: absolute;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  width: 80px;
  text-align: center;
}
.degree__label {
  margin-left: 0.5rem;
  color: #005093;
  font-size: 0.85rem;
}
.degree::after {
  content: "\a";
  display: block;
  width: 1px;
  height: 15px;
  margin: 0.5rem 0 0.75rem;
  background-color: #d8d8d8;
}

@media (min-width: 767px) {
  .degree__container {
    display: block;
  }
}
.lazy-loading {
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMzBweCIgdmlld0JveD0iMCAwIDI0IDMwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MCA1MDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPiAgICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iNCIgaGVpZ2h0PSIxMCIgZmlsbD0iI0FBQSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAzLjM2ODQpIj4gICAgICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVUeXBlPSJ4bWwiIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0idHJhbnNsYXRlIiB2YWx1ZXM9IjAgMDsgMCAyMDsgMCAwIiBiZWdpbj0iMCIgZHVyPSIxLjJzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSI+PC9hbmltYXRlVHJhbnNmb3JtPiAgICA8L3JlY3Q+ICAgIDxyZWN0IHg9IjEwIiB5PSIwIiB3aWR0aD0iNCIgaGVpZ2h0PSIxMCIgZmlsbD0iI0FBQSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAxNi43MDE3KSI+ICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmlidXRlVHlwZT0ieG1sIiBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InRyYW5zbGF0ZSIgdmFsdWVzPSIwIDA7IDAgMjA7IDAgMCIgYmVnaW49IjAuNHMiIGR1cj0iMS4ycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZVRyYW5zZm9ybT4gICAgPC9yZWN0PiAgICA8cmVjdCB4PSIyMCIgeT0iMCIgd2lkdGg9IjQiIGhlaWdodD0iMTAiIGZpbGw9IiNBQUEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgOS45NjQ5MykiPiAgICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZVR5cGU9InhtbCIgYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJ0cmFuc2xhdGUiIHZhbHVlcz0iMCAwOyAwIDIwOyAwIDAiIGJlZ2luPSIwLjhzIiBkdXI9IjEuMnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIj48L2FuaW1hdGVUcmFuc2Zvcm0+ICAgIDwvcmVjdD4gIDwvc3ZnPg==) center no-repeat !important;
  background-size: 1rem;
}

.pictogram {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background-color: #fff;
  pointer-events: none;
}
.pictogram__icon {
  width: 2.5rem;
  height: 2.5rem;
}

.newsletter-popup__container {
  display: flex;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.82);
}

.newsletter-popup {
  display: block;
  position: relative;
  width: 80vw;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.1);
}
.newsletter-popup__close {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  width: 3.15rem;
  height: 3.15rem;
  background-color: #005093;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.newsletter-popup__close .svg-icon {
  width: 1.2rem;
  height: 1.2rem;
}
.newsletter-popup__close:hover {
  background-color: #000;
}
.newsletter-popup__headline--accent {
  margin-bottom: -0.3rem;
  opacity: 0.32;
}
.newsletter-popup__content {
  margin: 0.5rem 0 1rem;
  line-height: 150%;
}

@media (min-width: 1020px) {
  .newsletter-popup {
    width: 55.8vw;
    min-height: 27.1vw;
    background: #fff url("/images/newsletter-popup.png") no-repeat top left;
    padding: 9vw 1vw 2vw 31vw;
    background-size: contain;
  }
}
.listing-wrap li {
  line-height: 2.15;
  font-size: 1.4rem;
  position: relative;
  opacity: 0.75;
}
.listing-wrap li:before {
  content: "";
  height: 3px;
  background: black;
  display: block;
  width: 1rem;
  position: absolute;
  top: 1.8rem;
  left: -2rem;
}

.listing-wrap__brackets {
  width: 100%;
  padding-left: 25px;
}
@media (min-width: 1020px) {
  .listing-wrap__brackets {
    padding-left: 15%;
  }
}
.listing-wrap__brackets li {
  text-transform: uppercase;
}
.listing-wrap__brackets li a {
  text-decoration: none;
}
.listing-wrap__brackets a {
  text-decoration: underline;
  color: inherit;
}

.border-box__listing-brackets {
  max-width: 100%;
}

.mykleusberg--header.intro {
  padding-top: 1rem;
}
.mykleusberg--header .breadcrumb {
  height: 1.75rem;
  margin-bottom: 2rem;
}

.svg-icon--my-kleusberg-login {
  width: 1.3rem;
  height: auto;
  position: relative;
  right: 8px;
  top: 2px;
}

.svg-icon--my-kleusberg-logout {
  width: 0.8rem;
  opacity: 0.5;
  top: 3px;
  position: relative;
  right: 12px;
}

.multi-pdf-export--container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: flex;
}
.multi-pdf-export--container .multi-pdf-export--controls {
  background-color: #e1eaf3;
  box-shadow: 0 0 1rem 3px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 1rem;
}
.multi-pdf-export--container .multi-pdf-export--controls .multi-pdf-export__reference-count {
  color: #005093;
}
.multi-pdf-export--container .multi-pdf-export--controls .button {
  margin: 0 0 1rem 0;
}
@media (min-width: 767px) {
  .multi-pdf-export--container .multi-pdf-export--controls .button {
    margin: 0 0 0 1.5rem;
  }
}

.multi-pdf--reset svg {
  width: 0.8rem;
  height: 0.8rem;
  opacity: 0.8;
  margin-left: 0.8rem;
}

.multi-pdf-selector {
  display: flex;
  position: absolute;
  top: 0;
  right: 10px;
  align-items: center;
  justify-content: center;
  width: 3.15rem;
  height: 3.15rem;
  background-color: #005093;
  cursor: pointer;
  z-index: 1;
}
.multi-pdf-selector:hover .svg-icon {
  opacity: 0.5;
}
.multi-pdf-selector.selected .svg-icon {
  opacity: 1 !important;
}
.multi-pdf-selector .svg-icon {
  width: 1.5rem;
  height: 1.5rem;
  transform: rotate(-10deg);
  opacity: 0.25;
  transition: opacity 0.1s;
}
.section--teaser-list-unfiltered .multi-pdf-selector {
  right: 0;
}

.mykleusberg--logout {
  cursor: pointer;
}

.top-level-container {
  display: inline-flex;
  align-items: center;
  height: 1rem;
}

.welcome {
  display: none;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9rem;
  text-transform: uppercase;
}

@media (min-width: 1350px) {
  .welcome {
    display: inline-flex;
  }
}
@media (min-width: 1420px) {
  .welcome {
    font-size: 0.65rem;
  }
}
.logo {
  display: inline-block;
  width: 9.5rem;
  margin-left: auto;
  transition: width 0.3s ease, height 0.3s ease;
  line-height: 0;
}

.logo__container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
}

@media (min-width: 1190px) {
  .logo {
    width: 10rem;
  }
}
@media (min-width: 1350px) {
  .logo {
    width: 10rem;
  }
}
@media (min-width: 1420px) {
  .logo {
    width: 10rem;
  }
}
.homepage__label {
  display: none;
}

.homepage__icon {
  display: none;
  width: 1.065rem;
  height: 1rem;
  height: initial;
  margin: 0 -0.2rem 0 0;
  line-height: 1.45rem;
}

@media (min-width: 1350px) {
  .homepage__label {
    display: block;
  }

  .homepage__icon {
    display: block;
    margin-right: 0.6rem;
  }
}
@media (min-width: 1420px) {
  .homepage__icon {
    width: 0.9rem;
    height: 0.9rem;
    height: initial;
    line-height: 0.75rem;
  }
}
.meta-nav {
  display: inline-flex;
}
.meta-nav .tool-nav__item {
  margin-right: 2.3rem;
}
.meta-nav .main-nav__element--level-2 {
  align-items: flex-start;
  height: 0.9rem;
  font-size: 0.9rem;
}

@media (min-width: 1190px) {
  .meta-nav .main-nav__element {
    display: none;
  }
}
.teaser {
  display: block;
  position: relative;
  overflow: hidden;
  transition: opacity 0.2s ease;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.teaser__pictogram {
  position: absolute;
  top: 1.75rem;
  left: 1.75rem;
}
.teaser__icon {
  position: absolute;
  bottom: 0;
  width: 1.45rem;
  height: 1.45rem;
  opacity: 0.8;
}
.teaser__link-list {
  margin: 0 -1rem;
}
.teaser--overview p {
  position: relative;
}
.teaser--inactive {
  pointer-events: none;
}
.teaser--inactive .teaser__image {
  filter: opacity(40%) grayscale(100%) brightness(110%);
}
.teaser--inactive .teaser__inner > * {
  opacity: 0.4;
}
.teaser:not(.teaser--static):hover .teaser__image {
  filter: opacity(95%);
}
.teaser__inner {
  position: relative;
  max-width: 27rem;
  min-height: 6rem;
  margin: -2.1rem 2.5rem 0 1.1rem;
  padding: 1rem 0.5rem 1.4rem 1rem;
}
.teaser__inner > * {
  transition: opacity 0.2s ease;
}
.teaser__inner h3 {
  width: calc(100% - 1.9rem);
  margin-bottom: 0.8rem;
  opacity: 0.8;
  font-size: 1.35rem;
  line-height: 100%;
}
.teaser__inner h3.no-margin {
  margin-bottom: 0.1rem;
}
.teaser__inner h4 {
  padding-bottom: 0.5rem;
  opacity: 0.8;
  font-size: 1rem;
}
.teaser__inner p {
  opacity: 0.8;
  font-size: 1rem;
  line-height: 130%;
}
.teaser__inner .button {
  margin-top: 1rem;
}
.teaser__inner--small {
  margin-top: -3rem;
  padding-right: 0;
}
.teaser__inner--small h3 {
  width: auto;
}
.teaser__inner--big {
  margin-top: -2.28rem;
}
.teaser__inner--big h3 {
  text-transform: inherit;
}
.teaser__inner--big .teaser__icon {
  bottom: 0;
  left: 1rem;
}
.teaser__inner--flat {
  margin-top: -2.28rem;
}
.teaser__inner--flat p {
  padding-bottom: 0.2rem;
}
.teaser__inner--flat .teaser__icon {
  top: 1.6rem;
  right: 1.6rem;
}
.teaser__inner--large, .teaser__inner--expansion-panel {
  padding: 1rem;
}
.teaser__inner--large h3, .teaser__inner--expansion-panel h3 {
  line-height: 120%;
}
.teaser__inner--large .teaser__icon, .teaser__inner--expansion-panel .teaser__icon {
  top: 0.75rem;
  right: 0.75rem;
}
.teaser__inner--expansion-panel {
  padding-bottom: 0;
}
.teaser__inner--on-gray {
  background-color: #f5f4f5;
}
.teaser__inner--on-white {
  background-color: #fff;
}

@media (min-width: 767px) {
  .teaser__inner--big {
    padding-bottom: 0;
  }
  .teaser__inner--big .teaser__icon {
    top: 1rem;
    right: 1rem;
    bottom: inherit;
    left: inherit;
  }
}
@media (min-width: 1020px) {
  .teaser__inner {
    margin: -4.35rem 7.5rem 0 1.3rem;
    padding: 1.4rem 1rem 1rem 1.7rem;
  }
  .teaser__inner h3 {
    font-size: 1.45rem;
  }
  .teaser__inner p {
    font-size: 1.1rem;
  }
  .teaser__inner--small {
    margin: -3.6rem 4rem 0 1rem;
    padding: 1.1rem 0 0 1.1rem;
  }
  .teaser__inner--small p {
    line-height: 110%;
  }
  .teaser__inner--full {
    padding: 0.8rem 0.5rem 1.4rem;
  }
  .teaser__inner--full .teaser__icon {
    top: 0.75rem;
    right: 0.75rem;
  }
  .teaser__inner--large .teaser__icon {
    top: 1.25rem;
    right: 1.25rem;
    width: 1.25rem;
  }
}
@media (min-width: 1350px) {
  .teaser__inner--flyout {
    margin-right: 6rem;
  }
}
@media (min-width: 1420px) {
  .teaser__inner h3 {
    width: calc(100% - 2.5rem);
    font-size: 1.5rem;
  }
  .teaser__inner p {
    font-size: 0.85rem;
  }
  .teaser__inner .teaser__icon {
    top: 1.7rem;
    right: 1.5rem;
    width: 1.5rem;
  }
  .teaser__inner--small {
    margin: -4.15rem 5.45rem 0 1.3rem;
    padding: 1.5rem 0.5rem 0 1.3rem;
  }
  .teaser__inner--big {
    margin: -5rem 6.3rem 0 1.7rem;
    padding: 1.75rem 0.5rem 0 2.1rem;
  }
  .teaser__inner--large, .teaser__inner--expansion-panel {
    margin: -5.45rem 9.5rem 0 1.75rem;
    padding: 1.75rem 3rem 0 2.3rem;
  }
  .teaser__inner--expansion-panel h3 {
    margin-bottom: 2rem;
  }
  .teaser__inner--full {
    position: absolute;
    bottom: 0;
    margin: 0 9.5rem 0 1.75rem;
    padding: 1.85rem 1.5rem 1.2rem 2.1rem;
  }
  .teaser__inner--flat {
    width: 49%;
    margin: -5.4rem 0 0 1.75rem;
    padding: 1.9rem 0.5rem 0 2.1rem;
  }
}
.teaser__image {
  position: relative;
  transition: filter 0.3s ease;
  background: no-repeat center;
  background-size: cover;
}
.teaser__image .play-button {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 4.5rem;
  height: 4.5rem;
  margin: -2.25rem -2.25rem 0 0;
}
.teaser__image--small {
  padding-top: 88.2%;
}
.teaser__image--flat {
  padding-top: 39.77%;
}
.teaser__image--full {
  padding-top: 43.88%;
}
.teaser__image--big {
  padding-top: 60%;
}
.teaser__image--large {
  padding-top: 54.19%;
}
.teaser__image--expansion-panel, .teaser__image--project {
  padding-top: 46.77%;
}

.teaser--static {
  cursor: auto;
}
.teaser--static .teaser__inner h3 {
  line-height: 120%;
  text-transform: uppercase;
}
@media (min-width: 1020px) {
  .teaser--static .teaser__inner h3 {
    font-size: 2rem;
  }
}

.teaser__image--flyout {
  display: block;
  padding-top: 60%;
}

.teaser__inner--flyout {
  min-height: 5rem;
  margin: -3.3rem 2rem 0 0.65rem;
  padding: 1.5rem 1rem 0;
}
.teaser__inner--flyout h3 {
  margin-bottom: 0;
  font-size: 1rem;
  text-transform: inherit;
}
.teaser__inner--flyout p {
  margin-top: 0.8rem;
}
.teaser__inner--flyout a {
  transition: color 0.15s ease-in;
  color: #000;
  text-decoration: none;
}
.teaser__inner--flyout a:hover {
  color: #005093;
}
.teaser__inner--flyout .teaser__text-icon {
  position: relative;
  margin-top: 2.5rem;
  padding-left: 1.4rem;
}
.teaser__inner--flyout .teaser__icon {
  top: 1.2rem;
  right: 1rem;
  bottom: inherit;
  left: inherit;
  width: 1.2rem;
  height: 1.2rem;
  transform: rotate(90deg);
}
.teaser__inner--flyout .teaser__icon--90deg {
  transform: rotate(0deg);
}
.teaser__inner--flyout .teaser__icon--info {
  top: 0;
  right: inherit;
  left: 0;
  width: 0.35rem;
  height: 0.75rem;
  transform: rotate(0deg);
}
.teaser__inner--flyout .teaser__icon--info::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0.7rem;
  width: 0.35rem;
  height: 1px;
  background-color: #000;
}

.teaser__container .inner-wrap, .teaser__container h6.with-decor,
.teaser__container .like-h6.with-decor {
  width: inherit;
}
.teaser__container--no-slider-wrap .teaser__icon {
  top: 0.5rem;
  right: 0.5rem;
  left: inherit;
}
.teaser__container--single-item .inner-slider__button {
  top: 40%;
}

.teaser__container--big .teaser-infobox {
  display: none;
  position: absolute;
  z-index: 2;
  top: 1.7rem;
  left: 8.7%;
  pointer-events: none;
}

@media (min-width: 767px) {
  .teaser__container .inner-wrap, .teaser__container h6.with-decor,
.teaser__container .like-h6.with-decor {
    width: 87.5%;
  }
  .teaser__container .inner-wrap--full {
    width: 100%;
  }
  .teaser__container.teaser__container--big .inner-wrap--full, .teaser__container.teaser__container--large .inner-wrap--full, .teaser__container.teaser__container--full .inner-wrap--full {
    width: 87.5%;
  }

  .teaser__container--big .teaser-infobox {
    display: flex;
  }
}
.hr-half-teaser {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vw;
  padding: 2.7rem 3rem 1.8rem 2.4rem;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.hr-half-teaser__link-box {
  align-self: baseline;
  padding: 1.65rem 1.35rem 1.5rem 1.35rem;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.23);
}
.hr-half-teaser__link-box h2 {
  margin-bottom: 1.5rem;
  color: #005093;
  font-size: 2rem;
  line-height: 0.9;
}
.hr-half-teaser__link-box.green {
  background-color: #0fd774;
}
.hr-half-teaser__link-box.lightblue {
  background-color: #0fd7cf;
}
.hr-half-teaser__link-box.yellow {
  background-color: #d7b50f;
}
.hr-half-teaser__link-box.red {
  background-color: #cc4747;
}
.hr-half-teaser__link-box.eosin {
  background-color: #d23f63;
}
.hr-half-teaser__link-box.orange {
  background-color: #f26946;
}
.hr-half-teaser__link-box.brown {
  background-color: #ab9778;
}
.hr-half-teaser__content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 2rem;
  margin-left: auto;
}
.hr-half-teaser__content .svg-icon {
  width: 4.1825rem;
  height: 4.375rem;
  margin-bottom: 1.25rem;
}
.hr-half-teaser__content .svg-icon svg {
  width: 4.1825rem;
  height: 4.375rem;
}
.hr-half-teaser__content p {
  color: #fff;
  text-align: right;
  font-size: 1.25rem;
  line-height: 1.25;
}
.hr-half-teaser__section .section {
  padding-top: 3.1rem;
  padding-bottom: 0;
}
.hr-half-teaser__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.hr-half-teaser__headline {
  max-width: 14.25rem;
  margin: 0 auto 2.7rem;
  color: #005093;
  font-size: 1.125rem;
  line-height: 1.2;
  text-align: center;
}

@media (max-width: 766px) {
  .hr-half-teaser__section .wrap {
    width: 100vw;
  }
  .hr-half-teaser__section .wrap [class*=col-] {
    padding: 0;
  }
}
@media (min-width: 767px) {
  .hr-half-teaser {
    height: 34rem;
    min-height: 0;
    margin: 0 0.575rem;
  }
  .hr-half-teaser:first-child {
    margin-left: 0;
  }
  .hr-half-teaser:last-child {
    margin-right: 0;
  }
  .hr-half-teaser__headline {
    max-width: 100%;
    margin-bottom: 1.7rem;
    font-size: 1.14rem;
  }
  .hr-half-teaser__link-box {
    width: 14.8rem;
  }
  .hr-half-teaser__link-box h2 {
    margin-bottom: 1.15rem;
    font-size: 1.86rem;
  }
  .hr-half-teaser__content {
    margin-bottom: 0;
  }
  .hr-half-teaser__content p {
    font-size: 1.14rem;
  }
  .hr-half-teaser__content .svg-icon {
    width: 6.21rem;
    height: 5.71rem;
    margin-bottom: 3.39rem;
  }
  .hr-half-teaser__content .svg-icon svg {
    width: 6.21rem;
    height: 5.71rem;
  }
}
@media (min-width: 1350px) {
  .hr-half-teaser {
    height: 40rem;
  }
  .hr-half-teaser__headline {
    margin-bottom: 2.7rem;
    font-size: 1.5rem;
  }
  .hr-half-teaser__link-box {
    width: 16.45rem;
    height: 14.55rem;
  }
  .hr-half-teaser__link-box h2 {
    margin-bottom: 1.8rem;
    font-size: 2.5rem;
  }
  .hr-half-teaser__content p {
    font-size: 1.5rem;
  }
  .hr-half-teaser__content .svg-icon {
    width: 8.2rem;
    height: 7.5rem;
    margin-bottom: 4.1rem;
  }
  .hr-half-teaser__content .svg-icon svg {
    width: 8.2rem;
    height: 7.5rem;
  }
}
.button {
  display: inline-flex;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  min-width: 9rem;
  height: 2.9rem;
  padding: 0.3rem 0.8rem 0;
  transition: background-color 0.3s ease;
  background-color: #005093;
  color: #fff;
  font-size: 0.9rem;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}
.button[disabled], .button--inactive {
  opacity: 0.7;
  pointer-events: none;
}
.button--hover, .text-teaser:hover .button, .story-teaser:hover .button, .shop-teaser:hover .button, .download:hover .button, .button:hover {
  background-color: #000;
}
.button--large {
  display: flex;
}
.button--high {
  height: 3.2rem;
}
.button--with-icon {
  justify-content: space-between;
}
.button--submit {
  min-width: 14.9rem;
}
.button--submit svg {
  width: 1rem;
}
.button--configurator {
  border-radius: 3px;
}
.button--container-comparison {
  padding: 0 0.75rem;
  background-color: #eaeaea;
}
.button__outline {
  background: transparent;
  color: #005093;
  border: 1px solid #005093;
}
.button__outline--hover, .button__outline:hover {
  background-color: initial;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}
.button__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem 0 4rem;
}
.button__container .button,
.button__container .border-button {
  margin: 0 0 1.5rem;
}
.button__container--center {
  justify-content: center;
  align-items: center;
}
.button__container--no-gap {
  margin: 0;
}
.button__hr {
  font-size: 1rem;
}
.button__mykleusberg-login {
  width: 100%;
}

.reference-filter-wrap.reference-filter-wrap__filter-button .reference-filter__label {
  opacity: 1;
  background: #296da5;
  color: #fff;
  text-indent: 0;
  padding: 0 0.75rem;
}
.reference-filter-wrap.reference-filter-wrap__filter-button button {
  background: #005093;
  color: #fff;
  font-size: 0.7rem;
  padding: 0 1.25rem;
  cursor: pointer;
  line-height: 2.5rem;
}

.svg-icon--zoom {
  width: 0.9rem;
  height: 0.9rem;
  margin-left: 1.6rem;
}

.svg-icon--back {
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 1rem;
  transform: rotate(180deg);
}

@media (min-width: 767px) {
  .button__container {
    display: flex;
    flex-direction: row;
  }
  .button__container .button,
.button__container .border-button {
    margin: 0 1.9rem 0 0;
  }
  .button__hr {
    font-size: 0.85rem !important;
  }
}
@media (min-width: 1020px) {
  .button {
    min-width: 10.5rem;
    padding-top: 0.2rem;
    font-size: 1rem;
  }
  .button--high {
    height: 3.2rem;
  }
  .button--configurator {
    min-width: 12.5rem;
  }
  .button__container {
    margin: 3.5rem 0 0 2.7rem;
  }
  .button__container--no-gap {
    margin: 0;
  }
  .button__container .button {
    margin: 0 1.9rem 0 0;
  }
}
@media (min-width: 1420px) {
  .button {
    min-width: 9rem;
    height: 2.5rem;
    padding: 0.3rem 1.4rem 0;
    font-size: 0.75rem;
  }
  .button--configurator {
    min-width: 16rem;
  }
  .button--high {
    height: 3.2rem;
  }
  .button__hr--big {
    height: 2.85rem;
    padding: 0.3rem 3.95rem 0;
  }
}
swiper > .swiper.s-wrapper .swiper-wrapper .swiper-slide.border-box {
  height: auto;
  margin-bottom: 1rem;
}

.border-box {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.border-box::before, .border-box::after {
  content: "\a";
  display: block;
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  width: 3.8rem;
  border: 1px solid rgba(151, 151, 151, 0.9);
}
.border-box::before {
  border-right: 0;
}
.border-box::after {
  border-left: 0;
}
.border-box--counter {
  margin: 2rem auto 0;
}
.border-box--blue::before, .border-box--blue::after, .border-box--blue.mobile-header__container::after {
  border-color: #4C85B4;
}
.border-box--blue h3,
.border-box--blue h4,
.border-box--blue .current,
.border-box--blue .total {
  color: #005093;
}
.border-box__inner {
  margin: 0 -1.7rem;
  padding: 1.9rem 0 1rem;
  overflow: hidden;
  flex-grow: 1;
}
.border-box__inner h3 {
  padding-bottom: 1.3rem;
  color: #000;
  font-size: 1.35rem;
  line-height: 130%;
}
.border-box__inner p {
  padding-bottom: 1rem;
  color: rgba(0, 0, 0, 0.7);
  font-size: 1.1rem;
  line-height: 130%;
}
.border-box__inner--counter {
  justify-content: center;
  padding-right: 0;
  text-align: center;
}

.border-box__project-data {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 3.1rem 0.3rem;
  overflow: hidden;
}
.border-box__project-data h3 {
  margin-bottom: 1rem;
}
.border-box__project-data .data {
  font-size: 0.85rem;
}
.border-box__project-data .data td {
  padding: 0.25rem 0;
}
.border-box__project-data .data td:first-child {
  padding-right: 1rem;
}

.border-box__column {
  flex-basis: 50%;
}
.border-box__column--full {
  flex-basis: inherit;
}

.border-box__counter {
  font-size: 4rem;
  line-height: 80%;
}

.border-box__counter-label {
  margin-bottom: 1rem;
}

.border-box__numbers {
  display: flex;
  justify-content: space-around;
  width: calc(100% - 4rem);
  margin: 0 -2rem;
  padding: 2.5rem 0;
}

.border-box__number {
  text-align: center;
}
.border-box__number h3 {
  font-size: 6rem;
  line-height: 100%;
}
.border-box__number h4 {
  font-size: 1.3rem;
  text-transform: uppercase;
}

.border-box__container .wrap {
  align-items: inherit;
}

.border-box__no-slider-wrap {
  margin-top: -20px;
}
.border-box__no-slider-wrap [class^=col-] {
  margin-top: 20px;
}

@media (min-width: 767px) {
  .border-box__inner--counter {
    margin-right: 0;
    padding-right: 0;
  }
  .border-box__numbers {
    width: calc(100% - 4.6rem);
    margin: 0;
    padding: 1.8rem 0 1.5rem;
  }
  .border-box--counter {
    margin: 0;
  }

  .border-box__container--numbers .border-box::before, .border-box__container--numbers .border-box::after {
    width: 2.3rem;
  }
  .border-box__container--numbers .inner-slider__navigation {
    right: calc(6% + 10px);
    transform: translateX(0) translateY(-100%);
  }

  .border-box__number h3 {
    font-size: 4rem;
  }
  .border-box__number h4 {
    font-size: 1.2rem;
  }
}
@media (min-width: 1020px) {
  .border-box__inner {
    padding: 3rem 0 2.7rem;
  }
  .border-box__inner h3 {
    font-size: 1.4rem;
    line-height: 100%;
  }
}
@media (min-width: 1420px) {
  .border-box::before, .border-box::after {
    width: 3rem;
  }
  .border-box__inner {
    margin: 0 2rem 0 -0.3rem;
    padding: 2.5rem 0 2.1rem;
  }
  .border-box__inner h3 {
    font-size: 1.5rem;
    line-height: 110%;
  }
  .border-box__inner--counter {
    margin-right: 0;
    padding-right: 0;
  }
  .border-box__numbers {
    width: calc(100% - 5.6rem);
    margin: 0;
    padding: 2.2rem 0 2.1rem;
  }

  .border-box__container--numbers .border-box::before, .border-box__container--numbers .border-box::after {
    width: 2.8rem;
  }
  .border-box__container--numbers .border-box__number h3 {
    font-size: 5.5rem;
  }
}
teaser-download {
  display: flex;
}

swiper > .swiper.s-wrapper .swiper-wrapper teaser-download.swiper-slide {
  height: auto;
}

.download {
  display: block;
  position: relative;
  width: 100%;
  padding: 1rem 1.2rem 1.2rem;
  background-color: #f5f4f5;
  color: #000;
  text-decoration: none;
}
.download__inner {
  padding-left: 0.4rem;
}
.download__headline {
  margin-bottom: 0.25rem;
  text-transform: none;
}
.download__bodytext {
  font-size: 0.85rem;
}
.download__image {
  width: 100%;
  margin-bottom: 1.5rem;
  padding-top: 141.58%;
  transition: box-shadow 0.3s ease;
  background-size: cover;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.3);
}
.download__button {
  display: none;
}
.download:hover .download__image {
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.4);
}
.download__container {
  display: flex;
}
.download__container .download {
  width: 30vw;
}
.download__container teaser-download:first-child {
  margin-right: 1.2rem;
}

@media (min-width: 1020px) {
  .download {
    padding: 2rem 2rem 1.85rem;
  }
  .download__bodytext {
    width: 75%;
    margin-bottom: 3.75rem;
  }
  .download__button {
    display: inline-flex;
    position: absolute;
    bottom: 2.1rem;
    min-width: auto;
    font-size: 0.65rem;
  }

  .download__container .download {
    width: 15.5vw;
  }
}
teaser-shop {
  display: flex;
  margin-bottom: 2rem;
}
teaser-shop:last-child {
  margin-bottom: 0;
}

.shop-teaser {
  display: block;
  max-width: 19.75rem;
  color: #000;
  text-decoration: none;
}
.shop-teaser__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.shop-teaser__inner {
  position: relative;
  margin-top: -3.6rem;
  padding: 0 0 0 0.5rem;
}
.shop-teaser__inner h3 {
  text-transform: inherit;
}
.shop-teaser__image {
  padding-top: 94.77%;
  background-size: cover;
}
.shop-teaser .button {
  margin-top: 2.6rem;
}
@media (min-width: 1020px) {
  teaser-shop {
    margin-bottom: 0;
  }
}
swiper > .swiper.s-wrapper .swiper-wrapper .swiper-slide.teaser-media {
  height: auto;
}

.teaser-media {
  position: relative;
  padding: 2.4rem 3.75rem 2.65rem;
  border: 2px solid transparent;
  text-decoration: none;
}
.teaser-media h3 {
  width: 60%;
  margin-bottom: 1.2rem;
  line-height: 120%;
}
.teaser-media h3.no-margin {
  margin-bottom: 0.1rem;
}
.teaser-media p {
  width: 75%;
  padding-bottom: 1.2rem;
  font-size: 0.85rem;
  line-height: 130%;
}

.teaser-media--blue {
  background-color: #005093;
  color: #fff;
}
.teaser-media--blue:hover {
  background-color: rgba(0, 0, 0, 0.95);
  color: #fff;
}

.teaser-media--gray {
  background-color: #f5f4f5;
}

.teaser-media__container {
  margin-bottom: 2rem;
}
.teaser-media__container .inner-slider__navigation {
  color: #fff;
}
.teaser-media__container .inner-slider__arrow path {
  fill: #fff;
}
.teaser-media__container .inner-wrap, .teaser-media__container h6.with-decor,
.teaser-media__container .like-h6.with-decor {
  width: 87.5%;
}

.teaser-media__icon {
  position: absolute;
  top: 2.4rem;
  right: 2.25rem;
  width: 1.45rem;
  height: 1.45rem;
}
.teaser-media__icon--bottom {
  top: inherit;
  bottom: 2.4rem;
}

.teaser-media__image {
  width: auto;
  max-width: 53%;
  margin: 0 auto;
  padding-top: 60%;
  background: center no-repeat;
  background-size: contain;
}

@media (min-width: 767px) {
  .teaser-media {
    padding-bottom: 1.65rem;
  }
}
.image-text-combo {
  display: block;
  margin-top: 2.75rem;
}
.image-text-combo:first-child {
  margin-top: 0;
}
.image-text-combo__image {
  display: block;
  position: relative;
  height: 47vw;
  background: no-repeat center;
  background-size: cover;
}
.image-text-combo__icon {
  position: absolute;
  bottom: 1.75rem;
  left: 1.75rem;
}
.image-text-combo__container--narrow {
  width: 88%;
  margin: 0 auto;
}
.image-text-combo--reverse div:first-child {
  order: 2;
}
.image-text-combo--reverse div:last-child {
  order: 1;
}
.image-text-combo--reverse .image-text-combo__icon {
  right: 1.75rem;
  left: auto;
}

.image-text-combo__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 2.1rem;
  background-color: #f5f4f5;
}
.image-text-combo__text h3 {
  padding-bottom: 0.7rem;
  font-size: 1.5rem;
}
.image-text-combo__text p {
  width: 100%;
  padding-bottom: 1.4rem;
  font-size: 1.1rem;
  line-height: 130%;
}
.image-text-combo__text--white {
  background-color: #fff;
}
.image-text-combo__text--white p {
  font-size: 1rem;
  line-height: 160%;
}

@media (min-width: 767px) {
  .image-text-combo {
    display: flex;
    align-items: stretch;
    margin: 0;
  }
  .image-text-combo__image {
    height: 100%;
  }
  .image-text-combo__text {
    min-height: 24rem;
    padding: 3.2rem 3.5rem;
  }
  .image-text-combo__text h3 {
    padding-bottom: 0.2rem;
    font-size: 1.4rem;
  }
}
@media (min-width: 1420px) {
  .image-text-combo__text {
    min-height: 22rem;
  }
  .image-text-combo__text h3 {
    padding-bottom: 0.3rem;
  }
  .image-text-combo__text p {
    font-size: 0.85rem;
  }
}
.textblock {
  margin-top: 1rem;
  line-height: 145%;
}
.textblock--intro {
  margin-top: 2rem;
  line-height: 120%;
}
.textblock--career-dropdowm {
  margin-top: 0;
}

@media (min-width: 767px) {
  .textblock {
    margin: 3.6rem 0 1.55rem;
    padding-left: 3.5rem;
    line-height: 145%;
  }
  .textblock--intro {
    width: 100%;
    padding-left: 3.3rem;
    line-height: 120%;
  }
  .textblock--two-columns {
    padding-right: 1rem;
    padding-left: 1rem;
    column-gap: 2rem;
    column-count: 2;
    -webkit-perspective: 1;
  }
  .textblock--two-columns-only {
    margin-top: 1.6rem;
  }
  .textblock--career-dropdowm {
    margin-top: 0;
    padding: 0;
  }
}
.detail-intro {
  position: relative;
  z-index: 2;
  padding: 1.5rem 0;
  background-color: #fff;
}
.detail-intro__subline {
  display: block;
  margin-bottom: 0.4rem;
}
.detail-intro--inline {
  padding: 1.5rem 2rem;
}

@media (min-width: 767px) {
  .detail-intro {
    width: 51.1%;
    margin-top: -6.3rem;
    padding: 2rem 3rem 2.3rem 2.7rem;
  }
  .detail-intro--inline {
    width: 58.4%;
    margin: -6.3rem 0 0 2.5rem;
    padding: 2rem 1rem 2.3rem 2.7rem;
  }
  .detail-intro__headline {
    min-height: 4rem;
  }
}
.detail-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 2rem 2.75rem 3rem;
  background-color: #f5f4f5;
}
.detail-box__upper {
  display: flex;
  justify-content: space-between;
}
.detail-box__title {
  font-size: 0.85rem;
  text-transform: uppercase;
}
.detail-box__indicator {
  font-size: 0.85rem;
}
.detail-box__headline {
  margin-bottom: 0.8rem;
}
.detail-box__bodytext {
  width: 90%;
}
.detail-box.detail-box__with-link {
  padding: 2rem 2.75rem 2rem;
}
.detail-box.detail-box__with-link .detail-box__title {
  text-transform: none;
  font-size: 0.75rem;
  opacity: 0.8;
}
.detail-box.detail-box__with-link .detail-box__headline {
  text-transform: none;
  font-size: 1.25rem;
}
.detail-box.detail-box__with-link p {
  font-size: 0.85rem;
  line-height: 1.3;
  opacity: 0.9;
}
.detail-box.detail-box__with-link p a {
  text-decoration: underline;
  color: inherit;
}

.project-detail-slider {
  position: relative;
  margin-bottom: 2.5rem;
}
.project-detail-slider__navigation {
  display: flex;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 1.9rem;
  align-items: center;
  user-select: none;
  font-size: 0.75rem;
}
.project-detail-slider__navigation .current::after {
  content: "/";
  display: inline-block;
  margin: 0 0.2rem;
}
.project-detail-slider__arrow {
  padding: 0 0.3rem;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.project-detail-slider__arrow .svg-icon {
  width: 1.4rem;
  height: 1.4rem;
}
.project-detail-slider__arrow--inactive {
  opacity: 0.1;
  pointer-events: none;
}
.project-detail-slider__arrow--prev .svg-icon svg {
  transform: rotate(180deg);
}
@media (min-width: 767px) {
  .project-detail-slider__navigation {
    right: 3.75rem;
  }
}
.project-detail-slider .flex-helper {
  align-items: stretch;
}
.project-detail-slider .inner-slider__button {
  top: 50%;
  margin-top: -1rem;
}
.project-detail-slider__navigation {
  right: 50%;
  bottom: 0.9rem;
  transform: translateX(50%);
}
.project-detail-slider__arrow .svg-icon {
  width: 1rem;
  height: 1rem;
}

@media (min-width: 767px) {
  .project-detail-slider {
    margin-bottom: 5.5rem;
  }
  .project-detail-slider__navigation {
    top: 2rem;
    right: 2.75rem;
    bottom: inherit;
  }

  .project-detail-slider--odd .project-detail-slider__component--text {
    order: 1;
  }
  .project-detail-slider--odd .project-detail-slider__component--image {
    order: 2;
  }
}
.teaser-infobox {
  display: flex;
  align-items: center;
  padding: 0.8rem 1.75rem 0.8rem 1rem;
  background-color: #fff;
  font-size: 0.75rem;
}
.teaser-infobox .svg-icon {
  width: 2rem;
  height: 2rem;
  margin: -0.5rem 0 0 1rem;
}
.teaser-infobox__icon-info.svg-icon {
  width: 0.5rem;
  height: inherit;
  margin: 0 0.6rem 0 0;
}
.teaser-infobox__label {
  margin: 0.4rem 2rem 0 0.6rem;
  text-transform: uppercase;
}
.teaser-infobox__slides {
  margin-top: 0.4rem;
}
.teaser-infobox--in-col {
  position: absolute;
  z-index: 2;
  top: 2.6rem;
  left: 2.35rem;
}

.vacancy-search__container > div {
  padding: 2rem 0;
  background-color: #d3e0ec;
}
.vacancy-search__container--filter-only > div {
  padding-top: 0;
}
.vacancy-search__container--filter-only .vacancy-search {
  padding: 0;
}

.vacancy-search {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 0 2rem;
}
.vacancy-search__button-container {
  padding: 0 1rem;
}
.vacancy-search__button {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 3.4rem;
  margin-top: 1rem;
  overflow: hidden;
  transition: opacity 0.2s ease;
  border-radius: 0.2rem;
  background-color: rgba(0, 80, 147, 0.8);
  color: #fff;
  line-height: 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}
.vacancy-search__button--disabled {
  pointer-events: none;
}
.vacancy-search__button:hover {
  opacity: 0.9;
}
.vacancy-search__button-text, .vacancy-search__button-cta {
  display: flex;
  align-items: center;
  padding: 0 1.5rem 0 1.8rem;
}
.vacancy-search__button-text {
  font-size: 1.1rem;
}
.vacancy-search__button-text > span {
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 1.5rem;
}
.vacancy-search__button-cta {
  width: auto;
  height: 3.75rem;
  margin: 0;
  transition: background-color 0.1s ease-in;
  border: 0;
  background-color: #005093;
  color: #fff;
  text-transform: uppercase;
}
.vacancy-search__filter-container {
  padding: 1.3rem 0;
}
.vacancy-search__filter-container h5 {
  margin-right: 1.25rem;
  text-transform: inherit;
}
.vacancy-search__filter-inner {
  display: flex;
  align-items: center;
}
.vacancy-search__filter-inner > span {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.9rem;
  background-color: #f5f8fb;
  font-size: 0.85rem;
}
.vacancy-search__filter-inner .vacancy-search__filter-close {
  display: inline-flex;
  height: 2.5rem;
  margin-left: 0.1rem;
  padding: 0 0.75rem;
  transition: background-color 0.2s ease;
  background-color: #eff4f8;
  cursor: pointer;
}
.vacancy-search__filter-inner svg {
  width: 1rem;
}
.vacancy-search__filter {
  margin-top: 1rem;
  cursor: pointer;
}
.vacancy-search__filter:hover .vacancy-search__filter-close {
  background: #005093;
}
.vacancy-search__filter-reset {
  text-decoration: underline;
  cursor: pointer;
}
.vacancy-search__dropdowns {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1rem;
}
.vacancy-search__dropdowns ng-select {
  margin-top: 1rem;
  background-color: #f5f8fb;
}
.vacancy-search__dropdowns ng-dropdown-panel {
  padding-left: 1rem;
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.5);
}
.vacancy-search__dropdowns ng-dropdown-panel,
.vacancy-search__dropdowns div.ng-value {
  background-color: #f6f8fb;
}

@media (min-width: 767px) {
  .vacancy-search__container--filter-only {
    width: 73%;
    margin: 0 auto;
  }
  .vacancy-search__dropdowns ng-select {
    flex: 1;
    margin-left: 0.9rem;
  }
  .vacancy-search .select-dropdown__container {
    margin-bottom: 0;
    margin-left: -0.9rem;
  }
  .vacancy-search__button {
    margin-left: 0.9rem;
  }
  .vacancy-search__filter-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vacancy-search__filter-inner {
    display: inline-flex;
  }
  .vacancy-search__filter-inner > span {
    width: auto;
  }
  .vacancy-search__filter {
    margin-top: 0;
    margin-right: 0.65rem;
  }
}
.tile__container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.tile {
  position: relative;
  min-height: 21rem;
  background-color: #f5f4f5;
  background-size: cover;
}

.tile--long {
  grid-column: span 2;
}

.tile--large {
  grid-column: span 2;
  grid-row: span 2;
}

@media (min-width: 1020px) {
  .tile__container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.infobox {
  flex-basis: 100%;
  text-align: center;
}
.infobox__text {
  margin: 0 0 1rem;
  font-size: 1.2rem;
  line-height: 120%;
}
.infobox__text.infobox__text--smaller-text {
  font-size: 1rem;
}
.infobox__icon {
  width: 0.7rem;
  height: 1.5rem;
}
.infobox__container {
  display: flex;
  justify-content: center;
}
.infobox__container .col-12 {
  display: flex;
  justify-content: center;
}
.infobox--without-icon {
  padding-top: 3.5rem;
}

@media (min-width: 540px) {
  .infobox {
    flex-basis: 75%;
  }
}
@media (min-width: 1020px) {
  .infobox {
    flex-basis: 50%;
  }
  .infobox__text {
    margin: 0.5rem 0 2.7rem;
    font-size: 2rem;
  }
}
swiper > .swiper.s-wrapper .swiper-wrapper teaser-contact.swiper-slide {
  height: auto;
}

.contact {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.contact__image {
  padding-top: 75.6%;
  background: #f5f4f5;
  background-size: cover;
}
.contact__name {
  text-transform: none;
}
.contact__info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 2.5rem 2.1rem;
  background-color: #f5f4f5;
}
.contact__extra {
  padding: 1.25rem 2.1rem 0.9rem;
  background-color: #e6e5e6;
}
.contact__position {
  margin-top: 0.2rem;
  font-size: 0.85rem;
}
.contact__phone {
  display: none;
  margin-top: 1.6rem;
}
.contact__phone-button {
  margin-top: 1rem;
}
.contact__button {
  margin-top: 1rem;
}
.contact--inline {
  width: 75%;
  margin-top: 2rem;
}
.contact--inline .contact__info {
  padding-bottom: 1rem;
}
.contact--inline .contact__name {
  margin-bottom: 0;
}

.contact__aside {
  margin-bottom: 4rem;
}

.contact__vcard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1px;
  font-size: 0.9rem;
  line-height: 1.35rem;
  padding: 1rem 2.1rem 0.75rem;
  background-color: #eaeaea;
  color: initial;
  text-decoration: none;
}
.contact__vcard:hover {
  opacity: 0.8;
}
.contact__vcard .vcard-container__left {
  display: flex;
  flex-direction: row;
}
.contact__vcard .svg-icon--business-card svg {
  height: 1.2rem;
  width: 1.2rem;
  opacity: 0.75;
  margin-right: 0.65rem;
}
.contact__vcard svg {
  height: 1rem;
  width: 1rem;
}

@media (min-width: 767px) {
  .contact__aside {
    margin: 0;
    padding-right: 2.6rem;
  }

  .contact__phone {
    display: block;
  }

  .contact__phone-button {
    display: none;
  }
}
.bookmark {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  background-color: #f5f4f5;
}
.bookmark__meta {
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  padding: 0.5rem 1.9rem 1rem;
}
.bookmark__date {
  font-size: 0.85rem;
}
.bookmark__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 2rem 0 1rem 1.9rem;
  transition: color 0.2s ease;
  color: #000;
  text-decoration: none;
}
.bookmark__content h4 {
  width: 95%;
  text-transform: uppercase;
}
.bookmark__content p {
  width: 95%;
  font-size: 0.85rem;
}
.bookmark__content:hover {
  color: #005093;
}
.bookmark__breadcrumb {
  display: none;
  align-items: center;
  margin-left: -0.3rem;
  font-size: 0.75rem;
}
.bookmark__breadcrumb .svg-icon {
  width: 1.45rem;
  height: 1.45rem;
  margin: 0 0.75rem 0 0;
}
.bookmark__image {
  display: none;
  flex-shrink: 0;
  width: 17.75rem;
  background: center no-repeat;
  background-size: cover;
}
.bookmark__delete {
  margin-right: -0.5rem;
}

.delete-button {
  display: flex;
  align-items: flex-end;
  transition: color 0.2s ease;
  cursor: pointer;
}
.delete-button__label {
  font-size: 0.85rem;
  text-decoration: underline;
}
.delete-button__icon {
  width: 1.3rem;
  height: 1.3rem;
  margin: 0 0 0.15rem 0.4rem;
}
.delete-button:hover {
  color: #7a1515;
}
.delete-button:hover .animated {
  transform: rotate(-10deg) translateY(-5px);
  transform-origin: left center;
}

@media (min-width: 1020px) {
  .bookmark {
    flex-wrap: nowrap;
    height: 10rem;
  }
  .bookmark__content {
    padding: 1rem 0 1rem 2.5rem;
  }
  .bookmark__content h4,
.bookmark__content p {
    width: 65%;
  }
  .bookmark__image {
    display: block;
  }
  .bookmark__meta {
    flex-basis: auto;
    flex-direction: column;
    padding: 2rem 1.9rem 1rem 0;
  }
  .bookmark__breadcrumb {
    display: block;
  }
}
app-google-map .google-map {
  display: block;
  width: 100%;
  height: 60vw;
}
app-google-map .teaser-infobox {
  display: none;
}

.google-maps__marker-window {
  display: block;
  position: relative;
  z-index: 1;
  max-width: 27rem;
  background-color: #fff;
  color: #000;
  text-decoration: none;
}
.google-maps__marker-window .svg-icon {
  cursor: pointer;
}
.google-maps__marker-window a {
  color: #000;
}
.google-maps__marker-window h3,
.google-maps__marker-window h3 + p {
  margin-bottom: 1.4rem;
}

@media (min-width: 1020px) {
  app-google-map .google-map {
    height: 40vw;
  }
  app-google-map .google-map .teaser-infobox {
    position: absolute;
    z-index: 1;
    top: 1.5rem;
    left: 2.35rem;
  }
}
.sidebar {
  padding: 2.2rem 2rem;
  background-color: #f4f4f5;
  font-size: 0.85rem;
  line-height: 130%;
}
.sidebar p {
  margin-bottom: 0.85rem;
}

.searchresult {
  display: block;
  position: relative;
  padding: 2.1rem 2.3rem;
  transition: color 0.2s ease;
  background-color: #fff;
  color: #000;
  text-decoration: none;
}
.searchresult__headline {
  margin-bottom: 0.75rem;
}
.searchresult__bodytext {
  width: 75%;
  font-size: 0.85rem;
  line-height: 130%;
}
.searchresult__path {
  margin-top: 1.1rem;
  font-size: 0.85rem;
  text-decoration: underline;
}
.searchresult__icon {
  position: absolute;
  top: 2.1rem;
  right: 2.1rem;
  width: 1.45rem;
  height: 1.45rem;
}
.searchresult__empty {
  font-size: 1.5rem;
  text-align: center;
  text-transform: none;
}
.searchresult__alternatives {
  margin-top: 1.8rem;
  font-size: 0.85rem;
  line-height: 150%;
  text-align: center;
}
.searchresult__alternative {
  transition: color 0.2s ease;
  text-decoration: underline;
  cursor: pointer;
}
.searchresult__alternative:hover {
  color: #005093;
}
.searchresult--variant-0 {
  background-color: #f5f4f5;
}
.searchresult:hover {
  color: #005093;
}
.story-teaser {
  display: flex;
  position: absolute;
  bottom: 2.25rem;
  left: 2.5rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 34%;
  min-height: 12.5rem;
  padding: 1.4rem 1.3rem;
  background-color: #fff;
  color: #000;
  line-height: 1.5rem;
  text-decoration: none;
  cursor: pointer;
}
.story-detail {
  position: relative;
}
.story-detail__slide {
  padding-top: 59.79%;
  background-position: center;
  background-size: cover;
}
.story-detail__box {
  padding: 2.4rem 2.9rem 0;
  background-color: #fff;
}
.story-detail__box h1 {
  margin-right: 4.5rem;
  margin-bottom: 2rem;
  font-size: 2rem;
}
.story-detail__box h4 {
  margin-bottom: 1.8rem;
}
.story-detail__box h4,
.story-detail__box p {
  font-size: 1rem;
  line-height: 160%;
}
.story-detail__box svg {
  width: 2rem;
}
.story-detail__navigation {
  display: flex;
  position: absolute;
  top: 2.4rem;
  right: 1.2rem;
  justify-content: space-between;
  width: 4.5rem;
}
.story-detail__arrow--prev {
  transform: rotateY(180deg);
}

@media (min-width: 1020px) {
  .story-detail__box {
    position: absolute;
    z-index: 1;
    bottom: 0;
  }
}
.outlet {
  display: flex;
  position: fixed;
  z-index: 11;
  top: 0;
  right: 0;
  align-items: center;
  height: 100vh;
  max-width: 100%;
}
.outlet__item {
  position: relative;
  padding: 1.6rem 1.2rem 1rem;
  background-color: #fff;
  box-shadow: -1px 7px 33px 0 rgba(0, 0, 0, 0.35);
  max-width: 100%;
}
.outlet__close {
  position: absolute;
  top: 1.4rem;
  right: 1.2rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.outlet h3 {
  margin-bottom: 1.4rem;
}

tc-chosen-dimensions {
  display: block;
  margin-top: 1.5rem;
  margin-bottom: 3.7rem;
}
tc-chosen-dimensions .align-center {
  margin: 0 auto;
}

.tc-chosen-dimensions {
  align-items: stretch;
  margin-top: 2rem;
  background-color: #dae1e8;
}
.tc-chosen-dimensions > div {
  width: 100%;
}
.tc-chosen-dimensions__image {
  display: flex;
  align-items: center;
  background-color: #e4edf3;
}
.tc-chosen-dimensions .form-section {
  margin: 0;
}

.tc-chosen-dimensions__content {
  padding: 3.5rem 1rem 4.6rem;
}
.tc-chosen-dimensions__content h4 {
  position: relative;
  text-transform: uppercase;
}
.tc-chosen-dimensions__content h4::before {
  content: "";
  display: none;
  position: absolute;
  top: -1rem;
  left: -2.7rem;
  width: 0;
  height: 0;
  transform: rotate(360deg);
  border-width: 1.5rem 0 1.5rem 1.5rem;
  border-style: solid;
  border-color: transparent transparent transparent #e4edf3;
}

@media (min-width: 767px) {
  .tc-chosen-dimensions > div {
    width: 50%;
  }
}
@media (min-width: 1350px) {
  .tc-chosen-dimensions__content {
    padding: 3.5rem 2rem 4.6rem;
  }
  .tc-chosen-dimensions__content h4::before {
    display: block;
  }
}
.tc-reporting-price-offer {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin: 1.5rem 0 0;
}
.tc-reporting-price-offer__price, .tc-reporting-price-offer__listed-options {
  padding: 1.6rem 1.4rem;
}
.tc-reporting-price-offer__price {
  position: relative;
  width: 100%;
  background-color: #e4edf3;
}
.tc-reporting-price-offer__price::before {
  content: "";
  display: none;
  position: absolute;
  top: 6rem;
  right: -1.5rem;
  width: 0;
  height: 0;
  transform: rotate(360deg);
  border-width: 1.5rem 0 1.5rem 1.5rem;
  border-style: solid;
  border-color: transparent transparent transparent #e4edf3;
}
.tc-reporting-price-offer__price h3 {
  margin-bottom: 1.5rem;
}
.tc-reporting-price-offer__price-summary p {
  font-weight: bold;
}
.tc-reporting-price-offer__price-summary p:first-child {
  margin-bottom: 0.6rem;
  font-weight: normal;
}
.tc-reporting-price-offer__overall-price {
  display: inline-block;
  position: relative;
  margin: 1.5rem -1.5rem 1.3rem;
  padding: 1.2rem 1.5rem;
  overflow: hidden;
  font-size: 2rem;
}
.tc-reporting-price-offer__overall-price::before {
  top: 0;
}
.tc-reporting-price-offer__overall-price::after {
  bottom: 0;
}
.tc-reporting-price-offer__overall-price::before, .tc-reporting-price-offer__overall-price::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  height: 1px;
  background: linear-gradient(to left, rgba(200, 200, 200, 0), rgba(200, 200, 200, 0.8), rgba(200, 200, 200, 0));
}

.tc-reporting-price-offer__listed-options {
  width: 100%;
  background-color: #dae1e8;
}
.tc-reporting-price-offer__listed-options ul {
  margin-top: 1.4rem;
}
.tc-reporting-price-offer__listed-options li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.1rem 1.2rem 1.2rem;
  border-bottom: 1px solid rgba(151, 151, 151, 0.4);
}
.tc-reporting-price-offer__listed-options li span:first-child {
  max-width: 70%;
}
.tc-reporting-price-offer__listed-options li.is-active {
  background-color: #e4edf3;
}
.tc-reporting-price-offer__listed-options li:first-child {
  border-top: 1px solid rgba(151, 151, 151, 0.4);
}
.tc-reporting-price-offer__listed-options li small {
  padding-left: 1.1rem;
}

@media (min-width: 1350px) {
  .tc-reporting-price-offer__price, .tc-reporting-price-offer__listed-options {
    padding: 2.6rem 3rem;
  }

  .tc-reporting-price-offer__price {
    width: 40%;
  }
  .tc-reporting-price-offer__price::before {
    display: block;
  }

  .tc-reporting-price-offer__listed-options {
    width: 60%;
  }
}
tc-blueprint {
  display: block;
  margin-bottom: 3.7rem;
}

.tc-blueprint__blueprints__disabled-overlay {
  position: absolute;
  display: flex;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  background: #f5f4f5;
}
.tc-blueprint__disabled-overlay-content {
  width: 100%;
  margin: auto;
  text-align: center;
}
.tc-blueprint__disabled-overlay-content p {
  margin-top: 0.5rem;
}
.tc-blueprint__disabled-overlay-content .button {
  margin-top: 2rem;
  font-size: 0.85rem;
}
.tc-blueprint__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.3rem;
}
.tc-blueprint__info {
  position: relative;
  flex-basis: 100%;
  padding: 2rem 1.5rem 0;
  background-color: #e7e7e7;
}
.tc-blueprint__info::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  transform: rotate(90deg) translateX(2rem) translateY(0.75rem);
  border-width: 1.5rem 0 1.5rem 1.5rem;
  border-style: solid;
  border-color: transparent transparent transparent #e7e7e7;
}
.tc-blueprint__info h2 {
  font-size: 1.5rem;
}
.tc-blueprint__info p {
  font-size: 0.85rem;
}
.tc-blueprint__mobile-select {
  max-width: 12rem;
}
.tc-blueprint__mobile-select .select-dropdown {
  background-color: #fff;
}
.tc-blueprint__mobile-select .select-dropdown .ng-value {
  background-color: #fff;
}
.tc-blueprint__blueprints {
  position: relative;
  flex-basis: 100%;
  padding: 0 1.5rem;
  background-color: #f5f4f5;
}
.tc-blueprint__nav {
  position: relative;
  z-index: 2;
  margin: 2.6rem 0 0;
  text-transform: uppercase;
}
.tc-blueprint__image {
  padding-top: 141%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.tc-blueprint__image-container {
  margin-top: -4rem;
}

.tc-blueprint__zoom-in {
  position: absolute;
  z-index: 2;
  top: 3rem;
  right: 1.5rem;
  width: 1.5rem;
}
.tc-blueprint__zoom-in:hover {
  cursor: pointer;
}

.tc-blueprint__view-link {
  display: none;
  position: relative;
  margin: 0 1.2rem;
  color: #636363;
}
.tc-blueprint__view-link::before {
  content: "";
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: -0.8rem;
  left: 0;
  height: 0.1rem;
  transition: background-color 0.2s ease;
  background-color: transparent;
}
.tc-blueprint__view-link--active {
  color: #000;
}
.tc-blueprint__view-link--active::before {
  background-color: #005093;
}
.tc-blueprint__view-link::after {
  content: "";
  position: absolute;
  left: -1.1rem;
  width: 0.1rem;
  height: 0.8rem;
  background-color: rgba(151, 151, 151, 0.4);
}
.tc-blueprint__view-link:first-child {
  margin-left: 0;
}
.tc-blueprint__view-link:first-child::after {
  display: none;
}
.tc-blueprint__view-link:hover {
  cursor: pointer;
}

.tc-blueprint-slider__navigation {
  display: flex;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 1.9rem;
  align-items: center;
  user-select: none;
  font-size: 0.75rem;
}
.tc-blueprint-slider__navigation .current::after {
  content: "/";
  display: inline-block;
  margin: 0 0.2rem;
}
.tc-blueprint-slider__arrow {
  padding: 0 0.3rem;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.tc-blueprint-slider__arrow .svg-icon {
  width: 1.4rem;
  height: 1.4rem;
}
.tc-blueprint-slider__arrow--inactive {
  opacity: 0.1;
  pointer-events: none;
}
.tc-blueprint-slider__arrow--prev .svg-icon svg {
  transform: rotate(180deg);
}
@media (min-width: 767px) {
  .tc-blueprint-slider__navigation {
    right: 3.75rem;
  }
}
.tc-blueprint-slider__navigation {
  right: 2rem;
}

.tc-blueprint__label {
  position: absolute;
  bottom: 2rem;
  left: 3.5rem;
  font-size: 0.85rem;
}

@media (min-width: 767px) {
  .tc-blueprint__nav {
    font-size: 0.75rem;
  }

  .tc-blueprint__container {
    flex-wrap: nowrap;
  }

  .tc-blueprint__mobile-select {
    display: none;
  }

  .tc-blueprint__image {
    padding-top: 46%;
  }

  .tc-blueprint__image-container {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin-top: 0;
    margin-bottom: 0;
    transform: translateY(-50%);
  }

  .tc-blueprint__view-link {
    display: inline-block;
  }

  .tc-blueprint__info {
    flex-basis: 25%;
    padding: 2.5rem 2.5rem 0;
  }
  .tc-blueprint__info::before {
    top: 6rem;
    right: -1.5rem;
    bottom: inherit;
    left: inherit;
    transform: rotate(0) translateX(0);
  }

  .tc-blueprint__zoom-in {
    top: 2.5rem;
    right: 2rem;
  }

  .tc-blueprint__blueprints {
    flex-basis: 75%;
    padding: 0 3.5rem;
  }
}
@media (min-width: 350px) {
  .tc-blueprint__nav {
    font-size: 16px;
  }
}
trendline-configurator-section {
  overflow: hidden;
}
trendline-configurator-section.wrap.full-height {
  align-items: flex-start;
}

trendline-configurator .section h6.with-decor {
  margin-bottom: 0.6rem;
}
trendline-configurator .section h6.with-decor--topspace {
  margin-top: 2rem;
}
trendline-configurator .section h6.with-decor + p {
  margin-bottom: 2rem;
}
trendline-configurator .section h6.with-decor--button {
  margin-bottom: 3rem;
}
trendline-configurator .section .align-center {
  margin: 0 auto;
}

trendline-configurator .horizontal-navigation__container {
  min-height: 3rem;
}
trendline-configurator .horizontal-navigation--fixed {
  display: none;
  position: fixed;
  z-index: 4;
  top: 3rem;
  right: 0;
  left: 0;
  background-color: #fff;
}
trendline-configurator p {
  font-size: 0.85rem;
}

.tc-reset-button {
  color: #000;
  font-size: 0.85rem;
  text-decoration: underline;
}
.tc-reset-button__container {
  margin: 0 0 2.5rem;
  text-align: center;
}

.popup-infobox {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 80vw;
  padding: 2.5rem 3.5rem;
  overflow: hidden;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  box-shadow: 1px 1px 22px rgba(0, 0, 0, 0.5);
  font-size: 0.85rem;
}
.popup-infobox__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.65rem;
  background-color: #005093;
  line-height: 0;
}
.popup-infobox__close:hover {
  cursor: pointer;
}
.popup-infobox__close svg {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0.7rem;
}
.popup-infobox__info-icon {
  position: absolute;
  top: -0.2rem;
  left: -1.5rem;
  width: 1rem;
}
.popup-infobox__info-icon svg {
  height: 1.5rem;
}
.popup-infobox__content-container {
  position: relative;
}
.popup-infobox__content h3 {
  margin-bottom: 1rem;
}
.popup-infobox ol {
  margin: 1rem 0 0;
  padding-left: 1rem;
  list-style: decimal;
}
.popup-infobox ol li {
  margin-bottom: 1rem;
}

@media (min-width: 767px) {
  trendline-configurator .align-center {
    width: 60%;
  }

  .popup-infobox {
    width: 60vw;
  }
}
@media (min-width: 1020px) {
  trendline-configurator .horizontal-navigation--fixed {
    display: flex;
    top: 5.8rem;
  }
}
@media (min-width: 1190px) {
  trendline-configurator-section.wrap.full-height {
    align-items: stretch;
  }

  trendline-configurator .horizontal-navigation--fixed {
    top: 6rem;
  }

  .popup-infobox {
    width: 50vw;
  }
}
@media (min-width: 1420px) {
  trendline-configurator .horizontal-navigation--fixed {
    top: 5.6rem;
  }

  .popup-infobox {
    width: 30vw;
  }
}
.section__slide-in-popup-sideways {
  position: fixed;
  transition: left 1s ease;
  top: 0;
  bottom: 0;
  right: 0;
  left: -600px;
  height: auto;
  width: 100%;
  z-index: 555;
  background-color: white;
  box-shadow: -1px 7px 33px 0 rgba(0, 0, 0, 0.35);
}
.section__slide-in-popup-sideways.section__slide-in-popup__open {
  transition: left 1s ease;
  left: 0;
}
.section__slide-in-popup-sideways .slide-in__close {
  height: 54px;
  width: 54px;
  padding: 17px;
  background-color: #005093;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.section__slide-in-popup-sideways .intro-slider__box {
  padding: 1.8rem;
  position: relative;
  width: 100%;
  top: 0;
  margin: 0;
}
.section__slide-in-popup-sideways .intro-slider__box h2 {
  font-size: 1.7rem;
}
@media (min-width: 767px) {
  .section__slide-in-popup-sideways {
    top: 12%;
    width: 350px;
    right: auto;
    bottom: auto;
  }
  .section__slide-in-popup-sideways .intro-slider__box {
    top: -75px;
    width: 85%;
    left: 15px;
  }
}
@media (min-width: 1420px) {
  .section__slide-in-popup-sideways {
    width: 500px;
  }
  .section__slide-in-popup-sideways .intro-slider__box {
    width: 76%;
    margin: 0 0 0 35px;
    top: -100px;
  }
}
@media (min-width: 1700px) {
  .section__slide-in-popup-sideways .intro-slider__box {
    top: -110px;
  }
}
@media screen and (max-height: 500px) {
  .section__slide-in-popup-sideways {
    top: 0;
    bottom: 0;
  }
  .section__slide-in-popup-sideways .intro-slider__box {
    top: -100%;
  }
}

.section__slide-in-popup-top {
  transition: height 1s ease;
  height: 0;
  background-size: cover;
  background-position: center;
}
.section__slide-in-popup-top.section__slide-in-popup__open {
  transition: height 1s ease;
  position: static;
  top: 0;
  left: 0;
  right: 0;
  z-index: 555;
  height: 500px;
}
@media (min-width: 1020px) {
  .section__slide-in-popup-top.section__slide-in-popup__open {
    height: 660px;
    padding: 0;
  }
}
.section__slide-in-popup-top .intro-slider__box {
  padding: 1.8rem;
}
.section__slide-in-popup-top .intro-slider__box-header {
  position: relative;
  margin-top: 50px;
  padding: 1rem 3.25rem 0.7rem 1.9rem;
  background-color: rgba(255, 255, 255, 0.6);
}
.section__slide-in-popup-top .intro-slider__box-header h4 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.85rem;
}
@media (min-width: 1020px) {
  .section__slide-in-popup-top .intro-slider__box-header {
    padding: 1rem 3.25rem 0.7rem 1.9rem;
  }
}
.section__slide-in-popup-top .slide-in__close {
  height: 2.7rem;
  width: 2.7rem;
  padding: 0.8rem;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
@media (min-width: 1020px) {
  .section__slide-in-popup-top .slide-in__close {
    height: 2.7rem;
    width: 2.7rem;
    padding: 0.8rem;
  }
}

.main-header.slide-in-popup__active {
  transition: top 1s ease;
  position: absolute;
  top: 500px;
}
@media (min-width: 1020px) {
  .main-header.slide-in-popup__active {
    top: 660px;
  }
}

.mobile-header__container {
  transition: top 1s ease;
}

.mobile-header__container.slide-in-popup__active {
  transition: top 1s ease;
  position: absolute;
  top: 500px;
}
@media (min-width: 1020px) {
  .mobile-header__container.slide-in-popup__active {
    top: 660px;
  }
}

.hr-call-to-action .section {
  padding-top: 2.5rem;
  padding-bottom: 4.1rem;
}
.hr-call-to-action__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.hr-call-to-action__headline, .hr-call-to-action__bodytext, .hr-call-to-action__call {
  color: #005093;
  text-align: center;
}
.hr-call-to-action__headline {
  margin-bottom: 0.85rem;
}
.hr-call-to-action__headline, .hr-call-to-action__call {
  font-size: 1.125rem;
  line-height: 1.94;
}
.hr-call-to-action__bodytext {
  margin-bottom: 0.45rem;
  font-size: 1.5rem;
  line-height: 1.4;
}
.hr-call-to-action__call {
  margin-bottom: 1.8rem;
}

@media (min-width: 767px) {
  .hr-call-to-action .section {
    padding-top: 3.42rem;
    padding-bottom: 3rem;
  }
  .hr-call-to-action__bodytext {
    max-width: 42.7rem;
    margin-bottom: 0.3rem;
    font-size: 2.14rem;
    line-height: 1.1;
  }
  .hr-call-to-action__headline, .hr-call-to-action__call {
    font-size: 1.14rem;
  }
  .hr-call-to-action__call {
    margin-bottom: 1.2rem;
  }
  .hr-call-to-action__headline {
    margin-bottom: 0.2rem;
  }
}
@media (min-width: 1350px) {
  .hr-call-to-action .section {
    padding-top: 4.35rem;
    padding-bottom: 5.65rem;
  }
  .hr-call-to-action__bodytext {
    max-width: 56rem;
    margin-bottom: 2.05rem;
    font-size: 3rem;
  }
  .hr-call-to-action__headline {
    margin-bottom: 1.85rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  .hr-call-to-action__call {
    margin-bottom: 2.6rem;
    font-size: 1.5rem;
  }
}
.border-button {
  display: inline-flex;
  align-items: center;
  height: 3.2rem;
  padding: 0 1.2rem 0 1.5rem;
  transition: color 0.3s ease, background-color 0.3s ease, opacity 0.3s ease;
  border: 1px solid #005093;
  background-color: rgba(0, 80, 147, 0.05);
  color: #005093;
  cursor: pointer;
}
.border-button__label {
  margin-top: 0.2rem;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.border-button__icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
.border-button--inactive {
  opacity: 0.4;
  pointer-events: none;
}
.border-button:hover {
  background-color: rgba(0, 80, 147, 0.15);
}

.pull-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: color 0.3s ease;
  color: #000;
  font-size: 0.75rem;
  text-decoration: none;
  text-transform: uppercase;
}
.pull-up__icon {
  width: 0.75rem;
  height: 0.75rem;
  margin-bottom: 0.6rem;
  transform: rotate(-90deg);
  transition: transform 0.2s ease;
}
.pull-up__icon path {
  stroke-width: 6px;
  stroke: #000;
}
.pull-up:hover {
  color: #005093;
}
.pull-up:hover .pull-up__icon {
  transform: rotate(-90deg) translateX(3px);
}

.intro-slider__slide {
  margin-top: 4rem;
  padding-top: 78%;
  background-position: center;
  background-size: cover;
  cursor: ew-resize;
}

.intro-slider {
  display: block;
  background-color: #f5f4f5;
}
.intro-slider__navigation {
  display: flex;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 1.9rem;
  align-items: center;
  user-select: none;
  font-size: 0.75rem;
}
.intro-slider__navigation .current::after {
  content: "/";
  display: inline-block;
  margin: 0 0.2rem;
}
.intro-slider__arrow {
  padding: 0 0.3rem;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.intro-slider__arrow .svg-icon {
  width: 1.4rem;
  height: 1.4rem;
}
.intro-slider__arrow--inactive {
  opacity: 0.1;
  pointer-events: none;
}
.intro-slider__arrow--prev .svg-icon svg {
  transform: rotate(180deg);
}
@media (min-width: 767px) {
  .intro-slider__navigation {
    right: 3.75rem;
  }
}

.intro-slider__slide-wrap {
  overflow: hidden;
}

.intro-slider__navigation {
  bottom: 3rem;
  font-size: 1rem;
}

.intro-slider__arrow .svg-icon {
  width: 1.8rem;
}

.intro-slider__navigation--wrapped {
  bottom: 0;
  left: 50%;
  justify-content: center;
  transform: translateX(-50%);
}

.intro-slider__overlay {
  width: 100%;
  pointer-events: none;
}
.intro-slider__overlay::after {
  content: "";
  display: block;
  width: 70%;
  height: 1px;
  margin: 0 auto;
  border: 0;
  background: #c8c8c8;
  background: -webkit-gradient(linear, 0 0, 100% 0, from(#fff), to(#fff), color-stop(50%, #c8c8c8));
}

.intro-slider__box {
  margin: 0 2rem 0 0;
  padding: 2.1rem 0 2.8rem;
  background-color: #fff;
  pointer-events: visible;
}
.intro-slider__box h2 {
  padding-bottom: 0.5rem;
  font-size: 1.9rem;
  line-height: 100%;
}
.intro-slider__box h3 {
  padding-bottom: 0.3rem;
  font-size: 0.85rem;
  line-height: 110%;
}
.intro-slider__box p {
  padding-bottom: 1.75rem;
  color: rgba(0, 0, 0, 0.7);
  font-size: 1.1rem;
  letter-spacing: -0.03rem;
  line-height: 130%;
}

.intro-slider__container {
  position: relative;
}
.intro-slider__container .intro-slider__button--wrapped {
  top: calc(50% - 1rem);
}

.intro-slider__overlay--wrapped {
  position: relative;
}
.intro-slider__overlay--wrapped h2 {
  position: relative;
  padding-bottom: 0.75rem;
  font-size: 3.5rem;
}
.intro-slider__overlay--wrapped h2::after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 3.6rem;
  height: 0.25rem;
  background-color: #005093;
}
.intro-slider__overlay--wrapped h3 {
  margin-top: 1.6rem;
  padding: 0;
  font-size: 2rem;
}
.intro-slider__overlay--wrapped p {
  margin-top: 1.6rem;
  font-size: 1rem;
  line-height: 150%;
}

.intro-slider__wrapped .intro-slider__slide {
  padding-top: 46.77%;
}

@media (min-width: 540px) {
  .intro-slider__slide {
    padding-top: 80%;
  }
}
@media (min-width: 767px) {
  .intro-slider__overlay {
    position: absolute;
    z-index: 2;
    bottom: 5.8vw;
    left: 0;
  }
  .intro-slider__overlay::after {
    display: none;
  }
  .intro-slider__box {
    padding: 2rem 3.25rem 1.7rem 1.9rem;
  }
  .intro-slider__navigation {
    right: 5.2rem;
    bottom: 1.9rem;
  }
  .intro-slider__slide {
    padding-top: 54.8%;
  }

  .intro-slider__overlay--wrapped {
    margin-top: -6.3rem;
  }
  .intro-slider__overlay--wrapped .intro-slider__box {
    position: relative;
    z-index: 1;
    max-width: 45rem;
    margin: 0 0 0 2.5rem;
    padding: 2.2rem 0 0 3.1rem;
  }
  .intro-slider__overlay--wrapped .inner {
    max-width: 27rem;
  }

  .intro-slider__navigation--wrapped {
    display: none;
  }
}
@media (min-width: 1020px) {
  .intro-slider__slide {
    margin-top: 0;
    padding-top: 54.5%;
  }
}
@media (min-width: 1350px) {
  .intro-slider__slide {
    padding-top: 38.5%;
  }
  .intro-slider__box h2 {
    font-size: 2rem;
  }
  .intro-slider__box p {
    font-size: 0.85rem;
  }
  .intro-slider__navigation {
    bottom: 1.9rem;
    font-size: 0.75rem;
  }
  .intro-slider__arrow .svg-icon {
    width: 1.4rem;
  }
}
.detail-slider__slide {
  background-position: center;
  background-size: cover;
}

.detail-slider {
  display: block;
  height: 90.5vw;
  background-color: transparent;
}
@media (min-width: 540px) {
  .detail-slider {
    height: 68.5vw;
  }
}
@media (min-width: 767px) {
  .detail-slider {
    height: 46.77vw;
  }
}
.detail-slider.detail-slider__fullscreen-toggleable {
  cursor: pointer;
}
.detail-slider__fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 11;
  margin: 0 !important;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1rem 1rem 1rem 0;
}
.detail-slider__fullscreen .swiper-slide.swiper-slide-active {
  width: 100% !important;
  height: auto !important;
  margin: 0;
}
.detail-slider__fullscreen .intro-slider__slide-wrap {
  margin-left: 1rem;
  box-shadow: -8px 8px 10px 0 rgba(0, 0, 0, 0.1);
}

.close--fullscreen {
  position: fixed;
  top: 2rem;
  left: 2rem;
  z-index: 11;
  background: #005093;
  width: 50px;
  height: 50px;
  padding: 14px;
  pointer-events: none;
}

.detail-slider--flat {
  display: block;
  height: 33.49vw;
  background-color: #f5f4f5;
}
@media (min-width: 540px) {
  .detail-slider--flat {
    height: 33.49vw;
  }
}
@media (min-width: 767px) {
  .detail-slider--flat {
    height: 33.49vw;
  }
}

.detail-slider--inline {
  height: auto;
  margin-top: 1.1rem;
}
.detail-slider--inline .detail-slider__slide--inline {
  height: auto !important;
  padding-top: 46.7%;
}

.bodytext--detail__extra-margin {
  margin-top: 1rem;
}

.project-detail-swiper__slide {
  padding-top: 65%;
  background-position: center;
  background-size: cover;
}

.inner-slider {
  min-width: 100%;
}
.inner-slider__navigation {
  display: flex;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 1.9rem;
  align-items: center;
  user-select: none;
  font-size: 0.75rem;
}
.inner-slider__navigation .current::after {
  content: "/";
  display: inline-block;
  margin: 0 0.2rem;
}
.inner-slider__arrow {
  padding: 0 0.3rem;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.inner-slider__arrow .svg-icon {
  width: 1.4rem;
  height: 1.4rem;
}
.inner-slider__arrow--inactive {
  opacity: 0.1;
  pointer-events: none;
}
.inner-slider__arrow--prev .svg-icon svg {
  transform: rotate(180deg);
}
@media (min-width: 767px) {
  .inner-slider__navigation {
    right: 3.75rem;
  }
}
.inner-slider__button {
  display: none;
  position: absolute;
  top: 42%;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.inner-slider__button--prev {
  left: -7.7%;
  transform: rotate(180deg);
}
.inner-slider__button--next {
  right: -7.7%;
}
.inner-slider__button.inner-slider__button--hide {
  display: none;
}
.inner-slider__button--inactive {
  opacity: 0.1;
  pointer-events: none;
}
.inner-slider__navigation {
  z-index: 1;
  right: 2.1rem;
  bottom: 0;
  transform: translateY(100%);
}
.inner-slider__navigation--centered {
  right: 50%;
  bottom: -4rem;
  -webkit-transform: translateX(50%) translateY(-100%);
  transform: translateX(50%) translateY(-100%);
}
.inner-slider__video-button {
  position: absolute;
  z-index: 1;
  top: 16vw;
  left: 50%;
  width: 3.75rem;
  height: 3.75rem;
  transform: translateX(-50%);
  transition: background-color 0.2s ease;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.inner-slider__video-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

@media (min-width: 767px) {
  .inner-slider__button {
    display: inline-block;
  }
  .inner-slider__video-button {
    top: 20vw;
    width: 6.75rem;
    height: 6.75rem;
  }
  .inner-slider__navigation {
    top: -1.45rem;
    right: calc(6% + 10px);
    height: 2rem;
    transform: translateY(-100%);
  }
}
.search {
  display: flex;
  position: relative;
  justify-content: stretch;
  width: 45%;
}
.search__input {
  width: 80%;
  border-bottom: 1px solid rgba(151, 151, 151, 0.35);
  background-color: transparent;
  font-size: 0.75rem;
}
.search__button {
  transition: opacity 0.2s ease;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  -webkit-appearance: none;
}
.search__button .svg-icon {
  width: 1rem;
  height: 1rem;
}
.search__button:hover {
  opacity: 0.7;
}
.search--small {
  width: 8.2rem;
}
.search--small .search__input {
  flex: 1 1 auto;
  width: auto;
  font-size: 0.7rem;
}
.search--small .search__button {
  flex: 0 0 auto;
  width: 0.8rem;
  height: 0.8rem;
  padding: 0;
}
.search--small .svg-icon {
  width: 100%;
  height: 100%;
}

@media (min-width: 1190px) {
  .search--small {
    width: 9.5rem;
  }
}
@media (min-width: 1350px) {
  .search--small {
    width: 11.5rem;
  }
}
@media (min-width: 1420px) {
  .search--small {
    margin-top: -0.5rem;
  }
}
.icon-button {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  width: 40vw;
  padding: 0 0.8rem;
  transition: opacity 0.2s ease;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}
.icon-button__icon {
  width: 3rem;
  height: 3rem;
}
.icon-button__label {
  margin-top: 0.6rem;
  text-align: center;
  text-transform: uppercase;
}
.icon-button--active:not(.icon-button--inactive), .icon-button--selected:not(.icon-button--inactive) {
  opacity: 1;
}
.icon-button--active:not(.icon-button--inactive) .icon-button__label, .icon-button--selected:not(.icon-button--inactive) .icon-button__label {
  color: #005093;
}
.icon-button--active:not(.icon-button--inactive)::after, .icon-button--active.mobile-header__container:not(.icon-button--inactive)::after, .icon-button--selected:not(.icon-button--inactive)::after, .icon-button--selected.mobile-header__container:not(.icon-button--inactive)::after {
  content: "\a";
  display: block;
  position: absolute;
  z-index: 1;
  bottom: calc((1rem + 3px) * -1);
  left: 0.8rem;
  width: calc(100% - 1.6rem);
  height: 1px;
  background-color: #005093;
}
.icon-button--inactive {
  opacity: 0.2;
  pointer-events: none;
}
.icon-button--selected::before {
  content: "\a";
  display: block;
  position: absolute;
  top: 0;
  right: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  background-image: url("//svg/close.svg");
}
.icon-button:hover {
  opacity: 1;
}

.icon-button__container {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1.1rem;
  overflow-x: auto;
  overflow-y: hidden;
}

@media (min-width: 767px) {
  .icon-button__container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .icon-button {
    width: auto;
    padding: 0 1.75rem;
  }
  .icon-button__label {
    text-align: left;
    display: block;
  }
}
.icon-button--inactive.icon-button--selected {
  pointer-events: auto !important;
}

.overlay {
  display: flex;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  background-color: rgba(255, 255, 255, 0.98);
}
.overlay--grey {
  background-color: #f5f4f5;
}
.overlay__close {
  position: absolute;
  top: 3rem;
  right: 3rem;
  width: 1.5rem;
  height: 1.5rem;
  transition: transform 0.3s ease;
  cursor: pointer;
  z-index: 1;
}
.overlay__inner {
  width: 95%;
}
.overlay__inner.overlay__inner--full-width {
  width: 100%;
}
.overlay__inner--image {
  width: 70%;
}

.overlay__video {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}
.overlay__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay__image {
  width: 100%;
  height: 90vh;
  background: no-repeat center;
  background-size: contain;
}

@media (min-width: 540px) {
  .overlay__inner {
    width: 60%;
  }
}
@media (min-width: 767px) {
  .overlay__inner {
    width: 80%;
  }
}
@media (min-width: 1020px) {
  .overlay__inner {
    width: 60%;
  }
}
.link-dropdown {
  position: relative;
  padding-bottom: 1.5rem;
}
.link-dropdown__list {
  position: absolute;
  top: 1.5rem;
  left: 0;
  width: 100%;
}
.link-dropdown__list .svg-icon {
  position: absolute;
  top: 0.2rem;
  right: 0;
  width: 0.75rem;
  height: 0.75rem;
  transform: rotate(90deg);
}
.link-dropdown__element {
  display: none;
  opacity: 0.54;
}
.link-dropdown__element--active {
  display: block;
}

.pull-down__icon {
  display: block;
  width: 1rem;
  height: 1rem;
  margin: 2.5rem auto;
  cursor: pointer;
}
.pull-down__icon svg {
  transform: rotate(90deg);
}
.link-list {
  border-top: 1px solid rgba(200, 200, 200, 0.5);
}
.link-list__item {
  display: block;
  padding: 0.7rem 3rem;
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
  color: #000;
  font-size: 0.85rem;
  text-decoration: none;
}
.link-list__item:hover, .link-list__item--active {
  color: #005093;
}

.searchmask {
  display: block;
  position: relative;
  width: 25rem;
  margin: 0 auto;
}
.searchmask__clear {
  position: absolute;
  top: 50%;
  left: 0.9rem;
  width: 1rem;
  height: 1rem;
  transform: translateY(-0.5rem);
  transition: opacity 0.2s ease;
  opacity: 0.3;
  cursor: pointer;
}
.searchmask__clear:hover {
  opacity: 1;
}
.searchmask__search-icon {
  position: absolute;
  top: 50%;
  right: 0.9rem;
  width: 1rem;
  height: 1rem;
  padding: 0;
  transform: translateY(-0.5rem);
  border: 0;
  background: transparent;
}
.searchmask__input.input {
  width: 100%;
  padding: 0 3rem 0 2.8rem;
}

.per-page {
  display: flex;
  justify-content: center;
  margin: 0 0 1.8rem;
  font-size: 0.85rem;
}
.per-page__label {
  margin-right: 0.5rem;
}

.per-page__item {
  padding: 0 0.6rem;
  transition: opacity 0.2s ease;
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  cursor: pointer;
}
.per-page__item::after {
  content: "\a";
  display: none;
  width: 0.8rem;
  height: 2px;
  background-color: #005093;
}
.per-page__item--active {
  opacity: 1;
}
.per-page__item--active::after {
  display: block;
}
.per-page__item:hover::after {
  display: block;
}
.per-page__item:last-child {
  border-right: 0;
}

.search-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0 0;
}
.search-pagination__icon {
  width: 1.3rem;
  height: 1.3rem;
  margin: 0 0.5rem;
  cursor: pointer;
}
.search-pagination__icon--inactive {
  opacity: 0.2;
  pointer-events: none;
}
.search-pagination__icon--back {
  transform: rotate(180deg);
}
.search-pagination__counter {
  margin-top: 0.2rem;
  font-size: 0.75rem;
}
.search-pagination::before, .search-pagination::after {
  content: "\a";
  display: block;
  flex-grow: 1;
  width: auto;
  height: 1px;
}
.search-pagination::before {
  background: linear-gradient(to right, rgba(200, 200, 200, 0), rgba(200, 200, 200, 0.8));
}
.search-pagination::after {
  background: linear-gradient(to left, rgba(200, 200, 200, 0), rgba(200, 200, 200, 0.8));
}

.link-with-icon {
  display: inline-flex;
  align-items: center;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}
.link-with-icon__label {
  padding: 0 0 0 0.5rem;
  font-size: 0.75rem;
  text-decoration: underline;
}
.link-with-icon .svg-icon {
  width: 2.1rem;
  height: 2.1rem;
}
.cta__container {
  display: flex;
  position: fixed;
  z-index: 3;
  top: 50vh;
  right: 0;
  flex-direction: column;
  align-items: flex-end;
  transform: translateY(-50%);
  transition: right 0.3s ease;
}

.cta__container--invisible {
  right: -200px;
}

.cta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 182px;
  height: 44px;
  margin-bottom: 1px;
  padding: 0 18px;
  transition: background-color 0.2s ease;
  background-color: #000;
  text-decoration: none;
  cursor: pointer;
}
.cta__label {
  flex-grow: 1;
  width: 100%;
  margin-top: 4px;
  transition: color 0.2s ease;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}
.cta__icon {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
}
.cta:hover {
  background-color: #404040;
}

.cta__container--collapsed .cta, .cta__container--invisible .cta {
  min-width: 0;
}
.cta__container--collapsed .cta .cta__label, .cta__container--invisible .cta .cta__label {
  flex-grow: 0;
  width: 0;
  overflow: hidden;
}

.cta--blue {
  background-color: #005093;
}
.cta--blue .cta__label {
  color: #fff;
}
.cta--blue:hover {
  background-color: #005ead;
}

.contact-zip-search {
  width: 23rem;
}
.contact-zip-search__headline {
  text-transform: none;
}
.contact-zip-search__bodytext {
  width: 85%;
  margin-top: 0.8rem;
  font-size: 0.75rem;
  line-height: 145%;
}
.contact-zip-search__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.contact-zip-search .zip-filter {
  justify-content: flex-start;
}
.contact-zip-search__loader {
  width: 40px;
  height: 40px;
  margin: 2rem auto;
}

.image-comparison {
  position: relative;
  padding-top: 46.7%;
  overflow: hidden;
}
.image-comparison__element {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.image-comparison__element--overlay {
  z-index: 2;
  width: 50%;
}
.image-comparison__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
.image-comparison__icon {
  position: absolute;
  top: 1.75rem;
}
.image-comparison__icon--left {
  left: 1.75rem;
}
.image-comparison__icon--right {
  right: 1.75rem;
}
.image-comparison__slider {
  position: absolute;
  z-index: 3;
  top: 0;
}
.image-comparison__textbox {
  position: absolute;
  z-index: 4;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.image-comparison__textbox--inactive {
  transform: translateY(1rem);
  opacity: 0;
}

.image-comparison-slider {
  width: 5px;
  height: 100%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 4px 2px 0.8rem 0 rgba(0, 0, 0, 0.5);
}
.image-comparison-slider__inner-handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0 0.1rem;
  transition: background-color 0.3s ease;
  background-color: #005093;
}
.image-comparison-slider__arrow {
  width: 1.5rem;
  height: 1.5rem;
}
.image-comparison-slider__arrow--left svg {
  transform: rotate(180deg);
}

.image-comparison-slider__handle {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  width: 7.5rem;
  height: 7.5rem;
  transform: translate(-50%, -50%);
  transition: background-color 0.2s ease;
  background-color: rgba(0, 80, 147, 0.36);
  cursor: pointer;
}
.image-comparison-slider__handle:hover {
  background-color: rgba(0, 80, 147, 0);
}
.image-comparison-slider__handle:hover .image-comparison-slider__inner-handle {
  background-color: #fff;
}
@media (min-width: 1020px) {
  .image-comparison__textbox {
    bottom: 0;
  }
  .image-comparison__textbox--right {
    right: 1.75rem;
    margin-right: 0;
  }
}
.threesixty__container {
  position: relative;
  margin-top: 1rem;
}

.threesixty__outer-container {
  display: flex;
  flex-direction: column;
}

.threesixty__tab-nav-container {
  display: flex;
  position: absolute;
  top: -1rem;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 0.65rem;
  line-height: 100%;
  text-transform: uppercase;
}
.threesixty__tab-nav-container .svg-icon {
  width: 1rem;
  height: 1rem;
  margin: -0.3rem 0.5rem 0 0;
}

.threesixty__tab-nav {
  position: relative;
  height: 0.65rem;
  margin-left: 0.9rem;
  line-height: 100%;
  cursor: pointer;
}
.threesixty__tab-nav:hover {
  color: #005093;
}
.threesixty__tab-nav--active {
  pointer-events: none;
}
.threesixty__tab-nav--active::after {
  content: "\a";
  display: block;
  position: absolute;
  bottom: -0.3rem;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #005093;
}

.threesixty__general-label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.65rem;
  text-transform: uppercase;
  transform: translateY(-1.5rem);
  margin-right: 0.5rem;
}
.threesixty__general-label .svg-icon {
  width: 1.35rem;
  height: 1.35rem;
  margin-right: 0.3rem;
}

.threesixty__bodytext {
  display: flex;
  flex-direction: column;
}
.threesixty__bodytext .threesixty__headline {
  order: 2;
  margin: 2rem 0 1.8rem;
  text-align: left;
}
.threesixty__bodytext p {
  order: 3;
  margin-bottom: 4rem;
  font-size: 0.85rem;
  line-height: 150%;
}
.threesixty__bodytext .threesixty-degree__container {
  order: 1;
}

.threesixty {
  display: none;
  width: 100%;
  height: auto;
  opacity: 0;
  pointer-events: none;
  user-select: none;
}
.threesixty--active {
  display: block;
  opacity: 1;
}

.threesixty__navigation-container {
  display: none;
  position: absolute;
  right: 1rem;
  bottom: 12rem;
}

.threesixty__navigation {
  height: 1.6rem;
  cursor: pointer;
}
.threesixty__navigation .svg-icon {
  width: 1.6rem;
  height: 1.6rem;
}
.threesixty__navigation--prev .svg-icon {
  transform: rotate(180deg);
}

.threesixty__navigation-inner-container {
  display: flex;
  align-items: center;
}

.threesixty__separator {
  width: 2.5rem;
  height: 1px;
  margin: 0 0.5rem;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #979797 50%, rgba(0, 0, 0, 0));
}

@media (min-width: 767px) {
  .threesixty__tab-nav-container {
    width: auto;
    left: 1rem;
  }

  .threesixty__general-label {
    position: absolute;
    top: -1.5rem;
    right: 1rem;
    transform: translateY(0);
  }

  .threesixty__bodytext {
    margin-top: -2rem;
  }
}
@media (min-width: 1020px) {
  .threesixty__infobox {
    position: relative;
    z-index: 1;
    margin-top: -4rem;
    order: 2;
  }

  .threesixty-slider {
    padding-bottom: 5px;
  }

  .threesixty__tab-nav-container {
    left: 1.5rem;
  }

  .threesixty__inner-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.8rem 0 1.5rem;
  }

  .threesixty__general-label {
    margin-right: 0.2rem;
  }

  .threesixty__bodytext {
    width: 25%;
    margin-top: 0;
  }
  .threesixty__bodytext .threesixty__headline {
    order: 1;
    margin-top: 4rem;
  }
  .threesixty__bodytext p {
    order: 2;
  }
  .threesixty__bodytext .threesixty-degree__container {
    order: 3;
  }

  .threesixty__navigation-container {
    display: flex;
    position: static;
  }
}
.threesixty__container--multi-setup {
  margin-top: 0;
  padding: 2rem 0;
  background: #f5f4f5;
}
.threesixty__container--multi-setup .threesixty-slider::-webkit-slider-runnable-track {
  height: 0.4rem;
}
.threesixty__container--multi-setup .threesixty-slider::-webkit-slider-thumb {
  width: 3.25rem;
  height: 2.5rem;
}
.threesixty__container--multi-setup .threesixty__inner-container {
  bottom: 4%;
  right: 0;
  padding: 0 0.8rem 0 0.9rem;
}
.threesixty__container--multi-setup .threesixty__inner-container .threesixty__bodytext {
  width: 100%;
}

.threesixty-slider {
  width: 100%;
  margin: 19.5px 0;
  background-color: transparent;
  -webkit-appearance: none;
}

.threesixty-slider:focus {
  outline: none;
}

.threesixty-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.8rem;
  border-radius: 0.4rem;
  background: #eae7ea;
  cursor: pointer;
}

.threesixty-slider::-webkit-slider-thumb {
  width: 80px;
  height: 55px;
  margin-top: -19.5px;
  border-radius: 4px;
  background: #005093 url("//svg/360-white.svg") center no-repeat;
  background-size: 50%;
  cursor: pointer;
  -webkit-appearance: none;
}

.threesixty-slider:focus::-webkit-slider-runnable-track {
  background: #eae7ea;
}

.threesixty-slider::-moz-range-track {
  width: 100%;
  height: 0.8rem;
  border-radius: 8px;
  background: #eae7ea;
  cursor: pointer;
}

.threesixty-slider::-moz-range-thumb {
  width: 80px;
  height: 55px;
  border-radius: 4px;
  background: #005093;
  cursor: pointer;
}

.threesixty-slider::-ms-track {
  width: 100%;
  height: 0.8rem;
  border-color: transparent;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

.threesixty-slider::-ms-fill-lower {
  border-radius: 50%;
  background: #eae7ea;
}

.threesixty-slider::-ms-fill-upper {
  border-radius: 50%;
  background: #eae7ea;
}

.threesixty-slider::-ms-thumb {
  width: 80px;
  height: 55px;
  border-radius: 4px;
  background: #005093;
  cursor: pointer;
}

.threesixty-slider:focus::-ms-fill-lower {
  background: #eae7ea;
}

.threesixty-slider:focus::-ms-fill-upper {
  background: #eae7ea;
}

.cookie-banner {
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.cookie-banner__inner {
  padding: 1.5rem;
  background-color: #ffffff;
  box-shadow: 0 2px 28px -4px rgba(0, 0, 0, 0.36);
}
.cookie-banner__content .bodytext p {
  font-size: 0.75rem;
}
.cookie-banner__headline {
  font-size: 1rem;
  padding-bottom: 0.4rem;
}
@media (min-width: 1020px) {
  .cookie-banner__inner {
    display: flex;
    justify-content: space-between;
    padding: 2.5rem 3.5rem;
  }
  .cookie-banner__content {
    width: 75%;
  }
  .cookie-banner__content .bodytext p {
    font-size: 1rem;
  }
  .cookie-banner__headline {
    font-size: 1.5rem;
    padding-bottom: 0.8rem;
  }
}

.section--container-comparison button, .section--container-comparison .button--container-comparison {
  cursor: pointer;
}

.container-comparison-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  line-height: 2.75rem;
  height: 2.75rem;
  font-size: 1rem;
  background-color: #f5f4f5;
  margin-bottom: 1px;
}
.container-comparison-header .icon-section {
  display: flex;
  justify-content: center;
}
.container-comparison-header h4 {
  font-size: 0.8rem;
  opacity: 0.7;
  line-height: 3rem;
}
@media (min-width: 1020px) {
  .container-comparison-header h4 {
    font-size: 1rem;
  }
}
.container-comparison-header .button--container-comparison:first-child {
  margin-right: 1px;
}

.slider--close-zoom {
  display: none;
}

.container-comparison-image {
  background: #f5f4f5;
  height: 100%;
}
.container-comparison-image button {
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: 1rem;
  top: 1rem;
  z-index: 1;
  cursor: pointer;
}
.container-comparison-image button.slider--open-zoom {
  display: none;
  left: 0;
  flex-direction: row;
}
.container-comparison-image button.slider--open-zoom svg {
  width: 1.25rem;
  height: 1.25rem;
}
.container-comparison-image button.slider--open-zoom span {
  font-size: 0.65rem;
  line-height: 1.25rem;
}
.container-comparison-image .open-zoom-button--hover-area {
  position: absolute;
  top: 5%;
  bottom: 22%;
  left: 5%;
  right: 5%;
  z-index: 1;
}
.container-comparison-image .open-zoom-button--hover-area:hover .slider--open-zoom {
  display: flex;
}

.threesixty__container--multi-setup.zoom-out {
  position: fixed;
  left: 3%;
  top: 3%;
  right: 3%;
  z-index: 10;
  box-shadow: -8px 8px 10px 0 rgba(0, 0, 0, 0.1);
}
.threesixty__container--multi-setup.zoom-out .threesixty__inner-container {
  bottom: 2%;
}
.threesixty__container--multi-setup.zoom-out .slider--open-zoom {
  display: none !important;
}
.threesixty__container--multi-setup.zoom-out .container__title {
  display: block;
  position: relative;
  font-size: 1rem;
  text-transform: uppercase;
  left: 1.5rem;
}
@media (min-width: 1020px) {
  .threesixty__container--multi-setup.zoom-out .container__title {
    font-size: 1.5rem;
  }
}
.threesixty__container--multi-setup.zoom-out .slider--close-zoom {
  display: block;
  background: #005093;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 50px;
  height: 50px;
}
.threesixty__container--multi-setup.zoom-out .slider--close-zoom .svg-icon {
  position: relative;
  top: 3px;
  height: 20px;
  width: 20px;
}

.container-comparison-criteria-header {
  background-color: #eaeaea;
  padding: 1.1rem;
  position: relative;
  z-index: 1;
  margin-top: 1px;
}
.container-comparison-criteria-header:after {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 12px solid #eaeaea;
  content: "";
  position: absolute;
  bottom: -11px;
  left: 1.5rem;
}
.container-comparison-criteria-header p {
  font-size: 0.85rem;
  opacity: 0.8;
}

.container-comparison-linkbox {
  background-color: #eaeaea;
  position: relative;
  margin-top: 2rem;
  padding: 1.25rem;
}
.container-comparison-linkbox h4 {
  max-width: 75%;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.container-comparison-linkbox .info-icon {
  width: 11px;
  height: 11px;
  display: inline-block;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
}
.container-comparison-linkbox .button {
  min-width: 7rem;
}
@media (min-width: 540px) {
  .container-comparison-linkbox .button {
    min-width: 9rem;
  }
}

.container-comparison-criteria-content {
  position: relative;
  background-color: #f5f4f5;
  padding: 1.5rem 1.35rem 1.2rem;
  width: 100%;
}
.container-comparison-criteria-content + .container-comparison-criteria-content {
  margin-top: 1px;
}
.container-comparison-criteria-content h5 {
  font-size: 0.8rem;
  opacity: 0.4;
  margin-bottom: 4px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.container-comparison-criteria-content p {
  font-size: 0.8rem;
}
.container-comparison-criteria-content .infobox-container-comparison {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 35px;
  width: 35px;
  background: #005093;
}
.container-comparison-criteria-content .infobox-container-comparison svg {
  position: relative;
  width: 13px;
  height: 13px;
  top: 2px;
}

.button--container-comparison__arrow-dropdown {
  padding: 0 1rem;
}

.button--container-comparison__arrow-remove .svg-icon {
  position: relative;
  width: 1.25rem;
}

.overlay__container-comparison {
  background-color: rgba(255, 255, 255, 0.85);
}
.overlay__container-comparison .overlay-container-comparison__content {
  padding: 2rem 6rem 2rem 2rem;
  background: #fff;
}
.overlay__container-comparison .overlay-container-comparison__content h3 {
  margin-bottom: 0.75rem;
}
.overlay__container-comparison .overlay__content-wrap {
  background-color: #fff;
  box-shadow: -8px 8px 10px 0 rgba(0, 0, 0, 0.1);
}
.overlay__container-comparison .bodytext {
  font-size: 0.8rem;
}
.overlay__container-comparison .popup-container-comparison__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background: #005093;
}
.overlay__container-comparison .popup-container-comparison__close .svg-icon {
  position: relative;
  top: 3px;
  height: 20px;
  width: 20px;
}

.wrap__align-sameheight {
  align-items: stretch;
  margin-bottom: 1px;
}
.wrap__align-sameheight .container-criteria__wrap {
  display: flex;
}

.hr-main-slider__slide {
  height: 53rem;
  background-position: bottom center;
  background-size: cover;
  cursor: ew-resize;
}

.hr-main-slider {
  display: block;
  background-color: #f5f4f5;
}
.hr-main-slider__navigation {
  display: flex;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 1.9rem;
  align-items: center;
  user-select: none;
  font-size: 0.75rem;
}
.hr-main-slider__navigation .current::after {
  content: "/";
  display: inline-block;
  margin: 0 0.2rem;
}
.hr-main-slider__arrow {
  padding: 0 0.3rem;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.hr-main-slider__arrow .svg-icon {
  width: 1.4rem;
  height: 1.4rem;
}
.hr-main-slider__arrow--inactive {
  opacity: 0.1;
  pointer-events: none;
}
.hr-main-slider__arrow--prev .svg-icon svg {
  transform: rotate(180deg);
}
@media (min-width: 767px) {
  .hr-main-slider__navigation {
    right: 3.75rem;
  }
}

.hr-main-slider__slide-wrap {
  overflow: hidden;
}

.hr-logo {
  z-index: 4;
  height: 2rem;
  margin-left: auto;
}
.hr-logo img {
  width: auto;
  height: 1.75rem;
}

.hr-main-slider__overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.hr-main-slider__overlay .wrap,
.hr-main-slider__overlay [class*=col-],
.hr-main-slider__overlay .inner-wrap,
.hr-main-slider__overlay h6.with-decor,
.hr-main-slider__overlay .like-h6.with-decor {
  height: 100%;
}
.hr-main-slider__overlay::after {
  display: none;
}
.hr-main-slider__overlay--inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}
.hr-main-slider__overlay--left {
  margin-top: 1.8rem;
}
.hr-main-slider__overlay--left, .hr-main-slider__overlay--right {
  width: 100%;
}

.hr-main-slider__content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
}
.hr-main-slider__content p {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: right;
  color: #fff;
}
.hr-main-slider__content .svg-icon {
  width: 7.56rem;
  height: 6.875rem;
  margin-bottom: 3.2rem;
}
.hr-main-slider__content .svg-icon svg {
  width: 7.56rem;
  height: 6.875rem;
}

.hr-main-slider__pagination {
  display: none;
  position: relative;
  height: 0.25rem;
  max-width: fit-content;
  margin-top: auto;
}
.hr-main-slider__pagination::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  width: 100%;
  height: 1px;
  background: #fff;
}
.hr-main-slider__pagination .swiper-pagination-bullet {
  display: table-cell;
  width: 2.5rem;
  height: 0.25rem;
  border-radius: 0;
  background: transparent;
}
.hr-main-slider__pagination .swiper-pagination-bullet-active {
  background: #005093;
}

.hr-main-slider__box {
  position: relative;
  width: 100%;
  margin: 0 0 2rem 0;
  background-color: #fff;
  pointer-events: visible;
  box-shadow: 9px 11px 38px -17px rgba(0, 0, 0, 0.5);
}
.hr-main-slider__box h3 {
  max-width: 14.15rem;
  padding-bottom: 0.55rem;
  color: #005093;
  font-size: 2.25rem;
  line-height: 0.84;
}
.hr-main-slider__box p {
  max-width: 12.7rem;
  padding-bottom: 1rem;
  color: #005093;
  font-size: 1.5rem;
  line-height: 1;
}
.hr-main-slider__box--top {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 3.2rem;
}
.hr-main-slider__box.green {
  background-color: #0fd774;
}
.hr-main-slider__box.green .audio-player__container {
  background-color: #57D798;
}
.hr-main-slider__box.green .audio-player__container .audio-player__title {
  background-color: #57D798;
}
.hr-main-slider__box.green .audio-player__container:hover {
  background-color: #73D7A5;
}
.hr-main-slider__box.green .audio-player__container:hover .audio-player__title {
  background-color: #73D7A5;
}
.hr-main-slider__box.lightblue {
  background-color: #0fd7cf;
}
.hr-main-slider__box.lightblue .audio-player__container {
  background-color: #85D7D4;
}
.hr-main-slider__box.lightblue .audio-player__container .audio-player__title {
  background-color: #85D7D4;
}
.hr-main-slider__box.lightblue .audio-player__container:hover {
  background-color: #97D7D4;
}
.hr-main-slider__box.lightblue .audio-player__container:hover .audio-player__title {
  background-color: #97D7D4;
}
.hr-main-slider__box.yellow {
  background-color: #d7b50f;
}
.hr-main-slider__box.yellow .audio-player__container {
  background-color: #D7C157;
}
.hr-main-slider__box.yellow .audio-player__container .audio-player__title {
  background-color: #D7C157;
}
.hr-main-slider__box.yellow .audio-player__container:hover {
  background-color: #D7C673;
}
.hr-main-slider__box.yellow .audio-player__container:hover .audio-player__title {
  background-color: #D7C673;
}
.hr-main-slider__box.brown {
  background-color: #ab9778;
}
.hr-main-slider__box.brown .audio-player__container {
  background-color: #B3A38B;
}
.hr-main-slider__box.brown .audio-player__container .audio-player__title {
  background-color: #B3A38B;
}
.hr-main-slider__box.brown .audio-player__container:hover {
  background-color: #B9AC98;
}
.hr-main-slider__box.brown .audio-player__container:hover .audio-player__title {
  background-color: #B9AC98;
}
.hr-main-slider__box.red {
  background-color: #cc4747;
}
.hr-main-slider__box.red .audio-player__container {
  background-color: #ce6363;
}
.hr-main-slider__box.red .audio-player__container .audio-player__title {
  background-color: #ce6363;
}
.hr-main-slider__box.red .audio-player__container:hover {
  background-color: #cf7777;
}
.hr-main-slider__box.red .audio-player__container:hover .audio-player__title {
  background-color: #cf7777;
}
.hr-main-slider__box.orange {
  background-color: #f26946;
}
.hr-main-slider__box.orange .audio-player__container {
  background-color: #EC7F63;
}
.hr-main-slider__box.orange .audio-player__container .audio-player__title {
  background-color: #EC7F63;
}
.hr-main-slider__box.orange .audio-player__container:hover {
  background-color: #E88E77;
}
.hr-main-slider__box.orange .audio-player__container:hover .audio-player__title {
  background-color: #E88E77;
}
.hr-main-slider__box.eosin {
  background-color: #D23F63;
}
.hr-main-slider__box.eosin .audio-player__container {
  background-color: #d35d7a;
}
.hr-main-slider__box.eosin .audio-player__container .audio-player__title {
  background-color: #d35d7a;
}
.hr-main-slider__box.eosin .audio-player__container:hover {
  background-color: #D3728A;
}
.hr-main-slider__box.eosin .audio-player__container:hover .audio-player__title {
  background-color: #D3728A;
}
.hr-main-slider__box--inner {
  padding: 1.5rem 2rem;
}

.hr-main-slider__scroll {
  height: 100%;
  width: 100%;
  background: white;
  border-radius: 3px;
  animation: 3s cubic-bezier(0.14, 1.12, 0.67, 0.99) 0s infinite normal none running scrolldown;
  -ms-animation: 3s cubic-bezier(0.14, 1.12, 0.67, 0.99) 0s infinite normal none scrolldown;
}
.hr-main-slider__scroll--container {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  height: 30px;
  width: 2px;
  border-radius: 3px;
  transform: translateX(-50%);
  overflow: hidden;
}

@-webkit-keyframes scrolldown {
  0% {
    transform: translateY(-200%);
  }
  50% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}
@keyframes scrolldown {
  0% {
    transform: translateY(-200%);
  }
  50% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}
@media (min-width: 540px) {
  .hr-main-slider__scroll--container {
    height: 50px;
  }
}
@media (min-width: 767px) {
  .hr-main-slider__slide {
    height: auto;
    padding-top: 80%;
  }
  .hr-main-slider__pagination {
    display: block;
  }
  .hr-main-slider__box {
    position: relative;
    margin: 0 2rem 0 0;
  }
  .hr-main-slider__box--inner {
    padding: 2.4rem 2.25rem 2.15rem 2.3rem;
  }
  .hr-main-slider__box--top {
    margin-bottom: 6.65rem;
  }
  .hr-main-slider__box h3 {
    padding-bottom: 0.9rem;
    font-size: 3rem;
  }
  .hr-main-slider__box p {
    padding-bottom: 1.65rem;
    font-size: 1.5rem;
  }
  .hr-main-slider__overlay--right, .hr-main-slider__overlay--left {
    margin-top: 4.5rem;
  }
  .hr-main-slider__overlay--right {
    width: auto;
  }
  .hr-main-slider__overlay--left {
    width: 26rem;
  }
  .hr-main-slider__content {
    height: 32.45rem;
  }
  .hr-main-slider__content .svg-icon {
    width: 11.45rem;
    height: 10.45rem;
    margin-bottom: 6.95rem;
  }
  .hr-main-slider__content .svg-icon svg {
    width: 11.45rem;
    height: 10.45rem;
  }
  .hr-main-slider__content .svg-icon p {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .hr-main-slider__overlay--left {
    width: calc(50% - 15px);
  }

  .hr-logo img {
    height: 2rem;
  }
}
@media (min-width: 1020px) {
  .hr-main-slider__slide {
    padding-top: 54.5%;
  }
  .hr-main-slider__box--top {
    margin-bottom: 3rem;
  }
  .hr-main-slider__box--top .hr-logo {
    height: 1.7rem;
  }
  .hr-main-slider__box--top .hr-logo img {
    height: 1.7rem;
  }
}
@media only screen and (min-width: 1020px) {
  .hr-main-slider__overlay--left {
    width: calc(33.33333333% - 15px);
  }
}
@media (min-width: 1190px) {
  .hr-main-slider__box--top {
    margin-bottom: 6.65rem;
  }
  .hr-main-slider__box--top .hr-logo {
    height: 2rem;
  }
  .hr-main-slider__box--top .hr-logo img {
    height: 2rem;
  }
}
@media only screen and (min-width: 1420px) {
  .hr-main-slider__overlay--left {
    width: calc(33.33333333% - 30px);
  }
}
@media (min-width: 1580px) {
  .hr-main-slider__slide {
    padding-top: 47.657%;
  }
}
app-audio-player {
  display: block;
  max-width: 100%;
}

.audio-player {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.audio-player .audio-player__icon, .audio-player .audio-player__play, .audio-player .audio-player__pause {
  pointer-events: none;
}
.audio-player__container {
  width: 100%;
  padding: 0.7875rem 1.55rem;
  background-color: rgba(216, 216, 216, 0.36);
  cursor: pointer;
}
.audio-player__icon {
  width: 2.8125rem;
  height: 2.8125rem;
}
.audio-player__icon svg {
  width: 2.8125rem;
  height: 2.8125rem;
}
.audio-player__icon svg path {
  fill: #005093;
}
.audio-player__duration {
  margin-left: 0.6rem;
  font-size: 1rem;
  line-height: 1.2rem;
  color: #005093;
}
.audio-player__title {
  position: relative;
  z-index: 2;
  width: 8rem;
  margin: 0 auto;
  font-size: 0.8125rem;
  line-height: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  color: #005093;
  pointer-events: none;
}
.audio-player__title--container {
  position: relative;
  width: 13.5rem;
}
.audio-player__title.green {
  background-color: #57d798;
}
.audio-player__title.lightblue {
  background-color: #57d7d2;
}
.audio-player__title.yellow {
  background-color: #d7c257;
}
.audio-player__title.brown {
  background-color: #bbae9b;
}
.audio-player__title.red {
  background-color: #d4768d;
}
.audio-player__title.orange {
  background-color: #EB927C;
}
.audio-player__line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  -webkit-appearance: none;
  pointer-events: none;
  background: #005093;
}
.audio-player__line::after {
  position: absolute;
  content: "\a";
  display: block;
  background-color: #005093;
}
.audio-player__line::after {
  top: -2px;
  right: 0;
  width: 4px;
  height: 4px;
  border-radius: 100%;
}
.audio-player__line--container {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}
.audio-player__line::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 2px;
  height: 0.5rem;
  border: none;
  border-radius: 3px;
  background-color: #005093;
  cursor: pointer;
  margin-top: -1px;
}
.audio-player__line::-moz-range-thumb {
  width: 2px;
  border: none;
  height: 0.5rem;
  border-radius: 3px;
  background-color: #005093;
  cursor: pointer;
}
.audio-player__line::-ms-thumb {
  width: 2px;
  height: 0.5rem;
  border-radius: 3px;
  border: none;
  background-color: #005093;
  cursor: pointer;
}
.audio-player__normal-line {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #005093;
}
.audio-player__normal-line::before, .audio-player__normal-line::after {
  position: absolute;
  content: "\a";
  display: block;
  background-color: #005093;
}
.audio-player__normal-line::before {
  top: -0.225rem;
  width: 2px;
  height: 0.5rem;
  border-radius: 4.5%;
}
.audio-player__normal-line::after {
  top: -2px;
  right: 0;
  width: 4px;
  height: 4px;
  border-radius: 100%;
}
.audio-player__normal-line--container {
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}
.audio-player progress {
  color: #005093;
}
.audio-player__timeline::-ms-fill {
  border: none;
}
.audio-player__timeline[value] {
  position: absolute;
  z-index: 3;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  top: -0.375rem;
  left: 0;
  width: 100%;
  height: 0.75rem;
  background-color: transparent;
  appearance: none;
}
.audio-player__timeline[value]::-webkit-progress-value {
  background-color: #005093;
}
.audio-player__timeline[value]::-webkit-progress-bar {
  background-color: transparent;
}
.audio-player__play {
  box-sizing: border-box;
  width: 0.875rem;
  height: 1rem;
  margin-right: 0.57rem;
  margin-left: 0.64rem;
  border-style: solid;
  border-width: 0.5rem 0 0.5rem 0.875rem;
  border-color: transparent transparent transparent #005093;
  cursor: pointer;
}
.audio-player__pause {
  width: 0.875rem;
  height: 1rem;
  margin-right: 0.57rem;
  margin-left: 0.64rem;
  border-style: double;
  border-width: 0 0 0 0.5rem;
  border-color: #005093;
  cursor: pointer;
}

@media (min-width: 767px) {
  .audio-player__container {
    padding: 1.5rem 1.55rem;
  }
  .audio-player__pause {
    width: 0.9rem;
  }
  .audio-player__play {
    width: 0.9rem;
  }
  .audio-player__play, .audio-player--pause {
    height: 0.75rem;
  }
  .audio-player__icon {
    width: 1.79rem;
    height: 1.79rem;
  }
  .audio-player__icon svg {
    width: 1.79rem;
    height: 1.79rem;
  }
  .audio-player__title {
    width: 7.36rem;
    font-size: 0.714rem;
  }
  .audio-player__duration {
    margin-left: 0.95rem;
    font-size: 0.86rem;
  }
}
@media (min-width: 1350px) {
  .audio-player__container {
    padding: 1.9rem 2.2rem 1.75rem 2.25rem;
  }
  .audio-player__icon {
    width: 2.5rem;
    height: 2.5rem;
  }
  .audio-player__icon svg {
    width: 2.5rem;
    height: 2.5rem;
  }
  .audio-player__title {
    width: 6.15rem;
    font-size: 0.6rem;
  }
  .audio-player__play, .audio-player__pause {
    margin-right: 0.6rem;
    margin-left: 1.15rem;
  }
  .audio-player__duration {
    font-size: 1rem;
  }
}
@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .audio-player__normal-line--container {
    display: block;
  }

  .audio-player__line--label {
    display: none;
  }
}
.hr-statement-slider > .swiper.s-wrapper .swiper-wrapper .swiper-slide {
  height: auto;
}

.hr-statement {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  color: #005093;
}
.hr-statement--standalone {
  margin-bottom: 1rem;
}
.hr-statement.hr-statement__link-slide {
  background-color: #005093;
  color: #fff;
  cursor: pointer !important;
  text-align: center;
}
.hr-statement.hr-statement__link-slide:hover {
  background-color: #19619D;
}
.hr-statement.hr-statement__link-slide a {
  color: #fff;
  text-decoration: none !important;
}
.hr-statement.hr-statement__link-slide .hr-statement__inner {
  padding-top: 8rem;
}
.hr-statement.hr-statement__link-slide .svg-icon {
  height: 5rem;
  width: 5rem;
  margin-bottom: 1.5rem;
}
.hr-statement__container {
  width: 100%;
}
.hr-statement__logo {
  z-index: 4;
  height: 2rem;
  margin-left: auto;
}
.hr-statement__logo .svg-icon {
  width: auto;
  height: 2rem;
}
.hr-statement__logo .svg-icon svg {
  width: auto;
  height: 2rem;
}
.hr-statement__logo .svg-icon svg .logo--full-white {
  transition: fill 0.5s ease;
}
.hr-statement__logo--active svg .logo--full-white {
  fill: #005093;
}
.hr-statement__nav-box {
  position: relative;
  background-color: #005093;
  color: #fff;
}
.hr-statement__nav-box .hr-statement__inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 2.35rem;
}
.hr-statement__nav-box .hr-statement__inner--bottom p {
  margin-bottom: 1.1rem;
}
.hr-statement__back-box {
  height: 6rem;
  padding: 1rem 0 1.5rem 0;
}
.hr-statement__back-button {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  color: #005093;
  font-size: 1.25rem;
  line-height: 1.2rem;
  text-decoration: none;
}
.hr-statement__back-button .svg-icon {
  width: 1.8rem;
  height: 1.5rem;
  margin-bottom: 0.8rem;
}
.hr-statement__back-button .svg-icon svg {
  width: 1.8rem;
  height: 1.5rem;
}
.hr-statement__back-button:hover {
  opacity: 0.7;
}
.hr-statement__inner {
  padding: 1.4rem 1.55rem 2rem;
}
.hr-statement__inner--top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.hr-statement__intro {
  max-width: 10rem;
  margin-top: 1.3rem;
  font-size: 0.9375rem;
  line-height: 1.25;
}
.hr-statement__portrait {
  width: 8.7rem;
  height: 8.7rem;
  border-radius: 100%;
  background-blend-mode: multiply;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.hr-statement__quote {
  font-size: 2rem;
  line-height: 1;
}
.hr-statement__section .section {
  padding-top: 1.7rem;
  padding-bottom: 0;
}
.hr-statement__slider-headline {
  width: 100%;
  max-width: 17.25rem;
  margin: 0 auto 0.8rem;
  color: #005093;
  font-size: 1.125rem;
  line-height: 1.2;
  text-align: center;
}
.hr-statement.green {
  background-color: #0fd774;
}
.hr-statement.green .hr-statement__portrait {
  background-color: #0fd774;
}
.hr-statement.green .audio-player__container {
  background-color: #57D798;
}
.hr-statement.green .audio-player__container .audio-player__title {
  background-color: #57D798;
}
.hr-statement.green .audio-player__container:hover {
  background-color: #73D7A5;
}
.hr-statement.green .audio-player__container:hover .audio-player__title {
  background-color: #73D7A5;
}
.hr-statement.lightblue {
  background-color: #0fd7cf;
}
.hr-statement.lightblue .hr-statement__portrait {
  background-color: #0fd7cf;
}
.hr-statement.lightblue .audio-player__container {
  background-color: #85D7D4;
}
.hr-statement.lightblue .audio-player__container .audio-player__title {
  background-color: #85D7D4;
}
.hr-statement.lightblue .audio-player__container:hover {
  background-color: #97D7D4;
}
.hr-statement.lightblue .audio-player__container:hover .audio-player__title {
  background-color: #97D7D4;
}
.hr-statement.yellow {
  background-color: #d7b50f;
}
.hr-statement.yellow .hr-statement__portrait {
  background-color: #d7b50f;
}
.hr-statement.yellow .audio-player__container {
  background-color: #D7C157;
}
.hr-statement.yellow .audio-player__container .audio-player__title {
  background-color: #D7C157;
}
.hr-statement.yellow .audio-player__container:hover {
  background-color: #D7C673;
}
.hr-statement.yellow .audio-player__container:hover .audio-player__title {
  background-color: #D7C673;
}
.hr-statement.brown {
  background-color: #ab9778;
}
.hr-statement.brown .hr-statement__portrait {
  background-color: #ab9778;
}
.hr-statement.brown .audio-player__container {
  background-color: #B3A38B;
}
.hr-statement.brown .audio-player__container .audio-player__title {
  background-color: #B3A38B;
}
.hr-statement.brown .audio-player__container:hover {
  background-color: #B9AC98;
}
.hr-statement.brown .audio-player__container:hover .audio-player__title {
  background-color: #B9AC98;
}
.hr-statement.red {
  background-color: #cc4747;
}
.hr-statement.red .hr-statement__portrait {
  background-color: #cc4747;
}
.hr-statement.red .audio-player__container {
  background-color: #ce6363;
}
.hr-statement.red .audio-player__container .audio-player__title {
  background-color: #ce6363;
}
.hr-statement.red .audio-player__container:hover {
  background-color: #cf7777;
}
.hr-statement.red .audio-player__container:hover .audio-player__title {
  background-color: #cf7777;
}
.hr-statement.orange {
  background-color: #f26946;
}
.hr-statement.orange .hr-statement__portrait {
  background-color: #f26946;
}
.hr-statement.orange .audio-player__container {
  background-color: #EC7F63;
}
.hr-statement.orange .audio-player__container .audio-player__title {
  background-color: #EC7F63;
}
.hr-statement.orange .audio-player__container:hover {
  background-color: #E88E77;
}
.hr-statement.orange .audio-player__container:hover .audio-player__title {
  background-color: #E88E77;
}
.hr-statement.eosin {
  background-color: #D23F63;
}
.hr-statement.eosin .hr-statement__portrait {
  background-color: #D23F63;
}
.hr-statement.eosin .audio-player__container {
  background-color: #d35d7a;
}
.hr-statement.eosin .audio-player__container .audio-player__title {
  background-color: #d35d7a;
}
.hr-statement.eosin .audio-player__container:hover {
  background-color: #D3728A;
}
.hr-statement.eosin .audio-player__container:hover .audio-player__title {
  background-color: #D3728A;
}

.hr-statement-slider {
  display: block;
  flex: 1;
  max-width: 100%;
  margin: 0 auto;
  background-color: transparent;
}
.hr-statement-slider__navigation {
  display: flex;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 1.9rem;
  align-items: center;
  user-select: none;
  font-size: 0.75rem;
}
.hr-statement-slider__navigation .current::after {
  content: "/";
  display: inline-block;
  margin: 0 0.2rem;
}
.hr-statement-slider__arrow {
  padding: 0 0.3rem;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.hr-statement-slider__arrow .svg-icon {
  width: 1.4rem;
  height: 1.4rem;
}
.hr-statement-slider__arrow--inactive {
  opacity: 0.1;
  pointer-events: none;
}
.hr-statement-slider__arrow--prev .svg-icon svg {
  transform: rotate(180deg);
}
@media (min-width: 767px) {
  .hr-statement-slider__navigation {
    right: 3.75rem;
  }
}
.hr-statement-slider--slide {
  cursor: ew-resize;
  height: 100%;
}
.hr-statement-slider--slide-wrap {
  overflow: hidden;
  height: 100%;
}
.hr-statement-slider__navigation {
  top: 0;
  right: -0.4rem;
  font-size: 1rem;
}
.hr-statement-slider__arrow .svg-icon {
  height: 1.55rem;
}
.hr-statement-slider__arrow--next {
  padding-right: 0;
}

@media (max-width: 766px) {
  .hr-statement-slider__navigation {
    position: static;
    justify-content: center;
    width: 100%;
    margin-bottom: 1.2rem;
  }
}
@media (min-width: 767px) {
  .hr-statement__slider-headline {
    max-width: none;
    margin-bottom: 1.5rem;
    font-size: 1.14rem;
  }
  .hr-statement__inner {
    padding: 1.2rem 1.55rem 2.64rem;
  }
  .hr-statement__inner--top {
    margin-bottom: 3.75rem;
  }
  .hr-statement__intro {
    font-size: 0.86rem;
  }
  .hr-statement__portrait {
    width: 7.64rem;
    height: 7.64rem;
  }
  .hr-statement__quote {
    font-size: 1.86rem;
  }
  .hr-statement__container {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 6rem;
  }
  .hr-statement__container > * {
    grid-column: span 6;
  }
  .hr-statement__section .section {
    padding-top: 2.55rem;
  }
  .hr-statement.grid--half {
    grid-row-end: span 3;
  }
  .hr-statement.grid--full {
    grid-row-end: span 4;
  }
  .hr-statement--standalone {
    margin-bottom: 0;
  }
}
@media (min-width: 1020px) {
  .hr-statement__container > * {
    grid-column: span 4;
  }
}
@media (min-width: 1350px) {
  .hr-statement__slider-headline {
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }
  .hr-statement.hr-statement__link-slide .hr-statement__inner {
    padding-top: 11.5rem;
  }
  .hr-statement__inner {
    padding: 1.75rem 2.15rem 3.75rem 2.5rem;
  }
  .hr-statement__intro {
    font-size: 1rem;
  }
  .hr-statement__portrait {
    width: 10rem;
    height: 10rem;
  }
  .hr-statement__quote {
    font-size: 2.5rem;
  }
  .hr-statement__section .section {
    padding-top: 3.25rem;
  }
  .hr-statement__section--standalone .section {
    padding-top: 4.5rem;
  }
  .hr-statement.grid--full {
    grid-row-end: span 5;
  }
}
.hr-menu {
  position: absolute;
  z-index: 4;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  top: 0;
  left: -110%;
  transition: left 0.5s ease-in-out;
  width: 100%;
  height: 100%;
  padding: 4.5rem 1.575rem 1.85rem;
  background-color: #fff;
  pointer-events: auto;
}
.hr-menu ul {
  margin-top: auto;
}
.hr-menu ul li {
  margin-bottom: 0.6rem;
}
.hr-menu ul li:last-child {
  margin-bottom: 0;
}
.hr-menu__link {
  font-size: 1.5rem;
  line-height: 1.11;
  color: #005093;
  text-transform: uppercase;
  text-decoration: none;
}
.hr-menu__link span {
  display: inline-block;
  margin-left: 1.5rem;
}
.hr-menu__link span::after {
  content: "";
  display: block;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 250ms ease-in-out;
  border-bottom: solid 3px #005093;
}
.hr-menu__link .svg-icon {
  position: absolute;
  margin-top: -0.15rem;
}
.hr-menu__link .svg-icon svg {
  width: 1rem;
  height: 1rem;
}
.hr-menu__link:hover span::after {
  transform: scaleX(1);
}
.hr-menu__link--active span::after {
  transform: scaleX(1);
}
.hr-menu__link--career {
  margin-top: 1.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #005093;
  text-transform: uppercase;
  text-decoration: none;
}
.hr-menu__link--career span {
  display: inline-block;
  margin-left: 1.5rem;
}
.hr-menu__link--career span::after {
  content: "";
  display: block;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 250ms ease-in-out;
  border-bottom: solid 2px #005093;
}
.hr-menu__link--career .svg-icon {
  position: absolute;
  width: 1rem;
  height: 1rem;
  margin-top: 0.25rem;
}
.hr-menu__link--career .svg-icon svg {
  width: 1rem;
  height: 1rem;
}
.hr-menu__link--career:hover span::after {
  transform: scaleX(1);
}
.hr-menu__container {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.hr-menu__container--big {
  height: 35.4rem;
}
.hr-menu--active {
  left: 0;
}
.hr-menu__burger {
  position: relative;
  z-index: 4;
  width: 2.3rem;
  height: 1.25rem;
  margin-top: auto;
  margin-right: 1.3rem;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.hr-menu__burger span {
  display: block;
  position: absolute;
  right: 0;
  width: 100%;
  height: 0.175rem;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  border-radius: 3px;
  opacity: 1;
}
.hr-menu__burger span:nth-child(1) {
  top: 0;
}
.hr-menu__burger span:nth-child(2) {
  top: 0.5rem;
}
.hr-menu__burger span:nth-child(3) {
  top: 1rem;
}
.hr-menu__burger--white span {
  background: #fff;
}
.hr-menu__burger--blue span {
  background: #005093;
}
.hr-menu__burger:hover span:nth-child(1) {
  width: 50%;
}
.hr-menu__burger:hover span:nth-child(2) {
  width: 75%;
}
.hr-menu__burger-open span:nth-child(1) {
  top: 0.5rem;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.hr-menu__burger-open span:nth-child(2) {
  opacity: 0;
}
.hr-menu__burger-open span:nth-child(3) {
  top: 0.5rem;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.hr-menu__burger-open:hover span:nth-child(1) {
  width: 100%;
}

@media (min-width: 767px) {
  .hr-menu {
    padding: 4.5rem 2.2rem 1.85rem;
  }
  .hr-menu__link {
    font-size: 1.8rem;
  }
  .hr-menu__link--career {
    margin-top: 2.7rem;
  }
  .hr-menu ul li {
    margin-bottom: 1.225rem;
  }
}
@media (min-width: 1190px) {
  .hr-menu__link {
    font-size: 2.25rem;
  }
}
.hr-section-nav {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  padding-left: 3vw;
  pointer-events: auto;
}
.hr-section-nav__container {
  display: none;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
.hr-section-nav__container--inner {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.hr-section-nav--up, .hr-section-nav--down {
  width: 1.55rem;
  height: 1.55rem;
  cursor: pointer;
  pointer-events: auto;
}
.hr-section-nav--up svg, .hr-section-nav--down svg {
  width: 1.55rem;
  height: auto;
}
.hr-section-nav--up {
  transform: rotate(-90deg);
  margin-bottom: 0.5rem;
}
.hr-section-nav--down {
  transform: rotate(90deg);
}
.hr-section-nav--disabled {
  pointer-events: none;
  opacity: 0;
}

@media (min-width: 767px) {
  .hr-section-nav__container {
    display: block;
  }
}
@media (min-width: 1020px) {
  .hr-section-nav {
    padding-left: 2vw;
  }
}
@media (min-width: 1350px) {
  .hr-section-nav {
    padding-left: 3vw;
  }
}
.section--teaser-map {
  padding-top: 0 !important;
  min-height: 100vh;
  max-height: 500px;
  overflow-y: scroll;
}
.section--teaser-map .teaser__inner {
  margin: -3.45rem 2.75rem 0 1.75rem;
  padding: 1.25rem;
}
@media (max-width: 539px) {
  .section--teaser-map .teaser__inner {
    margin: 0;
    max-width: initial;
  }
}
.section--teaser-map .teaser__icon {
  height: 1rem;
  width: 1rem;
  top: 1rem;
  right: 1rem;
}
.section--teaser-map .section--teaser-list .teaser {
  margin-bottom: 1rem;
}
@media (min-width: 1190px) {
  .section--teaser-map h3 {
    width: calc(100% - 1.5rem);
    font-size: 1rem;
  }
}
@media (min-width: 1420px) {
  .section--teaser-map h3 {
    font-size: 0.8rem;
  }
  .section--teaser-map p {
    font-size: 0.6rem;
  }
}
@media (min-width: 1890px) {
  .section--teaser-map h3 {
    font-size: 1rem;
  }
}
@media (max-width: 1019px) {
  .section--teaser-map .inner-wrap > div, .section--teaser-map h6.with-decor > div,
.section--teaser-map .like-h6.with-decor > div {
    padding: 0;
  }
}

body.overlayMode {
  overflow-y: hidden;
}

.overlay--reference-map {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -105%;
  transition: left 0.5s ease-in;
  z-index: 100;
  background: pink;
}
.overlay--reference-map.overlay-active {
  left: 0;
  transition: left 0.5s ease-in;
}

.filter-controls {
  min-width: 450px;
}

.icon-button__container-map {
  padding-bottom: 0;
}
.icon-button__container-map .icon-button {
  padding: 0;
}
.icon-button__container-map .icon-button__label {
  font-size: 0.6rem;
}
@media (min-width: 767px) {
  .icon-button__container-map {
    padding-bottom: 2.5rem;
  }
}
@media (min-width: 1350px) {
  .icon-button__container-map .svg-icon {
    width: 2rem;
    height: 2rem;
  }
}

.mapview--divider {
  display: block;
  width: 1px;
  height: 50%;
  background: lightgray;
  position: relative;
  top: 25%;
}

.reference-filter__container.reference-filter-maps__container {
  width: 100%;
  justify-content: space-between;
}
.reference-filter__container.reference-filter-maps__container .reference-filter-wrap {
  margin-right: 0;
  margin-top: 1.5rem;
}
@media (min-width: 540px) {
  .reference-filter__container.reference-filter-maps__container .reference-filter-wrap {
    width: 49%;
  }
}
@media (min-width: 1350px) {
  .reference-filter__container.reference-filter-maps__container .reference-filter-wrap {
    width: 32%;
  }
}
.reference-filter__container.reference-filter-maps__container .select-dropdown__reference-filter {
  padding-right: 4rem;
  padding-left: 0.5rem;
}
.reference-filter__container.reference-filter-maps__container .select-dropdown__reference-filter .ng-select-container .ng-value {
  text-indent: 0.5rem;
}
.reference-filter__container.reference-filter-maps__container .select-dropdown__reference-filter-smaller {
  padding-right: 2.5rem;
}
.reference-filter__container.reference-filter-maps__container .select-dropdown__reference-filter-smallest {
  padding-right: 1.5rem;
}
@media (max-width: 1019px) {
  .reference-filter__container.reference-filter-maps__container .reference-filter-wrap.reference-filter-wrap__filter-button {
    box-shadow: none;
  }
  .reference-filter__container.reference-filter-maps__container .reference-filter-wrap.reference-filter-wrap__filter-button .show-filtered-results {
    box-shadow: 6px 6px 6px -1px rgba(0, 0, 0, 0.1);
  }
}
@media (max-width: 1019px) {
  .reference-filter__container.reference-filter-maps__container {
    margin-top: 0;
  }
}

.projects-map-wrap {
  position: fixed;
  height: 100vh;
  width: 50vw;
  background-color: #f5f4f5;
}
@media (max-width: 766px) {
  .projects-map-wrap {
    height: 90vh;
    width: 100vw;
  }
}

.teaser-infobox--reference-map-mobile {
  display: inline-block;
  position: absolute;
  top: 1.5rem;
  left: 0;
  z-index: 5;
  cursor: pointer;
}
@media (min-width: 767px) {
  .teaser-infobox--reference-map-mobile {
    display: none;
  }
}

.projects-maps__google-map {
  width: 100%;
  height: 100%;
}

.intro.intro--map-overlay {
  padding: 1rem 0;
}
.intro.intro--map-overlay .logo__container {
  padding: 0 !important;
}
.intro.intro--map-overlay .close-map-label {
  cursor: pointer;
  text-decoration: none;
  color: black;
}
.intro.intro--map-overlay .close-map-label .svg-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}
.intro.intro--map-overlay .close-map-label span {
  font-size: 0.9rem;
  opacity: 0.6;
}
.intro.intro--map-overlay h1 {
  text-align: left;
  margin-top: 1rem;
}
@media (min-width: 1020px) {
  .intro.intro--map-overlay {
    padding: 2.5rem 0 1rem;
  }
  .intro.intro--map-overlay h1 {
    margin-top: 2.5rem;
  }
}

.wrap.wrap-map-overlay {
  width: 100vw;
  margin: 0;
  padding: 0;
  background: #fff;
}
@media (max-width: 766px) {
  .wrap.wrap-map-overlay .intro.intro--map-overlay, .wrap.wrap-map-overlay section {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.purpose-filter-slider .icon-button__icon {
  width: 4rem;
  height: 4rem;
}
.purpose-filter-slider__wrap {
  width: 88%;
}
@media (min-width: 1020px) {
  .purpose-filter-slider__wrap {
    width: 90%;
  }
}
.purpose-filter-slider__arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.purpose-filter-slider__arrow .svg-icon {
  height: 1.25rem;
  width: 1.25rem;
}
.purpose-filter-slider__arrow--prev {
  transform: rotateY(180deg);
}

.end-referenced-by-marker {
  position: absolute;
  right: 10px;
  top: 0;
  width: 3rem;
  height: 3rem;
  background: #005093;
  padding: 0.75rem;
  z-index: 5;
  cursor: pointer;
}
@media (max-width: 1019px) {
  .end-referenced-by-marker {
    right: 0;
  }
}

.mapview-toggle-wrap {
  cursor: pointer;
  padding: 0 0.35rem;
  padding-top: 0.4rem;
}
.mapview-toggle-wrap:hover {
  background-color: #F4FBFF;
}
.mapview-toggle-wrap:hover .mapview-toggle--label {
  display: block;
}
.mapview-toggle-wrap .mapview-toggle--label {
  display: none;
  position: absolute;
  bottom: -43px;
  font-size: 0.75rem;
  left: 0;
  right: 0;
  background-color: #F4FBFF;
  color: #005093;
  padding: 1rem;
  z-index: 1;
  box-shadow: 6px 6px 6px -1px rgba(0, 0, 0, 0.1);
}
@media (min-width: 540px) {
  .mapview-toggle-wrap .mapview-toggle--label {
    bottom: -48px;
  }
}
@media (min-width: 767px) {
  .mapview-toggle-wrap .mapview-toggle--label {
    bottom: -37px;
  }
}
@media (min-width: 1350px) {
  .mapview-toggle-wrap .mapview-toggle--label {
    bottom: -51px;
  }
}
@media (min-width: 1700px) {
  .mapview-toggle-wrap .mapview-toggle--label {
    bottom: -58px;
  }
}
.mapview-toggle-wrap .svg-icon--mapview-toggle {
  padding: 0.4rem;
  width: 2rem;
  height: 2rem;
}
.mapview-toggle-wrap .svg-icon--mapview {
  position: absolute;
  height: 1rem;
  width: 1rem;
  right: 0.4rem;
}

.reference-filter--hide-toggle {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: right;
  background: #fff;
}
.reference-filter--hide-toggle .map-filter--arrow-icon {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  transform: rotate(135deg);
  display: inline-block;
  position: relative;
  top: 6px;
  left: 12px;
}
.reference-filter--hide-toggle .map-filter--arrow-icon.filter--open {
  transform: rotate(135deg);
  transition: transform 0.35s linear;
}
.reference-filter--hide-toggle .map-filter--arrow-icon.filter--closed {
  transform: rotate(315deg);
  transition: transform 0.35s linear;
  top: 3px;
}

.reference-map-filters, .project-list--filters {
  max-height: 0;
  overflow: auto;
  transition: max-height 0.5s ease-in;
}
.reference-map-filters hr, .project-list--filters hr {
  margin: 1.35rem auto 0.35rem;
}
.reference-map-filters.active, .project-list--filters.active {
  transition: max-height 0.5s ease-out;
  max-height: 350px;
}
@media (min-width: 1350px) {
  .reference-map-filters.active, .project-list--filters.active {
    max-height: 175px;
  }
}
@media (min-width: 1420px) {
  .reference-map-filters.active, .project-list--filters.active {
    max-height: 215px;
  }
}
@media (min-width: 1700px) {
  .reference-map-filters.active, .project-list--filters.active {
    max-height: 240px;
  }
}

.reference-map-filters {
  overflow: inherit;
}

.project-list--filters.active {
  overflow: inherit;
  max-height: 610px;
}
@media (min-width: 540px) {
  .project-list--filters.active {
    max-height: 425px;
  }
}

.button__reset-filters {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  width: 40px;
  height: 40px;
  right: 10px !important;
  top: 10px !important;
  cursor: pointer;
  display: none;
}
.gm-style .button__reset-filters {
  display: block;
}
.button__reset-filters i {
  padding: 11px;
  transform: rotate(63deg);
  position: relative;
  top: 0;
  left: 1px;
  opacity: 0.6;
}

.gmnoprint.gm-bundled-control {
  top: 50px !important;
}

inline-swiper {
  width: 100%;
}

accordion-helper {
  width: 100%;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
}
.pagination__button {
  min-width: 2.5rem;
  margin-right: 0.2rem;
  padding-right: 0;
  padding-left: 0;
  border: 0;
  background-color: #e1eaf3;
  color: #000;
}
.pagination__button:hover {
  background-color: #fff;
}
.pagination__button--arrow {
  background-color: rgba(0, 80, 147, 0.08);
}
.pagination__button--arrow:hover path {
  fill: #000;
}
.pagination__button--arrow path {
  fill: #fff;
}
.pagination__button--arrow-left {
  transform: rotateY(180deg);
}
.pagination__controls {
  display: inline-flex;
  margin-left: 0.8rem;
}
.pagination__controls svg {
  width: 1rem;
}
.pagination h5 {
  min-width: 100%;
  margin-bottom: 0.75rem;
}

@media (min-width: 767px) {
  .pagination {
    align-items: center;
    justify-content: center;
  }
  .pagination h5 {
    min-width: auto;
    margin: 0 0.5rem 0 0;
    text-transform: inherit;
  }
}
.expansion-panel {
  display: flex;
  flex-direction: column;
  margin-top: -1px;
  transition: background-color 0.3s ease-in;
  border: 1px solid rgba(151, 151, 151, 0.4);
  border-right: 0;
  border-left: 0;
}
.expansion-panel .expansion-panel__header {
  display: flex;
  position: relative;
  align-items: center;
  padding: 1.4rem 3.75rem 1rem;
  text-transform: uppercase;
  cursor: pointer;
}
.expansion-panel .expansion-panel__header i {
  position: absolute;
}
.expansion-panel .expansion-panel__header svg {
  width: 1.1rem;
  height: 1.1rem;
  transition: transform 0.3s ease-in;
}
.expansion-panel .expansion-panel__header--opened .expansion-panel__icon-arrow svg {
  transform: rotateZ(90deg);
}
.expansion-panel .expansion-panel__header--opened .expansion-panel__icon-plus {
  transform: rotateZ(90deg) translateX(-50%) scale(1.2);
}
.expansion-panel .expansion-panel__header--opened .expansion-panel__icon-plus::before {
  opacity: 0;
}
.expansion-panel--white.expansion-panel--opened {
  background-color: #fff;
}
.expansion-panel--career-variant .expansion-panel__header {
  text-transform: inherit;
}
.expansion-panel--career-variant .expansion-panel__content {
  padding-right: 0;
  padding-left: 0;
}
.expansion-panel__career-content-wrap {
  padding-left: 1.9rem;
}
.expansion-panel__career-content-wrap .vacancy-search__button {
  margin-left: 0;
}
.expansion-panel--opened {
  padding-bottom: 1.5rem;
  background-color: rgba(245, 244, 245, 0.5);
}
.expansion-panel .expansion-panel__content {
  height: 0;
  padding: 0 1.3rem;
  overflow: hidden;
  transition: height 0.3s ease-in;
}
.expansion-panel__icon-arrow {
  left: 1.3rem;
  transform: scale(1.5);
}
.expansion-panel__icon-plus {
  display: inline-flex;
  top: 50%;
  right: 1.3rem;
  transform: translateY(-50%) scale(1.2);
  transform-origin: 50% 50%;
  transition: transform 0.3s ease-in;
}
.expansion-panel__icon-plus::before, .expansion-panel__icon-plus::after {
  content: "";
  display: block;
  border-radius: 3px;
  background-color: #000;
  font-style: initial;
}
.expansion-panel__icon-plus::before {
  align-self: center;
  width: 1rem;
  height: 2px;
  transition: opacity 0.3s ease-in;
  opacity: 1;
}
.expansion-panel__icon-plus::after {
  width: 2px;
  height: 1rem;
  margin-left: -1px;
  transform: translateX(-0.5rem);
}
.expansion-panel__image {
  margin-bottom: 1rem;
  padding-top: 50%;
  background-size: cover;
}

@media (min-width: 767px) {
  .expansion-panel__header:not(.sitemap__headline) {
    font-size: 1.5rem;
  }
  .expansion-panel--career-variant .expansion-panel__content {
    padding-right: 0;
    padding-left: 0;
  }
  .expansion-panel .expansion-panel__content-inner {
    padding: 1.25rem 0;
  }
}
@media (min-width: 1020px) {
  .expansion-panel .expansion-panel__content-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .expansion-panel .expansion-panel__content-inner--without-image .expansion-panel__text {
    width: 75%;
  }
  .expansion-panel .expansion-panel__image {
    flex-shrink: 0;
    order: 2;
    width: calc(60% - 2.75rem);
    margin-right: 3rem;
    padding-top: 30.8%;
  }
  .expansion-panel .expansion-panel__text {
    padding-right: 2.5rem;
  }
  .expansion-panel__career-content-wrap {
    padding-left: 4rem;
  }
}
@media (min-width: 1350px) {
  .expansion-panel--career-variant .vacancy-search__button {
    margin-left: 0;
    float: right;
  }
}
.expansion-panel--only-mobile i {
  display: none;
}

@media all and (max-width: 766px) {
  .expansion-panel--only-mobile {
    display: flex;
    flex-direction: column;
    margin-top: -1px;
    border: 1px solid rgba(151, 151, 151, 0.4);
    border-right: 0;
    border-left: 0;
  }
  .expansion-panel--only-mobile.expansion-panel--opened {
    padding-bottom: 0;
  }
  .expansion-panel--only-mobile .expansion-panel__header {
    position: relative;
    padding-top: 1rem;
    cursor: pointer;
  }
  .expansion-panel--only-mobile .expansion-panel__header i {
    display: inline;
  }
  .expansion-panel--only-mobile .expansion-panel__header svg {
    position: absolute;
    right: 0.4rem;
    width: auto;
    height: 0.4rem;
    transform: translateY(100%);
    transition: transform 0.3s ease-in;
  }
  .expansion-panel--only-mobile .expansion-panel__header--opened svg {
    transform: translateY(100%) rotateX(180deg);
  }
  .expansion-panel--only-mobile .expansion-panel__content {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in;
  }
}
.text-teaser {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 1rem;
  padding: 1rem 1rem 0.8rem;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.text-teaser__container {
  margin-top: -1rem;
  padding-bottom: 3rem;
}
.text-teaser__headline {
  flex-basis: 100%;
}
.text-teaser__col,
.text-teaser .button {
  margin-top: 1rem;
}
.text-teaser__col {
  font-size: 0.85rem;
}
.text-teaser__col--align-right {
  display: flex;
  justify-content: flex-end;
}
.text-teaser__label {
  opacity: 0.7;
  text-transform: uppercase;
}
@media (min-width: 767px) {
  .text-teaser {
    flex-direction: inherit;
    align-items: center;
  }
  .text-teaser__headline {
    margin-bottom: 1rem;
    padding-right: 2rem;
  }
  .text-teaser__col {
    flex-grow: 1;
    margin-top: 0;
  }
  .text-teaser .button {
    margin-top: 0;
  }
}
@media (min-width: 1350px) {
  .text-teaser {
    padding: 1rem 1.5rem 0.9rem;
  }
  .text-teaser__headline {
    flex-basis: 40%;
    margin-bottom: 0;
  }
  .text-teaser__col {
    flex-basis: 15%;
  }
}
form h6.with-decor {
  margin-bottom: 2.5rem;
}

.form-section {
  margin-top: -1.8rem;
  margin-bottom: 3.7rem;
  transition: opacity 0.2s ease;
}
.form-section--no-top-margin {
  margin-top: 0;
}
.form-section--disabled {
  position: relative;
  pointer-events: none;
  opacity: 0.1;
}
.form-section--ranger {
  margin-top: -3rem;
  margin-bottom: 0;
}
.form-section .form-icon--edit {
  position: absolute;
  right: 0.8rem;
  bottom: 0;
  transform: translateY(-0.2rem);
}
.form-section .form-icon--edit svg {
  height: 1.5rem;
}
.form-section .input {
  width: 100%;
}
.form-section__subline {
  width: 90%;
  margin: 0 auto 2.1rem;
  line-height: 1.6rem;
  text-align: center;
}
.form-section__subline + app-pull-down .pull-down {
  margin-bottom: 4.45rem;
}
.form-section__legend {
  padding-top: 0.8rem;
  font-size: 0.85rem;
}
.form-section__mykleusberg {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-top: 2rem;
}

.form-element__container {
  display: block;
  position: relative;
  margin-top: 1.8rem;
}
.form-element__container--text {
  margin-top: 0;
}
.form-element__container--text h6 {
  margin-bottom: 0.2rem;
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: none;
}
.form-element__container--no-gap-top {
  margin-top: 0;
}
.form-element__container--no-label {
  margin-top: 71px;
}
.form-element__label {
  display: inline-flex;
  margin-bottom: 0.6rem;
  padding-left: 0.5rem;
}
.form-element__label--required::after {
  content: "*";
  align-self: flex-start;
  margin-left: 0.1rem;
}
.form-element__legend {
  position: absolute;
  bottom: 0.8rem;
  left: 3.1rem;
  font-size: 0.75rem;
}

@media (min-width: 1020px) {
  .form-section__subline {
    width: 62%;
  }
}
.select-dropdown {
  flex-basis: 100%;
  height: 3.75rem;
  padding: 0 0 0 1rem;
  background-color: #fff;
  line-height: 3.75rem;
}
.select-dropdown.ng-invalid.ng-touched {
  border: 1px solid #7a1515;
}
.select-dropdown.ng-invalid.ng-touched .ng-arrow-wrapper {
  border-bottom: 1px solid #7a1515;
}
.select-dropdown__container {
  margin-bottom: 1rem;
}
.select-dropdown__container--no-label {
  margin: 3rem auto 1rem;
}
.select-dropdown .ng-value {
  position: absolute;
  width: 100%;
  background-color: #fff;
}
.select-dropdown ng-dropdown-panel {
  left: 0;
  height: auto;
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  background-color: #f5f4f5;
  line-height: 2rem;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
}
.select-dropdown .ng-value-container,
.select-dropdown ng-option {
  cursor: pointer;
}
.select-dropdown .ng-arrow-wrapper {
  height: calc(2.5rem - 1px);
  margin-right: 1.65rem;
}
.select-dropdown .ng-arrow-wrapper::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 6px;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 4' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.667 4a.524.524 0 0 1-.363-.144L.15.84a.477.477 0 0 1 0-.695.53.53 0 0 1 .727 0l2.79 2.67 2.79-2.67a.53.53 0 0 1 .726 0c.2.192.2.503 0 .695L4.03 3.856A.524.524 0 0 1 3.667 4z' fill='000' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.select-dropdown.ng-select-opened .ng-arrow-wrapper::before {
  transform: rotateX(180deg);
}
.select-dropdown .ng-placeholder {
  background-color: transparent;
}
.select-dropdown .ng-input {
  line-height: 2.3rem;
}
.select-dropdown .ng-input input {
  line-height: 0;
}

.select-dropdown--no-shadow ng-dropdown-panel {
  box-shadow: none;
}

.select-dropdown--gray {
  height: 2.5rem;
  background-color: #f5f4f5;
  line-height: 2.5rem;
  text-align: left;
}
.select-dropdown--gray .ng-value {
  background: #f5f4f5;
}
.select-dropdown--gray .ng-arrow-wrapper {
  width: 2.7rem;
  margin: 0;
  background-color: #eceaec;
}
@media (min-width: 350px) {
  .select-dropdown--gray {
    font-size: 16px;
  }
}
@media (min-width: 767px) {
  .select-dropdown--gray {
    font-size: 0.75rem;
  }
}

.select-dropdown--white-plus {
  height: 2.75rem;
  font-size: 1rem;
  background-color: #f5f4f5;
  text-transform: uppercase;
}
.select-dropdown--white-plus .ng-value {
  display: none;
}
.select-dropdown--white-plus .ng-placeholder {
  font-size: 0.8rem;
  line-height: 1.6rem;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
@media (min-width: 1020px) {
  .select-dropdown--white-plus .ng-placeholder {
    font-size: 1rem;
  }
}
@media (min-width: 1420px) {
  .select-dropdown--white-plus .ng-placeholder {
    line-height: 2rem;
  }
}
.select-dropdown--white-plus .ng-arrow-wrapper {
  background-color: #005093;
  height: 2.75rem;
  width: 2.75rem;
}
.select-dropdown--white-plus .ng-arrow-wrapper:before {
  background: none;
}
.select-dropdown--white-plus .ng-arrow-wrapper .ng-arrow {
  pointer-events: none;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
  margin: 0;
  left: -1.1rem;
  bottom: 0.2rem;
}
.select-dropdown--white-plus .ng-arrow-wrapper .ng-arrow:before {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 1px;
  background-color: #fff;
}
.select-dropdown--white-plus .ng-arrow-wrapper .ng-arrow:after {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 1px;
  background-color: #fff;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.select-dropdown--white-plus .ng-option {
  position: relative;
}
.select-dropdown--white-plus .ng-option:after {
  content: "";
  position: absolute;
  right: 23px;
  top: 13px;
  width: 8px;
  height: 8px;
  border-top: solid 2px currentColor;
  border-right: solid 2px currentColor;
  transform: rotate(45deg);
}
.select-dropdown--white-plus .ng-option-disabled span {
  opacity: 0.2;
}

.select-dropdown__swap-container.ng-select {
  position: unset;
  padding: 0;
}
.select-dropdown__swap-container.ng-select .ng-value-container {
  display: none !important;
}
.select-dropdown__swap-container.ng-select .ng-arrow-wrapper {
  width: 2.75rem;
  height: 2.75rem;
}
.select-dropdown__swap-container.ng-select .ng-option {
  font-size: 1rem;
}
.select-dropdown__swap-container.ng-select .ng-option-disabled span {
  opacity: 0.2;
}

.select-dropdown--light {
  height: 2.5rem;
  background-color: #fff;
  line-height: 2.5rem;
  text-align: left;
}
.select-dropdown--light .ng-value {
  background: #fff;
}
.select-dropdown--light .ng-arrow-wrapper {
  width: 2.7rem;
  margin: 0;
  background-color: #eceaec;
}
@media (min-width: 350px) {
  .select-dropdown--light {
    font-size: 16px;
  }
}
@media (min-width: 767px) {
  .select-dropdown--light {
    font-size: 0.75rem;
  }
}

.select-dropdown--shadow {
  height: 3.4rem;
  background-color: #fff;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  line-height: 3.4rem;
}
.select-dropdown--shadow .ng-value {
  display: flex;
  top: 22%;
  align-items: center;
  width: 80%;
  height: 70%;
}
.select-dropdown--shadow .ng-placeholder {
  height: 3.4rem;
  line-height: 3.8rem;
}

.reference-filter-wrap__input-label input[disabled] {
  cursor: not-allowed;
}

.reference-filter__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  overflow: visible;
  justify-content: center;
}
@media (min-width: 1020px) {
  .reference-filter__container {
    margin-top: 0;
  }
}
.reference-filter__container .reference-filter-wrap {
  display: flex;
  box-shadow: 6px 6px 6px -1px rgba(0, 0, 0, 0.1);
  margin-right: 1rem;
  margin-top: 2.05rem;
  position: relative;
  width: 95%;
}
.reference-filter__container .reference-filter-wrap.reference-filter-wrap__filter-button {
  width: auto;
}
.reference-filter__container .reference-filter-wrap.reference-filter-wrap__filter-button .reference-filter__label {
  width: auto;
}
.reference-filter__container .reference-filter__label {
  line-height: 2.5rem;
  height: 2.5rem;
  font-size: 0.7rem;
  text-indent: 1rem;
  opacity: 0.3;
  overflow: auto;
  width: 35%;
}
.reference-filter__container .select-dropdown__reference-filter {
  flex-basis: auto;
  line-height: 2.5rem;
  height: 2.5rem;
  font-size: 0.7rem;
  padding-right: 5rem;
  width: 65%;
}
.reference-filter__container .select-dropdown__reference-filter .ng-select-container {
  position: unset;
}
.reference-filter__container .select-dropdown__reference-filter .ng-select-container .ng-value {
  right: 0;
  text-indent: 1rem;
}
.reference-filter__container .select-dropdown__reference-filter .ng-arrow-wrapper {
  position: absolute;
  right: 1rem;
  height: calc(2rem - 1px);
  margin: 0;
}
.reference-filter__container .select-dropdown__reference-filter .ng-arrow-wrapper:before {
  width: 10px;
  height: 5px;
}
.reference-filter__container .select-dropdown__reference-filter .ng-dropdown-panel {
  background-color: #fff;
  box-shadow: 6px 6px 6px -1px rgba(0, 0, 0, 0.1);
}
.reference-filter__container .select-dropdown__reference-filter.ng-select-disabled {
  opacity: 0.2;
}
.reference-filter__container .select-dropdown__reference-filter.ng-select-disabled .ng-input {
  cursor: not-allowed;
}
.reference-filter__container .select-dropdown__reference-filter.ng-select-disabled .ng-arrow-wrapper {
  display: none !important;
}
.reference-filter__container .select-dropdown__reference-filter.select-dropdown__reference-filter-smaller {
  position: relative;
}
.reference-filter__container .select-dropdown__reference-filter.select-dropdown__zipcodes {
  width: 120px;
  padding-right: 0;
}
@media (min-width: 540px) {
  .reference-filter__container .select-dropdown__reference-filter.select-dropdown__zipcodes {
    width: 160px;
  }
}
.reference-filter__container .select-dropdown__reference-filter.select-dropdown__zipcodes .ng-input {
  margin-left: 15px;
  margin-top: 2px;
  max-width: 75%;
}
@media (min-width: 767px) {
  .reference-filter__container .select-dropdown__reference-filter.select-dropdown__zipcodes .ng-input {
    margin-left: 13px;
  }
}
@media (min-width: 1420px) {
  .reference-filter__container .select-dropdown__reference-filter.select-dropdown__zipcodes .ng-input {
    margin-left: 17px;
  }
}
.reference-filter__container .select-dropdown__reference-filter.select-dropdown__zipcodes .ng-arrow-wrapper {
  display: none;
}
.reference-filter__container .select-dropdown__reference-filter.select-dropdown__zipcodes .ng-dropdown-panel {
  position: absolute;
  min-width: 150px;
  width: auto;
  left: 0;
  top: 35px;
  padding-right: 1rem;
}
.reference-filter__container .reference-filter__input {
  font-size: 0.7rem;
  text-indent: 0.25rem;
  width: 3rem;
  padding: 0;
  background: transparent;
}
@media (min-width: 540px) {
  .reference-filter__container {
    justify-content: space-between;
  }
  .reference-filter__container .reference-filter-wrap {
    width: 46%;
  }
  .reference-filter__container .reference-filter__label {
    width: 40%;
  }
  .reference-filter__container .select-dropdown__reference-filter {
    width: 60%;
  }
  .reference-filter__container .reference-filter-wrap--zipcodes .reference-filter__label {
    width: calc(40% - 30px);
  }
  .reference-filter__container .reference-filter__label {
    line-height: 2.5rem;
    height: 2.5rem;
    font-size: 0.7rem;
    text-indent: 1rem;
    opacity: 0.3;
    overflow: hidden;
  }
}
@media (min-width: 767px) {
  .reference-filter__container {
    justify-content: center;
  }
  .reference-filter__container .reference-filter-wrap {
    width: auto;
  }
  .reference-filter__container .reference-filter-wrap--zipcodes {
    margin-right: 0;
  }
}
@media (max-width: 539px) {
  .reference-filter__container .reference-filter__label, .reference-filter__container .select-dropdown__reference-filter {
    font-size: 0.85rem;
  }
}

.reference-filter-wrap--zipcodes {
  padding-right: 5px;
}
.reference-filter-wrap--zipcodes .reference-filter__label {
  text-indent: 0.7rem;
  width: calc(35% - 43px);
}

.input {
  height: 2.5rem;
  padding: 0 1rem;
  border: 0;
  background-color: #f5f4f5;
}
.input--white {
  background-color: #fff;
  border: 1px solid #e4e4e4;
}
.input.ng-touched.ng-invalid {
  border: 1px solid #7a1515;
}
@media (min-width: 350px) {
  .input {
    font-size: 16px;
  }
}
@media (min-width: 767px) {
  .input {
    font-size: 0.75rem;
  }
}

.checkbox,
.radio {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
  background-color: #f5f4f5;
}
.checkbox--image,
.radio--image {
  background-color: #e6e7e6;
}
.checkbox__label-text p,
.radio__label-text p {
  display: inline;
}
.checkbox--error,
.radio--error {
  border: 1px solid #7a1515;
}
.checkbox--error + .checkbox__label-text,
.radio--error + .checkbox__label-text {
  color: #7a1515;
}
.checkbox__container,
.radio__container {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}
.checkbox__label-text,
.radio__label-text {
  width: calc(80% - 1.5rem);
  margin-top: 0.75rem;
}
.checkbox__label-text a,
.radio__label-text a {
  color: inherit;
}
.checkbox input,
.radio input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  opacity: 0;
}
.checkbox input:checked ~ i,
.radio input:checked ~ i {
  opacity: 1;
}

.checkbox__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  opacity: 0;
}
.checkbox__icon svg {
  width: 1.35rem;
  height: 1rem;
  transform: rotate(-10deg);
  fill: #005093;
}

.radio__icon {
  width: 1rem;
  height: 1rem;
  opacity: 0;
  background-color: #005093;
}
.radio__container {
  display: inline-flex;
  position: relative;
  margin-right: 1.9rem;
}

@media (min-width: 1350px) {
  .checkbox__label-text,
.radio__label-text {
    width: 80%;
  }
}
.textarea {
  width: 100%;
  min-height: 15.75rem;
  padding: 0.5rem 1rem;
  background-color: #f5f4f5;
}

.simple-text {
  width: 90%;
  margin: 3.3rem auto 0;
  color: #636363;
  font-size: 0.85rem;
  text-align: center;
}
.simple-text a {
  color: #636363;
  text-decoration: none;
}
.simple-text a:hover {
  color: #005093;
}

@media (min-width: 1020px) {
  .simple-text {
    width: 62%;
  }
}
ranger-input {
  display: flex;
  width: 100%;
}
ranger-input .ranger-input__custom-label {
  position: relative;
}
ranger-input .ranger-input__custom-label span {
  font-size: 0.75rem;
  display: block;
  text-align: center;
  color: #005093;
  font-size: 0.75rem;
}

ranger-input ng5-slider {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 0.8rem;
  margin: 35px 0 15px 0;
  vertical-align: middle;
  user-select: none;
  touch-action: pan-y;
}
ranger-input ng5-slider.with-legend {
  margin-bottom: 40px;
}
ranger-input ng5-slider[disabled] {
  cursor: not-allowed;
}
ranger-input ng5-slider[disabled] .ng5-slider-pointer {
  background-color: #d8e0f3;
  cursor: not-allowed;
}
ranger-input ng5-slider[disabled] .ng5-slider-draggable {
  cursor: not-allowed;
}
ranger-input ng5-slider[disabled] .ng5-slider-selection {
  background: #8b91a2;
}
ranger-input ng5-slider[disabled] .ng5-slider-tick {
  cursor: not-allowed;
}
ranger-input ng5-slider[disabled] .ng5-slider-tick.ng5-slider-selected {
  background: #8b91a2;
}
ranger-input ng5-slider .ng5-slider-span {
  position: absolute;
  display: inline-block;
  white-space: nowrap;
}
ranger-input ng5-slider .ng5-slider-base {
  width: 100%;
  height: 100%;
  padding: 0;
}
ranger-input ng5-slider .ng5-slider-bar-wrapper {
  z-index: 1;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 2.75rem;
  margin-top: -1.375rem;
  padding-top: 1.375rem;
}
ranger-input ng5-slider .ng5-slider-draggable {
  cursor: move;
}
ranger-input ng5-slider .ng5-slider-bar {
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  border-radius: 0.4rem;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 0.8rem;
  background: #f5f4f5;
}
ranger-input ng5-slider .ng5-slider-bar-wrapper.ng5-slider-transparent .ng5-slider-bar {
  background: transparent;
}
ranger-input ng5-slider .ng5-slider-bar-wrapper.ng5-slider-left-out-selection .ng5-slider-bar {
  background: #df002d;
}
ranger-input ng5-slider .ng5-slider-bar-wrapper.ng5-slider-right-out-selection .ng5-slider-bar {
  background: #03a688;
}
ranger-input ng5-slider .ng5-slider-selection {
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  border-radius: 0.4rem;
  z-index: 2;
  background: #005093;
}
ranger-input ng5-slider .ng5-slider-pointer {
  z-index: 3;
  top: -0.975rem;
  width: 2.75rem;
  height: 2.75rem;
  background-color: #005093;
  cursor: pointer;
  -webkit-border-radius: 1.375rem;
  -moz-border-radius: 1.375rem;
  border-radius: 1.375rem;
}
ranger-input ng5-slider .ng5-slider-pointer::after {
  content: "";
  position: absolute;
  top: 1.175rem;
  left: 1.175rem;
  width: 0.4rem;
  height: 0.4rem;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  border-radius: 0.2rem;
  background: #fff;
}
ranger-input ng5-slider .ng5-slider-pointer:hover::after {
  background-color: #fff;
}
ranger-input ng5-slider .ng5-slider-pointer.ng5-slider-active {
  z-index: 3;
}
ranger-input ng5-slider .ng5-slider-pointer.ng5-slider-active::after {
  background-color: #451aff;
}
ranger-input ng5-slider .ng5-slider-bubble {
  bottom: 1.375rem;
  padding: 1px 3px;
  color: #55637d;
  font-size: 1rem;
  cursor: default;
}
ranger-input ng5-slider .ng5-slider-bubble.ng5-slider-limit {
  color: #55637d;
}
ranger-input ng5-slider .ng5-slider-ticks {
  position: absolute;
  z-index: 1;
  top: -0.725rem;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 0;
  margin: 0;
  list-style: none;
}
ranger-input ng5-slider .ng5-slider-ticks-values-under .ng5-slider-tick-value {
  top: auto;
  bottom: -36px;
}
ranger-input ng5-slider .ng5-slider-tick {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.05rem;
  height: 2.25rem;
  margin-left: 1.85rem;
  border-radius: 50%;
  background: #cacaca;
  text-align: center;
  cursor: pointer;
}
ranger-input ng5-slider .ng5-slider-tick.ng5-slider-selected {
  background: #cacaca;
}
ranger-input ng5-slider .ng5-slider-tick-value {
  position: absolute;
  top: -34px;
  transform: translate(-50%, 0);
}
ranger-input ng5-slider .ng5-slider-tick-legend {
  position: absolute;
  top: 24px;
  max-width: 50px;
  transform: translate(-50%, 0);
  white-space: normal;
}
ranger-input ng5-slider.ng5-slider-vertical {
  position: relative;
  width: 0.8rem;
  height: 100%;
  margin: 0 20px;
  padding: 0;
  vertical-align: baseline;
  touch-action: pan-x;
}
ranger-input ng5-slider.ng5-slider-vertical .ng5-slider-base {
  width: 100%;
  height: 100%;
  padding: 0;
}
ranger-input ng5-slider.ng5-slider-vertical .ng5-slider-bar-wrapper {
  top: auto;
  left: 0;
  width: 2.75rem;
  height: 100%;
  margin: 0 0 0 -1.375rem;
  padding: 0 0 0 1.375rem;
}
ranger-input ng5-slider.ng5-slider-vertical .ng5-slider-bar {
  bottom: 0;
  left: auto;
  width: 0.8rem;
  height: 100%;
}
ranger-input ng5-slider.ng5-slider-vertical .ng5-slider-pointer {
  top: auto;
  bottom: 0;
  left: -0.975rem !important;
}
ranger-input ng5-slider.ng5-slider-vertical .ng5-slider-bubble {
  bottom: 0;
  left: 1.375rem !important;
}
ranger-input ng5-slider.ng5-slider-vertical .ng5-slider-ticks {
  z-index: 1;
  top: 0;
  left: -0.725rem;
  width: 0;
  height: 100%;
}
ranger-input ng5-slider.ng5-slider-vertical .ng5-slider-tick {
  margin-top: 1.35rem;
  margin-left: auto;
  vertical-align: middle;
}
ranger-input ng5-slider.ng5-slider-vertical .ng5-slider-tick-value {
  top: auto;
  left: 24px;
  transform: translate(0, -28%);
}
ranger-input ng5-slider.ng5-slider-vertical .ng5-slider-tick-legend {
  top: auto;
  right: 24px;
  max-width: none;
  transform: translate(0, -28%);
  white-space: nowrap;
}
ranger-input ng5-slider.ng5-slider-vertical .ng5-slider-ticks-values-under .ng5-slider-tick-value {
  right: 24px;
  bottom: auto;
  left: auto;
}

ranger-input ng5-slider {
  margin: 2rem 0 6rem;
}
ranger-input ng5-slider .ng5-slider-tick {
  border-radius: 0;
  width: 1px;
}
ranger-input ng5-slider .ng5-slider-pointer {
  width: auto;
  padding: 0 0.2rem;
  border-radius: 0.2rem;
}
ranger-input ng5-slider .ng5-slider-pointer::after {
  display: none;
}
ranger-input ng5-slider .ng5-slider-model-value {
  bottom: -5rem;
}
ranger-input ng5-slider .ng5-slider-model-value::before {
  content: "";
  display: inline-block;
  position: relative;
  left: 50%;
  width: 1px;
  height: 0.8rem;
  transform: translate(-50%, -200%);
  background-color: #cacaca;
}

.ng5-slider-inner-tooltip {
  display: flex;
}
.ng5-slider-inner-tooltip::before {
  content: "";
  align-self: center;
  width: 1px;
  align-self: center;
  height: 1.35rem;
  background-color: #fff;
}

.ng5-slider-handlebar-icon {
  display: flex;
  align-items: center;
  height: 2.75rem;
}
.ng5-slider-handlebar-icon .svg-icon {
  width: 1.3rem;
  margin: 0 0.25rem;
  line-height: 0;
}
.ng5-slider-handlebar-icon .svg-icon--reversed {
  transform: rotateY(180deg);
}

.wrap .form-section .form-image {
  height: auto;
}

.form-image {
  display: flex;
  align-items: flex-start;
  margin-top: 2.8rem;
  background-color: #f5f4f5;
  text-transform: uppercase;
}
.form-image--select {
  text-transform: inherit;
}
.form-image__container {
  width: 100%;
  margin: 0 0 0;
  padding: 84% 2rem 5%;
  background-size: cover;
  background-repeat: no-repeat;
}
.form-image__info-icon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #005093;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.75rem 0;
}
.form-image__info-icon svg {
  height: 1rem;
}
.form-image__top-label {
  position: absolute;
  top: 1.5rem;
  left: 2.2rem;
}

.form-image--select .form-image__container {
  padding-top: 88%;
}
.form-image--select .select-dropdown--gray {
  height: 2.5rem;
  background-color: #fff;
  line-height: 2.5rem;
  text-align: left;
}
.form-image--select .select-dropdown--gray .ng-value {
  background: #fff;
}
.form-image--select .select-dropdown--gray .ng-arrow-wrapper {
  width: 2.7rem;
  margin: 0;
  background-color: #eceaec;
}
@media (min-width: 350px) {
  .form-image--select .select-dropdown--gray {
    font-size: 16px;
  }
}
@media (min-width: 767px) {
  .form-image--select .select-dropdown--gray {
    font-size: 0.75rem;
  }
}

.jump-navigation {
  display: flex;
  justify-content: center;
  margin: 2rem 0 1rem;
}
.jump-navigation__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.25rem;
  text-decoration: none;
}
.jump-navigation__icon {
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.5rem;
  opacity: 0.8;
}
.jump-navigation__label {
  color: #000;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.jump-navigation__arrow {
  width: 0.75rem;
  height: 0.75rem;
  margin-top: 0.5rem;
  transform: rotate(90deg);
}
.jump-navigation__arrow path {
  stroke-width: 0.5rem;
  stroke: #000;
}

.jump-navigation__item:hover .jump-navigation__icon,
.jump-navigation__item:hover .jump-navigation__arrow {
  opacity: 1;
}

.main-nav {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.main-nav--level-1 {
  margin-right: 0.4rem;
}
.main-nav--level-1 li:first-child {
  display: none;
}
.main-nav--level-2 li:last-child .main-nav__element {
  display: none;
  margin-right: 0;
}
.main-nav--level-2 li:nth-last-child(2) .main-nav__element {
  margin-right: 0;
}

.main-nav__element {
  display: flex;
  position: relative;
  align-items: flex-end;
  margin-right: 1.05rem;
  transition: color 0.3s ease;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}
.main-nav__element:hover {
  color: #005093;
}
.main-nav__element--active::after {
  content: "\a";
  display: block;
  position: absolute;
  z-index: 1;
  bottom: -12px;
  left: -4%;
  width: 110%;
  height: 3px;
  background: #005093;
}
.main-nav__element--opened a {
  color: #005093;
}

.main-nav__element--level-1 {
  padding-top: 1.25rem;
  letter-spacing: -0.03rem;
}

.main-nav__element--level-2 {
  font-size: 1rem;
  letter-spacing: -0.02rem;
}

@media (min-width: 1190px) {
  .main-nav_element {
    margin-right: 0.85rem;
  }

  .main-nav__element--level-1 {
    font-size: 1.2rem;
  }

  .main-nav--level-1 li:first-child {
    display: block;
  }

  .main-nav--level-2 li:last-child .main-nav__element {
    display: flex;
  }

  .main-nav--level-2 li:nth-last-child(2) .main-nav__element {
    margin-right: 1.05rem;
  }
}
@media (min-width: 1350px) {
  .main-nav__element {
    margin-right: 1.25rem;
  }
  .main-nav__element--active::after {
    bottom: -19px;
  }

  .main-nav__element--level-1 {
    padding-top: 1.4rem;
  }

  .main-nav--level-2 li:nth-last-child(2) .main-nav__element {
    margin-right: 1.3rem;
  }
}
@media (min-width: 1420px) {
  .main-nav__element {
    padding-top: 1.15rem;
    line-height: 0.75rem;
  }
  .main-nav__element--active::after {
    bottom: -21px;
    height: 5px;
  }

  .main-nav__element--level-1 {
    font-size: 1rem;
  }

  .main-nav__element--level-2 {
    font-size: 0.75rem;
  }
}
@media (min-width: 1580px) {
  .main-nav__element--active::after {
    bottom: -21px;
  }
}
@media (min-width: 1700px) {
  .main-nav__element--active::after {
    bottom: -19px;
  }
}
.nav-flyout {
  display: none;
  position: absolute;
  z-index: -1;
  top: 100%;
  right: 0;
  left: 0;
  transition: opacity 0.3s ease-in;
  border-top: 3px solid #c8c8c8;
  opacity: 0;
  background-color: #f5f4f5;
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 70px;
  pointer-events: all;
}
.nav-flyout--opened {
  display: flex;
  opacity: 1;
}
.nav-flyout > div {
  position: relative;
  z-index: 10;
}

.flyout {
  display: flex;
  width: 100%;
  margin: 1.9rem 1.6rem 2.9rem;
}

@media (min-width: 1190px) {
  .flyout {
    margin: 2.3rem 2rem 3.2rem;
  }
}
@media (min-width: 1350px) {
  .flyout {
    margin: 3rem 3.6rem 3.45rem;
  }
}
@media (min-width: 1420px) {
  .nav-flyout {
    border-top-width: 5px;
  }
}
.tool-nav__item {
  display: inline-flex;
  align-items: flex-start;
  height: 0.9rem;
  margin-right: 1.1rem;
  transition: color 0.2s ease;
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.9rem;
  text-decoration: none;
  text-transform: uppercase;
}
.tool-nav__item:hover, .tool-nav__item--active {
  color: #005093;
}
.tool-nav__item:hover .tool-nav__badge, .tool-nav__item--active .tool-nav__badge {
  background-color: #005093;
  color: #fff;
}
.tool-nav__item .item-label {
  height: 0.9rem;
}

.tool-nav__icon {
  width: 0.9rem;
  height: 0.9rem;
  line-height: 0.9rem;
  margin: 0 0.3rem 0 0;
}
.tool-nav__icon--tack {
  width: 0.9rem;
  height: 0.9rem;
  line-height: 0.9rem;
  align-self: self-start;
}

.tool-nav__badge {
  align-self: flex-start;
  margin: 0 0 0 0.3rem;
}

@media (min-width: 1420px) {
  .tool-nav__item {
    font-size: 0.65rem;
  }

  .tool-nav__icon {
    width: 0.7rem;
    height: 0.7rem;
    line-height: 0.7rem;
  }
  .tool-nav__icon--tack {
    width: 0.85rem;
    height: 0.85rem;
    line-height: 0.85rem;
  }
  .tool-nav__icon--book {
    width: 0.8rem;
    height: 0.8rem;
    line-height: 0.8rem;
    margin-top: -0.15rem;
    margin-right: 0.35rem;
  }

  .tool-nav__badge {
    transform: translateY(-0.15rem);
  }
}
.legal {
  display: flex;
  flex-direction: column;
}

.legal__item {
  margin-top: 1.2rem;
}
.legal__item:first-child {
  margin-top: 0;
}
.legal__item a {
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9rem;
  text-decoration: none;
}

@media (min-width: 767px) {
  .legal {
    flex-direction: inherit;
  }

  .legal__item {
    height: 1.1rem;
    margin-top: 0;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    border-right: 1px solid rgba(0, 0, 0, 0.7);
  }
  .legal__item:last-child {
    margin: 0;
    padding: 0;
    border: 0;
  }
}
.sitemap {
  max-width: 80vw;
  margin: 0 auto;
}
.sitemap__column {
  flex-basis: 100%;
}
.sitemap__headline {
  padding-bottom: 1rem;
  text-transform: uppercase;
}
.sitemap ul {
  overflow: hidden;
}

.sitemap__item {
  margin-bottom: 0.9rem;
}
.sitemap__item a {
  display: block;
  transition: color 0.2s ease;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9rem;
  text-decoration: none;
}
.sitemap__item a:hover {
  color: #005093;
}

@media (min-width: 767px) {
  .sitemap {
    justify-content: left;
    max-width: 70vw;
  }
  .sitemap__column {
    flex-basis: 33.333%;
  }
}
@media (min-width: 1350px) {
  .sitemap {
    justify-content: space-around;
    max-width: 80vw;
  }
  .sitemap__column {
    flex-basis: inherit;
  }
}
.social {
  display: flex;
  justify-content: flex-end;
}
.social__label {
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9rem;
}
.social__icon {
  width: 1.1rem;
  height: 1.1rem;
  margin-left: 0.8rem;
  transition: opacity 0.2s ease;
}
.social__icon:hover {
  opacity: 1;
}

@media (min-width: 767px) {
  .social {
    justify-content: flex-start;
  }
}
.contact-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.contact-nav__item {
  display: flex;
  margin-top: 1.2rem;
  padding: 0;
  text-decoration: none;
}
.contact-nav__item:first-child {
  margin-top: 0;
}
.contact-nav__item:hover .contact-nav__icon {
  opacity: 1;
}
.contact-nav__label {
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9rem;
}

.contact-nav__icon {
  display: none;
  transition: opacity 0.2s ease;
}
.contact-nav__icon--phone {
  width: 0.85rem;
  height: 0.85rem;
  margin: 0.1rem 0.5rem 0 0;
}
.contact-nav__icon--email {
  width: 1.05rem;
  height: 1.05rem;
  margin-right: 0.5rem;
}

@media (min-width: 767px) {
  .contact-nav {
    flex-direction: inherit;
  }
  .contact-nav__item {
    margin-top: 0;
    padding: 0 0.8rem;
  }
  .contact-nav__icon {
    display: block;
  }
}
@media (min-width: 1020px) {
  .contact-nav {
    justify-content: flex-end;
  }
}
.breadcrumb__container {
  display: none;
}

.breadcrumb {
  height: 3.1rem;
  border-bottom: 1px solid rgba(200, 200, 200, 0.4);
  background-color: rgba(245, 244, 245, 0.45);
  pointer-events: visible;
}

.breadcrumb-navigation {
  display: flex;
  padding: 1.5rem 0 1rem 1.5rem;
}
.breadcrumb-navigation__arrow {
  width: 0.35rem;
  height: 0.35rem;
  margin: -0.25rem 0.6rem 0;
}
.breadcrumb-navigation__link {
  display: block;
  transition: color 0.3s ease;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.65rem;
  text-decoration: none;
  text-transform: uppercase;
}
.breadcrumb-navigation__link:hover {
  color: #005093;
}

.breadcrumb-navigation__item {
  display: flex;
  align-items: flex-start;
}
.breadcrumb-navigation__item:last-child .breadcrumb-navigation__link {
  color: #000;
  pointer-events: none;
}
.breadcrumb-navigation__item:last-child .svg-icon {
  display: none;
}

@media (min-width: 767px) {
  .breadcrumb__container {
    display: block;
  }
}
.bookmark-page {
  display: flex;
  position: absolute;
  top: 0;
  right: 1.5rem;
  align-items: center;
  height: 3.1rem;
  padding-top: 0.4rem;
  font-size: 0.65rem;
}
.bookmark-page__arrow-container {
  height: 1rem;
  margin: 0.3rem -0.25rem 0;
  padding-left: 0.25rem;
}

.bookmark-page__arrow {
  width: 0.75rem;
  height: 0.75rem;
  margin: 0 0.25rem;
  transition: opacity 0.2s ease;
  opacity: 0.7;
  cursor: pointer;
}
.bookmark-page__arrow:hover {
  opacity: 1;
}
.bookmark-page__arrow:hover path {
  stroke: #005093;
}
.bookmark-page__arrow path {
  stroke-width: 0.5rem;
  stroke: #000;
}
.bookmark-page__arrow--left {
  transform: rotateY(180grad);
}
.bookmark-page__arrow--inactive {
  opacity: 0.2;
  pointer-events: none;
}

.bookmark-page__button {
  position: relative;
  margin-right: 0.5rem;
  padding-right: 1rem;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}
.bookmark-page__button::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 1px;
  right: 0;
  width: 1px;
  height: 1.1rem;
  background-color: rgba(151, 151, 151, 0.53);
}
.bookmark-page__button .svg--tack {
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 0.1rem;
  transform: translateY(0.2rem);
}
.bookmark-page__button--bookmarked {
  opacity: 0.5;
  pointer-events: none;
}
.bookmark-page__button:hover {
  color: #005093;
}

.horizontal-navigation__container {
  display: flex;
  position: relative;
  justify-content: center;
  width: auto;
  margin: 1rem auto 0;
}

.horizontal-navigation {
  display: none;
}

.horizontal-navigation--mobile {
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  height: 1.2rem;
  padding-top: 2.2rem;
  padding-right: 1.8rem;
  background-color: #fff;
  transform: translateX(-50%);
  overflow-y: hidden;
  white-space: nowrap;
}
.horizontal-navigation--mobile::after {
  content: "\a";
  display: block;
  position: absolute;
  width: 0.7rem;
  height: 0.7rem;
  top: 0.9rem;
  right: 0.5rem;
  transform: rotate(90deg);
  transition: transform 0.3s ease;
  background-image: url("//svg/simple-arrow.svg");
  background-size: cover;
}
.horizontal-navigation--mobile:hover {
  height: auto;
  overflow-y: visible;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
}
.horizontal-navigation--mobile:hover .horizontal-navigation__item {
  pointer-events: visible;
}
.horizontal-navigation--mobile:hover::after {
  transform: rotate(-90deg);
}
.horizontal-navigation--mobile .horizontal-navigation__item {
  display: block;
  padding: 0.4rem 1.2rem;
  color: #000;
  text-decoration: none;
  pointer-events: none;
}
.horizontal-navigation--mobile .horizontal-navigation__item--active {
  position: absolute;
  top: 0.3rem;
  left: 0;
  pointer-events: none;
  color: #005093;
}

@media (min-width: 1020px) {
  .horizontal-navigation__container {
    margin-top: 0;
  }

  .horizontal-navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .horizontal-navigation::after {
    content: "\a";
    display: block;
    flex-basis: 100%;
    width: 40vw;
    height: 1px;
    background: linear-gradient(to right, rgba(200, 200, 200, 0), #c8c8c8, rgba(200, 200, 200, 0));
  }
  .horizontal-navigation li:last-child .horizontal-navigation__item::before {
    width: 100%;
  }
  .horizontal-navigation li:last-child .horizontal-navigation__item::after {
    display: none;
  }

  .horizontal-navigation__item {
    display: flex;
    position: relative;
    padding: 1.25rem 0 0.8rem;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.75rem;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
  }
  .horizontal-navigation__item::after {
    content: "\a";
    display: block;
    width: 1px;
    height: 0.75rem;
    margin: 0 1.1rem;
    background-color: #979797;
  }
  .horizontal-navigation__item--disabled {
    pointer-events: none;
    opacity: 0.3;
  }
  .horizontal-navigation__item--previous {
    color: #005093;
  }
  .horizontal-navigation__item--active {
    color: #000;
  }
  .horizontal-navigation__item--active::before {
    content: "\a";
    display: block;
    position: absolute;
    bottom: -1px;
    width: calc(100% - 2.2rem);
    height: 1px;
    background: #005093;
  }
  .horizontal-navigation__item:hover {
    color: #005093;
  }

  .horizontal-navigation--mobile {
    display: none;
  }
}
.preview {
  position: relative;
  height: 1.72rem;
  padding: 0.5rem 5rem 0.4rem 2rem;
  background-color: #fff;
}
.preview__headline {
  font-size: 0.85rem;
}
.preview__image {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.7rem;
  height: 100%;
  background-size: cover;
}

.preview__container {
  display: flex;
  justify-content: flex-end;
}

.main-header {
  display: none;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s ease, top 1s ease;
  pointer-events: none;
}
.main-header > .wrap {
  z-index: 1;
}
.main-header--compact {
  transform: translate3d(0, -2.8rem, 0);
}
.main-header--compact .logo {
  width: 7rem;
}
.main-header--compact.slide-in-popup__active {
  top: 0;
  position: fixed;
  transition: none;
}
.main-header--compact .breadcrumb {
  background-color: #fbfafa;
}
.main-header--no-shadow .main-header__inner::after, .main-header--no-shadow .mobile-header__container::after {
  box-shadow: none;
}

.main-header__inner, .mobile-header__container::after {
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: #fff;
  pointer-events: visible;
}
.main-header__inner::after, .mobile-header__container::after {
  content: "\a";
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background: rgba(255, 255, 255, 0.49);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.main-header__level {
  padding: 0 0 0 1.3rem;
}

.main-header__level--1 {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.6rem;
}
.main-header__level--1 .tool-nav {
  justify-content: center;
}

.main-header__level--2 {
  align-items: flex-end;
}
.main-header__level--2 nav {
  display: flex;
}

.kle__container {
  display: flex;
  flex-wrap: wrap;
}
.kle__container .button {
  margin-top: 2rem;
  margin-right: 1.65rem;
}
.kle__learn-more {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1.2rem 1.3rem 0.75rem 1.15rem;
  background-color: #ebf1f7;
}
.kle__learn-more p {
  font-size: 0.8rem;
  line-height: 1;
  text-transform: uppercase;
}
.kle__learn-more .svg-icon {
  width: 7.45rem;
  height: auto;
}

@media (min-width: 1020px) {
  .main-header {
    display: block;
  }
  .main-header__inner, .mobile-header__container::after {
    margin: 0;
  }
  .main-header__level--1 {
    display: flex;
  }
}
@media (min-width: 1190px) {
  .main-header--compact .logo {
    width: 7rem;
  }
}
@media (min-width: 1350px) {
  .main-header__level {
    padding-left: 2.2rem;
  }

  .main-header__level--1 {
    padding-top: 1.7rem;
    padding-bottom: 0.1rem;
  }

  .main-header--compact .logo {
    width: 9rem;
  }
}
@media (min-width: 1420px) {
  .main-header--compact {
    transform: translate3d(0, -2.4rem, 0);
  }
  .main-header--compact .logo {
    width: 7rem;
  }

  .main-header__level {
    padding-left: 1.3rem;
  }

  .main-header__level--1 {
    padding-top: 1.1rem;
    padding-bottom: 0;
  }

  .main-header__inner::after, .mobile-header__container::after {
    bottom: -5px;
    height: 5px;
  }
}
.mobile-header__inner-padding {
  padding-right: 0.7rem;
  padding-left: 0.7rem;
}

.mobile-header__container {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
}
.mobile-header__container--opened::after {
  background-color: rgba(151, 151, 151, 0.4);
  box-shadow: none;
}
.mobile-header__container--opened .mobile-header__overlay {
  transform: translateX(0);
}
.mobile-header__container::after {
  padding-bottom: 0;
  bottom: -5px;
  height: 5px;
  transition: background-color 0.3s ease-in;
}

.mobile-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.mobile-header__inner-container {
  height: 4rem;
  padding: 1.1rem 0 0.5rem;
}
.mobile-header__menu-button {
  margin-left: -0.7rem;
  padding: 0.5rem;
  border: 0;
  background: transparent;
}
.mobile-header__burger {
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  user-select: none;
  padding-top: 0;
  padding-bottom: 0;
  text-indent: -9999px;
}
.mobile-header__burger, .mobile-header__burger::before, .mobile-header__burger::after {
  display: block;
  width: 35px;
  height: 2px;
  transition-property: background-color, transform;
  transition-duration: 0.3s;
  outline: 1px solid transparent;
  background-color: #000;
  border-radius: 2px;
}
.mobile-header__burger::before, .mobile-header__burger::after {
  content: "";
  position: absolute;
}
.mobile-header__burger::before {
  top: -8px;
}
.mobile-header__burger::after {
  top: 8px;
}
.mobile-header__burger::after {
  right: 0;
  width: 26px;
}
.mobile-header__burger--to-cross {
  background-color: transparent;
}
.mobile-header__burger--to-cross::before, .mobile-header__burger--to-cross::after {
  background-color: #000;
}
.mobile-header__burger--to-cross::before {
  transform: translateY(8px) rotate(45deg);
}
.mobile-header__burger--to-cross::after {
  transform: translateY(-8px) rotate(-45deg);
}
.mobile-header__burger--to-cross::before {
  top: -8px;
}
.mobile-header__burger--to-cross::after {
  top: 8px;
  width: 35px;
}
.mobile-header_logo {
  transform: translateY(-0.45rem);
  line-height: 0;
}
.mobile-header_logo img {
  display: inline-flex;
  width: auto;
  height: 1.8rem;
}
.mobile-header__overlay-inner {
  position: relative;
  min-height: calc(100vh - 6.7rem);
  padding-bottom: 6.35rem;
}
.mobile-header__overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 4rem;
  padding: 2.64rem 0 0;
  overflow-y: scroll;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in;
  background-color: #f5f4f5;
}
.mobile-header__overlay--opened {
  transform: translateX(0);
}
.mobile-header__meta {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.285rem;
  border-bottom: 1px solid rgba(151, 151, 151, 0.4);
}
.mobile-header__tool-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.mobile-header__tool-item {
  margin: 0 0.2rem;
}
.mobile-header__tool-item svg {
  width: 1.5rem;
}
.mobile-header__level-2 {
  margin-top: 2.45rem;
}
.mobile-header__level-3 {
  margin-top: 1.85rem;
}
.mobile-header__level-3 .svg-icon {
  width: 1.5rem;
  margin-left: 0.6rem;
}
.mobile-header__breadcrumb {
  margin-top: 1.35rem;
}
.mobile-header__breadcrumb > span {
  display: inline-flex;
  align-items: flex-start;
  margin-right: 0.6rem;
  text-decoration: underline;
}
.mobile-header__breadcrumb i {
  margin-right: 0.2rem;
  transform: rotateZ(180deg);
}
.mobile-header__breadcrumb svg {
  width: 0.7rem;
}
.mobile-header__legal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -1.3rem;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.mobile-header__legal a {
  margin-left: 1.3rem;
  color: #000;
}
.mobile-header__legal-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #eeecee;
}

.mobile-header__nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  padding: 0 0.7rem;
  border-bottom: 1px solid rgba(151, 151, 151, 0.4);
  color: #000;
  font-size: 1.15rem;
  text-decoration: none;
}
.mobile-header__nav-item svg {
  width: 1.4rem;
}
.mobile-header__nav-item--main {
  height: 3.75rem;
  font-size: 1.25rem;
  text-transform: uppercase;
}
.mobile-header__nav-item--main span {
  margin-top: 0.3rem;
}
.mobile-header__nav-item--link {
  justify-content: inherit;
  padding: 0.75rem 0;
  border: 0;
  font-size: 1.125rem;
  text-transform: uppercase;
}
.mobile-header__nav-item--link i {
  margin-right: 0.6rem;
}
.mobile-header__nav-item--link svg {
  width: 1.2rem;
}

.content-wrap {
  position: relative;
  margin-top: 0;
  left: 0;
  transition: left 0.3s ease-in;
}

.mobile-menu--opened {
  position: relative;
  overflow: hidden;
}
.mobile-menu--opened .content-wrap {
  left: 60%;
}

@media (min-width: 1020px) {
  app-mobile-header {
    display: none;
  }

  .mobile-menu--opened .content-wrap {
    left: 0;
  }
}
.main-footer__level-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 1.75rem;
  background-color: #fff;
}

.main-footer__level-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 0 3.25rem;
  background-color: #eeedee;
}
.main-footer__level-2 h3 {
  font-size: 1.2rem;
}
.main-footer__level-2 .search {
  justify-content: center;
  padding-top: 2rem;
}

.main-footer__level-3 {
  padding: 4.25rem 0 3.5rem;
  background-color: #f5f4f5;
}

.main-footer__level-4 {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0;
  background-color: #fff;
}
.main-footer__level-4 .legal,
.main-footer__level-4 .contact-nav,
.main-footer__level-4 .social {
  padding-top: 0.2rem;
}
.main-footer__level-4 .legal,
.main-footer__level-4 .contact-nav {
  flex-basis: 50%;
  min-width: 50%;
}
.main-footer__level-4 .social {
  flex-basis: 100%;
  min-width: 100%;
}

.main-footer--grey {
  background-color: #f5f4f5;
}
.main-footer--grey .main-footer__level-4 {
  background-color: transparent;
}

.main-footer__niemann {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
}
.main-footer__niemann ul {
  display: flex;
  flex-wrap: wrap;
}
.main-footer__niemann ul li {
  margin-right: 2.15rem;
}
.main-footer__niemann ul li a {
  color: #4c90d7;
  cursor: pointer;
}
.main-footer__niemann ul li a:hover {
  opacity: 0.8;
}

@media (max-width: 766px) {
  .main-footer--alt .legal,
.main-footer--alt .contact-nav,
.main-footer--alt .social {
    flex-basis: 33.333%;
    min-width: 33.333%;
  }
}
@media (min-width: 767px) {
  .main-footer__level-4 {
    padding: 0;
  }
  .main-footer__level-4 .legal,
.main-footer__level-4 .contact-nav,
.main-footer__level-4 .social {
    align-items: center;
    height: 3rem;
  }
  .main-footer__level-4 .contact-nav {
    justify-content: flex-end;
  }
}
@media (min-width: 1020px) {
  .main-footer__level-4 {
    justify-content: space-between;
  }
  .main-footer__level-4 .legal,
.main-footer__level-4 .contact-nav,
.main-footer__level-4 .social {
    flex-basis: auto;
    min-width: auto;
  }
  .main-footer__level-4 .contact-nav {
    flex: 2;
    justify-content: center;
  }
  .main-footer__level-4 .social {
    justify-content: flex-end;
  }
}
@media (min-width: 1350px) {
  .main-footer--alt .legal,
.main-footer--alt .contact-nav,
.main-footer--alt .social {
    height: 4rem;
  }
}
.footer-static-swiper__image {
  display: block;
  width: auto;
  height: 4rem;
  margin: 0 auto 0.9rem;
}
.footer-static-swiper__headline {
  text-transform: uppercase;
}
.footer-static-swiper__slide {
  text-align: center;
}
.footer-static-swiper__pagination {
  display: flex;
  justify-content: center;
  margin: 1rem 0 0 -1rem;
}
.footer-static-swiper__pagination-bullet {
  width: 2.5rem;
  margin-left: 1rem;
  padding: 1rem 0;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
}
.footer-static-swiper__pagination-bullet::after {
  content: "\a";
  display: block;
  height: 0.15rem;
  background-color: rgba(151, 151, 151, 0.4);
}
.footer-static-swiper__pagination-bullet:hover::after, .footer-static-swiper__pagination-bullet--active::after {
  background-color: #005093;
}

.section {
  padding-top: 2.5rem;
  padding-bottom: 4rem;
}
.section--no-gap-top {
  padding-top: 0 !important;
}
.section--no-gap-bottom {
  padding-bottom: 0 !important;
}
.section--no-padding {
  padding: 0 !important;
}
.section--big-padding {
  padding-top: 3rem;
}
.section.section--teaser-list {
  padding-top: 0.5rem;
}

.section--white {
  background-color: #fff;
}

.section--gray {
  background-color: #f5f4f5;
}

.section--blue {
  background-color: rgba(0, 80, 147, 0.08);
}

.section--mid-blue {
  background-color: #d3e0ec;
}

.section--lighter-blue {
  background-color: #e1eaf3;
}

.section--lightest-blue {
  background-color: #f5f8fb;
}

.section__headline {
  padding-bottom: 0.8rem;
  text-align: center;
}
.section__headline h2 {
  font-size: 1rem;
}
.section__headline h3 {
  margin-bottom: 0.3rem;
  font-size: 0.75rem;
}
.section__headline h4 a {
  padding: 1rem;
}
.section__headline svg {
  width: 1rem;
  height: 1rem;
}

.section__pullup .section {
  margin-top: -12vw;
  padding-bottom: 4rem;
}

.section__bottom-gap {
  padding-bottom: 2rem;
}

.section--filter {
  text-align: center;
}
.section--filter h2 {
  margin-bottom: 0.5rem;
}

.section--teaser-list .teaser {
  margin-bottom: 3rem;
}

@media (min-width: 767px) {
  .section {
    padding-top: 2.05rem;
  }
  .section__headline {
    padding-bottom: 1.7rem;
  }
  .section__headline h2 {
    font-size: 1.2rem;
  }
  .section__headline h3 {
    margin-bottom: 0;
    font-size: 0.9rem;
  }
  .section--big-padding {
    padding-top: 5rem;
  }
}
@media (min-width: 1420px) {
  .section__headline h2 {
    font-size: 1rem;
  }
  .section__headline h3 {
    margin-bottom: 0.1rem;
    font-size: 0.75rem;
  }
}
.intro {
  padding: 7rem 0.5rem 0;
  background-color: #f5f4f5;
}
.intro h1,
.intro h2 {
  text-align: center;
}
.intro h2 {
  padding-bottom: 1.35rem;
}
.intro h2 + .icon-button__container {
  margin-top: 0;
}
.intro--flat {
  padding-top: 0.5rem;
}
.intro--padding-bottom {
  padding-bottom: 3.6rem;
}
.intro--mid-blue {
  background-color: #d3e0ec;
}
.intro--light-blue {
  background-color: rgba(0, 80, 147, 0.08);
}
.intro--lighter-blue {
  background-color: #e1eaf3;
}
.intro .teaser {
  margin-bottom: 2rem;
}
.intro .teaser__inner p {
  margin-bottom: 1rem;
}

.intro__image {
  display: block;
  margin: 0;
  padding-top: 90.5vw;
  background: center #f5f4f5;
  background-size: cover;
}

@media (min-width: 540px) {
  .intro__image {
    padding-top: 68.5vw;
  }
}
@media (min-width: 767px) {
  .intro:not(.intro--flat) .icon-button__container {
    margin-top: 2rem;
  }
  .intro:not(.intro--flat) .icon-button__container--after-headline {
    margin-top: 0;
  }
  .intro .teaser {
    margin-bottom: 0;
  }
  .intro .teaser__inner p {
    margin-bottom: 0;
  }

  .intro__image {
    padding-top: 48.5vw;
  }
}
@media (min-width: 1020px) {
  .intro:not(.intro--flat) {
    padding-top: 12.1rem;
  }

  .intro__image {
    padding-top: 33.5vw;
  }
  .intro__image--big {
    padding-top: 46.51%;
  }
}
@media (min-width: 1420px) {
  .intro:not(.intro--flat) {
    padding-top: 10.1rem;
  }
}
.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12rem 0 8rem;
}
.page-not-found__icon {
  width: 30vw;
  margin-bottom: 1rem;
  opacity: 0.1;
}

.section-headline {
  margin-bottom: 0;
}
.section-headline.with-decor {
  margin-bottom: 0;
}

.section-headline__container {
  padding-top: 1.5rem;
}

.zip-filter {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 2rem auto 0;
}
.zip-filter .select-dropdown__container {
  min-width: 17rem;
  margin-right: 0.5rem;
}
.zip-filter .input {
  min-width: 17rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}
.zip-filter__reset {
  flex-basis: 100%;
}

.reset-button {
  display: flex;
  justify-content: center;
  transition: color 0.3s ease;
  font-size: 0.85rem;
  cursor: pointer;
}
.reset-button:hover {
  color: #005093;
}
.reset-button__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: #000;
}
.reset-button .svg-icon {
  width: 0.375rem;
  height: 0.375rem;
  line-height: 0.375rem;
}

.contact-zip-search .zip-filter {
  flex-direction: row;
  align-items: flex-start;
}
.contact-zip-search .input {
  min-width: 9rem;
}

@media (min-width: 767px) {
  .zip-filter {
    flex-direction: row;
    align-items: flex-start;
  }
}
.zukunft-raum {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 2rem auto 0;
}

.zukunft-raum-teaser {
  display: block;
  position: relative;
  height: 22.5rem;
  background-size: cover;
  text-decoration: none;
}
.zukunft-raum-teaser:hover .zukunft-raum-button {
  background-color: #0058b1;
}

.zukunft-raum-teaser__inner {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  max-width: 22rem;
  padding: 2rem 2.25rem 6rem;
  background-color: #fff;
  color: #000;
  text-align: left;
}
.zukunft-raum-teaser__inner h3 {
  font-size: 1.3rem;
  text-transform: none;
}
.zukunft-raum-teaser__inner p {
  font-size: 0.9rem;
  line-height: 165%;
}
.zukunft-raum-teaser__inner .zukunft-raum-button {
  position: absolute;
  bottom: 1.5rem;
  left: 2rem;
}

.zukunft-raum-teaser--layout-0 {
  grid-column: span 4;
}
.zukunft-raum-teaser--layout-0 .zukunft-raum-teaser__inner p {
  display: none;
}

.zukunft-raum-teaser--layout-1 {
  grid-column: span 4;
}
.zukunft-raum-teaser--layout-1 .zukunft-raum-teaser__inner {
  top: 0;
  left: 0;
  width: 100%;
  max-width: inherit;
  height: 100%;
  background-color: #005093;
  color: #fff;
}
.zukunft-raum-teaser--layout-1 .zukunft-raum-teaser__inner .zukunft-raum-button {
  bottom: 2.5rem;
  background-color: rgba(255, 255, 255, 0.2);
}

.zukunft-raum-teaser--layout-2 {
  grid-column: span 4;
}
.zukunft-raum-teaser--layout-2 .zukunft-raum-teaser__inner {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.zukunft-raum-teaser--layout-2 .zukunft-raum-teaser__inner .zukunft-raum-button {
  bottom: 2.5rem;
}

.zukunft-raum-teaser--layout-3 {
  grid-column: span 4;
}
.zukunft-raum-teaser--layout-3 .zukunft-raum-teaser__inner p {
  display: none;
}

.zukunft-raum-button {
  display: inline-block;
  position: relative;
  height: 3rem;
  padding: 0 2.5rem;
  transition: background-color 0.3s ease;
  border: 0;
  background-color: #005093;
  color: #fff;
  line-height: 3rem;
  cursor: pointer;
}

@media (min-width: 540px) {
  .zukunft-raum-teaser--layout-1 {
    height: auto;
    min-height: 14rem;
  }
}
@media (min-width: 1020px) {
  .zukunft-raum-teaser .zukunft-raum-teaser__inner {
    width: 42%;
  }
  .zukunft-raum-teaser--layout-0 {
    grid-column: span 2;
  }
  .zukunft-raum-teaser--layout-1 {
    grid-column: span 1;
    height: 22.5rem;
  }
  .zukunft-raum-teaser--layout-1 .zukunft-raum-teaser__inner {
    width: 100%;
  }
  .zukunft-raum-teaser--layout-3 {
    grid-column: span 1;
  }
  .zukunft-raum-teaser--layout-3 .zukunft-raum-teaser__inner {
    width: calc(100% - 2rem);
  }
  .zukunft-raum-teaser--layout-2 {
    grid-column: span 1;
  }
  .zukunft-raum-teaser--layout-2 .zukunft-raum-teaser__inner {
    width: 100%;
  }
}
.article-detail__headline {
  margin-top: 10.7rem;
}
.article-detail__buttons {
  display: flex;
  margin: 1rem 0 6rem;
}
.article-detail__buttons .button {
  margin-right: 0.8rem;
}
.article-detail__slider {
  display: block;
  margin: 2rem 0;
}
.article-detail__slider img {
  line-height: 0;
}
.article-detail__slider .inner-slider__button {
  top: 48%;
}

.article-detail__block--media {
  position: relative;
  margin: 1.5rem 0 2rem;
  line-height: 0;
  cursor: pointer;
}
.article-detail__block--media img {
  width: 100%;
  height: auto;
  transition: opacity 0.2s ease;
}
.article-detail__block--media .zoom-icon {
  position: absolute;
  bottom: 1.2rem;
  left: 1.1rem;
}
.article-detail__block--media .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5rem;
  height: 5rem;
  transform: translate(-50%, -50%);
}
.article-detail__block--media:hover img {
  opacity: 0.9;
}
.bp p {
  margin-bottom: 1.5rem;
  font-size: 0.85rem;
}
.bp .radio {
  display: inline-block;
  width: 8rem;
  height: 7.5rem;
  margin: 0.5rem;
}
.bp .radio:hover .radio__container {
  color: #000;
}
.bp .radio:hover .svg-icon * {
  fill: #000;
}
.bp .radio .svg-icon {
  width: 4.5rem;
  height: 4.5rem;
  margin-top: -0.95rem;
}
.bp .radio input {
  position: absolute;
  z-index: -1;
  top: 0;
}
.bp .radio input:checked + .radio__container {
  background-color: #005093;
  color: #fff;
}
.bp .radio input:checked + .radio__container .svg-icon * {
  fill: #fff;
}
.bp .radio__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-right: 0;
  background-color: #f5f4f5;
  color: #4a4a4a;
  font-size: 0.65rem;
  text-align: center;
  text-transform: uppercase;
}
.bp .button--configurator {
  min-height: 3.65rem;
  border-radius: 5px;
  font-size: 1rem;
}
.bp .form-section {
  margin-top: 0;
  margin-bottom: 1.5rem;
}
.bp .ng-placeholder {
  opacity: 0.65;
}
.bp .form-element__label {
  margin-bottom: 0.5rem;
  padding-left: 0;
  font-size: 0.85rem;
}
.bp h6.with-decor {
  margin-bottom: 1.5rem;
}
.bp .pull-down__icon {
  margin: 1.5rem auto;
}
.bp-purposes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -0.5rem;
}
.bp-size {
  display: flex;
  flex-direction: column;
}
.bp-size__image {
  width: 100%;
  padding-top: 60%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.bp-size__content {
  width: 100%;
  padding: 0 1.5rem 1.5rem;
  background-color: #f5f4f5;
}
.bp-size input {
  background-image: url("//svg/pencil-edit-button.svg");
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 1.1rem 1.1rem;
}
.bp-size input, .bp-size .select-dropdown, .bp-size .ng-arrow-wrapper {
  background-color: rgba(155, 155, 155, 0.1);
}
.bp-size .ng-value {
  background-color: #ecebec;
}
.bp-size .form-element__container {
  margin-top: 2.3rem;
}
.bp-useful-life {
  justify-content: center;
}
.bp-useful-life .ng5-slider-tick-value {
  display: none;
}
.bp-useful-life .ng5-slider-tick-legend {
  top: 3rem;
  max-width: auto;
  white-space: nowrap;
}
.bp-infos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.bp-result__wrap {
  justify-content: center;
  margin-bottom: 2rem;
}
.bp-result__wrap + .bp-result__wrap {
  margin-top: -2rem;
}
.bp-result .contact__info {
  padding-top: 1.5rem;
}
.bp .with-decor--button {
  height: auto;
  margin-top: 1rem;
}
.bp .centered-headline {
  margin-bottom: 0.5rem;
}
.bp .checkbox__container {
  justify-content: center;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
.bp .checkbox__label-text {
  font-size: 0.85rem;
}

@media (min-width: 767px) {
  .bp .radio {
    width: calc(25% - 1rem);
    height: 9.5rem;
  }
  .bp .radio__container {
    font-size: 0.85rem;
  }
  .bp .button--configurator {
    min-width: 21.5rem;
  }
  .bp-size__content {
    padding: 0 3.3rem 1.5rem;
  }
}
@media (min-width: 1020px) {
  .bp-size {
    flex-direction: row;
    align-items: stretch;
  }
  .bp-size__image {
    width: 63.5%;
    padding-top: 0;
  }
  .bp-size__content {
    width: 36.5%;
  }
}
@media (min-width: 1350px) {
  .bp .radio {
    width: calc(12.5% - 1rem);
  }
  .bp .radio__container {
    font-size: 0.85rem;
  }
  .bp .contact {
    height: 31.85rem;
    overflow: hidden;
  }
  .bp .teaser__image {
    height: 31.85rem;
    padding-top: 0;
  }
}
@media (min-width: 1420px) {
  .bp .contact {
    height: 29rem;
  }
  .bp .teaser__image {
    height: 29rem;
  }
}
@media (min-width: 1580px) {
  .bp .contact {
    height: 31.85rem;
  }
  .bp .teaser__image {
    height: 31.85rem;
  }
}
@media print {
  @page {
    margin: 0.5cm;
  }
  .no-print {
    display: none;
  }

  .wrap {
    width: 100%;
  }

  [class*=col-] {
    width: 100%;
  }

  body,
html {
    background: #fff none;
  }
}