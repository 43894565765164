.input {
  height: 2.5rem;
  padding: 0 1rem;
  border: 0;
  background-color: $light-gray;

  &--white {
    background-color: $white;
    border: 1px solid #e4e4e4;
  }

  &.ng-touched.ng-invalid {
    border: 1px solid $error;
  }

  @include respond-to(mobile) {
    font-size: 16px;
  }

  @include respond-to(ipad-portrait) {
    font-size: .75rem;
  }
}
