.vacancy-search__container {
  > div {
    padding: 2rem 0;
    background-color: $mid-blue;
  }

  &--filter-only {
    > div {
      padding-top: 0;
    }

    .vacancy-search {
      padding: 0;
    }
  }
}

.vacancy-search {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 0 2rem;

  &__button-container {
    padding: 0 1rem;
  }

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 3.4rem;
    margin-top: 1rem;
    overflow: hidden;
    transition: opacity .2s ease;
    border-radius: .2rem;
    background-color: rgba(0, 80, 147, .8);
    color: $white;
    line-height: 1.5rem;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &--disabled {
      @extend .trans-30;
      pointer-events: none;
    }

    &:hover {
      opacity: .9;
    }
  }

  &__button-text,
  &__button-cta {
    display: flex;
    align-items: center;
    padding: 0 1.5rem 0 1.8rem;
  }

  &__button-text {
    font-size: 1.1rem;

    > span {
      display: inline-block;
      margin-right: .5rem;
      font-size: 1.5rem;
    }
  }

  &__button-cta {
    width: auto;
    height: 3.75rem;
    margin: 0;
    transition: background-color .1s ease-in;
    border: 0;
    background-color: $blue;
    color: $white;
    text-transform: uppercase;
  }

  &__filter-container {
    padding: 1.3rem 0;

    h5 {
      margin-right: 1.25rem;
      text-transform: inherit;
    }
  }

  &__filter-inner {
    display: flex;
    align-items: center;

    > span {
      display: inline-flex;
      align-items: center;
      width: 100%;
      height: 2.5rem;
      padding: 0 .9rem;
      background-color: $lightest-blue;
      font-size: .85rem;
    }

    .vacancy-search__filter-close {
      display: inline-flex;
      height: 2.5rem;
      margin-left: .1rem;
      padding: 0 .75rem;
      transition: background-color .2s ease;
      background-color: #eff4f8;
      cursor: pointer;
    }

    svg {
      width: 1rem;
    }
  }

  &__filter {
    margin-top: 1rem;
    cursor: pointer;

    &:hover {
      .vacancy-search__filter-close {
        @extend .svg-icon--white;
        background: $blue;
      }
    }
  }

  &__filter-reset {
    text-decoration: underline;
    cursor: pointer;
  }

  &__dropdowns {
    display: flex;
    flex-wrap: wrap;
    margin-top: -1rem;

    ng-select {
      margin-top: 1rem;
      background-color: $lightest-blue;

    }

    ng-dropdown-panel {
      padding-left: 1rem;
      box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, .5);
    }

    ng-dropdown-panel,
    div.ng-value {
      background-color: #f6f8fb;
    }
  }
}

@include respond-to(ipad-portrait) {
  .vacancy-search {
    &__container {
      &--filter-only {
        width: 73%;
        margin: 0 auto;
      }
    }

    &__dropdowns {
      ng-select {
        flex: 1;
        margin-left: .9rem;
      }
    }

    .select-dropdown__container {
      margin-bottom: 0;
      margin-left: -.9rem;
    }

    &__button {
      margin-left: .9rem;
    }

    &__filter-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__filter-inner {
      display: inline-flex;

      > span {
        width: auto;
      }
    }

    &__filter {
      margin-top: 0;
      margin-right: .65rem;
    }
  }
}
