.inner-slider {
  @include slider-navigation();
  min-width: 100%;

  &__button {
    display: none;
    position: absolute;
    top: 42%;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    transition: opacity .3s ease;

    &--prev {
      left: -7.7%;
      transform: rotate(180deg);
    }

    &--next {
      right: -7.7%;
    }

    &.inner-slider__button--hide {
      display: none;
    }

    &:hover {
      @extend %svg-icon--blue;
    }

    &--inactive {
      opacity: .1;
      pointer-events: none;
    }
  }

  &__navigation {
    z-index: 1;
    right: 2.1rem;
    bottom: 0;
    transform: translateY(100%);

    &--centered {
      right: 50%;
      bottom: -4rem;
      -webkit-transform: translateX(50%) translateY(-100%);
      transform: translateX(50%) translateY(-100%);
    }
  }

  &__video-button {
    position: absolute;
    z-index: 1;
    top: 16vw;
    left: 50%;
    width: 3.75rem;
    height: 3.75rem;
    transform: translateX(-50%);
    transition: background-color .2s ease;
    border-radius: 50%;
    background-color: rgba(#fff, .15);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
    cursor: pointer;

    &:hover {
      background-color: rgba(#fff, .4);
    }
  }
}


@include respond-to(ipad-portrait) {
  .inner-slider {
    &__button {
      display: inline-block;
    }

    &__video-button {
      top: 20vw;
      width: 6.75rem;
      height: 6.75rem;
    }

    &__navigation {
      top: -1.45rem;
      right: calc(6% + 10px);
      height: 2rem;
      transform: translateY(-100%);
    }
  }
}
