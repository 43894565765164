.threesixty__container {
  position: relative;
  margin-top: 1rem;
}

.threesixty__outer-container {
  display: flex;
  flex-direction: column;
}

.threesixty__tab-nav-container {
  display: flex;
  position: absolute;
  top: -1rem;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: .65rem;
  line-height: 100%;
  text-transform: uppercase;

  .svg-icon {
    width: 1rem;
    height: 1rem;
    margin: -.3rem .5rem 0 0;
  }
}

.threesixty__tab-nav {
  position: relative;
  height: .65rem;
  margin-left: .9rem;
  line-height: 100%;
  cursor: pointer;

  &:hover {
    color: $blue;
  }

  &--active {
    pointer-events: none;
  }

  &--active::after {
    content: '\A';
    display: block;
    position: absolute;
    bottom: -.3rem;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $blue;
  }
}


.threesixty__general-label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: .65rem;
  text-transform: uppercase;
  transform: translateY(-1.5rem);
  margin-right: .5rem;

  .svg-icon {
    width: 1.35rem;
    height: 1.35rem;
    margin-right: .3rem;
  }
}

.threesixty__bodytext {
  display: flex;
  flex-direction: column;
  .threesixty__headline {
    order: 2;
    margin: 2rem 0 1.8rem; // margin: 4rem 0 1.8rem;
    text-align: left;
  }

  p {
    order: 3;
    margin-bottom: 4rem;
    font-size: .85rem;
    line-height: 150%;
  }

  .threesixty-degree__container {
    order: 1;
  }
}

.threesixty {
  display: none;
  width: 100%;
  height: auto;
  opacity: 0;
  pointer-events: none;
  user-select: none;

  &--active {
    display: block;
    opacity: 1;
  }
}

.threesixty__navigation-container {
  display: none;
  position: absolute;
  right: 1rem;
  bottom: 12rem;
}

.threesixty__navigation {
  height: 1.6rem;
  cursor: pointer;

  &:hover .svg-icon {
    @extend %svg-icon--blue;
  }

  .svg-icon {
    width: 1.6rem;
    height: 1.6rem;
  }

  &--prev {
    .svg-icon {
      transform: rotate(180deg);
    }
  }
}

.threesixty__navigation-inner-container {
  display: flex;
  align-items: center;
}

.threesixty__separator {
  width: 2.5rem;
  height: 1px;
  margin: 0 .5rem;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(151, 151, 151, 1) 50%, rgba(0, 0, 0, 0));
}
@include respond-to('ipad-portrait') {
  .threesixty__tab-nav-container {
    width: auto;
    left: 1rem;
  }

  .threesixty__general-label {
    position: absolute;
    top: -1.5rem;
    right: 1rem;
    transform: translateY(0);
  }

  .threesixty__bodytext {
    margin-top: -2rem;
  }
}
@include respond-to('ipad-landscape') {
  .threesixty__infobox {
    position: relative;
    z-index: 1;
    margin-top: -4rem;
    order: 2;
  }

  .threesixty-slider {
    padding-bottom: 5px;
  }

  .threesixty__tab-nav-container {
    left: 1.5rem;
  }

  .threesixty__inner-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    padding: 0 .8rem 0 1.5rem;
  }

  .threesixty__general-label {
    margin-right: .2rem;
  }
  
  .threesixty__bodytext {
    width: 25%;
    margin-top: 0;

    .threesixty__headline {
      order: 1;
      margin-top: 4rem;
    }

    p {
      order: 2;
    }

    .threesixty-degree__container {
      order: 3;
    }
  }

  .threesixty__navigation-container {
    display: flex;
    position: static;
  }
}

.threesixty__container--multi-setup {
  margin-top: 0;
  padding: 2rem 0;
  background: $light-gray;

  .threesixty-slider::-webkit-slider-runnable-track {
    height: 0.4rem;
  }

  .threesixty-slider::-webkit-slider-thumb {
    width: 3.25rem;
    height: 2.5rem;
  }

  .threesixty__inner-container {
    bottom: 4%;
    right: 0;
    padding: 0 .8rem 0 .9rem;

    .threesixty__bodytext {
      width: 100%;
    }
  }
}
