@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


%fadein {
  animation: fadein .45s linear both;
}


%fadein--slow {
  animation: fadein .6s linear both;
}
