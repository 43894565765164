.section__slide-in-popup-sideways {
  position: fixed;
  transition: left 1s ease;
  top: 0;
  bottom: 0;
  right: 0;
  left: -600px;
  height: auto;
  width: 100%;
  z-index: 555;
  background-color: white;
  box-shadow: -1px 7px 33px 0 rgba(#000,.35);

  &.section__slide-in-popup__open {
    transition: left 1s ease;
    left: 0;
  }

  .slide-in__close {
    height: 54px;
    width: 54px;
    padding: 17px;
    background-color: $blue;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .intro-slider__box {
    padding: 1.8rem;
    position: relative;
    width: 100%;
    top: 0;
    margin: 0;

    h2 {
      font-size: 1.7rem;
    }
  }

  @include respond-to(mobile-landscape) {
  }

  @include respond-to(ipad-portrait) {
    top: 12%;
    width: 350px;
    right: auto;
    bottom: auto;

    .intro-slider__box {
      top: -75px;
      width: 85%;
      left: 15px;
    }
  }

  @include respond-to(desktop-small) {
    width: 500px;

    .intro-slider__box {
      width: 76%;
      margin: 0 0 0 35px;
      top: -100px;
    }
  }

  @include respond-to(desktop-large) {
    .intro-slider__box {
      top: -110px;
    }
  }

  @media screen and (max-height: 500px) {
    top: 0;
    bottom: 0;

    .intro-slider__box {
      top: -100%;
    }
  }

}

.section__slide-in-popup-top {
  transition: height 1s ease;
  height: 0;
  background-size: cover;
  background-position: center;

  &.section__slide-in-popup__open {
    transition: height 1s ease;
    position: static;
    top: 0;
    left: 0;
    right: 0;
    z-index: 555;
    height: 500px;

    @include respond-to(ipad-landscape) {
      height: 660px;
      padding: 0;
    }
  }

  .intro-slider__box {
    padding: 1.8rem;
  }

  .intro-slider__box-header {
    position: relative;
    margin-top: 50px;
    padding: 1rem 3.25rem 0.7rem 1.9rem;
    background-color: rgba(255,255,255,0.6);

    h4 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.85rem;
    }

    @include respond-to(ipad-landscape) {
      padding: 1rem 3.25rem 0.7rem 1.9rem;
    }
  }

  .slide-in__close {
    height: 2.7rem;
    width: 2.7rem;
    padding: 0.8rem;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    @include respond-to(ipad-landscape) {
      height: 2.7rem;
      width: 2.7rem;
      padding: 0.8rem;
    }
  }
}


.main-header.slide-in-popup__active {
  transition: top 1s ease;
  position: absolute;
  top: 500px;

  @include respond-to(ipad-landscape) {
    top: 660px;
  }
}

.mobile-header__container {
  transition: top 1s ease;
}

.mobile-header__container.slide-in-popup__active {
  transition: top 1s ease;
  position: absolute;
  top: 500px;

  @include respond-to(ipad-landscape) {
    top: 660px;
  }
}

.mobile-menu--opened {

}