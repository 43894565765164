swiper > .swiper.s-wrapper .swiper-wrapper .swiper-slide.teaser-media {
  height: auto;
}

.teaser-media {
  position: relative;
  padding: 2.4rem 3.75rem 2.65rem;
  border: 2px solid transparent;
  text-decoration: none;

  h3 {
    @extend %regular;
    width: 60%;
    margin-bottom: 1.2rem;
    line-height: 120%;

    &.no-margin {
      margin-bottom: .1rem;
    }
  }

  p {
    width: 75%;
    padding-bottom: 1.2rem;
    font-size: .85rem;
    line-height: 130%;
  }
}

.teaser-media--blue {
  background-color: $blue;
  color: $white;

  &:hover {
    background-color: rgba(#000, .95);
    color: #fff;
  }
}

.teaser-media--gray {
  background-color: $light-gray;
}

.teaser-media__container {
  margin-bottom: 2rem;

  .inner-slider__navigation {
    color: $white;
  }

  .inner-slider__arrow path {
    fill: $white;
  }

  .inner-wrap {
    width: 87.5%;
  }
}

.teaser-media__icon {
  position: absolute;
  top: 2.4rem;
  right: 2.25rem;
  width: 1.45rem;
  height: 1.45rem;

  &--bottom {
    top: inherit;
    bottom: 2.4rem;
  }
}

.teaser-media__image {
  width: auto;
  max-width: 53%;
  margin: 0 auto;
  padding-top: 60%;
  background: center no-repeat;
  background-size: contain;
}

@include respond-to(ipad-portrait) {
  .teaser-media {
    padding-bottom: 1.65rem;
  }
}
