.welcome {
  display: none;
  color: rgba(#000, .7);
  font-size: .9rem;
  text-transform: uppercase;
}

@include respond-to(desktop-smallest) {
  .welcome {
    display: inline-flex;
  }
}

@include respond-to(desktop-small) {
  .welcome {
    font-size: .65rem;
  }
}
