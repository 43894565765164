.overlay {
  @extend %fadein;
  display: flex;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  align-items: center;
  justify-content: center;

  transition: opacity .3s ease;
  background-color: rgba(#fff, .98);

  &--grey {
    background-color: #f5f4f5;
  }
  
  &__close {
    position: absolute;
    top: 3rem;
    right: 3rem;
    width: 1.5rem;
    height: 1.5rem;
    transition: transform .3s ease;
    cursor: pointer;
    z-index: 1;

    &:hover {
      @extend .svg-icon--black;
    }
  }

  &__inner {
    width: 95%;

    &.overlay__inner--full-width {
      width: 100%;
    }
  }

  &__inner--image {
    width: 70%;
  }
}

.overlay__video {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.overlay__image {
  width: 100%;
  height: 90vh;
  background: no-repeat center;
  background-size: contain;
}

@include respond-to(mobile-landscape) {
  .overlay__inner {
    width: 60%;
  }
}


@include respond-to(ipad-portrait) {
  .overlay__inner {
    width: 80%;
  }
}

@include respond-to(ipad-landscape) {
  .overlay__inner {
    width: 60%;
  }
}
