.degree__container {
  display: none;
  position: relative;
  width: calc(100% - 70px);
  height: 3rem;
}

.degree {
  display: flex;
  position: absolute;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  width: 80px;
  text-align: center;

  &__label {
    @extend %bold;
    margin-left: .5rem;
    color: $blue;
    font-size: .85rem;
  }

  &::after {
    content: '\A';
    display: block;
    width: 1px;
    height: 15px;
    margin: .5rem 0 .75rem;
    background-color: #d8d8d8;
  }
}

@include respond-to('ipad-portrait') {
  .degree__container {
    display: block;
  }
}
