.simple-text {
  width: 90%;
  margin: 3.3rem auto 0;
  color: $dark-gray;
  font-size: .85rem;
  text-align: center;

  a {
    color: $dark-gray;
    text-decoration: none;

    &:hover {
      color: $blue;
    }
  }
}

@include respond-to(ipad-landscape) {
  .simple-text {
    width: 62%;
  }
}
