.zoom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 2.9rem;
  transition: background-color .2s ease;
  background-color: #fff;

  .svg-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  &--hover,
  &:hover {
    .svg-icon {
      @extend .svg-icon--blue;
    }
  }
}
