.section {
  padding-top: 2.5rem;
  padding-bottom: 4rem;

  &--no-gap-top {
    padding-top: 0 !important;
  }

  &--no-gap-bottom {
    padding-bottom: 0 !important;
  }

  &--no-padding {
    padding: 0 !important;
  }

  &--big-padding {
    padding-top: 3rem;
  }

  &.section--teaser-list {
    padding-top: 0.5rem;
  }
}

.section--white {
  background-color: #fff;
}

.section--gray {
  background-color: $light-gray;
}

.section--blue {
  background-color: $light-blue;
}

.section--mid-blue {
  background-color: $mid-blue;
}

.section--lighter-blue {
  background-color: $lighter-blue;
}

.section--lightest-blue {
  background-color: $lightest-blue;
}

.section__headline {
  padding-bottom: .8rem;
  text-align: center;

  h2 {
    font-size: 1rem;
  }

  h3 {
    margin-bottom: .3rem;
    font-size: .75rem;
  }

  h4 {
    a {
      padding: 1rem;
    }
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.section__pullup {
  .section {
    margin-top: -12vw;
    padding-bottom: 4rem;
  }
}

.section__bottom-gap {
  padding-bottom: 2rem;
}

.section--filter {
  text-align: center;

  h2 {
    margin-bottom: .5rem;
  }
}

.section--teaser-list {
  .teaser {
    margin-bottom: 3rem;
  }
}

@include respond-to(ipad-portrait) {
  .section {
    padding-top: 2.05rem;

    &__headline {
      padding-bottom: 1.7rem;

      h2 {
        font-size: 1.2rem;
      }

      h3 {
        margin-bottom: 0;
        font-size: .9rem;
      }
    }

    &--big-padding {
      padding-top: 5rem;
    }
  }
}

@include respond-to(desktop-small) {
  .section {
    &__headline {
      h2 {
        font-size: 1rem;
      }

      h3 {
        margin-bottom: .1rem;
        font-size: .75rem;
      }
    }
  }
}
