$enableNgDeep: false !important;

$handleActiveColor: #451aff;
$handleHoverColor: #fff;
$labelTextColor: #55637d;
$handleBgColor: $blue;
$handleInnerColor: #fff;
$handleDisabledColor: #d8e0f3;
$limitLabelTextColor: $labelTextColor;
$barFillColor: $handleBgColor;
$barDisabledFillColor: #8b91a2;
$barNormalColor: #f5f4f5;
$barLeftOutSelectionColor: #df002d;
$barRightOutSelectionColor: #03a688;

$ticksColor: #cacaca;
$selectedTicksColor: $ticksColor;
$ticksWidth: .05rem;
$ticksHeight: 2.25rem;
$ticksValuePosition: -34px;
$ticksLegendPosition: 24px;
$ticksValuePositionOnVertical: 24px;
$tickLegendMaxWidth: 50px;

$handleSize: 2.75rem;
$handlePointerSize: .4rem;
$bubblePaddingVertical: 1px;
$bubblePaddingHorizontal: 3px;
$labelFontSize: 1rem;
$barDimension: .8rem;

$withLegendMargin: 40px;

$sliderMargin: 15px;
$sliderMarginWithLabel: 35px;
$sliderVerticalMargin: 20px;

@mixin rounded($radius: 2px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

ranger-input {
  display: flex;
  width: 100%;

  .ranger-input__custom-label {
    position: relative;
    
    span {
      font-size: .75rem;
      display: block;
      text-align: center;
      color: $blue;
      font-size: .75rem; 
    }
  }
}
ranger-input ng5-slider {
  display: inline-block;
  position: relative;
  width: 100%;
  height: $barDimension;
  margin: $sliderMarginWithLabel 0 $sliderMargin 0;
  vertical-align: middle;
  user-select: none;
  touch-action: pan-y;

  &.with-legend {
    margin-bottom: $withLegendMargin;
  }

  &[disabled] {
    cursor: not-allowed;

    .ng5-slider-pointer {
      background-color: $handleDisabledColor;
      cursor: not-allowed;
    }

    .ng5-slider-draggable {
      cursor: not-allowed;
    }

    .ng5-slider-selection {
      background: $barDisabledFillColor;
    }

    .ng5-slider-tick {
      cursor: not-allowed;

      &.ng5-slider-selected {
        background: $barDisabledFillColor;
      }
    }
  }

  .ng5-slider-span {
    position: absolute;
    display: inline-block;
    white-space: nowrap;
  }

  .ng5-slider-base {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .ng5-slider-bar-wrapper {
    z-index: 1;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: $handleSize;
    margin-top: -$handleSize / 2;
    padding-top: $handleSize / 2;
  }

  .ng5-slider-draggable {
    cursor: move;
  }

  .ng5-slider-bar {
    @include rounded($barDimension / 2);
    z-index: 1;
    left: 0;
    width: 100%;
    height: $barDimension;
    background: $barNormalColor;
  }

  .ng5-slider-bar-wrapper.ng5-slider-transparent .ng5-slider-bar {
    background: transparent;
  }

  .ng5-slider-bar-wrapper.ng5-slider-left-out-selection .ng5-slider-bar {
    background: $barLeftOutSelectionColor;
  }

  .ng5-slider-bar-wrapper.ng5-slider-right-out-selection .ng5-slider-bar {
    background: $barRightOutSelectionColor;
  }

  .ng5-slider-selection {
    @include rounded($barDimension / 2);
    z-index: 2;
    background: $barFillColor;
  }

  .ng5-slider-pointer {
    z-index: 3;
    top: -$handleSize / 2 + $barDimension / 2;
    width: $handleSize;
    height: $handleSize;
    background-color: $handleBgColor;
    cursor: pointer;

    @include rounded($handleSize / 2);

    &::after {
      content: '';
      position: absolute;
      top: $handleSize / 2 - $handlePointerSize / 2;
      left: $handleSize / 2 - $handlePointerSize / 2;
      width: $handlePointerSize;
      height: $handlePointerSize;
      @include rounded($handlePointerSize / 2);
      background: $handleInnerColor;
    }

    &:hover::after {
      background-color: $handleHoverColor;
    }

    &.ng5-slider-active {
      z-index: 3;

      &::after {
        background-color: $handleActiveColor;
      }
    }
  }

  .ng5-slider-bubble {
    bottom: $handleSize / 2;
    padding: $bubblePaddingVertical $bubblePaddingHorizontal;
    color: $labelTextColor;
    font-size: $labelFontSize;
    cursor: default;

    &.ng5-slider-limit {
      color: $limitLabelTextColor;
    }
  }

  .ng5-slider-ticks {
    position: absolute;
    z-index: 1;
    top: -($ticksHeight - $barDimension) / 2;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 0;
    margin: 0;
    list-style: none;
  }

  .ng5-slider-ticks-values-under {
    .ng5-slider-tick-value {
      top: auto;
      bottom: $ticksValuePosition - 2;
    }
  }

  .ng5-slider-tick {
    position: absolute;
    top: 0;
    left: 0;
    width: $ticksWidth;
    height: $ticksHeight;
    margin-left: $handleSize / 2 - $ticksWidth / 2 + .5rem; // for centering
    border-radius: 50%;
    background: $ticksColor;
    text-align: center;
    cursor: pointer;

    &.ng5-slider-selected {
      background: $selectedTicksColor;
    }
  }

  .ng5-slider-tick-value {
    position: absolute;
    top: $ticksValuePosition;
    transform: translate(-50%, 0);
  }

  .ng5-slider-tick-legend {
    position: absolute;
    top: $ticksLegendPosition;
    max-width: $tickLegendMaxWidth;
    transform: translate(-50%, 0);
    white-space: normal;
  }

  &.ng5-slider-vertical {
    position: relative;
    width: $barDimension;
    height: 100%;
    margin: 0 $sliderVerticalMargin;
    padding: 0;
    vertical-align: baseline;
    touch-action: pan-x;

    .ng5-slider-base {
      width: 100%;
      height: 100%;
      padding: 0;
    }

    .ng5-slider-bar-wrapper {
      top: auto;
      left: 0;
      width: $handleSize;
      height: 100%;
      margin: 0 0 0 (-$handleSize / 2);
      padding: 0 0 0 $handleSize / 2;
    }

    .ng5-slider-bar {
      bottom: 0;
      left: auto;
      width: $barDimension;
      height: 100%;
    }

    .ng5-slider-pointer {
      top: auto;
      bottom: 0;
      left: -$handleSize / 2 + $barDimension / 2 !important;
    }

    .ng5-slider-bubble {
      bottom: 0;
      left: $handleSize / 2 !important;
    }

    .ng5-slider-ticks {
      z-index: 1;
      top: 0;
      left: -($ticksHeight - $barDimension) / 2;
      width: 0;
      height: 100%;
    }

    .ng5-slider-tick {
      margin-top: $handleSize / 2 - $ticksWidth / 2;
      margin-left: auto;
      vertical-align: middle;
    }

    .ng5-slider-tick-value {
      top: auto;
      left: $ticksValuePositionOnVertical;
      transform: translate(0, -28%);
    }

    .ng5-slider-tick-legend {
      top: auto;
      right: $ticksLegendPosition;
      max-width: none;
      transform: translate(0, -28%);
      white-space: nowrap;
    }

    .ng5-slider-ticks-values-under {
      .ng5-slider-tick-value {
        right: $ticksValuePositionOnVertical;
        bottom: auto;
        left: auto;
      }
    }
  }
}


// custom styles
ranger-input ng5-slider {
  margin: 2rem 0 6rem;

  .ng5-slider-tick {
    border-radius: 0;
    width: 1px;
  }

  .ng5-slider-pointer {
    width: auto;
    padding: 0 .2rem;
    border-radius: .2rem;

    &::after {
      display: none;
    }
  }

  .ng5-slider-model-value {
    bottom: -5rem;

    &::before {
      content: '';
      display: inline-block;
      position: relative;
      left: 50%;
      width: 1px;
      height: .8rem;
      transform: translate(-50%, -200%);
      background-color: $ticksColor;

    }
  }
}

.ng5-slider-inner-tooltip {
  display: flex;

  &::before {
    content: '';
    align-self: center;
    width: 1px;
    align-self: center;
    height: $ticksHeight * .6;
    background-color: $white;
  }
}

.ng5-slider-handlebar-icon {
  display: flex;
  align-items: center;
  height: 2.75rem;

  .svg-icon {
    width: 1.3rem;
    margin: 0 .25rem;
    line-height: 0;

    &--reversed {
      transform: rotateY(180deg);
    }
  }
}
