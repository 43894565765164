.detail-intro {
  position: relative;
  z-index: 2;
  padding: 1.5rem 0;
  background-color: #fff;

  &__subline {
    display: block;
    margin-bottom: .4rem;
  }

  &--inline {
    padding: 1.5rem 2rem;
  }
}

@include respond-to(ipad-portrait) {
  .detail-intro {
    width: 51.1%;
    margin-top: -6.3rem;
    padding: 2rem 3rem 2.3rem 2.7rem;

    &--inline {
      width: 58.4%;
      margin: -6.3rem 0 0 2.5rem;
      padding: 2rem 1rem 2.3rem 2.7rem;
    }

    &__headline {
      min-height: 4rem;
    }
  }
}
