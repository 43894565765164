.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  img {
    width: 12rem;
    height: auto;
  }
}
