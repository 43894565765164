.meta-nav {
  display: inline-flex;

  .tool-nav__item {
    margin-right: 2.3rem;
  }

  .main-nav__element--level-2 {
    align-items: flex-start;
    height: .9rem;
    font-size: .9rem;
  }
}

@include respond-to(ipad-pro) {
  .meta-nav .main-nav__element {
    display: none;
  }
}
