.searchresult {
  display: block;
  position: relative;
  padding: 2.1rem 2.3rem;
  transition: color .2s ease;
  background-color: #fff;

  color: #000;
  text-decoration: none;

  &__headline {
    margin-bottom: .75rem;

  }

  &__bodytext {
    width: 75%;
    font-size: .85rem;
    line-height: 130%;
  }

  &__path {
    margin-top: 1.1rem;
    font-size: .85rem;
    text-decoration: underline;
  }

  &__icon {
    position: absolute;
    top: 2.1rem;
    right: 2.1rem;
    width: 1.45rem;
    height: 1.45rem;
  }

  &__empty {
    font-size: 1.5rem;
    text-align: center;
    text-transform: none;
  }

  &__alternatives {
    margin-top: 1.8rem;
    font-size: .85rem;
    line-height: 150%;
    text-align: center;
  }

  &__alternative {
    transition: color .2s ease;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $blue;
    }
  }

  &--variant-0 {
    background-color: $light-gray;
  }

  &:hover {
    color: $blue;

    .searchresult__icon {
      @extend .svg-icon--blue;
    }
  }
}

