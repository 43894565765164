.horizontal-navigation__container {
  display: flex;
  position: relative;
  justify-content: center;
  width: auto;
  margin: 1rem auto 0;
}

.horizontal-navigation {
  display: none;
}

.horizontal-navigation--mobile {
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  height: 1.2rem;
  padding-top: 2.2rem;
  padding-right: 1.8rem;
  background-color: #fff;
  transform: translateX(-50%);
  overflow-y: hidden;
  white-space: nowrap;

  &::after {
    content: '\A';
    display: block;
    position: absolute;
    width: .7rem;
    height: .7rem;
    top: .9rem;
    right: .5rem;
    transform: rotate(90deg);
    transition: transform .3s ease;
    background-image: url('//svg/simple-arrow.svg');
    background-size: cover;
  }

  &:hover {
    height: auto;
    overflow-y: visible;
    box-shadow: 0 4px 4px rgba(#000, .3);

    .horizontal-navigation__item {
      pointer-events: visible;
    }

    &::after {
      transform: rotate(-90deg);
    }
  }

  .horizontal-navigation__item {
    display: block;
    padding: .4rem 1.2rem;
    color: #000;
    text-decoration: none;
    pointer-events: none;
  }

  .horizontal-navigation__item--active {
    position: absolute;
    top: .3rem;
    left: 0;
    pointer-events: none;
    color: $blue;
  }
}




@include respond-to(ipad-landscape) {
  .horizontal-navigation__container {
    margin-top: 0;
  }

  .horizontal-navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &::after {
      content: '\A';
      display: block;
      flex-basis: 100%;
      width: 40vw;
      height: 1px;
      background: linear-gradient(to right, rgba(#c8c8c8, 0), rgba(#c8c8c8, 1), rgba(#c8c8c8, 0));

    }

    li:last-child .horizontal-navigation__item {
      &::before {
        width: 100%;
      }

      &::after {
        display: none;
      }
    }
  }

  .horizontal-navigation__item {
    display: flex;
    position: relative;
    padding: 1.25rem 0 .8rem;

    color: rgba(#000, .5);
    font-size: .75rem;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &::after {
      content: '\A';
      display: block;
      width: 1px;
      height: .75rem;
      margin: 0 1.1rem;
      background-color: #979797;
    }

    &--disabled {
      pointer-events: none;
      opacity: .3;
    }

    &--previous {
      color: $blue;
    }

    &--active {
      color: #000;

      &::before {
        content: '\A';
        display: block;
        position: absolute;
        bottom: -1px;
        width: calc(100% - 2.2rem);
        height: 1px;
        background: $blue;
      }
    }

    &:hover {
      color: $blue;
    }
  }

  .horizontal-navigation--mobile {
    display: none;
  }
}
