.button {
  display: inline-flex;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  min-width: 9rem;
  height: 2.9rem;
  padding: .3rem .8rem 0;
  transition: background-color .3s ease;
  background-color: $blue;
  color: $white;
  font-size: .9rem;
  text-decoration: none;
  text-transform: uppercase;

  cursor: pointer;

  &[disabled],
  &--inactive {
    opacity: .7;
    pointer-events: none;
  }

  &--hover,
  &:hover {
    background-color: #000;
  }

  &--large {
    display: flex;
  }

  &--high {
    height: 3.2rem;
  }

  &--with-icon {
    justify-content: space-between;
  }

  &--submit {
    min-width: 14.9rem;

    svg {
      width: 1rem;
    }
  }

  &--configurator {
    border-radius: 3px;
  }

  &--container-comparison {
    padding: 0 0.75rem;
    background-color: $contrast-gray;
  }

  &__outline {
    background: transparent;
    color: #005093;
    border: 1px solid #005093;

    &--hover,
    &:hover {
      background-color: initial;
      opacity: 0.7;
      transition: opacity .3s ease;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0 4rem;

    .button,
    .border-button {
      margin: 0 0 1.5rem;
    }

    &--center {
      justify-content: center;
      align-items: center;
    }

    &--no-gap {
      margin: 0;
    }
  }

  &__hr {
    font-size: 1rem;
  }

  &__mykleusberg-login {
    width: 100%;
  }
}

.reference-filter-wrap.reference-filter-wrap__filter-button {
  .reference-filter__label {
    opacity: 1;
    background: #296da5;
    color: #fff;
    text-indent: 0;
    padding: 0 0.75rem;
  }

  button {
    background: #005093;
    color: #fff;
    font-size: 0.7rem;
    padding: 0 1.25rem;
    cursor: pointer;
    line-height: 2.5rem;
  }
}

.svg-icon--zoom {
  width: .9rem;
  height: .9rem;
  margin-left: 1.6rem;
}


.svg-icon--back {
  width: .9rem;
  height: .9rem;
  margin-right: 1rem;
  transform: rotate(180deg);
}

@include respond-to(ipad-portrait) {
  .button {
    &__container {
      display: flex;
      flex-direction: row;

      .button,
      .border-button {
        margin: 0 1.9rem 0 0;
      }
    }

    &__hr {
      font-size: .85rem !important;
    }
  }
}

@include respond-to(ipad-landscape) {
  .button {
    min-width: 10.5rem;
    padding-top: .2rem;
    font-size: 1rem;

    &--high {
      height: 3.2rem;
    }

    &--configurator {
      min-width: 12.5rem;
    }

    &__container {
      margin: 3.5rem 0 0 2.7rem;

      &--no-gap {
        margin: 0;
      }

      .button {
        margin: 0 1.9rem 0 0;
      }
    }
  }
}

@include respond-to(desktop-small) {
  .button {
    min-width: 9rem;
    height: 2.5rem;
    padding: .3rem 1.4rem 0;
    font-size: .75rem;

    &--configurator {
      min-width: 16rem;
    }

    &--high {
      height: 3.2rem;
    }

    &__hr {

      &--big {
        height: 2.85rem;
        padding: .3rem 3.95rem 0;
      }
    }
  }
}
