.bp {

  p {
    margin-bottom: 1.5rem;
    font-size: .85rem;
  }

  .radio {
    display: inline-block;
    width: 8rem;
    height: 7.5rem;
    margin: .5rem;

    &:hover {
      .radio__container {
        color: $black;
      }

      .svg-icon * {
        fill: $black;
      }
    }

    .svg-icon {
      width: 4.5rem;
      height: 4.5rem;
      margin-top: -.95rem;
    }

    input {
      position: absolute;
      z-index: -1;
      top: 0;

      &:checked {
        + .radio__container {
          background-color: $blue;
          color: $white;

          .svg-icon * {
            fill: $white;
          }
        }
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin-right: 0;
      background-color: $light-gray;
      color: #4a4a4a;
      font-size: .65rem;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .button--configurator {
    min-height: 3.65rem;
    border-radius: 5px;
    font-size: 1rem;
  }

  .form-section {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  .ng-placeholder {
    opacity: .65;
  }

  .form-element__label {
    margin-bottom: .5rem;
    padding-left: 0;
    font-size: .85rem;
  }

  h6.with-decor {
    margin-bottom: 1.5rem;
  }

  .pull-down__icon {
    margin: 1.5rem auto;
  }

  &-purposes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -.5rem;
  }

  &-size {
    display: flex;
    flex-direction: column;

    &__image {
      width: 100%;
      padding-top: 60%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    &__content {
      width: 100%;
      padding: 0 1.5rem 1.5rem;
      background-color: $light-gray;
    }

    input {
      background-image: url('//svg/pencil-edit-button.svg');
      background-repeat: no-repeat;
      background-position: 95% center;
      background-size: 1.1rem 1.1rem;
    }

    input, .select-dropdown, .ng-arrow-wrapper {
      background-color: rgba(#9b9b9b, .1);
    }

    .ng-value {
      background-color: #ecebec;
    }

    .form-element__container {
      margin-top: 2.3rem;
    }
  }

  &-useful-life {
    justify-content: center;

    .ng5-slider-tick-value {
      display: none;
    }

    .ng5-slider-tick-legend {
      top: 3rem;
      max-width: auto;
      white-space: nowrap;
    }
  }

  &-infos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-result {
    &__wrap {
      justify-content: center;
      margin-bottom: 2rem;

      + .bp-result__wrap {
        margin-top: -2rem;
      }
    }

    .contact__info {
      padding-top: 1.5rem;
    }
  }

  .with-decor--button {
    height: auto;
    margin-top: 1rem;
  }

  .centered-headline {
    margin-bottom: .5rem;
  }

  .checkbox {
    &__container {
      justify-content: center;
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }

    &__label-text {
      font-size: .85rem;
    }
  }
}

@include respond-to(ipad-portrait) {
  .bp {
    .radio {
      width: calc(25% - 1rem);
      height: 9.5rem;

      &__container {
        font-size: .85rem;
      }
    }

    .button--configurator {
      min-width: 21.5rem;
    }

    &-size {
      &__content {
        padding: 0 3.3rem 1.5rem;
      }
    }
  }
}

@include respond-to(ipad-landscape) {
  .bp {
    &-size {
      flex-direction: row;
      align-items: stretch;

      &__image {
        width: 63.5%;
        padding-top: 0;
      }

      &__content {
        width: 36.5%;
      }
    }
  }
}

@include respond-to(desktop-smallest) {
  .bp {
    .radio {
      width: calc(12.5% - 1rem);

      &__container {
        font-size: .85rem;
      }
    }

    .contact {
      height: 31.85rem;
      overflow: hidden;
    }

    .teaser__image {
      height: 31.85rem;
      padding-top: 0;
    }
  }
}

@include respond-to(desktop-small) {
  .bp {
    .contact {
      height: 29rem;
    }

    .teaser__image {
      height: 29rem;
    }
  }
}

@include respond-to(desktop-medium) {
  .bp {
    .contact {
      height: 31.85rem;
    }

    .teaser__image {
      height: 31.85rem;
    }
  }
}
