.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12rem 0 8rem;

  &__icon {
    width: 30vw;
    margin-bottom: 1rem;
    opacity: .1;
  }
}
