.intro {
  padding: 7rem .5rem 0;
  background-color: $light-gray;

  h1,
  h2 {
    @extend %fadein;
    text-align: center;
  }

  h2 {
    padding-bottom: 1.35rem;

    + .icon-button__container {
      margin-top: 0;
    }
  }

  &--flat {
    padding-top: .5rem;
  }

  &--padding-bottom {
    padding-bottom: 3.6rem;
  }

  &--mid-blue {
    background-color: $mid-blue;
  }

  &--light-blue {
    background-color: $light-blue;
  }

  &--lighter-blue {
    background-color: $lighter-blue;
  }

  .teaser {
    margin-bottom: 2rem;

    &__inner p {
      margin-bottom: 1rem;
    }
  }
}

.intro__image {
  display: block;
  margin: 0;
  padding-top: 90.5vw;
  background: center $light-gray;
  background-size: cover;
}

@include respond-to(mobile-landscape) {
  .intro__image {
    padding-top: 68.5vw;
  }
}

@include respond-to(ipad-portrait) {
  .intro {
    &:not(.intro--flat) .icon-button__container {
      margin-top: 2rem;

      &--after-headline {
        margin-top: 0;
      }
    }

    .teaser {
      margin-bottom: 0;
    }

    .teaser__inner {
      p {
        margin-bottom: 0;
      }
    }
  }

  .intro__image {
    padding-top: 48.5vw;
  }
}

@include respond-to(ipad-landscape) {
  .intro {
    &:not(.intro--flat) {
      padding-top: 12.1rem;
    }
  }

  .intro__image {
    padding-top: 33.5vw;

    &--big {
      padding-top: 46.51%;
    }
  }
}

@include respond-to(desktop-small) {
  .intro {
    &:not(.intro--flat) {
      padding-top: 10.1rem;
    }
  }
}
