tc-chosen-dimensions {
  display: block;
  margin-top: 1.5rem;
  margin-bottom: 3.7rem;

  .align-center {
    margin: 0 auto;
  }
}

//.inner-wrap ranger-input {
//  margin-top: 3rem;
//}

.tc-chosen-dimensions {
  align-items: stretch;
  margin-top: 2rem;
  background-color: $tc-blue;

  > div {
    width: 100%;
  }

  &__image {
    display: flex;
    align-items: center;
    background-color: $tc-light-blue;
  }

  .form-section {
    margin: 0;
  }
}

.tc-chosen-dimensions__content {
  padding: 3.5rem 1rem 4.6rem;

  h4 {
    position: relative;
    text-transform: uppercase;

    &::before {
      content: '';
      display: none;
      position: absolute;
      top: -1rem;
      left: -2.7rem;
      width: 0;
      height: 0;
      transform: rotate(360deg);
      border-width: 1.5rem 0 1.5rem 1.5rem;
      border-style: solid;
      border-color: transparent transparent transparent $tc-light-blue;
    }
  }
}

@include respond-to(ipad-portrait) {
  .tc-chosen-dimensions > div {
    width: 50%;
  }
}

@include respond-to(desktop-smallest) {
  .tc-chosen-dimensions__content {
    padding: 3.5rem 2rem 4.6rem;

    h4::before {
      display: block;
    }
  }
}
