.detail-slider__slide {
  @extend %fadein;
  background-position: center;
  background-size: cover;
}

.detail-slider {
  display: block;
  height: 90.5vw;
  background-color: transparent;

  @include respond-to(mobile-landscape) {
    height: 68.5vw;
  }

  @include respond-to(ipad-portrait) {
    height: 46.77vw;
  }

  &.detail-slider__fullscreen-toggleable {
    cursor: pointer;
  }

  &__fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 11;
    margin: 0!important;
    background-color: rgba(255,255,255,0.9);
    padding: 1rem 1rem 1rem 0;

    .swiper-slide.swiper-slide-active {
      width: 100%!important;
      height: auto!important;
      margin: 0;
    }

    .intro-slider__slide-wrap {
      margin-left: 1rem;
      box-shadow: -8px 8px 10px 0 rgba(#000, .1);
    }
  }
}

.close--fullscreen {
  position: fixed;
  top: 2rem;
  left: 2rem;
  z-index: 11;
  background: $blue;
  width: 50px;
  height: 50px;
  padding: 14px;
  pointer-events: none;
}

.detail-slider--flat {
  display: block;
  height: 33.49vw;
  background-color: $light-gray;

  @include respond-to(mobile-landscape) {
    height: 33.49vw;
  }

  @include respond-to(ipad-portrait) {
    height: 33.49vw;
  }
}

.detail-slider--inline {
  height: auto;
  margin-top: 1.1rem;

  .detail-slider__slide--inline {
    height: auto !important;
    padding-top: 46.7%;
  }
}

.bodytext--detail__extra-margin {
  margin-top: 1rem;
}
