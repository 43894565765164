.story-teaser {
  display: flex;
  position: absolute;
  bottom: 2.25rem;
  left: 2.5rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 34%;
  min-height: 12.5rem;
  padding: 1.4rem 1.3rem;

  background-color: #fff;
  color: #000;
  line-height: 1.5rem;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    .button {
      @extend .button--hover;
    }
  }
}
