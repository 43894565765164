.center-block {
  margin-right: auto;
  margin-left: auto;
}

.clear,
.clearfix,
%clearfix,
%clear {
  @include clearfix;
}

.noscroll {
  overflow: hidden;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

$i: 5;
@while $i < 100 {
  .trans-#{$i} {
    opacity: #{$i / 100};
  }

  $i: $i + 5;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-justify {
  text-align: justify;
}

.uppercase {
  text-transform: uppercase;
}

.disabled--mobile {
  display: none;
}

.f-horizontal-centered {
  justify-content: center;
}

@include respond-to(ipad-landscape) {
  .disabled--mobile {
    display: flex;
  }
}
