.newsletter-popup__container {
  @extend %fadein;
  display: flex;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(#fff, .82);
}


.newsletter-popup {
  display: block;
  position: relative;
  width: 80vw;
  padding: 2rem;
  background-color: #fff;


  box-shadow: 0 0 3rem rgba(#000, .1);

  &__close {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    width: 3.15rem;
    height: 3.15rem;
    background-color: $blue;
    transition: background-color .3s ease;
    cursor: pointer;

    .svg-icon {
      width: 1.2rem;
      height: 1.2rem;
    }

    &:hover {
      background-color: #000;
    }
  }

  &__headline--accent {
    margin-bottom: -.3rem;
    opacity: .32;
  }

  &__content {
    margin: .5rem 0 1rem;
    line-height: 150%;
  }
}

@include respond-to(ipad-landscape) {
  .newsletter-popup {
    width: 55.8vw;
    min-height: 27.1vw;
    background: #fff url('/images/newsletter-popup.png') no-repeat top left;
    padding: 9vw 1vw 2vw 31vw;
    background-size: contain;
  }
}
