.hr-call-to-action {

  .section {
    padding-top: 2.5rem;
    padding-bottom: 4.1rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__headline,
  &__bodytext,
  &__call {
    color: $blue;
    text-align: center;
  }

  &__headline {
    margin-bottom: .85rem;
  }

  &__headline,
  &__call {
    font-size: 1.125rem;
    line-height: 1.94;
  }

  &__bodytext {
    @extend %bold;
    margin-bottom: .45rem;
    font-size: 1.5rem;
    line-height: 1.4;
  }

  &__call {
    margin-bottom: 1.8rem;
  }
}

@include respond-to(ipad-portrait) {
  .hr-call-to-action {

    .section {
      padding-top: 3.42rem;
      padding-bottom: 3rem;
    }

    &__bodytext {
      max-width: 42.7rem;
      margin-bottom: .3rem;
      font-size: 2.14rem;
      line-height: 1.1;
    }

    &__headline,
    &__call {
      font-size: 1.14rem;
    }

    &__call {
      margin-bottom: 1.2rem;
    }

    &__headline {
      margin-bottom: .2rem;
    }
  }
}

@include respond-to(desktop-smallest) {
  .hr-call-to-action {

    .section {
      padding-top: 4.35rem;
      padding-bottom: 5.65rem;
    }

    &__bodytext {
      max-width: 56rem;
      margin-bottom: 2.05rem;
      font-size: 3rem;
    }

    &__headline {
      margin-bottom: 1.85rem;
      font-size: 1.5rem;
      line-height: 1.75rem;
    }

    &__call {
      margin-bottom: 2.6rem;
      font-size: 1.5rem;
    }
  }
}
