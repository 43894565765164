.image-text-combo {
  @extend %no-gutters;
  @extend %fadein;
  display: block;
  margin-top: 2.75rem;

  &:first-child {
    margin-top: 0;
  }

  &__image {
    display: block;
    position: relative;
    height: 47vw;
    background: no-repeat center;
    background-size: cover;
  }

  &__icon {
    position: absolute;
    bottom: 1.75rem;
    left: 1.75rem;
  }

  &__container--narrow {
    width: 88%;
    margin: 0 auto;
  }

  &--reverse {
    div:first-child {
      order: 2;
    }

    div:last-child {
      order: 1;
    }

    .image-text-combo__icon {
      right: 1.75rem;
      left: auto;
    }
  }
}

.image-text-combo__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 2.1rem;
  background-color: $light-gray;

  h3 {
    padding-bottom: .7rem;
    font-size: 1.5rem;
  }

  p {
    width: 100%;
    padding-bottom: 1.4rem;
    font-size: 1.1rem;
    line-height: 130%;
  }

  &--white {
    background-color: #fff;

    p {
      font-size: 1rem;
      line-height: 160%;
    }
  }
}

@include respond-to('ipad-portrait') {
  .image-text-combo {
    display: flex;
    align-items: stretch;
    margin: 0;

    &__image {
      height: 100%;
    }

    &__text {
      min-height: 24rem;
      padding: 3.2rem 3.5rem;

      h3 {
        padding-bottom: .2rem;
        font-size: 1.4rem;
      }
    }
  }
}

@include respond-to(desktop-small) {
  .image-text-combo {
    &__text {
      min-height: 22rem;

      h3 {
        padding-bottom: .3rem;
      }

      p {
        font-size: .85rem;
      }
    }
  }
}
