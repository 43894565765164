.section--container-comparison {
  button, .button--container-comparison {
    cursor: pointer;
  }
}

.container-comparison-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  line-height: 2.75rem;
  height: 2.75rem;
  font-size: 1rem;
  background-color: $light-gray;
  margin-bottom: 1px;

  .icon-section {
    display: flex;
    justify-content: center;
  }

  h4 {
    font-size: 0.8rem;
    opacity: 0.7;
    line-height: 3rem;

    @include respond-to(ipad-landscape) {
      font-size: 1rem;
    }
  }

  .button--container-comparison:first-child {
    margin-right: 1px;
  }
}

.slider--close-zoom {
  display: none;
}

.container-comparison-image {
  background: $light-gray;
  height: 100%;

  button {
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 1rem;
    top: 1rem;
    z-index: 1;
    cursor: pointer;

    &.slider--open-zoom {
      display: none;
      left: 0;
      flex-direction: row;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }

      span {
        font-size: 0.65rem;
        line-height: 1.25rem;
      }
    }
  }

  .open-zoom-button--hover-area {
    position: absolute;
    top: 5%;
    bottom: 22%;
    left: 5%;
    right: 5%;
    z-index: 1;

    &:hover .slider--open-zoom {
      display: flex;
    }
  }
}

.threesixty__container--multi-setup.zoom-out {
  @extend %fadein;
  position: fixed;
  left: 3%;
  top: 3%;
  right: 3%;
  z-index: 10;
  box-shadow: -8px 8px 10px 0 rgba(#000, .1);

  .threesixty__inner-container {
    bottom: 2%;
  }

  .slider--open-zoom {
    display: none !important;
  }

  .container__title {
    display: block;
    position: relative;
    font-size: 1rem;
    text-transform: uppercase;
    left: 1.5rem;

    @include respond-to(ipad-landscape) {
      font-size: 1.5rem;
    }
  }

  .slider--close-zoom {
    display: block;
    background: $blue;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 50px;
    height: 50px;

    .svg-icon {
      position: relative;
      top: 3px;
      height: 20px;
      width: 20px;
    }
  }
}

.container-comparison-criteria-header {
  background-color: $contrast-gray;;
  padding: 1.1rem;
  position: relative;
  z-index: 1;
  margin-top: 1px;

  &:after {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 12px solid #eaeaea;
    content: "";
    position: absolute;
    bottom: -11px;
    left: 1.5rem;
  }

  p {
    font-size: 0.85rem;
    opacity: 0.8;
  }
}

.container-comparison-linkbox {
  background-color: $contrast-gray;;
  position: relative;
  margin-top: 2rem;
  padding: 1.25rem;

  h4 {
    max-width: 75%;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .info-icon {
    width: 11px;
    height: 11px;
    display: inline-block;
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
  }

  .button {
    min-width: 7rem;

    @include respond-to(mobile-landscape) {
      min-width: 9rem;
    }
  }
}

.container-comparison-criteria-content {
  position: relative;
  background-color: $light-gray;
  padding: 1.5rem 1.35rem 1.2rem;
  width: 100%;

  +.container-comparison-criteria-content {
    margin-top: 1px;
  }

  h5 {
    font-size: 0.8rem;
    opacity: 0.4;
    margin-bottom: 4px;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  p {
    font-size: 0.8rem;
  }

  .infobox-container-comparison {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 35px;
    width: 35px;
    background: $blue;

    svg {
      position: relative;
      width: 13px;
      height: 13px;
      top: 2px;
    }
  }
}

.button--container-comparison__arrow-dropdown {
  padding: 0 1rem;
}

.button--container-comparison__arrow-remove {
  .svg-icon {
    position: relative;
    width: 1.25rem;
  }
}


.overlay__container-comparison {
  background-color: rgba(255, 255, 255, 0.85);

  .overlay-container-comparison__content {
    padding: 2rem 6rem 2rem 2rem;
    background: #fff;

    h3 {
      margin-bottom: 0.75rem;
    }
  }

  .overlay__content-wrap {
    background-color: #fff;
    box-shadow: -8px 8px 10px 0 rgba(#000, .1);
  }

  .bodytext {
    font-size: 0.8rem;
  }

  .popup-container-comparison__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background: $blue;

    .svg-icon {
      position: relative;
      top: 3px;
      height: 20px;
      width: 20px;
    }
  }
}

.wrap__align-sameheight {
  align-items: stretch;
  margin-bottom: 1px;

  .container-criteria__wrap {
    display: flex;
  }
}