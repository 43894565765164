.badge {
  display: inline-flex;
  align-items: center;
  height: .9rem;
  padding: 0 .45rem;
  transition: background-color .2s ease, color .2s ease;
  border-radius: .2rem;
  background: rgba(#d8d8d8, .5);
  color: rgba(#000, .7);
  font-size: .55rem;
}
