.hr-statement-slider > .swiper.s-wrapper .swiper-wrapper .swiper-slide {
  height: auto;
}
.hr-statement {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  color: $blue;

  &--standalone {
    margin-bottom: 1rem;
  }

  &.hr-statement__link-slide {
    background-color: $blue;
    color: #fff;
    cursor: pointer!important;
    text-align: center;

    &:hover {
      background-color: #19619D;
    }

    a {
      color: #fff;
      text-decoration: none!important;
    }

    .hr-statement__inner {
      padding-top: 8rem;
    }

    .svg-icon {
      height: 5rem;
      width: 5rem;
      margin-bottom: 1.5rem;
    }
  }

  &__container {
    width: 100%;
  }

  &__logo {
    z-index: 4;
    height: 2rem;
    margin-left: auto;

    .svg-icon {
      width: auto;
      height: 2rem;

      svg {
        width: auto;
        height: 2rem;
      }

      svg .logo--full-white{
        transition: fill .5s ease;
       }
    }

    &--active {
      svg .logo--full-white{
        fill: $blue;
      }
    }
  }

  &__nav-box {
    position: relative;
    background-color: $blue;
    color: #fff;

    .hr-statement__inner {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-bottom: 2.35rem;
    }

    .hr-statement__inner--bottom {
      p {
        margin-bottom: 1.1rem;
      }
    }
  }

  &__back-box {
    height: 6rem;
    padding: 1rem 0 1.5rem 0;
  }

  &__back-button {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    color: $blue;
    font-size: 1.25rem;
    line-height: 1.2rem;
    text-decoration: none;

    .svg-icon {
      width: 1.8rem;
      height: 1.5rem;
      margin-bottom: .8rem;

      svg {
        width: 1.8rem;
        height: 1.5rem;
      }
    }

    &:hover {
      opacity: .7;
    }
  }

  &__inner {
    padding: 1.4rem 1.55rem 2rem;

    &--top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
    }
  }

  &__intro {
    max-width: 10rem;
    margin-top: 1.3rem;
    font-size: .9375rem;
    line-height: 1.25;
  }

  &__portrait {
    width: 8.7rem;
    height: 8.7rem;
    border-radius: 100%;
    background-blend-mode: multiply;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__quote {
    @extend %bold;
    font-size: 2rem;
    line-height: 1;
  }

  &__section {
    .section {
      padding-top: 1.7rem;
      padding-bottom: 0;
    }
  }

  &__slider-headline {
    width: 100%;
    max-width: 17.25rem;
    margin: 0 auto .8rem;
    color: $blue;
    font-size: 1.125rem;
    line-height: 1.2;
    text-align: center;
  }

  &.green {
    background-color: #0fd774;

    .hr-statement__portrait {
      background-color: #0fd774;
    }

    .audio-player__container {
      background-color: #57D798;
      .audio-player__title {
        background-color: #57D798;
      }

      &:hover {
        background-color: #73D7A5;
        .audio-player__title {
          background-color: #73D7A5;
        }
      }
    }
  }

  &.lightblue {
    background-color: #0fd7cf;

    .hr-statement__portrait {
      background-color: #0fd7cf;
    }

    .audio-player__container {
      background-color: #85D7D4;
      .audio-player__title {
        background-color: #85D7D4;
      }

      &:hover {
        background-color: #97D7D4;
        .audio-player__title {
          background-color: #97D7D4;
        }
      }
    }
  }

  &.yellow {
    background-color: #d7b50f;

    .hr-statement__portrait {
      background-color: #d7b50f;
    }

    .audio-player__container {
      background-color: #D7C157;
      .audio-player__title {
        background-color: #D7C157;
      }

      &:hover {
        background-color: #D7C673;
        .audio-player__title {
          background-color: #D7C673;
        }
      }
    }
  }

  &.brown {
    background-color: #ab9778;

    .hr-statement__portrait {
      background-color: #ab9778;
    }

    .audio-player__container {
      background-color: #B3A38B;
      .audio-player__title {
        background-color: #B3A38B;
      }

      &:hover {
        background-color: #B9AC98;
        .audio-player__title {
          background-color: #B9AC98;
        }
      }
    }
  }

  &.red {
    background-color: #cc4747;

    .hr-statement__portrait {
      background-color: #cc4747;
    }

    .audio-player__container {
      background-color: #ce6363;
      .audio-player__title {
        background-color: #ce6363;
      }

      &:hover {
        background-color: #cf7777;
        .audio-player__title {
          background-color: #cf7777;
        }
      }
    }
  }

  &.orange {
    background-color: #f26946;

    .hr-statement__portrait {
      background-color: #f26946;
    }

    .audio-player__container {
      background-color: #EC7F63;
      .audio-player__title {
        background-color: #EC7F63;
      }

      &:hover {
        background-color: #E88E77;
        .audio-player__title {
          background-color: #E88E77;
        }
      }
    }
  }

  &.eosin {
    background-color: #D23F63;

    .hr-statement__portrait {
      background-color: #D23F63;
    }

    .audio-player__container {
      background-color: #d35d7a;
      .audio-player__title {
        background-color: #d35d7a;
      }

      &:hover {
        background-color: #D3728A;
        .audio-player__title {
          background-color: #D3728A;
        }
      }
    }
  }
}

.hr-statement-slider {
  @include slider-navigation();
  display: block;
  flex: 1;
  max-width: 100%;
  margin: 0 auto;
  background-color: transparent;

  &--slide {
    cursor: ew-resize;
    height: 100%;
  }

  &--slide-wrap {
    overflow: hidden;
    height: 100%;
  }

  &__navigation {
    top: 0;
    right: -.4rem;
    font-size: 1rem;
  }

  &__arrow {
    .svg-icon {
      height: 1.55rem;
    }

    &--next {
      padding-right: 0;
    }
  }
}

@include respond-till(ipad-portrait) {
  .hr-statement-slider {
    &__navigation {
      position: static;
      justify-content: center;
      width: 100%;
      margin-bottom: 1.2rem;
    }
  }
}

@include respond-to(ipad-portrait) {
  .hr-statement {
    &__slider-headline {
      max-width: none;
      margin-bottom: 1.5rem;
      font-size: 1.14rem;
    }

    &__inner {
      padding: 1.2rem 1.55rem 2.64rem;

      &--top {
        margin-bottom: 3.75rem;
      }
    }

    &__intro {
      font-size: .86rem;
    }

    &__portrait {
      width: 7.64rem;
      height: 7.64rem;
    }

    &__quote {
      font-size: 1.86rem;
    }

    &__container {
      display: grid;
      grid-gap: 1.5rem;
      grid-template-columns: repeat(12, 1fr);
      grid-auto-rows: 6rem;

      > * {
        grid-column: span 6;
      }
    }

    &__section {
      .section {
        padding-top: 2.55rem;
      }
    }

    &.grid--half {
      grid-row-end: span 3;
    }

    &.grid--full {
      grid-row-end: span 4;
    }

    &--standalone {
      margin-bottom: 0;
    }
  }
}

@include respond-to(ipad-landscape) {
  .hr-statement {
    &__container {
      > * {
        grid-column: span 4;
      }
    }
  }
}

@include respond-to(desktop-smallest) {
  .hr-statement {
    &__slider-headline {
      margin-bottom: 2rem;
      font-size: 1.5rem;
    }

    &.hr-statement__link-slide {
      .hr-statement__inner {
        padding-top: 11.5rem;
      }
    }

    &__inner {
      padding: 1.75rem 2.15rem 3.75rem 2.5rem;
    }

    &__intro {
      font-size: 1rem;
    }

    &__portrait {
      width: 10rem;
      height: 10rem;
    }

    &__quote {
      font-size: 2.5rem;
    }

    &__section {
      .section {
        padding-top: 3.25rem;
      }

      &--standalone {
        .section {
          padding-top: 4.5rem;
        }
      }
    }

    &.grid--full {
      grid-row-end: span 5;
    }
  }
}
