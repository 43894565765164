.infobox {
  flex-basis: 100%;
  text-align: center;

  &__text {
    margin: 0 0 1rem;
    font-size: 1.2rem;
    line-height: 120%;

    &.infobox__text--smaller-text {
      font-size: 1rem;
    }
  }

  &__icon {
    width: .7rem;
    height: 1.5rem;
  }

  &__container {
    display: flex;
    justify-content: center;

    .col-12 {
      display: flex;
      justify-content: center;
    }
  }

  &--without-icon {
    padding-top: 3.5rem;
  }
}

@include respond-to(mobile-landscape) {
  .infobox {
    flex-basis: 75%;
  }
}

@include respond-to(ipad-landscape) {
  .infobox {
    flex-basis: 50%;

    &__text {
      margin: .5rem 0 2.7rem;
      font-size: 2rem;
    }
  }
}
