form {
  h6.with-decor {
    margin-bottom: 2.5rem;
  }
}

.form-section {
  margin-top: -1.8rem;
  margin-bottom: 3.7rem;
  transition: opacity .2s ease;

  &--no-top-margin {
    margin-top: 0;
  }

  &--disabled {
    position: relative;
    pointer-events: none;
    opacity: .1;
  }

  &--ranger {
    margin-top: -3rem;
    margin-bottom: 0;
  }

  .form-icon--edit {
    position: absolute;
    right: .8rem;
    bottom: 0;
    transform: translateY(-.2rem);

    svg {
      height: 1.5rem;
    }
  }

  .input {
    width: 100%;
  }

  &__subline {
    width: 90%;
    margin: 0 auto 2.1rem;
    line-height: 1.6rem;
    text-align: center;

    + app-pull-down .pull-down {
      margin-bottom: 4.45rem;
    }
  }

  &__legend {
    padding-top: .8rem;
    font-size: .85rem;
  }

  &__mykleusberg {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-top: 2rem;
  }
}

.form-element {
  &__container {
    display: block;
    position: relative;
    margin-top: 1.8rem;

    &--text {
      margin-top: 0;

      h6 {
        margin-bottom: .2rem;
        font-size: .85rem;
        font-weight: 600;
        text-transform: none;
      }
    }

    &--no-gap-top {
      margin-top: 0;
    }

    &--no-label {
      margin-top: 71px;
    }
  }

  &__label {
    display: inline-flex;
    margin-bottom: .6rem;
    padding-left: .5rem;

    &--required::after {
      content: '*';
      align-self: flex-start;
      margin-left: .1rem;
    }
  }

  &__legend {
    position: absolute;
    bottom: .8rem;
    left: 3.1rem;
    font-size: .75rem;
  }
}


@include respond-to(ipad-landscape) {
  .form-section__subline {
    width: 62%;
  }
}
