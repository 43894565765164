.select-dropdown {
  flex-basis: 100%;
  height: 3.75rem;
  padding: 0 0 0 1rem;
  background-color: $white;
  line-height: 3.75rem;

  &.ng-invalid.ng-touched {
    border: 1px solid $error;

    .ng-arrow-wrapper {
      border-bottom: 1px solid $error;
    }
  }

  &__container {
    margin-bottom: 1rem;
  }

  &__container--no-label {
    margin: 3rem auto 1rem; 
  }

  .ng-value {
    position: absolute;
    width: 100%;
    background-color: $white;
  }

  ng-dropdown-panel {
    left: 0;
    height: auto;
    margin: 0;
    padding-top: .5rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    background-color: $light-gray;
    line-height: 2rem;

    box-shadow: 0 3px 2px rgba(#000, .3);
  }

  .ng-value-container,
  ng-option {
    cursor: pointer;
  }

  .ng-arrow-wrapper {
    height: calc(2.5rem - 1px);
    margin-right: 1.65rem;
  }

  .ng-arrow-wrapper::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 6px;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 8 4' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.667 4a.524.524 0 0 1-.363-.144L.15.84a.477.477 0 0 1 0-.695.53.53 0 0 1 .727 0l2.79 2.67 2.79-2.67a.53.53 0 0 1 .726 0c.2.192.2.503 0 .695L4.03 3.856A.524.524 0 0 1 3.667 4z' fill='000' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }

  &.ng-select-opened {
    .ng-arrow-wrapper::before {
      transform: rotateX(180deg);
    }
  }

  .ng-placeholder {
    background-color: transparent;
  }

  .ng-input {
    line-height: 2.3rem;

    input {
      line-height: 0;
    }
  }
}

.select-dropdown--no-shadow {
  ng-dropdown-panel {
    box-shadow: none;
  }
}

.select-dropdown--gray {
  height: 2.5rem;
  background-color: $light-gray;
  line-height: 2.5rem;
  text-align: left;

  .ng-value {
    background: $light-gray;
  }

  .ng-arrow-wrapper {
    width: 2.7rem;
    margin: 0;
    background-color: #eceaec;
  }

  @include respond-to(mobile) {
    font-size: 16px;
  }

  @include respond-to(ipad-portrait) {
    font-size: .75rem;
  }
}

.select-dropdown--white-plus {
  height: 2.75rem;
  font-size: 1rem;
  background-color: $light-gray;
  text-transform: uppercase;

  .ng-value {
    display: none;
  }

  .ng-placeholder {
    font-size: 0.8rem;
    line-height: 1.6rem;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

    @include respond-to(ipad-landscape) {
      font-size: 1rem;
    }
    @include respond-to(desktop-small) {
      line-height: 2rem;
    }
  }

  .ng-arrow-wrapper {
    background-color: $blue;
    height: 2.75rem;
    width: 2.75rem;

    &:before {
      background: none;
    }

    .ng-arrow {
      pointer-events: none;
      display: inline-block;
      height: 0;
      width: 0;
      position: relative;
      margin: 0;
      left: -1.1rem;
      bottom: 0.2rem;
    }

    .ng-arrow:before {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1px;
      background-color: #fff;

    }

    .ng-arrow:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1px;
      background-color: #fff;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }

  .ng-option {
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      right: 23px;
      top: 13px;
      width: 8px;
      height: 8px;
      border-top: solid 2px currentColor;
      border-right: solid 2px currentColor;
      transform: rotate(45deg);
    }
  }


  .ng-option-disabled span {
    opacity: 0.2;
  }
}

.select-dropdown__swap-container.ng-select {
  position: unset;
  padding: 0;

  .ng-value-container {
    display: none!important;
  }

  .ng-arrow-wrapper {
    width: 2.75rem;
    height: 2.75rem;
  }

  .ng-option {
    font-size: 1rem;
  }

  .ng-option-disabled span {
    opacity: 0.2;
  }
}

.select-dropdown--light {
  height: 2.5rem;
  background-color: $white;
  line-height: 2.5rem;
  text-align: left;

  .ng-value {
    background: $white;
  }

  .ng-arrow-wrapper {
    width: 2.7rem;
    margin: 0;
    background-color: #eceaec;
  }

  @include respond-to(mobile) {
    font-size: 16px;
  }

  @include respond-to(ipad-portrait) {
    font-size: .75rem;
  }
}

.select-dropdown--shadow {
  height: 3.4rem;
  background-color: #fff;
  box-shadow: inset 0 1px 2px 0 rgba(#000, .5);
  line-height: 3.4rem;

  .ng-value {
    display: flex;
    top: 22%;
    align-items: center;
    width: 80%;
    height: 70%;
  }

  .ng-placeholder {
    height: 3.4rem;
    line-height: 3.8rem;
  }
}

.reference-filter-wrap__input-label {
  input[disabled] {
    cursor: not-allowed;
  }
}


.reference-filter__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  overflow: visible;
  justify-content: center;


  @include respond-to(ipad-landscape) {
    margin-top: 0;
  }

  .reference-filter-wrap {
    display: flex;
    box-shadow: 6px 6px 6px -1px rgba(#000, .1);
    margin-right: 1rem;
    margin-top: 2.05rem;
    position: relative;
    width: 95%;

    &.reference-filter-wrap__filter-button {
      width: auto;

      .reference-filter__label {
        width: auto;
      }
    }
  }

  .reference-filter__label {
    line-height: 2.5rem;
    height: 2.5rem;
    font-size: 0.7rem;
    text-indent: 1rem;
    opacity: 0.3;
    overflow: auto;
    width: 35%;
  }

  .select-dropdown__reference-filter {
    flex-basis: auto;
    line-height: 2.5rem;
    height: 2.5rem;
    font-size: 0.7rem;
    padding-right: 5rem;
    width: 65%;

    .ng-select-container {
      position: unset;

      .ng-value {
        right: 0;
        text-indent: 1rem;
      }
    }

    .ng-arrow-wrapper {
      position: absolute;
      right: 1rem;
      height: calc(2rem - 1px);
      margin: 0;

      &:before {
        width: 10px;
        height: 5px;
      }
    }

    .ng-dropdown-panel {
      background-color: #fff;
      box-shadow: 6px 6px 6px -1px rgba(#000, .1);
    }

    &.ng-select-disabled {
      opacity: 0.2;

      .ng-input {
        cursor: not-allowed;
      }

      .ng-arrow-wrapper {
        display:none!important;
      }
    }

    &.select-dropdown__reference-filter-smaller {
      position: relative;
    }

    &.select-dropdown__zipcodes {
      width: 120px;
      padding-right: 0;

      @include respond-to(mobile-landscape) {
        width: 160px;
      }

      .ng-input {
        margin-left: 15px;
        margin-top: 2px;
        max-width: 75%;

        @include respond-to(ipad-portrait) {
          margin-left: 13px;
        }

        @include respond-to(desktop-small) {
          margin-left: 17px;
        }
      }

      .ng-arrow-wrapper {
        display:none;
      }

      .ng-dropdown-panel {
        position: absolute;
        min-width: 150px;
        width: auto;
        left: 0;
        top: 35px;
        padding-right: 1rem;
      }
    }
  }

  .reference-filter__input {
    font-size: 0.7rem;
    text-indent: 0.25rem;
    width: 3rem;
    padding: 0;
    background: transparent;
  }

  @include respond-to(mobile-landscape) {
    justify-content: space-between;

    .reference-filter-wrap {
      width: 46%;
    }

    .reference-filter__label {
      width: 40%;
    }

    .select-dropdown__reference-filter {
      width: 60%;
    }

    .reference-filter-wrap--zipcodes .reference-filter__label {
      width: calc(40% - 30px);
    };

    .reference-filter__label {
      line-height: 2.5rem;
      height: 2.5rem;
      font-size: 0.7rem;
      text-indent: 1rem;
      opacity: 0.3;
      overflow: hidden;
    }
  }

  @include respond-to(ipad-portrait) {
    justify-content: center;

    .reference-filter-wrap {
      width: auto;
    }

    .reference-filter-wrap--zipcodes {
      margin-right: 0;
    }
  }

  @include respond-till(mobile-landscape) {
    .reference-filter__label, .select-dropdown__reference-filter {
      font-size: 0.85rem;
    }
  }
}

.reference-filter-wrap--zipcodes {
  padding-right: 5px;

  .reference-filter__label {
    text-indent: 0.7rem;
    width: calc(35% - 43px)
  }
}
