.zukunft-raum {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 2rem auto 0;
}

.zukunft-raum-teaser {
  display: block;
  position: relative;
  height: 22.5rem;
  background-size: cover;
  text-decoration: none;

  &:hover .zukunft-raum-button {
    background-color: #0058b1;
  }
}

.zukunft-raum-teaser__inner {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  max-width: 22rem;
  padding: 2rem 2.25rem 6rem;
  background-color: #fff;
  color: #000;
  text-align: left;

  h3 {
    font-size: 1.3rem;
    text-transform: none;
  }

  p {
    font-size: .9rem;
    line-height: 165%;
  }

  .zukunft-raum-button {
    position: absolute;
    bottom: 1.5rem;
    left: 2rem;
  }
}

.zukunft-raum-teaser--layout-0 {
  grid-column: span 4;

  .zukunft-raum-teaser__inner {
    p {
      display: none;
    }
  }
}

.zukunft-raum-teaser--layout-1 {
  grid-column: span 4;

  .zukunft-raum-teaser__inner {
    top: 0;
    left: 0;
    width: 100%;
    max-width: inherit;
    height: 100%;
    background-color: $blue;
    color: #fff;

    .zukunft-raum-button {
      bottom: 2.5rem;
      background-color: rgba(#fff, .2);
    }
  }
}

.zukunft-raum-teaser--layout-2 {
  grid-column: span 4;

  .zukunft-raum-teaser__inner {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;

    .zukunft-raum-button {
      bottom: 2.5rem;
    }
  }
}

.zukunft-raum-teaser--layout-3 {
  grid-column: span 4;

  .zukunft-raum-teaser__inner {
    p {
      display: none;
    }
  }
}

.zukunft-raum-button {
  display: inline-block;
  position: relative;
  height: 3rem;
  padding: 0 2.5rem;
  transition: background-color .3s ease;
  border: 0;
  background-color: $blue;
  color: #fff;
  line-height: 3rem;
  cursor: pointer;
}

@include respond-to(mobile-landscape) {
  .zukunft-raum-teaser--layout-1 {
    height: auto;
    min-height: 14rem;
  }
}

@include respond-to(ipad-landscape) {
  .zukunft-raum-teaser {
    .zukunft-raum-teaser__inner {
      width: 42%;
    }

    &--layout-0 {
      grid-column: span 2;
    }

    &--layout-1 {
      grid-column: span 1;
      height: 22.5rem;

      .zukunft-raum-teaser__inner {
        width: 100%;
      }
    }

    &--layout-3 {
      grid-column: span 1;

      .zukunft-raum-teaser__inner {
        width: calc(100% - 2rem);
      }
    }

    &--layout-2 {
      grid-column: span 1;

      .zukunft-raum-teaser__inner {
        width: 100%;
      }
    }
  }
}

