.legal {
  display: flex;
  flex-direction: column;
}

.legal__item {
  margin-top: 1.2rem;

  &:first-child {
    margin-top: 0;
  }

  a {
    color: rgba(#000, .7);
    font-size: .9rem;
    text-decoration: none;
  }
}

@include respond-to(ipad-portrait) {
  .legal {
    flex-direction: inherit;
  }

  .legal__item {
    height: 1.1rem;
    margin-top: 0;
    margin-right: .5rem;
    padding-right: .5rem;
    border-right: 1px solid rgba(#000, .7);

    &:last-child {
      margin: 0;
      padding: 0;
      border: 0;
    }
  }
}
