.teaser-infobox {
  display: flex;
  align-items: center;
  padding: .8rem 1.75rem .8rem 1rem;
  background-color: $white;
  font-size: .75rem;

  .svg-icon {
    width: 2rem;
    height: 2rem;
    margin: -.5rem 0 0 1rem;
  }

  &__icon-info {
    &.svg-icon {
      width: .5rem;
      height: inherit;
      margin: 0 .6rem 0 0;
    }
  }

  &__label {
    margin: .4rem 2rem 0 .6rem;
    text-transform: uppercase;
  }

  &__slides {
    margin-top: .4rem;
  }

  &--in-col {
    position: absolute;
    z-index: 2;
    top: 2.6rem;
    left: 2.35rem;
  }
}
