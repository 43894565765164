.sidebar {
  padding: 2.2rem 2rem;
  background-color: #f4f4f5;

  font-size: .85rem;
  line-height: 130%;

  p {
    margin-bottom: .85rem;
  }
}
