.expansion-panel {
  display: flex;
  flex-direction: column;
  margin-top: -1px;
  transition: background-color .3s ease-in;
  border: 1px solid $border-color;
  border-right: 0;
  border-left: 0;

  .expansion-panel__header {
    display: flex;
    position: relative;
    align-items: center;
    padding: 1.4rem 3.75rem 1rem;
    text-transform: uppercase;
    cursor: pointer;

    i {
      position: absolute;
    }

    svg {
      width: 1.1rem;
      height: 1.1rem;
      transition: transform .3s ease-in;
    }

    &--opened .expansion-panel__icon-arrow svg {
      transform: rotateZ(90deg);
    }

    &--opened .expansion-panel__icon-plus {
      transform: rotateZ(90deg) translateX(-50%) scale(1.2);
    }

    &--opened .expansion-panel__icon-plus::before {
      opacity: 0;
    }
  }

  &--white {
    &.expansion-panel--opened {
      background-color: $white;
    }
  }

  &--career-variant {
    .expansion-panel__header {
      text-transform: inherit;
    }

    .expansion-panel__content {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__career-content-wrap {
    padding-left: 1.9rem;

    .vacancy-search__button {
      margin-left: 0;
    }
  }

  &--opened {
    padding-bottom: 1.5rem;
    background-color: rgba($light-gray, .5);
  }

  .expansion-panel__content {
    height: 0;
    padding: 0 1.3rem;
    overflow: hidden;
    transition: height .3s ease-in;
  }

  &__icon-arrow {
    left: 1.3rem;
    transform: scale(1.5);
  }

  &__icon-plus {
    display: inline-flex;
    top: 50%;
    right: 1.3rem;
    transform: translateY(-50%) scale(1.2);
    transform-origin: 50% 50%;
    transition: transform .3s ease-in;

    &::before,
    &::after {
      content: '';
      display: block;
      border-radius: 3px;
      background-color: $black;
      font-style: initial;
    }

    &::before {
      align-self: center;
      width: 1rem;
      height: 2px;
      transition: opacity .3s ease-in;
      opacity: 1;
    }

    &::after {
      width: 2px;
      height: 1rem;
      margin-left: -1px;
      transform: translateX(-.5rem);
    }
  }

  &__image {
    margin-bottom: 1rem;
    padding-top: 50%;
    background-size: cover;
  }
}

@include respond-to(ipad-portrait) {
  .expansion-panel {
    &__header:not(.sitemap__headline) {
      font-size: 1.5rem;
    }


    &--career-variant {
      .expansion-panel__content {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .expansion-panel__content-inner {
      padding: 1.25rem 0;
    }
  }
}

@include respond-to(ipad-landscape) {
  .expansion-panel {
    .expansion-panel__content-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--without-image {
        .expansion-panel__text {
          width: 75%;
        }
      }
    }

    .expansion-panel__image {
      flex-shrink: 0;
      order: 2;
      width: calc(60% - 2.75rem);
      margin-right: 3rem;
      padding-top: 30.8%;
    }

    .expansion-panel__text {
      padding-right: 2.5rem;
    }

    &__career-content-wrap {
      padding-left: 4rem;
    }
  }
}

@include respond-to(desktop-smallest) {
  .expansion-panel--career-variant {
    .vacancy-search__button {
      margin-left: 0;
      float: right;
    }
  }
}

.expansion-panel--only-mobile {
  i {
    display: none;
  }
}

@media all and (max-width: 766px) {
  .expansion-panel--only-mobile {
    display: flex;
    flex-direction: column;
    margin-top: -1px;
    border: 1px solid $border-color;
    border-right: 0;
    border-left: 0;

    &.expansion-panel--opened {
      padding-bottom: 0;
    }

    .expansion-panel__header {
      position: relative;
      padding-top: 1rem;
      cursor: pointer;

      i {
        display: inline;
      }

      svg {
        position: absolute;
        right: .4rem;
        width: auto;
        height: .4rem;
        transform: translateY(100%);
        transition: transform .3s ease-in;
      }

      &--opened svg {
        transform: translateY(100%) rotateX(180deg);
      }
    }

    .expansion-panel__content {
      height: 0;
      overflow: hidden;
      transition: height .3s ease-in;
    }
  }
}
