// Do NOT use rem-values in this file

.cta__container {
  @extend %fadein;
  display: flex;
  position: fixed;
  z-index: 3;
  top: 50vh;
  right: 0;
  flex-direction: column;
  align-items: flex-end;
  transform: translateY(-50%);
  transition: right .3s ease;
}

.cta__container--invisible {
  @extend .cta__container--collapsed;
  right: -200px;
}

.cta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 182px;
  height: 44px;
  margin-bottom: 1px;
  padding: 0 18px;
  transition: background-color .2s ease;
  background-color: #000;
  text-decoration: none;
  cursor: pointer;

  &__label {
    flex-grow: 1;
    width: 100%;
    margin-top: 4px;
    transition: color .2s ease;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
  }

  &__icon {
    flex-shrink: 0;
    width: 22px;
    height: 22px;
  }

  &:hover {
    background-color: lighten(#000, 25);
  }
}

.cta__container--collapsed {
  .cta {
    min-width: 0;

    .cta__label {
      flex-grow: 0;
      width: 0;
      overflow: hidden;
    }
  }
}

.cta--blue {
  background-color: $blue;

  .cta__label {
    color: #fff;
  }

  &:hover {
    background-color: lighten($blue, 5);
  }
}
