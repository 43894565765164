.main-header {
  display: none;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate3d(0, 0, 0);
  transition: transform .5s ease, top 1s ease;
  pointer-events: none;

  > .wrap {
    z-index: 1;
  }

  &--compact {
    transform: translate3d(0, -2.8rem, 0);

    .logo {
      width: 7rem;
    }

    &.slide-in-popup__active {
      top: 0;
      position: fixed;
      transition: none;
    }

    .breadcrumb {
      background-color: #fbfafa;
    }
  }

  &--no-shadow {
    .main-header__inner::after {
      box-shadow: none;
    }
  }
}

.main-header__inner {
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: #fff;
  pointer-events: visible;

  &::after {
    content: '\A';
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    background: rgba(#fff, .49);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
  }
}

.main-header__level {
  padding: 0 0 0 1.3rem;
}

.main-header__level--1 {
  @extend %flex-helper;
  @extend %no-gutters;
  display: none;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.6rem;

  .tool-nav {
    justify-content: center;
  }
}

.main-header__level--2 {
  @extend %flex-helper;
  @extend %no-gutters;
  align-items: flex-end;

  nav {
    display: flex;
  }
}

.kle {
  &__container {
    display: flex;
    flex-wrap: wrap;

    .button {
      margin-top: 2rem;
      margin-right: 1.65rem;
    }
  }

  &__learn-more {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding: 1.2rem 1.3rem .75rem 1.15rem;
    background-color: #ebf1f7;

    p {
      @extend %regular;
      font-size: .8rem;
      line-height: 1;
      text-transform: uppercase;
    }

    .svg-icon {
      width: 7.45rem;
      height: auto;
    }
  }
}

@include respond-to(ipad-landscape) {
  .main-header {
    display: block;

    &__inner {
      margin: 0;
    }

    &__level--1 {
      display: flex;
    }
  }
}

@include respond-to(ipad-pro) {
  .main-header--compact {
    .logo {
      width: 7rem;
    }
  }
}

@include respond-to(desktop-smallest) {
  .main-header__level {
    padding-left: 2.2rem;
  }

  .main-header__level--1 {
    padding-top: 1.7rem;
    padding-bottom: .1rem;
  }

  .main-header--compact {
    .logo {
      width: 9rem;
    }
  }
}

@include respond-to(desktop-small) {
  .main-header--compact {
    transform: translate3d(0, -2.4rem, 0);

    .logo {
      width: 7rem;
    }
  }

  .main-header__level {
    padding-left: 1.3rem;
  }

  .main-header__level--1 {
    padding-top: 1.1rem;
    padding-bottom: 0;
  }

  .main-header__inner {

    &::after {
      bottom: -5px;
      height: 5px;
    }
  }
}

@include respond-to(desktop-large) {
  .main-header__inner {
  }
}
