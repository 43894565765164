.shortfact {
  display: flex;
  flex-basis: 50%;
  align-items: flex-end;
  margin: 0 0 1rem;

  &__icon {
    width: 2rem;
    height: 2rem;
    margin: -.2rem .5rem 0 0;
  }

  &__label {
    margin-bottom: -.1rem;
    font-size: .85rem;
  }
}

.shortfact_container {
  display: flex;
  flex-wrap: wrap;
  margin: .5rem 0 .2rem .7rem;
  padding-bottom: 1.35rem;
}

@include respond-to(ipad-portrait) {
  .shortfact {
    flex-basis: auto;
    margin: 0 0 0 2rem;
  }
}
