.contact-zip-search {
  width: 23rem;

  &__headline {
    @extend %bold;
    text-transform: none;
  }

  &__bodytext {
    width: 85%;
    margin-top: .8rem;
    font-size: .75rem;
    line-height: 145%;
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;

    &:hover {
      @extend .svg-icon--blue;
    }
  }

  .zip-filter {
    justify-content: flex-start;
  }

  &__loader {
    width: 40px;
    height: 40px;
    margin: 2rem auto;
  }
}
