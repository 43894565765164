.pull-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: color .3s ease;
  color: #000;
  font-size: .75rem;
  text-decoration: none;
  text-transform: uppercase;

  &__icon {
    width: .75rem;
    height: .75rem;
    margin-bottom: .6rem;
    transform: rotate(-90deg);
    transition: transform .2s ease;

    path {
      stroke-width: 6px;
      stroke: #000;
    }
  }

  &:hover {
    color: $blue;

    .pull-up__icon {
      transform: rotate(-90deg) translateX(3px);
    }
  }
}
