.mobile-header__inner-padding {
  padding-right: .7rem;
  padding-left: .7rem;
}

.mobile-header__container {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  background-color: $white;

  &--opened {
    &::after {
      background-color: $border-color;
      box-shadow: none;
    }

    .mobile-header__overlay {
      transform: translateX(0);
    }
  }

  &::after {
    @extend .main-header__inner, ::after;
    padding-bottom: 0;
    bottom: -5px;
    height: 5px;
    transition: background-color .3s ease-in;
  }
}

.mobile-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &__inner-container {
    height: 4rem;
    padding: 1.1rem 0 .5rem;
  }

  &__menu-button {
    margin-left: -.7rem;
    padding: .5rem;
    border: 0;
    background: transparent;
  }

  &__burger {
    @include burger($width: 35px, $height: 2px, $gutter: 6px, $border-radius: 2px, $transition-duration: .3s);
    padding-top: 0;
    padding-bottom: 0;
    text-indent: -9999px;

    &::after {
      right: 0;
      width: 26px;
    }
  }

  &__burger--to-cross {
    @include burger-to-cross();
    &::before {
      top: -8px;
    }

    &::after {
      top: 8px;
      width: 35px;
    }
  }

  &_logo {
    transform: translateY(-.45rem);
    line-height: 0;

    img {
      display: inline-flex;
      width: auto;
      height: 1.8rem;
    }
  }

  &__overlay-inner {
    position: relative;
    min-height: calc(100vh - 6.7rem);
    padding-bottom: 6.35rem;
  }

  &__overlay {
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-top: 4rem;
    padding: 2.64rem 0 0;
    overflow-y: scroll;
    transform: translateX(-100%);
    transition: transform .3s ease-in;
    background-color: $light-gray;

    &--opened {
      transform: translateX(0);
    }
  }

  &__meta {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.285rem;
    border-bottom: 1px solid $border-color;
  }

  &__tool-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__tool-item {
    margin: 0 .2rem;

    svg {
      width: 1.5rem;
    }
  }

  &__level-2 {
    margin-top: 2.45rem;
  }

  &__level-3 {
    margin-top: 1.85rem;

    .svg-icon {
      width: 1.5rem;
      margin-left: .6rem;
    }
  }

  &__breadcrumb {
    margin-top: 1.35rem;

    > span {
      display: inline-flex;
      align-items: flex-start;
      margin-right: .6rem;
      text-decoration: underline;
    }

    i {
      margin-right: .2rem;
      transform: rotateZ(180deg);
    }

    svg {
      width: .7rem;
    }
  }

  &__legal {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -1.3rem;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;

    a {
      margin-left: 1.3rem;
      color: $black;
    }
  }

  &__legal-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: darken($light-gray, 3%);
  }
}

.mobile-header__nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  padding: 0 .7rem;
  border-bottom: 1px solid $border-color;
  color: $black;
  font-size: 1.15rem;
  text-decoration: none;

  svg {
    width: 1.4rem;
  }

  &--main {
    height: 3.75rem;
    font-size: 1.25rem;
    text-transform: uppercase;

    span {
      @extend %bold;
      margin-top: .3rem;
    }
  }

  &--link {
    justify-content: inherit;
    padding: .75rem 0;
    border: 0;
    font-size: 1.125rem;
    text-transform: uppercase;

    i {
      margin-right: .6rem;
    }

    svg {
      width: 1.2rem;
    }
  }
}

.content-wrap {
  position: relative;
  margin-top: 0;
  left: 0;
  transition: left .3s ease-in;
}

.mobile-menu--opened {
  position: relative;
  overflow: hidden;

  .content-wrap {
   left: 60%;
  }
}

@include respond-to(ipad-landscape) {
  app-mobile-header {
    display: none;
  }

  .mobile-menu--opened {
    .content-wrap {
      left: 0;
    }
  }
}
