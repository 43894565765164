.section-headline {
  margin-bottom: 0;

  &.with-decor {
    margin-bottom: 0;
  }
}

.section-headline__container {
  padding-top: 1.5rem;
}
