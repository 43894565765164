app-audio-player {
  display: block;
  max-width: 100%;
}

.audio-player {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .audio-player__icon, .audio-player__play, .audio-player__pause {
    pointer-events: none;
  }

  &__container {
    width: 100%;
    padding: .7875rem 1.55rem;
    background-color: rgba(#d8d8d8, .36);
    cursor: pointer;
  }

  &__icon {
    width: 2.8125rem;
    height: 2.8125rem;

    svg {
      width: 2.8125rem;
      height: 2.8125rem;

      path {
        fill: #005093;
      }
    }
  }

  &__duration {
    margin-left: .6rem;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #005093;
  }

  &__title {
    position: relative;
    z-index: 2;
    width: 8rem;
    margin: 0 auto;
    font-size: .8125rem;
    line-height: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    color: #005093;
    pointer-events: none;

    &--container {
      position: relative;
      width: 13.5rem;
    }

    &.green {
      background-color: rgb(87, 215, 152);
    }

    &.lightblue {
      background-color: rgb(87, 215, 210);
    }

    &.yellow {
      background-color: rgb(215, 194, 87);
    }

    &.brown {
      background-color: rgb(187, 174, 155);
    }

    &.red {
      background-color: rgb(212, 118, 141);
    }

    &.orange {
      background-color: #EB927C;
    }
  }

  &__line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    -webkit-appearance: none;
    pointer-events: none;
    background: #005093;

    &::after {
      position: absolute;
      content: '\A';
      display: block;
      background-color: #005093;
    }

    &::after {
      top: -2px;
      right: 0;
      width: 4px;
      height: 4px;
      border-radius: 100%;
    }

    &--container {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 2px;
      height: .5rem;
      border: none;
      border-radius: 3px;
      background-color: #005093;
      cursor: pointer;
      margin-top: -1px;
    }

    &::-moz-range-thumb {
      width: 2px;
      border: none;
      height: .5rem;
      border-radius: 3px;
      background-color: #005093;
      cursor: pointer;
    }

    &::-ms-thumb {
      width: 2px;
      height: .5rem;
      border-radius: 3px;
      border: none;
      background-color: #005093;
      cursor: pointer;
    }
  }

  &__normal-line {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: #005093;

    &::before,
    &::after {
      position: absolute;
      content: '\A';
      display: block;
      background-color: #005093;
    }

    &::before {
      top: -.225rem;
      width: 2px;
      height: .5rem;
      border-radius: 4.5%;
    }

    &::after {
      top: -2px;
      right: 0;
      width: 4px;
      height: 4px;
      border-radius: 100%;
    }

    &--container {
      display: none;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
    }
  }

  progress {
    color: #005093;
  }

  &__timeline {
    &::-ms-fill {
      border: none;
    }

    &[value] {
      position: absolute;
      z-index: 3;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      top: -.375rem;
      left: 0;
      width: 100%;
      height: .75rem;
      background-color: transparent;
      appearance: none;


      &::-webkit-progress-value {
        background-color: #005093;
      }

      &::-webkit-progress-bar {
        background-color: transparent;
      }
    }
  }

  &__play {
    box-sizing: border-box;
    width: .875rem;
    height: 1rem;
    margin-right: .57rem;
    margin-left: .64rem;
    border-style: solid;
    border-width: .5rem 0 .5rem .875rem;
    border-color: transparent transparent transparent #005093;
    cursor: pointer;
  }

  &__pause {
    width: .875rem;
    height: 1rem;
    margin-right: .57rem;
    margin-left: .64rem;
    border-style: double;
    border-width: 0 0 0 .5rem;
    border-color: #005093;
    cursor: pointer;
  }
}

@include respond-to(ipad-portrait) {
  .audio-player {
    &__container {
      padding: 1.5rem 1.55rem;
    }

    &__pause {
      width: .9rem;
    }

    &__play {
      width: .9rem;
    }

    &__play,
    &--pause {
      height: .75rem;
    }

    &__icon {
      width: 1.79rem;
      height: 1.79rem;

      svg {
        width: 1.79rem;
        height: 1.79rem;
      }
    }

    &__title {
      width: 7.36rem;
      font-size: .714rem;
    }

    &__duration {
      margin-left: .95rem;
      font-size: .86rem;
    }
  }
}

@include respond-to(desktop-smallest) {
  .audio-player {
    &__container {
      padding: 1.9rem 2.2rem 1.75rem 2.25rem;
    }

    &__icon {
      width: 2.5rem;
      height: 2.5rem;

      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    &__title {
      width: 6.15rem;
      font-size: .6rem;
    }

    &__play,
    &__pause {
      margin-right: .6rem;
      margin-left: 1.15rem;
    }

    &__duration {
      font-size: 1rem;
    }
  }
}

@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .audio-player__normal-line--container {
    display: block;
  }

  .audio-player__line--label {
    display: none;
  }
}
