.hr-menu {
  position: absolute;
  z-index: 4;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  top: 0;
  left: -110%;
  transition: left .5s ease-in-out;
  width: 100%;
  height: 100%;
  padding: 4.5rem 1.575rem 1.85rem;
  background-color: #fff;
  pointer-events: auto;

  ul {
    margin-top: auto;

    li {
      margin-bottom: .6rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__link {
    font-size: 1.5rem;
    line-height: 1.11;
    color: $blue;
    text-transform: uppercase;
    text-decoration: none;

    span {
      @extend %bold;
      display: inline-block;
      margin-left: 1.5rem;

      &::after {
        content: '';
        display: block;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: transform 250ms ease-in-out;
        border-bottom: solid 3px $blue;
      }
    }

    .svg-icon {
      position: absolute;
      margin-top: -.15rem;
      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    &:hover span::after {
      transform: scaleX(1);
    }

    &--active {
      span::after {
        transform: scaleX(1);
      }
    }

    &--career {
      margin-top: 1.5rem;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: $blue;
      text-transform: uppercase;
      text-decoration: none;

      span {
        display: inline-block;
        margin-left: 1.5rem;

        &::after {
          content: '';
          display: block;
          transform: scaleX(0);
          transform-origin: 0 50%;
          transition: transform 250ms ease-in-out;
          border-bottom: solid 2px $blue;
        }
      }

      .svg-icon {
        position: absolute;
        width: 1rem;
        height: 1rem;
        margin-top: .25rem;

        svg {
          width: 1rem;
          height: 1rem;
        }
      }

      &:hover span::after {
        transform: scaleX(1);
      }
    }
  }

  &__container {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &--big {
      height: 35.4rem;
    }
  }

  &--active {
    left: 0;
  }

  &__burger {
    position: relative;
    z-index: 4;
    width: 2.3rem;
    height: 1.25rem;
    margin-top: auto;
    margin-right: 1.3rem;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      right: 0;
      width: 100%;
      height: .175rem;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      border-radius: 3px;
      opacity: 1;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: .5rem;
      }

      &:nth-child(3) {
        top: 1rem;
      }
    }

    &--white {
      span {
        background: #fff;
      }
    }

    &--blue {
      span {
        background: $blue;
      }
    }

    &:hover {
      span:nth-child(1) {
        width: 50%;
      }

      span:nth-child(2) {
        width: 75%;
      }
    }
  }

  &__burger-open {
    span {
      &:nth-child(1) {
        top: .5rem;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        top: .5rem;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
    &:hover {
      span:nth-child(1) {
        width: 100%;
      }
    }
  }
}

@include respond-to(ipad-portrait) {
  .hr-menu {
    padding: 4.5rem 2.2rem 1.85rem;

    &__link {
      font-size: 1.8rem;

      &--career {
        margin-top: 2.7rem;
      }
    }

    ul {
      li {
        margin-bottom: 1.225rem;
      }
    }
  }
}

@include respond-to(ipad-pro) {
  .hr-menu {

    &__link {
      font-size: 2.25rem;
    }
  }
}
