@import url("//hello.myfonts.net/count/32186d");


@font-face {
  font-family: 'Regular';
  font-display: swap;
  src: url('../webfonts/32186D_0_unhinted_0.woff2') format('woff2'), url('../webfonts/32186D_0_unhinted_0.woff') format('woff'), url('../webfonts/32186D_0_unhinted_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Bold';
  font-display: swap;
  src: url('../webfonts/32186D_1_unhinted_0.woff2') format('woff2'), url('../webfonts/32186D_1_unhinted_0.woff') format('woff'), url('../webfonts/32186D_1_unhinted_0.ttf') format('truetype');
}



%regular {
  font-family: 'Regular', Helvetica, Arial, sans-serif;
  font-weight: normal;
}

%bold {
  font-family: 'Bold', Helvetica, Arial, sans-serif;
  font-weight: normal;
}

%bodytext {
  font-size: .85rem;
  line-height: 130%;
}

* { @extend %regular; }

h1,
.like-h1 {
  font-size: 1.4rem;
  text-transform: uppercase;
}

h2,
.like-h2 {
  font-size: 1rem;
  text-transform: uppercase;
}

h3,
.like-h3 {
  font-size: 1.1rem;
  text-transform: uppercase;
}

h4,
.like-h4 {
  font-size: 1.1rem;
}

h5,
.like-h5 {
  font-size: .888rem;
  text-transform: uppercase;
}

h6,
.like-h6 {
  margin: 0 0 2.5rem;
  font-size: 1rem;
  text-transform: uppercase;

  &.with-decor {
    @extend .inner-wrap;
    display: flex;
    width: 85%;
    align-items: center;
    height: 1rem;
    margin-bottom: 2.05rem;

    &::before,
    &::after {
      content: '\A';
      display: block;
      flex-grow: 1;
      width: auto;
      height: 1px;
    }

    &::before {
      margin-right: 1rem;
      background: linear-gradient(to right, rgba(#c8c8c8, 0%), rgba(#c8c8c8, .8));
    }

    &::after {
      margin-left: 1rem;
      background: linear-gradient(to left, rgba(#c8c8c8, 0%), rgba(#c8c8c8, .8));
    }
  }
}

img {
  width: 100%;
  height: auto;
}


.centered-headline {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.center {
  text-align: center;
}

.color--blue {
  color: $blue;
}

.color--gray {
  color: $gray;
}


strong {
  @extend %bold;
  font-weight: normal;
}

small {
  font-size: 100%;
  text-transform: none;
}

sup {
  top: -1em;
  font-size: .5em;
}



@include respond-to(ipad-landscape) {
  h1,
  .like-h1 {
    font-size: 2rem;
  }

  h3,
  .like-h3 {
    font-size: 1.45rem;
  }

  .centered-headline {
    width: 65%;
  }
}
