.homepage__label {
  @extend %bold;
  display: none;
}

.homepage__icon {
  display: none;
  width: 1.065rem;
  height: 1rem;
  height: initial;
  margin: 0 -.2rem 0 0;
  line-height: 1.45rem;
}

@include respond-to(desktop-smallest) {
  .homepage__label {
    display: block;
  }

  .homepage__icon {
    display: block;
    margin-right: .6rem;
  }
}

@include respond-to(desktop-small) {
  .homepage__icon {
    width: .9rem;
    height: .9rem;
    height: initial;
    line-height: .75rem;
  }
}
